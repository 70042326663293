var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Fragment, useCallback, useEffect, useMemo, useReducer, } from 'react';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'moment-timezone';
import RoomAvailabilityToolbar, { RANGE, } from './RoomAvailabilityToolbar/RoomAvailabilityToolbar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import EventComponent from './CustomEvent/EventComponent';
import LoaderContainer from '../LoaderContainer/LoaderContainer';
import useWindowDimensions from '../WindowDimensions/WindowDimensions';
import RoomAvailabilityEventModal from '../Modal/Room/RoomAvailabilityModal/RoomAvailabilityEventModal/RoomAvailabilityEventModal';
import { CreateEventCalendarModal } from '../Modal/Room/RoomAvailabilityModal/CreateEventCalendarModal/CreateEventCalendarModal';
import { AVAILABILITY_ACTIONS, roomAvailabilityReducer, } from './RoomAvailabilityReducer/RoomAvailabilityReducer';
import { getWeekRange } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { closeRoomAvailability, loadRoomEvents } from '../../../actions';
const locales = {
    'en-US': enUS,
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});
const initialState = {
    eventModal: null,
    range: RANGE.WEEK,
    viewState: Views.WEEK,
    selectedSlot: null,
    selectedDay: new Date(),
    dateWeeklyRange: getWeekRange(new Date()),
};
const RoomAvailability = ({ room, onCloseModal }) => {
    const [state, dispatch] = useReducer(roomAvailabilityReducer, initialState);
    const { isLoading, events } = useSelector(state => state.roomAvailability);
    const { eventModal, viewState, selectedSlot, selectedDay, dateWeeklyRange, } = state;
    const reduxDispatch = useDispatch();
    const { height } = useWindowDimensions();
    const { scrollToTime, formats } = useMemo(() => ({
        scrollToTime: moment()
            .set({ h: 7, m: 0 })
            .toDate(),
        formats: {
            eventTimeRangeFormat: () => {
                return null;
            },
        },
    }), []);
    useEffect(() => {
        if (room) {
            const selectedDay = new Date();
            dispatch({ type: AVAILABILITY_ACTIONS.SET_SELECTED_DAY, selectedDay });
        }
        else {
            reduxDispatch(closeRoomAvailability);
        }
    }, [room]);
    useEffect(() => {
        function fetchEvents(range) {
            return __awaiter(this, void 0, void 0, function* () {
                reduxDispatch(loadRoomEvents(room.roomId, range));
            });
        }
        if (room && dateWeeklyRange) {
            fetchEvents(dateWeeklyRange);
        }
    }, [dateWeeklyRange]);
    const handleSelectSlot = useCallback(({ start, end }) => {
        const selectedSlot = { startDate: start, endDate: end };
        dispatch({ type: AVAILABILITY_ACTIONS.SET_SELECTED_SLOT, selectedSlot });
    }, []);
    const handleSelectEvent = useCallback(event => dispatch({ type: AVAILABILITY_ACTIONS.SHOW_SELECTED_EVENT_MODAL, event }), []);
    function handleDateChange(date) {
        if (date) {
            const selectedDay = moment(date).toDate();
            dispatch({ type: AVAILABILITY_ACTIONS.SET_SELECTED_DAY, selectedDay });
        }
    }
    function renderModals() {
        return (React.createElement(Fragment, null,
            React.createElement(CreateEventCalendarModal, { isOpen: !!selectedSlot, data: selectedSlot, room: room, calendarId: room ? room.calendarId : undefined, onClose: close => {
                    dispatch({ type: AVAILABILITY_ACTIONS.RESET_SELECTED_SLOT });
                    if (close && close.closeParent) {
                        onCloseModal();
                    }
                } }),
            React.createElement(RoomAvailabilityEventModal, { event: eventModal, onClose: () => dispatch({ type: AVAILABILITY_ACTIONS.HIDE_SELECTED_EVENT_MODAL }) })));
    }
    let calendar;
    if (isLoading) {
        calendar = React.createElement(LoaderContainer, null);
    }
    else {
        calendar = (React.createElement(Calendar, { components: {
                toolbar: RoomAvailabilityToolbar,
                timeGutterHeader: () => (React.createElement("div", { className: "has-text-white" }, "12:00 AM")),
                event: EventComponent,
            }, tooltipAccessor: null, endAccessor: ({ end }) => {
                if (end) {
                    return new Date(end.getTime() - 1);
                }
            }, style: { height: 0.75 * height }, date: selectedDay, views: [Views.WEEK, Views.DAY], showMultiDayTimes: true, defaultView: viewState, events: events, localizer: localizer, onSelectEvent: handleSelectEvent, onSelectSlot: handleSelectSlot, selectable: true | 'ignoreEvents', scrollToTime: scrollToTime, onRangeChange: rangeArray => dispatch({ type: AVAILABILITY_ACTIONS.SET_RANGE, rangeArray }), onNavigate: handleDateChange, formats: formats }));
    }
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: isLoading ? 'is-loading' : null },
            React.createElement("section", null, calendar)),
        renderModals()));
};
export default RoomAvailability;
