import React from 'react';
import progressBarStatus from '../../../enums/progressBarStatus.enum';
const renderColor = status => {
    switch (status) {
        case progressBarStatus.SUCCESS:
            return 'is-success';
        case progressBarStatus.ERROR:
            return 'is-danger';
        case progressBarStatus.INFO:
        default:
            return 'is-info';
    }
};
const ProgressBar = ({ status, value, max }) => {
    return (React.createElement("progress", { className: `progress ${renderColor(status)}`, value: value, max: max }));
};
export default ProgressBar;
