var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import RoomsFeaturesBulkEditForm from './RoomsFeaturesBulkEditForm/RoomsFeaturesBulkEditForm';
import { Accordion, Table } from '../../index';
import { roomsActions } from '../../../actions';
import ReactDOM from 'react-dom';
import { featureActionsConstants as bulkAction } from '../../../constants/roomsBulk.constants';
import { getColumns, toRoomListRowDto, } from '../../../utils/RoomsUtils/RoomsBulkTableUtils/roomsBulkFeatures.utils';
class RoomsFeaturesBulkEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rooms: this.props.rooms,
            lastChangeRooms: 0,
            lastRoomsState: false,
            lastAction: null,
        };
    }
    addHandler(featuresIds, resetForm, featureAction) {
        return __awaiter(this, void 0, void 0, function* () {
            const { bulkRoomFeaturesAdd } = this.props;
            const isSuccessful = this.onRoomsUpdate(yield bulkRoomFeaturesAdd(featuresIds, this.props.selectedRoomsIds), featureAction);
            if (isSuccessful) {
                this.resetForm(resetForm);
            }
        });
    }
    removeHandler(featuresIds, resetForm, featureAction) {
        return __awaiter(this, void 0, void 0, function* () {
            const { bulkRoomFeaturesRemove } = this.props;
            const isSuccessful = this.onRoomsUpdate(yield bulkRoomFeaturesRemove(featuresIds, this.props.selectedRoomsIds), featureAction);
            if (isSuccessful) {
                this.resetForm(resetForm);
            }
        });
    }
    undoHandler(resetForm) {
        return __awaiter(this, void 0, void 0, function* () {
            const { rooms } = this.state;
            const { bulkRoomFeaturesUndo } = this.props;
            let oldRoomsState = [...rooms];
            oldRoomsState = oldRoomsState.map(r => r.oldState);
            const isSuccessful = this.onRoomsUpdate(yield bulkRoomFeaturesUndo(oldRoomsState), bulkAction.UNDO);
            if (isSuccessful) {
                this.resetForm(resetForm);
            }
        });
    }
    onRoomsUpdate(updatedRooms, featureAction) {
        if (updatedRooms && !(updatedRooms instanceof Error)) {
            const roomsFreshState = updatedRooms.map(room => {
                return toRoomListRowDto(room);
            });
            if (featureAction === bulkAction.UNDO) {
                this.setState({
                    rooms: roomsFreshState,
                    lastRoomsState: false,
                    lastAction: bulkAction.UNDO,
                });
            }
            else {
                this.setState({
                    rooms: roomsFreshState,
                    lastRoomsState: true,
                    lastAction: featureAction,
                });
            }
            return true;
        }
        return false;
    }
    resetForm(resetForm) {
        this.cleanFieldManually('.bulk-features');
        resetForm();
    }
    cleanFieldManually(fieldClassName) {
        const node = ReactDOM.findDOMNode(this);
        const firstTableHeader = node.querySelector(fieldClassName);
        const clearActionField = firstTableHeader.querySelector('.rs__clear-indicator');
        this.simulateMouseClick(clearActionField);
    }
    simulateMouseClick(element) {
        if (element) {
            const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
            mouseClickEvents.forEach(mouseEventType => element.dispatchEvent(new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1,
            })));
        }
    }
    render() {
        const { t } = this.props;
        const { lastAction, lastRoomsState, rooms } = this.state;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "header-panel" },
                    React.createElement(Accordion, { textShow: t('ROOM_FEATURES'), textHide: t('ROOM_FEATURES'), isOpen: true, className: "accordion-section-title" },
                        React.createElement(RoomsFeaturesBulkEditForm, { onAdd: this.addHandler.bind(this), onRemove: this.removeHandler.bind(this), onUndo: this.undoHandler.bind(this), lastRoomsState: lastRoomsState }),
                        React.createElement(Table, { data: rooms, columns: getColumns(lastAction), showPaginationTop: false, showPaginationBottom: false }))))));
    }
}
const mapDispatchToProps = dispatch => ({
    bulkRoomFeaturesUndo: rooms => dispatch(roomsActions.bulkRoomFeaturesUndo(rooms)),
    bulkRoomFeaturesAdd: (featureIds, roomIds) => dispatch(roomsActions.bulkRoomFeaturesAdd(featureIds, roomIds)),
    bulkRoomFeaturesRemove: (featureIds, roomIds) => dispatch(roomsActions.bulkRoomFeaturesRemove(featureIds, roomIds)),
});
export default withNamespaces('translations')(connect(null, mapDispatchToProps)(RoomsFeaturesBulkEdit));
