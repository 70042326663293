import Yup from '../yup';
const code = Yup.string()
    .required('SITE_CODE_IS_REQUIRED')
    .matches(/^[A-Z0-9]+$/, 'SITES_CODE_UPPERCASE_ALPHANUMERIC');
const name = Yup.string().required('SITE_NAME_REQUIRED');
const description = Yup.string().required('SITE_DESCRIPTION_REQUIRED');
const catering = Yup.string()
    .url('SITE_CATERING_SHOULD_BE_URL')
    .nullable();
const city = Yup.string().required('SITE_CITY_REQUIRED');
const countryCode = Yup.string().required('SITE_COUNTRY_CODE_REQUIRED');
const latitude = Yup.string()
    .nullable()
    .label('LATITUDE');
const longitude = Yup.string()
    .nullable()
    .label('LONGITUDE');
const timezone = Yup.string().required('SITE_TIME_ZONE_REQUIRED');
const SitesSchema = Yup.object().shape({
    code,
    name,
    description,
    catering,
    city,
    countryCode,
    latitude,
    longitude,
    timezone,
});
export { SitesSchema };
