import { sitesConstants } from '../constants';
export const sitesState = {
    sites: [],
    site: null,
    sitesError: null,
    sitesLoading: false,
    pageNumber: 0,
    pageSize: 0,
    totalPages: 1,
    sort: [],
    totalElements: 0,
};
const sites = (state = sitesState, action) => {
    switch (action.type) {
        case sitesConstants.FETCH_SITES:
            return Object.assign(Object.assign({}, state), { sites: [...action.sites], pageNumber: action.pageNumber, pageSize: action.pageSize, sort: action.sort, totalPages: action.totalPages, sitesError: null, sitesLoading: false, totalElements: action.totalElements });
        case sitesConstants.FETCH_SINGLE_SITE:
            return Object.assign(Object.assign({}, state), { site: action.site, sitesError: null, sitesLoading: false });
        case sitesConstants.ERROR_SITES:
            return Object.assign(Object.assign({}, state), { sitesError: action.error, sitesLoading: false });
        case sitesConstants.LOADING_SITES:
            return Object.assign(Object.assign({}, state), { sitesLoading: true });
        default:
            return state;
    }
};
export default sites;
