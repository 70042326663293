import React from 'react';
import PropTypes from 'prop-types';
const tagStyle = {
    margin: 0,
    paddingTop: 20,
};
const TagsWrappedList = props => {
    const { names } = props;
    let wrappedNames = null;
    if (names && names.length > 0) {
        wrappedNames = (React.createElement("div", { className: "tags are-medium is-centered", style: tagStyle }, names.map((item, i) => {
            return (React.createElement("span", { className: "tag", key: i }, item));
        })));
    }
    return wrappedNames;
};
export default TagsWrappedList;
TagsWrappedList.propTypes = {
    names: PropTypes.array,
};
