import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { Button } from '../index';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import ErrorPage from './ErrorPage';
import i18n from '../../i18n';
import { withRouter } from 'react-router-dom';
class Error404Page extends Component {
    render() {
        const { location } = this.props;
        let { title, text, backLinkPath } = location.state
            ? location.state
            : this.props;
        if (typeof text === 'object') {
            var sub_msg = (React.createElement("p", { className: "not-found" },
                text.msg,
                " ",
                React.createElement("a", { href: `mailto:${text.email}` },
                    text.email,
                    ".")));
        }
        return (React.createElement(ErrorPage, { title: title ? title : i18n.t('ERROR_TITLE') },
            sub_msg ? (sub_msg) : (React.createElement(Trans, { defaults: "ERROR_404_TEXT", values: { type: text }, components: [React.createElement("p", null, "univers"), React.createElement("p", null, "univers")] })),
            React.createElement(Button, { to: backLinkPath, icon: React.createElement(FAIcon, { icon: ['fas', 'arrow-left'] }), className: "is-text back-link", text: i18n.t('GO_BACK_TO_LIST') })));
    }
}
ErrorPage.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    backLinkPath: PropTypes.string,
};
export default withRouter(Error404Page);
