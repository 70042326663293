import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers';
import { userActions } from './actions';
import { errorConstants } from './constants';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const authMiddleware = state => next => action => {
    if (action.type === errorConstants.ERROR &&
        action.data.error.response &&
        action.data.error.response.status === 401) {
        state.dispatch(userActions.handleUnauthorized());
    }
    else {
        next(action);
    }
};
const middleware = [thunk, authMiddleware];
if (process.env.NODE_ENV === 'development')
    middleware.push(logger);
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));
export default store;
