import AsyncReactSelect from '../Select/AsyncReactSelect/AsyncReactSelect';
import React, { Component } from 'react';
import { components } from 'react-select';
import { userSearchActions } from '../../../actions';
const UserOption = props => (React.createElement(components.Option, Object.assign({}, props),
    React.createElement("div", { className: "media" },
        React.createElement("div", { className: "media-content" },
            React.createElement("div", { className: "content" },
                React.createElement("strong", null, props.data.fullName),
                React.createElement("br", null),
                props.data.email)))));
class UserOrGroupSearch extends Component {
    constructor() {
        super(...arguments);
        this.searchUsersOrGroups = (q = '') => {
            return userSearchActions.fetchUsersOrGroups(this.props.size, q);
        };
    }
    render() {
        return (React.createElement(AsyncReactSelect, Object.assign({ getOptionLabel: e => e.fullName ? `${e.fullName} (${e.email})` : e.email, getOptionValue: e => e.email, loadOptions: this.searchUsersOrGroups, components: { Option: UserOption }, openMenuOnClick: false, onChangeSelect: this.props.onChange }, this.props)));
    }
}
class AdminSearch extends Component {
    constructor() {
        super(...arguments);
        this.searchAdmins = (email = '') => {
            return userSearchActions.fetchAdmins(email);
        };
    }
    render() {
        return (React.createElement(AsyncReactSelect, Object.assign({ getOptionLabel: e => e.fullName ? `${e.fullName} (${e.email})` : e.email, getOptionValue: e => e.email, loadOptions: this.searchAdmins, components: { Option: UserOption }, openMenuOnClick: false, onChangeSelect: this.props.onChange }, this.props)));
    }
}
class UserSearch extends Component {
    constructor() {
        super(...arguments);
        this.searchUsers = (q = '') => {
            return userSearchActions.fetchUsers(this.props.size, q);
        };
    }
    render() {
        return (React.createElement(AsyncReactSelect, Object.assign({ getOptionLabel: e => e.fullName ? `${e.fullName} (${e.email})` : e.email, getOptionValue: e => e.email, loadOptions: this.searchUsers, components: { Option: UserOption }, openMenuOnClick: false }, this.props)));
    }
}
export { UserSearch, UserOrGroupSearch, AdminSearch };
