import React from 'react';
import { ConfirmModal } from '../../../../index';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import TagsWrappedList from '../../../TagsWrappedList/TagsWrappedList';
const CancelMaintenanceModal = props => {
    const { t, names, isOpen, onCancel, onClose } = props;
    let confirmText = React.createElement("p", null, t('MAINTENANCE_CANCEL_FOR_ROOM'));
    if (names && names.length > 1) {
        confirmText = React.createElement("p", null, t('MAINTENANCE_CANCEL_FOR_ROOMS'));
    }
    return (React.createElement(ConfirmModal, { title: t('MAINTENANCE_CANCEL'), text: React.createElement("div", null,
            confirmText,
            React.createElement(TagsWrappedList, { names: names })), onClose: () => {
            onClose();
        }, isOpen: isOpen, primaryButton: {
            label: t('MAINTENANCE_CANCEL'),
            type: 'is-primary',
            action: onClose => {
                onCancel();
                onClose();
            },
            icon: React.createElement(FAIcon, { icon: ['fas', 'undo'] }),
        }, secondaryButton: {
            label: t('MAINTENANCE_LEAVE'),
            type: 'is-light',
            action: onClose => onClose(),
        } }));
};
export default CancelMaintenanceModal;
