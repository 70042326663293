var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { roomsActions, roomsUploadActions } from '../../actions';
import { Trans, withNamespaces } from 'react-i18next';
import { Field, Form, Formik, getIn } from 'formik';
import classnames from 'classnames/bind';
import i18n from '../../i18n';
import { BackToList, Button, ConfirmModal, ErrorMsg, FormButtons, FormControl, FormErrorNotification, FormSection, LoaderContainer, MediaUploader, ReactSelect, SiteBuildingFloorSelect, TitleSection, ToggleInfo, Translations, UserOrGroupSearch, } from '../';
import { RoomsSchema } from '../../validations';
import { appConstants, errorConstants, errorTypesConstants as err, routes, } from '../../constants';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { formatRoute } from 'react-router-named-routes';
import { languages } from '../../enums';
import api from '../../api';
import withErrorHandling from '../Common/WithErrorHandling/withErrorHandling';
import ClickableLinks from '../Common/ClickableLinks/ClickableLinks';
import FeaturesInput from '../Common/FeaturesInput/FeaturesInput';
import { statusConstants } from '../../constants';
import { updateErrorMessagesOnLanguageChange } from '../../utils/formik.utils';
import { roomImageTypeConstants } from '../../constants/roomImageType.constants';
class RoomEdit extends Component {
    constructor(props) {
        super(props);
        this.emptyFormState = {
            name: '',
            siteCode: '',
            buildingId: '',
            floorId: '',
            floorSection: '',
            restrictedAccess: false,
            status: statusConstants.ACTIVE,
            googleOwnerVisible: true,
            contactInformationVisible: true,
            capacity: '',
            typeId: '',
            localDescription: '',
            translations: [],
            owner: '',
            contactInformation: '',
            privacy: false,
            googleOwner: '',
            featureIds: [],
            images: [],
            clickableLinks: [],
        };
        this.state = {
            isAdditionalFeaturesOnDemand: false,
            isRoomRearrangementOnDemand: false,
            isCateringOnDemand: false,
            isRestrictedAccess: false,
            isBookingConfirmation: false,
            isReady: false,
            roomTypes: [],
            itemToEdit: null,
            itemToAdd: null,
            itemConflict: false,
            accessDenied: false,
            isFeaturesModalOpen: false,
            selectedFeatures: [],
        };
        this.fetchFeatures = (searchQuery = '') => __awaiter(this, void 0, void 0, function* () {
            const res = yield api.Features.search(searchQuery);
            return res.data;
        });
        this.fetchRoomTypes = (searchQuery = '') => __awaiter(this, void 0, void 0, function* () {
            const res = yield api.RoomTypes.search(searchQuery);
            return res.data;
        });
        const editId = props.match.params.id;
        if (!isNaN(editId)) {
            this.id = parseInt(editId);
        }
    }
    isEdit() {
        return routes.roomEdit === this.props.match.path;
    }
    isDuplicate() {
        return routes.roomDuplicate === this.props.match.path;
    }
    isDataToFetch() {
        return this.id !== undefined;
    }
    isFetchedReady() {
        return this.isDataToFetch() && this.state.isReady;
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.loadData();
        });
    }
    componentWillUnmount() {
        i18n.off('languageChanged');
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            const { loadRoom } = this.props;
            this.setState({
                roomTypes: Object.values(yield this.fetchRoomTypes()),
            });
            if (this.isDataToFetch()) {
                try {
                    yield loadRoom({ id: this.id });
                    this.setState({
                        isReady: true,
                        selectedFeatures: this.props.room.features,
                    });
                }
                catch (error) {
                    const { t, history, handleError404, roomsError } = this.props;
                    if (roomsError && roomsError.type === err.LOAD_RESOURCE) {
                        handleError404({
                            text: {
                                msg: t('ERROR_404_ROOM'),
                                email: appConstants.DEFAULT_CONTACT_EMAIL,
                            },
                            backLinkPath: this.buildRoomsListUrl(),
                        });
                    }
                    else {
                        history.push(this.buildRoomsListUrl());
                    }
                }
            }
            else {
                this.setState({
                    isReady: true,
                });
            }
        });
    }
    getInitialValues() {
        return this.isDataToFetch() ? this.getRoomValues() : this.emptyFormState;
    }
    getRoomValues() {
        if (this.isEdit()) {
            return this.props.room;
        }
        else {
            const room = Object.assign({}, this.props.room);
            return Object.assign(Object.assign({}, room), { status: statusConstants.ACTIVE });
        }
    }
    getInitialIsValid() {
        return this.isDuplicate();
    }
    getRoomTitle(room, t) {
        return `${room.name}  (${this.isEdit() ? t('EDIT_ROOM') : t('DUPLICATE_ROOM')})`;
    }
    saveImages(images, room) {
        return __awaiter(this, void 0, void 0, function* () {
            const { saveRoomImages } = this.props;
            const formData = new FormData();
            const imageTypeParams = new URLSearchParams();
            images.forEach(img => {
                formData.append('images', img, img.name);
                const type = img.imageType
                    ? img.imageType
                    : roomImageTypeConstants.PICTURE;
                imageTypeParams.append(img.name, type);
            });
            saveRoomImages(room, formData, imageTypeParams);
        });
    }
    onSubmit(values, { setSubmitting }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { addRoom, updateRoom, history } = this.props;
            values.clickableLinks = this.setOrderOfLinks(values);
            values.availableFrom = new Date(values.availableFrom).toJSON();
            values.availableUntil = new Date(values.availableUntil).toJSON();
            const savedRoom = this.isEdit()
                ? yield updateRoom(this.id, values)
                : yield addRoom(values);
            if (savedRoom && !(savedRoom instanceof Error)) {
                this.saveImages(values.images, savedRoom);
            }
            setSubmitting(false);
            if (!(savedRoom instanceof Error)) {
                const modal = this.isEdit()
                    ? { itemToEdit: Object.assign({}, values) }
                    : { itemToAdd: Object.assign(Object.assign({}, values), { id: savedRoom.id }) };
                this.setState(modal);
            }
            else if (savedRoom.response.data.reason === errorConstants.ROOM_NOT_FOUND_UPDATE ||
                savedRoom.response.data.reason === errorConstants.ROOM_NOT_FOUND_DELETE) {
                history.push({
                    pathname: this.buildRoomsListUrl(),
                    state: { errorModal: savedRoom.response.data.reason },
                });
            }
            else if (savedRoom.response.data.reason === errorConstants.ACCESS_DENIED) {
                this.setState({ accessDenied: true });
            }
            else if (savedRoom.response.data.reason === errorConstants.CONFLICT) {
                this.loadData();
                this.setState({ itemConflict: true });
            }
        });
    }
    setOrderOfLinks(values) {
        return values.clickableLinks.map((c, i) => {
            return Object.assign(Object.assign({}, c), { positionNumber: i });
        });
    }
    renderModals() {
        const { itemToEdit, itemToAdd, itemConflict, accessDenied } = this.state;
        const { t, history } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(ConfirmModal, { title: t('ADD_ROOM'), text: t('MODAL_ADD_ROOM_CONFIRM', {
                    name: itemToAdd ? itemToAdd.name : '',
                }), onClose: () => {
                    this.setState({ itemToAdd: null });
                    history.push(this.buildRoomsListUrl());
                }, isOpen: itemToAdd != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                }, secondaryButton: {
                    label: t('SEE_ROOM_PREVIEW'),
                    type: 'is-light',
                    action: () => {
                        history.push(formatRoute(routes.roomDetails, {
                            id: itemToAdd.id,
                        }));
                    },
                } }),
            React.createElement(ConfirmModal, { title: t('EDIT'), text: React.createElement(Trans, { defaults: "MODAL_EDIT_ROOM_CONFIRM", values: {
                        name: itemToEdit ? itemToEdit.name : '',
                    }, components: [React.createElement("p", null, "univers")] }), onClose: () => {
                    this.setState({ itemToEdit: null });
                    history.push(this.buildRoomsListUrl());
                }, isOpen: itemToEdit != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                }, secondaryButton: {
                    label: t('SEE_ROOM_PREVIEW'),
                    type: 'is-light',
                    action: () => {
                        history.push(formatRoute(routes.roomDetails, {
                            id: itemToEdit.id,
                        }));
                    },
                } }),
            React.createElement(ConfirmModal, { title: t('ACCESS_DENIED'), text: t('ACCESS_DENIED_LOST_RIGHTS'), onClose: () => history.push(routes.logout), isOpen: accessDenied, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { title: t('CONFLICT'), text: t('ROOM_EDIT_CONFLICT'), onClose: () => this.setState({ itemConflict: false }), isOpen: itemConflict, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } })));
    }
    buildRoomsListUrl() {
        return routes.rooms + this.props.listParams;
    }
    render() {
        const { t, room, roomsError } = this.props;
        const { selectedFeatures } = this.state;
        if (this.isFetchedReady()) {
            room.featureIds = room.features ? room.features.map(f => f.id) : [];
            room.typeId = room.type.id;
        }
        return (React.createElement(Fragment, null,
            React.createElement(TitleSection, { title: this.isFetchedReady() ? this.getRoomTitle(room, t) : t('ADD_ROOM'), isNarrow: false }),
            React.createElement(LoaderContainer, { className: "section" }, this.state.isReady && (React.createElement("div", { className: "container" },
                React.createElement("div", { className: "header-panel" },
                    React.createElement("div", { className: "buttons" },
                        React.createElement(BackToList, { to: this.buildRoomsListUrl(), t: t }))),
                React.createElement("div", { className: "content" },
                    React.createElement(Formik, { isInitialValid: this.getInitialIsValid(), enableReinitialize: true, initialValues: this.getInitialValues(), validationSchema: RoomsSchema, onSubmit: (values, actions) => {
                            values.featureIds = selectedFeatures.map(f => f.id);
                            this.onSubmit.bind(this)(values, actions);
                        } }, form => {
                        const { isSubmitting, values, handleChange, errors, touched, setFieldValue, setFieldError, setFieldTouched, } = form;
                        updateErrorMessagesOnLanguageChange(errors, setFieldError, setFieldTouched);
                        return (React.createElement(Form, null,
                            this.isEdit() && !room.synchronized && (React.createElement("div", { className: "notification is-danger" }, t('ROOM_SYNCHRONIZATION'))),
                            this.isEdit() && (React.createElement("div", { className: "notification is-warning" }, t('ROOM_EDIT_SYNC_WARNING', { name: values.name }))),
                            React.createElement(FormErrorNotification, Object.assign({ t: t, error: roomsError, schema: RoomsSchema }, form)),
                            React.createElement("p", null, t('REQUIRED_FIELDS_TEXT')),
                            React.createElement(FormSection, { title: t('BASIC_INFORMATION') },
                                React.createElement("div", { className: "columns" },
                                    React.createElement("div", { className: "column is-4" },
                                        React.createElement(FormControl, { label: t('SHORT_NAME'), required: true },
                                            React.createElement(Field, { className: classnames('input', {
                                                    'is-danger': errors.name && touched.name,
                                                }), type: "text", placeholder: t('FIELD_ROOM_NAME_PLACEHOLDER'), name: "name", value: values.name, onChange: e => {
                                                    handleChange(e);
                                                } }),
                                            React.createElement(ErrorMsg, { name: "name" })))),
                                React.createElement(Field, { component: SiteBuildingFloorSelect }),
                                !this.isEdit() && (React.createElement("div", { className: "columns" },
                                    React.createElement("div", { className: "column is-8" },
                                        React.createElement(FormControl, { label: t('STATUS'), className: "toggle-info-field" },
                                            React.createElement(ToggleInfo, { name: "status", labelOn: t('ACTIVE'), labelOff: t('NOT_YET_ACTIVE'), onChange: e => {
                                                    setFieldValue('status', values.status === statusConstants.ACTIVE
                                                        ? statusConstants.NOT_YET_ACTIVE
                                                        : statusConstants.ACTIVE);
                                                }, checked: values.status === statusConstants.ACTIVE }))))),
                                React.createElement("div", { className: "columns" },
                                    React.createElement("div", { className: "column is-8" },
                                        React.createElement(FormControl, { label: t('RESTRICTION'), className: "toggle-info-field" },
                                            React.createElement(ToggleInfo, { name: "restrictedAccess", labelOn: t('ROOM_RESTRICTED_AREA_YES'), labelOff: t('ROOM_RESTRICTED_AREA_NO'), onChange: e => {
                                                    handleChange(e);
                                                }, checked: values.restrictedAccess })))),
                                React.createElement("div", { className: "columns" },
                                    React.createElement("div", { className: "column is-4" },
                                        React.createElement(FormControl, { label: t('CAPACITY'), required: true },
                                            React.createElement(Field, { className: classnames('input', {
                                                    'is-danger': errors.capacity && touched.capacity,
                                                }), type: "number", placeholder: t('FIELD_ROOM_CAPACITY_PLACEHOLDER'), name: "capacity", onChange: e => {
                                                    handleChange(e);
                                                } }),
                                            React.createElement(ErrorMsg, { name: "capacity" }))),
                                    React.createElement("div", { className: "column is-4" },
                                        React.createElement(FormControl, { label: t('ROOM_TYPE'), required: true },
                                            React.createElement(Field, { name: "typeId", component: ReactSelect, placeholder: t('SELECT_ROOM_TYPE_PLACEHOLDER'), getOptionLabel: e => e.name, getOptionValue: e => e.id, options: Object.values(this.state.roomTypes), className: classnames({
                                                    'is-danger': errors.typeId && touched.typeId,
                                                }) }),
                                            React.createElement(ErrorMsg, { name: "typeId" })))),
                                React.createElement("div", { className: "columns" },
                                    React.createElement("div", { className: "column is-8" },
                                        React.createElement(FormControl, { label: `${t('DESCRIPTION')} (${t('ENGLISH')})` },
                                            React.createElement(Field, { className: classnames('textarea', {
                                                    'is-danger': errors.localDescription &&
                                                        touched.localDescription,
                                                }), component: "textarea", maxLength: 255, placeholder: t('DESCRIBE_ROOM'), name: "localDescription" }),
                                            React.createElement(ErrorMsg, { name: "localDescription" })),
                                        React.createElement(Field, { name: "translations", label: t('TRANSLATIONS'), component: Translations, itemDefaultValues: { description: '' }, labelAdd: t('ADD_TRANSLATION_DESCRIPTION'), item: (dataItem, index) => {
                                                let tDescription = `translations[${index}]description`;
                                                let langName = languages[dataItem.locale]
                                                    ? ` (${languages[dataItem.locale].name})`
                                                    : '';
                                                return (React.createElement(FormControl, { label: `${t('DESCRIPTION')}${langName}`, required: true },
                                                    React.createElement(Field, { component: "textarea", maxLength: 255, name: tDescription, placeholder: `${t('FIELD_ROOM_TRANSLATION_DESCRIPTION_PLACEHOLDER')}${langName}`, className: classnames('textarea', {
                                                            'is-danger': getIn(errors, tDescription) &&
                                                                getIn(touched, tDescription),
                                                        }) }),
                                                    React.createElement(ErrorMsg, { name: tDescription })));
                                            } }),
                                        React.createElement(Field, { name: "clickableLinks", label: t('CLICKABLE_LINKS'), component: ClickableLinks, itemDefaultValues: { name: '' }, labelAdd: t('ADD_CLICKABLE_LINK'), item: (dataItem, index) => {
                                                let clickableLinksName = `clickableLinks[${index}]name`;
                                                return (React.createElement(FormControl, { label: t('FIELD_CLICKABLE_LINK_NAME_PLACEHOLDER'), required: true },
                                                    React.createElement(Field, { type: "text", maxLength: 255, name: clickableLinksName, placeholder: t('FIELD_CLICKABLE_LINK_NAME_PLACEHOLDER'), className: classnames('input', {
                                                            'is-danger': getIn(errors, clickableLinksName) &&
                                                                getIn(touched, clickableLinksName),
                                                        }) }),
                                                    React.createElement(ErrorMsg, { name: clickableLinksName })));
                                            } }))),
                                React.createElement("div", { className: "columns" },
                                    React.createElement("div", { className: "column is-8" },
                                        React.createElement(FormControl, { label: t('ROOM_OWNER'), required: true },
                                            React.createElement("p", null, t('FIELD_OWNER_DESCRIPTION')),
                                            React.createElement(Field, { name: "owner", component: UserOrGroupSearch, defaultValue: this.isDataToFetch() && {
                                                    email: values.owner,
                                                }, placeholder: t('SELECT_ROOM_OWNER_PLACEHOLDER'), className: classnames({
                                                    'is-danger': errors.owner && touched.owner,
                                                }) }),
                                            React.createElement(ErrorMsg, { name: "owner" })))),
                                React.createElement("div", { className: "columns" },
                                    React.createElement("div", { className: "column is-8" },
                                        React.createElement(FormControl, { label: t('SUPPORT_CONTACT') },
                                            React.createElement("p", null, t('FIELD_CONTACT_DESCRIPTION')),
                                            React.createElement(Field, { className: classnames('input', {
                                                    'is-danger': errors.contactInformation &&
                                                        touched.contactInformation,
                                                }), type: "text", placeholder: t('SUPPORT_CONTACT_PLACEHOLDER'), name: "contactInformation" }),
                                            React.createElement(ErrorMsg, { name: "contactInformation" })))),
                                React.createElement("div", { className: "columns" },
                                    React.createElement("div", { className: "column is-8 align-self-end" },
                                        React.createElement(FormControl, null,
                                            React.createElement(ToggleInfo, { name: "contactInformationVisible", labelOn: React.createElement(Trans, { defaults: "IS_SUPPORT_CONTACT_VISIBLE", components: [React.createElement("strong", null, "univers")] }), labelOff: React.createElement(Trans, { defaults: "IS_NOT_SUPPORT_CONTACT_VISIBLE", components: [React.createElement("strong", null, "univers")] }), checked: values.contactInformationVisible, onChange: e => {
                                                    setFieldValue('contactInformationVisible', !values.contactInformationVisible);
                                                } }))))),
                            React.createElement(FormSection, { title: t('BOOKING') },
                                React.createElement("div", { className: "columns" },
                                    React.createElement("div", { className: "column is-8" },
                                        React.createElement(FormControl, { label: t('BOOKING_METHOD'), required: true },
                                            React.createElement("div", { className: "field" },
                                                React.createElement(Field, { className: "is-checkradio is-info is-circle", id: "privacy-public", name: "privacy", type: "radio", value: "false", checked: String(values.privacy) === 'false', onChange: e => {
                                                        handleChange(e);
                                                    } }),
                                                React.createElement("label", { htmlFor: "privacy-public" },
                                                    React.createElement(Trans, { defaults: "OPTION_PUBLIC_ROOM", components: [React.createElement("strong", null, "univers")] }))),
                                            React.createElement("div", { className: "field" },
                                                React.createElement(Field, { className: "is-checkradio is-info is-circle", id: "privacy-private", name: "privacy", type: "radio", value: "true", checked: String(values.privacy) === 'true' }),
                                                React.createElement("label", { htmlFor: "privacy-private" },
                                                    React.createElement(Trans, { defaults: "OPTION_PRIVATE_ROOM", components: [React.createElement("strong", null, "univers")] })))))),
                                React.createElement("div", { className: "columns" },
                                    React.createElement("div", { className: "column is-8" },
                                        React.createElement(FormControl, { label: t('GOOGLE_OWNER'), required: true },
                                            React.createElement("p", null, t('FIELD_GOOGLE_OWNER_DESCRIPTION')),
                                            React.createElement(Field, { component: UserOrGroupSearch, defaultValue: this.isDataToFetch() && {
                                                    email: values.googleOwner,
                                                }, placeholder: t('SELECT_GOOGLE_OWNER_PLACEHOLDER'), name: "googleOwner", className: classnames({
                                                    'is-danger': errors.googleOwner && touched.googleOwner,
                                                }) }),
                                            React.createElement(ErrorMsg, { name: "googleOwner" })))),
                                React.createElement("div", { className: "columns" },
                                    React.createElement("div", { className: "column is-8 align-self-end" },
                                        React.createElement(FormControl, null,
                                            React.createElement(ToggleInfo, { name: "googleOwnerVisible", labelOn: React.createElement(Trans, { defaults: "IS_GOOGLE_OWNER_VISIBLE", components: [React.createElement("strong", null, "univers")] }), labelOff: React.createElement(Trans, { defaults: "IS_NOT_GOOGLE_OWNER_VISIBLE", components: [React.createElement("strong", null, "univers")] }), checked: values.googleOwnerVisible, onChange: e => {
                                                    setFieldValue('googleOwnerVisible', !values.googleOwnerVisible);
                                                } }))))),
                            React.createElement(FormSection, { title: t('MEDIA') },
                                React.createElement("div", { className: "columns" },
                                    React.createElement("div", { className: "column is-8" },
                                        React.createElement(FormControl, { label: t('IMAGES') },
                                            React.createElement(MediaUploader, { icon: ['fas', 'image'], title: t('ADD_IMAGES'), onChange: files => {
                                                    setFieldValue('images', files);
                                                }, initialData: values.images, accept: [
                                                    'image/gif',
                                                    'image/jpeg',
                                                    'image/png',
                                                ], maxSize: appConstants.FILE_SIZE_LIMIT }))))),
                            React.createElement(FormSection, { title: t('FEATURES') },
                                React.createElement("div", { className: "columns" },
                                    React.createElement("div", { className: "column is-8" },
                                        React.createElement(FormControl, null,
                                            React.createElement("p", null, t('FIELD_FEATURES_DESCRIPTION')),
                                            React.createElement(FeaturesInput, { initFeatures: this.isDataToFetch()
                                                    ? this.props.room.features
                                                    : [], onFeaturesChange: features => {
                                                    this.setState({
                                                        selectedFeatures: features,
                                                    });
                                                } }))))),
                            React.createElement(FormButtons, null,
                                React.createElement(Button, { type: "submit", className: "is-primary", isLoading: isSubmitting, disabled: isSubmitting, text: t('SAVE'), icon: React.createElement(FAIcon, { icon: ['fas', 'check'] }) }),
                                React.createElement(Button, { to: this.buildRoomsListUrl(), className: "is-light", text: t('CANCEL') }))));
                    }))))),
            this.renderModals()));
    }
}
const mapStateToProps = state => {
    const { rooms: { room, roomsError, listParams }, } = state;
    return {
        roomsError,
        room,
        listParams,
    };
};
const mapDispatchToProps = dispatch => ({
    addRoom: data => dispatch(roomsActions.addRoom(data)),
    updateRoom: (id, data) => dispatch(roomsActions.updateRoom(id, data)),
    loadRoom: conf => dispatch(roomsActions.loadRoom(conf)),
    saveRoomImages: (id, data, imageTypeParams) => dispatch(roomsUploadActions.saveRoomImages(id, data, imageTypeParams)),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(withErrorHandling(RoomEdit))));
