import { roomTypesConstants } from '../constants';
export const roomTypesState = {
    roomTypes: [],
    roomType: null,
    roomTypesError: null,
    roomTypesLoading: false,
    pageNumber: 0,
    pageSize: 20,
    sort: [],
    totalPages: 1,
    totalElements: 0,
    listParams: '',
};
const roomTypes = (state = roomTypesState, action) => {
    switch (action.type) {
        case roomTypesConstants.FETCH_ROOM_TYPES:
            return Object.assign(Object.assign({}, state), { roomTypes: [...action.roomTypes], pageNumber: action.pageNumber, pageSize: action.pageSize, sort: action.sort, totalPages: action.totalPages, roomTypesError: null, roomTypesLoading: false, totalElements: action.totalElements, listParams: action.listParams || '' });
        case roomTypesConstants.FETCH_SINGLE_ROOM_TYPE:
            return Object.assign(Object.assign({}, state), { roomType: action.roomType, roomTypesError: null, roomTypesLoading: false });
        case roomTypesConstants.ADD_ROOM_TYPE:
        case roomTypesConstants.UPDATE_ROOM_TYPE:
            return Object.assign(Object.assign({}, state), { roomTypesError: null, roomTypesLoading: false });
        case roomTypesConstants.ERROR_ROOM_TYPES:
            return Object.assign(Object.assign({}, state), { roomTypesError: action.error, roomTypesLoading: false });
        case roomTypesConstants.LOADING_ROOM_TYPES:
            return Object.assign(Object.assign({}, state), { roomTypesLoading: true });
        default:
            return state;
    }
};
export default roomTypes;
