import React, { Component } from 'react';
import { endpoints } from '../../../constants';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import bulmaCarousel from 'bulma-carousel/dist/js/bulma-carousel.min';
import { roche_logo } from '../../../images';
import { LoaderContainer } from '../../';
import { pathUtils } from '../../../utils';
import { connect } from 'react-redux';
import { imageActions } from '../../../actions/image.actions';
class ImageCarousel extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            imagesLoaded: [],
        };
        this.openFullScreen = image => {
            const { images } = this.props;
            const index = images.findIndex(img => img.name === image.name);
            this.props.openFullScreen(images, index);
        };
    }
    componentDidMount() {
        bulmaCarousel.attach();
    }
    render() {
        const { images, t } = this.props;
        if (images.length === 0) {
            return this.renderNoImages(t);
        }
        else if (images.length === 1) {
            return this.renderImage(images[0]);
        }
        else {
            return this.renderCarousel(images);
        }
    }
    renderCarousel(images) {
        const { className } = this.props;
        return (React.createElement("div", { className: classnames('carousel', className) },
            React.createElement("div", { className: "carousel-container" }, images.map((image, index) => (React.createElement("div", { key: index, className: classnames('carousel-item has-background') }, this.renderImage(image))))),
            React.createElement("div", { className: "carousel-navigation is-overlay" },
                React.createElement("div", { className: "carousel-nav-left" },
                    React.createElement(FAIcon, { icon: ['fas', 'arrow-left'] })),
                React.createElement("div", { className: "carousel-nav-right" },
                    React.createElement(FAIcon, { icon: ['fas', 'arrow-right'] })))));
    }
    prepareImagePath(image) {
        const { imgMaxWidth, imgMaxHeight, imgCrop } = this.props;
        const resizeParams = pathUtils.prepareImageResizeParams(imgMaxWidth, imgMaxHeight, imgCrop);
        return `${endpoints.url}${endpoints.storage}/${image.name}?${resizeParams}`;
    }
    renderImage(image) {
        return (React.createElement(LoaderContainer, { className: "centered-loader" },
            React.createElement("img", { src: this.prepareImagePath(image), alt: "", ref: img => (this.img = img), onLoad: this.onImageLoaded.bind(this, image), onError: this.onImageError.bind(this, image), onClick: () => this.openFullScreen(image) })));
    }
    onImageLoaded(img) {
        this.setImageAsLoaded(img);
    }
    onImageError(img) {
        this.setImageAsLoaded(img, () => {
            this.img.src = roche_logo;
            this.img.classList.add('img-broken');
        });
    }
    setImageAsLoaded(img, callback) {
        this.setState(prev => ({
            imagesLoaded: [...prev.imagesLoaded, img.name],
        }), callback);
    }
    renderNoImages(t) {
        return (React.createElement("div", { className: "has-text-centered", style: {
                height: 100,
                paddingTop: '2em',
                background: 'whitesmoke',
            } }, t('No images.')));
    }
}
ImageCarousel.propTypes = {
    images: PropTypes.array,
    imgMaxWidth: PropTypes.number,
    imgMaxHeight: PropTypes.number,
    imgCrop: PropTypes.bool,
};
const mapDispatchToProps = dispatch => ({
    openFullScreen: (imagesLoaded, index) => dispatch(imageActions.openFullScreen(imagesLoaded, index)),
});
export default withNamespaces('translations')(connect(null, mapDispatchToProps)(ImageCarousel));
