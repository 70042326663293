var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Tooltip } from '../../';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import './InfoTooltip.scss';
const InfoTooltip = (_a) => {
    var { text, name } = _a, props = __rest(_a, ["text", "name"]);
    return text ? (React.createElement("span", { className: "info-tooltip" },
        React.createElement(FAIcon, Object.assign({ icon: ['far', 'question-circle'], className: "has-text-grey-lighter" }, { [name]: text })),
        React.createElement(Tooltip, Object.assign({ persist: true, name: name }, props)))) : null;
};
export default InfoTooltip;
