import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { useState } from 'react';
import { GlobalLoader } from './index';
export const GoogleOAuthLoader = props => {
    const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);
    if (!process.env.REACT_APP_AUTH_CLIENT_ID) {
        throw Error('Missing REACT_APP_AUTH_CLIENT_ID');
    }
    return (React.createElement(GoogleOAuthProvider, { clientId: process.env.REACT_APP_AUTH_CLIENT_ID, onScriptLoadSuccess: () => setIsGoogleScriptLoaded(true), onScriptLoadError: () => {
            throw Error('ERROR WHILE LOADING GoogleOAuthProvider');
        } }, isGoogleScriptLoaded ? props.children : React.createElement(GlobalLoader, { active: true })));
};
