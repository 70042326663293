import React, { useEffect, useState } from 'react';
import moment from 'moment';
import classnames from 'classnames/bind';
import { getDateTime } from '../../../../utils/RoomsUtils/calendarDates.utils';
const MIN_HOURS_TO_DISPLAY_ORGANIZER = 2;
const TITLE_ROWS_HOURS_BOUNDARY = 2;
function EventComponent(props) {
    const [durationInHours, setDurationInHours] = useState(0);
    const { start, end, organizer, title } = props.event;
    useEffect(() => {
        const getDurationInHours = () => {
            const startDate = moment(start);
            const endDate = moment(end);
            const duration = moment.duration(endDate.diff(startDate));
            return duration.asHours();
        };
        setDurationInHours(getDurationInHours());
    }, [start, end]);
    const organizerContainer = () => {
        const shouldDisplayOrganizer = () => {
            return durationInHours >= MIN_HOURS_TO_DISPLAY_ORGANIZER;
        };
        let organizerContainer = null;
        if (shouldDisplayOrganizer()) {
            organizerContainer = (React.createElement("p", { className: "event-element organizer" },
                "Organizer: ",
                organizer));
            return organizerContainer;
        }
    };
    const dateTime = () => {
        let dateTime = null;
        if (durationInHours > 0.5) {
            dateTime = (React.createElement("p", { className: "event-element range" }, getDateTime(start, end)));
        }
        return dateTime;
    };
    const isOneRow = () => {
        return durationInHours > 0.5 && durationInHours < TITLE_ROWS_HOURS_BOUNDARY;
    };
    const areTwoRows = () => {
        return durationInHours >= TITLE_ROWS_HOURS_BOUNDARY;
    };
    return (React.createElement("div", { className: "room-availability-event" },
        React.createElement("p", { className: classnames('event-element title', {
                'one-row': isOneRow(),
                'two-rows': areTwoRows(),
            }) }, title),
        dateTime(),
        organizerContainer()));
}
export default EventComponent;
