import React, { Component } from 'react';
import { filterActiveAndSortByDate, hasAnyActive, } from '../../../utils/roomMaintenance.utils';
import { Table, TableHeaderCell, TableTooltip } from '../../index';
import i18n from '../../../i18n';
import { dateTextFormatter } from '../../../utils/textFormatter.utils';
import CancelMaintenanceModal from '../../Common/Modal/Room/CancelMaintenanceModal/CancelMaintenanceModal';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { roomsMaintenancesActions } from '../../../actions';
import { MaintenanceListRowButtons } from '../../Common/Table/RowButtons/MaintenanceListRowButtons';
import { statusConstants } from '../../../constants';
import classnames from 'classnames/bind';
import ExpandTextTableTooltip from '../../Common/TableTooltip/ExpandTextTableTooltip/ExpandTextTableTooltip';
class RoomDetailsMaintenances extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemToCancel: null,
        };
        this.cancelMaintenanceHandler = this.cancelMaintenanceHandler.bind(this);
    }
    cancelMaintenanceHandler() {
        const { cancelMaintenance, room } = this.props;
        const { itemToCancel } = this.state;
        cancelMaintenance(room.id, {
            version: itemToCancel.version,
            id: itemToCancel.id,
        });
    }
    getColumns() {
        return [
            {
                Header: React.createElement(TableHeaderCell, { text: i18n.t('FROM') }),
                accessor: 'from',
                filterable: false,
                maxWidth: 170,
                sortable: false,
                Cell: cellInfo => dateTextFormatter(cellInfo.original.from),
            },
            {
                Header: React.createElement(TableHeaderCell, { text: i18n.t('TO') }),
                accessor: 'to',
                filterable: false,
                maxWidth: 170,
                sortable: false,
                Cell: cellInfo => dateTextFormatter(cellInfo.original.to),
            },
            {
                Header: React.createElement(TableHeaderCell, { text: i18n.t('TITLE') }),
                accessor: 'title',
                filterable: false,
                sortable: false,
                Cell: cellInfo => {
                    const m = cellInfo.original;
                    return (React.createElement(ExpandTextTableTooltip, { text: m.title, minTextLengthToShowTooltip: 55, itemId: `m-title-${m.id}`, item: m }));
                },
            },
            {
                Header: React.createElement(TableHeaderCell, { text: i18n.t('REASON') }),
                accessor: 'reason',
                filterable: false,
                sortable: false,
                Cell: cellInfo => {
                    const m = cellInfo.original;
                    return (React.createElement(ExpandTextTableTooltip, { text: m.reason, minTextLengthToShowTooltip: 55, itemId: `m-reason-${m.id}` }));
                },
            },
            {
                accessor: 'roomId',
                maxWidth: 150,
                sortable: false,
                filterable: false,
                className: 'td-actions',
                Cell: cellInfo => this.renderButtons(cellInfo),
            },
        ];
    }
    renderTextRowWithTooltip(m, text, minTextLengthToShowTooltip) {
        return this.shouldRenderTableTooltip(text, minTextLengthToShowTooltip) ? (React.createElement(TableTooltip, { className: classnames('react-hint', 'tooltip-default-colors'), minWidth: 700, name: `table-tooltip-${m.id}`, text: text }, text)) : (text);
    }
    renderButtons(row) {
        const { isRoomAdmin, room, editMaintenance } = this.props;
        const isPendingDeletion = room.status === statusConstants.PENDING_DELETION;
        let buttons = null;
        if (isRoomAdmin) {
            buttons = (React.createElement(MaintenanceListRowButtons, { isPendingDeletion: isPendingDeletion, cancelMaintenance: () => this.setState({
                    itemToCancel: row.original,
                }), editMaintenance: () => editMaintenance(row.original) }));
        }
        return buttons;
    }
    render() {
        const { room, t } = this.props;
        const { itemToCancel } = this.state;
        if (!hasAnyActive(room.maintenances)) {
            return null;
        }
        const maintenances = filterActiveAndSortByDate(room.maintenances);
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "has-text-danger has-text-weight-semibold" }, `${i18n.t('MAINTENANCE')}`),
            React.createElement(Table, { className: "maintenanace-table", data: maintenances, columns: this.getColumns(), showPaginationTop: false, showPaginationBottom: false }),
            React.createElement(CancelMaintenanceModal, { t: t, names: room.name.split(), isOpen: itemToCancel, onCancel: this.cancelMaintenanceHandler, onClose: () => this.setState({ itemToCancel: false }) })));
    }
}
const mapStateToProps = state => {
    const { rooms: { rooms, roomsLoading, totalPages, pageSize, pageNumber, sort, totalElements, }, user: { role, sites }, } = state;
    return {
        rooms,
        roomsLoading,
        totalPages,
        role,
        pageSize,
        page: pageNumber,
        sort,
        sites,
        totalElements,
    };
};
const mapDispatchToProps = dispatch => ({
    cancelMaintenance: (id, data) => dispatch(roomsMaintenancesActions.cancelMaintenance(id, data, {
        roomDetailsView: true,
    })),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(RoomDetailsMaintenances)));
