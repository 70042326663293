import { TableHeaderCell } from '../../../components';
import { Link } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { routes } from '../../../constants';
import React from 'react';
import i18n from '../../../i18n';
import { renderRow, renderRowWithOldState } from './roomsBulkTable.utils';
export function toBulkRoomUpdateDto(room) {
    return {
        roomId: room.roomId,
        name: room.roomName,
        owner: room.roomOwner,
        contactInformation: room.contactInformation,
        contactInformationVisible: room.contactInformationVisible,
        googleOwner: room.googleOwner,
        googleOwnerVisible: room.googleOwnerVisible,
    };
}
export function toRoomListRowDto(room) {
    return {
        roomId: room.id,
        roomName: room.name,
        roomOwner: room.owner,
        contactInformation: room.contactInformation,
        contactInformationVisible: room.contactInformationVisible,
        googleOwner: room.googleOwner,
        googleOwnerVisible: room.googleOwnerVisible,
        oldState: room.oldState,
    };
}
export function getColumns(lastAction) {
    return [
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('NAME') }),
            accessor: 'roomName',
            filterable: false,
            maxWidth: 235,
            Cell: cellInfo => (React.createElement(Link, { to: formatRoute(routes.roomDetails, {
                    id: cellInfo.original.roomId,
                }) }, cellInfo.original.roomName)),
        },
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('ROOM_OWNER') }),
            accessor: 'roomOwner',
            filterable: false,
            sortable: false,
            maxWidth: 245,
            Cell: cellInfo => renderRoomOwner(cellInfo, lastAction),
        },
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('SUPPORT_CONTACT') }),
            accessor: 'contactInformation',
            filterable: false,
            sortable: false,
            Cell: cellInfo => renderSupportContact(cellInfo, lastAction),
        },
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('VISIBILITY_SUPPORT_CONTACT') }),
            accessor: 'contactInformationVisible',
            filterable: false,
            sortable: false,
            maxWidth: 185,
            Cell: cellInfo => renderSupportContactVisibility(cellInfo, lastAction),
        },
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('GOOGLE_OWNER') }),
            accessor: 'googleOwner',
            filterable: false,
            sortable: false,
            Cell: cellInfo => renderGoogleOwner(cellInfo, lastAction),
        },
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('VISIBILITY_GOOGLE_OWNER') }),
            accessor: 'googleOwnerVisible',
            filterable: false,
            sortable: false,
            maxWidth: 180,
            Cell: cellInfo => renderGoogleOwnerVisibility(cellInfo, lastAction),
        },
    ];
}
function renderRoomOwner(cellInfo, lastAction) {
    const oldState = cellInfo.original.oldState;
    const actualValue = cellInfo.original.roomOwner;
    if (oldState) {
        const oldValue = cellInfo.original.oldState.owner;
        return renderRowWithOldState(oldValue, actualValue, lastAction);
    }
    else {
        return renderRow(actualValue);
    }
}
function renderSupportContact(cellInfo, lastAction) {
    const oldState = cellInfo.original.oldState;
    const actualValue = cellInfo.original.contactInformation;
    if (oldState) {
        const oldValue = cellInfo.original.oldState.contactInformation;
        return renderRowWithOldState(oldValue, actualValue, lastAction);
    }
    else {
        return renderRow(actualValue);
    }
}
function renderGoogleOwner(cellInfo, lastAction) {
    const oldState = cellInfo.original.oldState;
    const actualValue = cellInfo.original.googleOwner;
    if (oldState) {
        const oldValue = cellInfo.original.oldState.googleOwner;
        return renderRowWithOldState(oldValue, actualValue, lastAction);
    }
    else {
        return renderRow(actualValue);
    }
}
function renderSupportContactVisibility(cellInfo, lastAction) {
    const oldState = cellInfo.original.oldState;
    const actualValue = renderVisibility(cellInfo.original.contactInformationVisible);
    if (oldState) {
        const oldValue = renderVisibility(cellInfo.original.oldState.contactInformationVisible);
        return renderRowWithOldState(oldValue, actualValue, lastAction);
    }
    else {
        return renderRow(actualValue);
    }
}
function renderGoogleOwnerVisibility(cellInfo, lastAction) {
    const oldState = cellInfo.original.oldState;
    const actualValue = renderVisibility(cellInfo.original.googleOwnerVisible);
    if (oldState) {
        const oldValue = renderVisibility(cellInfo.original.oldState.googleOwnerVisible);
        return renderRowWithOldState(oldValue, actualValue, lastAction);
    }
    else {
        return renderRow(actualValue);
    }
}
function renderVisibility(visibility) {
    return visibility === true ? i18n.t('VISIBLE') : i18n.t('HIDDEN');
}
