import { imageConstants } from '../constants';
export const imageState = {
    fullScreenImages: [],
    fullScreenImagesIndex: null,
};
const image = (state = imageState, action) => {
    switch (action.type) {
        case imageConstants.OPEN_FULL_SCREEN:
            return Object.assign(Object.assign({}, state), { fullScreenImages: action.imagesLoaded, fullScreenImagesIndex: action.index });
        case imageConstants.CLOSE_FULL_SCREEN:
            return Object.assign(Object.assign({}, state), { fullScreenImages: [], fullScreenImagesIndex: null });
        default:
            return state;
    }
};
export default image;
