import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
class Pagination extends Component {
    render() {
        const { t } = this.props;
        return (React.createElement("nav", { className: "pagination is-centered is-small", role: "navigation", "aria-label": "pagination" },
            React.createElement(ReactPaginate, { previousLabel: t('PREVIOUS'), nextLabel: t('NEXT PAGE'), breakLabel: React.createElement("span", { class: "pagination-ellipsis" }, "\u2026"), breakClassName: '', pageCount: this.props.pageCount, marginPagesDisplayed: 2, pageRangeDisplayed: 5, onPageChange: this.props.pageClick, containerClassName: 'pagination-list', subContainerClassName: '', activeClassName: 'is-current', pageLinkClassName: 'pagination-link', previousLinkClassName: 'pagination-previous', nextLinkClassName: 'pagination-next' })));
    }
}
Pagination.propTypes = {
    pageCount: PropTypes.number.isRequired,
    pageClick: PropTypes.func.isRequired,
    t: PropTypes.any.isRequired,
};
export default Pagination;
