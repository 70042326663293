var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component, Fragment } from 'react';
import Geosuggest from 'react-geosuggest';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import { marker2x } from '../../../images';
import { withNamespaces } from 'react-i18next';
import { Button } from '../../';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import './LocationSelector.scss';
const LocationMapSelector = compose(withProps({
    loadingElement: React.createElement("div", { style: { height: `360px` } }),
    containerElement: React.createElement("div", { className: "map-selector" }),
    mapElement: React.createElement("div", { id: "map-selector-element", style: { height: `360px` } }),
}), withGoogleMap)((_a) => {
    var { className, onSuggestSelect, mapLocation, updateMarker, suggest, map, zoom, updateParentState, resetGeoSugest, geosuggest } = _a, props = __rest(_a, ["className", "onSuggestSelect", "mapLocation", "updateMarker", "suggest", "map", "zoom", "updateParentState", "resetGeoSugest", "geosuggest"]);
    return (React.createElement(Fragment, null,
        React.createElement(GoogleMap, { defaultZoom: zoom, defaultCenter: mapLocation, center: mapLocation, ref: map, zoom: zoom, defaultOptions: {
                streetViewControl: false,
                fullscreenControl: false,
                controlSize: 26,
                minZoom: 1,
            } },
            React.createElement(Marker, { position: mapLocation, draggable: true, onDragEnd: updateMarker, defaultCursor: "move", icon: {
                    url: marker2x,
                    scaledSize: { width: 33, height: 39 },
                } })),
        React.createElement("div", { className: "geosuggest-group" },
            React.createElement(Geosuggest, Object.assign({ ref: geosuggest, onSuggestSelect: onSuggestSelect, location: new google.maps.LatLng(suggest), inputClassName: className, placeDetailFields: [] }, props)),
            React.createElement(Button, { type: "button", onClick: () => resetGeoSugest(), icon: React.createElement(FAIcon, { icon: ['far', 'trash-alt'] }) }))));
});
class LocationSelector extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            location: { lat: 0, lng: 0 },
            suggest: { lat: 0, lng: 0 },
            init: { lat: 0, lng: 0 },
            value: '',
            zoom: 1,
        };
    }
    componentDidMount() {
        const { coords } = this.props;
        const { init } = this.state;
        if (coords !== '' && init.lat === 0 && init.lng === 0) {
            const [lat, lng] = coords.split(',').map(c => parseFloat(c));
            this.setState({ init: { lat, lng } });
        }
    }
    componentDidUpdate() {
        const { coords } = this.props;
        const { location } = this.state;
        if (coords !== '' && location.lat === 0 && location.lng === 0) {
            const [lat, lng] = coords.split(',').map(c => parseFloat(c));
            this.geoCodePlace.bind(this)({ lat, lng });
        }
    }
    suggestSelect(suggest) {
        if (suggest) {
            const { location } = suggest;
            let zoom = !location.lat && !location.lng ? 1 : 16;
            this.setState({ location, zoom }, () => {
                this.map.zoom = zoom;
                this.map.panTo(location);
                this.geoCodePlace(suggest.location);
            });
        }
    }
    geoCodePlace({ lat, lng }) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK') {
                if (results.length > 0)
                    this.setState({
                        value: results[0].formatted_address,
                        location: { lat, lng },
                        suggest: { lat, lng },
                        zoom: 16,
                    });
            }
        });
    }
    updateMarker(marker) {
        const { lat, lng } = marker.latLng;
        const location = { lat: lat(), lng: lng() };
        this.map.panTo(location);
        this.geoCodePlace.bind(this)(location);
    }
    resetGeoSugest() {
        this._geoSuggest.selectSuggest({
            location: { lat: 0, lng: 0 },
            init: { lat: 0, lng: 0 },
        }, () => this.reset());
    }
    reset() {
        this.setState({
            location: this.props.init,
            suggest: this.props.init,
        });
        this.suggestSelect({ location: this.props.init });
    }
    selectLocation() {
        const { locationSelected, onClose } = this.props;
        locationSelected(this.state.location);
        this.setState({ init: this.state.location });
        onClose();
    }
    closeModal() {
        const { onClose } = this.props;
        this.geoCodePlace.bind(this)(this.state.init);
        this._geoSuggest.selectSuggest({
            location: this.state.init,
            int: this.state.init,
        });
        onClose();
    }
    render() {
        const { isOpen, t } = this.props;
        const { location, suggest, value, zoom } = this.state;
        return (React.createElement("div", { className: `modal modal-location-selector ${isOpen ? 'is-active' : ''}` },
            React.createElement("div", { className: "modal-background" }),
            React.createElement("div", { className: "modal-content" },
                React.createElement("article", { className: "message" },
                    React.createElement("div", { className: "message-header" },
                        React.createElement("p", null, t('LOCATION_SEARCH')),
                        React.createElement("button", { className: "delete", "aria-label": "close", type: "button", onClick: this.closeModal.bind(this) })),
                    React.createElement("div", { className: "message-body" },
                        React.createElement(LocationMapSelector, Object.assign({}, this.props, { map: ref => (this.map = ref), geosuggest: ref => (this._geoSuggest = ref), updateMarker: this.updateMarker.bind(this), onSuggestSelect: this.suggestSelect.bind(this), mapLocation: location, suggest: suggest, initialValue: value, zoom: zoom, resetGeoSugest: this.resetGeoSugest.bind(this) })),
                        React.createElement(Button, { onClick: this.selectLocation.bind(this), className: "is-primary", text: t('SELECT_LOCATION') }))))));
    }
}
LocationSelector.defaultProps = {
    radius: 20,
    className: 'input',
    placeholder: 'LOCATION_PLACEHOLDER',
};
LocationSelector.propTypes = {
    radius: PropTypes.number,
    className: PropTypes.string,
    locationSelected: PropTypes.func.isRequired,
};
export default withNamespaces('translations')(LocationSelector);
