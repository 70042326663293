import Yup from '../yup';
const email = Yup.string()
    .label('NAME')
    .required('ADMINISTRATOR_NAME_REQUIRED');
const role = Yup.string();
const siteCodes = Yup.array()
    .label('SITE')
    .when('role', {
    is: 'ROLE_SITE_ADMIN',
    then: Yup.array().required('LOCAL_ADMINISTRATOR_SITE_REQUIRED'),
    otherwise: Yup.array().notRequired(),
});
const AdminsSchema = Yup.object().shape({
    email,
    role,
    siteCodes,
});
export { AdminsSchema };
