var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { buildingsActions } from '../../actions';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { TitleSection, ConfirmModal, Button, Search, Table, LoaderContainer, TableHeaderCell, TableFilter, ExportButton, } from '../';
import { userRoleConstants, routes, urlDefaultStateConstants, errorConstants, statusConstants, } from '../../constants';
import { formatRoute } from 'react-router-named-routes';
import { toastError } from '../../toasts';
import { pathUtils, buttonsUtils } from '../../utils';
import api from '../../api';
import tableFilterType from '../../enums/tableFilterType.enum';
import { GoogleLoginWrapper } from '../Common/GoogleLoginWrapper/GoogleLoginWrapper';
class Buildings extends Component {
    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.state = Object.assign(Object.assign({ itemToDelete: null, itemDeletedInfo: null, itemToDeleteNotFound: false, errorModal: null }, urlDefaultStateConstants), pathUtils.parseUrlParams(props.history.location.search));
    }
    componentDidMount() {
        const props = Object.assign(Object.assign({}, urlDefaultStateConstants), pathUtils.parseUrlParams(this.props.location.search));
        const { location } = this.props;
        if (location.state && location.state.errorModal) {
            this.setState({ errorModal: location.state.errorModal });
        }
        this.fetchData(props);
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            const props = Object.assign(Object.assign({}, urlDefaultStateConstants), pathUtils.parseUrlParams(this.props.location.search));
            this.fetchData(props);
        }
    }
    fetchData(state) {
        return __awaiter(this, void 0, void 0, function* () {
            this.setState({ errorLoading: false });
            const { loadBuildings, history, t } = this.props;
            const { page, pageSize, sorted, search, siteCodes, status, } = pathUtils.updateState(state, history.location.search);
            const response = yield loadBuildings({
                pageNumber: page,
                pageSize,
                sort: sorted,
                search,
                siteCodes,
                status,
                listParams: history.location.search,
            });
            if (response instanceof Error) {
                toastError(t(response.response.data.reason));
                this.setState({ errorLoading: true });
            }
        });
    }
    updateParams(params) {
        const { history } = this.props;
        const url = pathUtils.prepareUrl(pathUtils.updateState(history.location.search, params));
        history.push(`?${url}`);
    }
    renderButtons(row) {
        const { SITE_ADMIN, ADMIN } = userRoleConstants;
        const { t, role, sites } = this.props;
        const adminSites = sites.map(site => `${site.name} (${site.code})`);
        const { siteCode, siteName } = row.original;
        const deleteSettings = buttonsUtils.isDeleteDisabled({
            item: row.original,
            hasRoomsTooltip: 'BUILDING_HAS_ROOMS',
            allowRemovalTooltip: 'ITEM_USED',
        });
        if (role === ADMIN ||
            (role === SITE_ADMIN && adminSites.includes(`${siteName} (${siteCode})`)))
            return (React.createElement("div", { className: "buttons is-right" },
                React.createElement(Button, { to: formatRoute(routes.buildingEdit, {
                        id: row.original.buildingId,
                    }), className: "is-text has-text-primary", tooltip: t('EDIT'), icon: React.createElement(FAIcon, { icon: ['far', 'edit'] }) }),
                React.createElement(Button, { className: "is-text has-text-primary", icon: React.createElement(FAIcon, { icon: deleteSettings.icon }), tooltip: t(deleteSettings.tooltip), disabled: deleteSettings.disabled, onClick: () => this.setState({
                        itemToDelete: row.original,
                    }) })));
        return null;
    }
    getColumns() {
        const { t } = this.props;
        return [
            {
                Header: React.createElement(TableHeaderCell, { text: t('NAME') }),
                accessor: 'buildingName',
                Cell: cellInfo => (React.createElement(Link, { to: formatRoute(routes.buildingDetails, {
                        id: cellInfo.original.buildingId,
                    }) }, cellInfo.original.buildingName)),
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('SITE') }),
                accessor: 'siteName',
                Cell: cellInfo => `${cellInfo.original.siteName} (${cellInfo.original.siteCode})`,
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('NUMBER_OF_ROOMS') }),
                accessor: 'numberOfRooms',
                sortable: false,
            },
            {
                accessor: 'buildingId',
                sortable: false,
                width: 120,
                className: 'td-actions',
                Cell: cellInfo => this.renderButtons(cellInfo),
            },
            {
                accessor: 'buildingId',
                sortable: false,
                width: 120,
                Cell: cellInfo => {
                    const { siteCode, siteName, buildingId, buildingName, numberOfRooms, } = cellInfo.original;
                    return (numberOfRooms > 0 && (React.createElement(Link, { to: `${routes.rooms}?siteCodes[]="${siteCode}"&siteName[]="${siteName}"&buildingIds[]="${buildingId}"&buildingName[]="${buildingName}"` }, t('SEE_ALL_ROOMS'))));
                },
            },
        ];
    }
    updateSearch(search) {
        this.updateParams({ search });
        this.setState({ search, errorLoading: false });
    }
    renderModals() {
        const { itemToDelete, itemDeletedInfo, itemToDeleteNotFound, errorModal, } = this.state;
        const { t, deleteBuilding, page, pageSize, sorted } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(ConfirmModal, { title: t('CONFIRM'), text: t('DELETE_CONFIRM_MESSAGE_BUILDING', {
                    item: itemToDelete ? itemToDelete.buildingName : '',
                }), onClose: () => this.setState({ itemToDelete: null }), isOpen: itemToDelete != null, primaryButton: {
                    label: t('DELETE'),
                    type: 'is-danger',
                    action: (onClose) => __awaiter(this, void 0, void 0, function* () {
                        const deletedBuilding = yield deleteBuilding(itemToDelete.buildingId, {
                            page,
                            pageSize,
                            sorted,
                        });
                        if (!(deletedBuilding instanceof Error)) {
                            this.setState({ itemDeletedInfo: Object.assign({}, itemToDelete) });
                        }
                        else {
                            if (deletedBuilding.response.data.reason ===
                                errorConstants.BUILDING_NOT_FOUND_DELETE) {
                                this.fetchData(urlDefaultStateConstants);
                                this.setState({ itemToDeleteNotFound: true });
                            }
                            else {
                                toastError(t(deletedBuilding.response.data.reason));
                            }
                        }
                        onClose();
                    }),
                    icon: React.createElement(FAIcon, { icon: ['far', 'trash-alt'] }),
                }, secondaryButton: {
                    label: t('CANCEL'),
                    type: 'is-light',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t('MODAL_DELETE_BUILDING_INFO', {
                    name: itemDeletedInfo ? itemDeletedInfo.buildingName : '',
                }), onClose: () => this.setState({ itemDeletedInfo: null }), isOpen: itemDeletedInfo != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t('BUILDING_NOT_FOUND_DELETE'), onClose: () => this.setState({ itemToDeleteNotFound: false }), isOpen: itemToDeleteNotFound, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t(errorModal), onClose: () => {
                    pathUtils.clearLocationState('errorModal');
                    this.setState({ errorModal: null });
                }, isOpen: errorModal !== null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } })));
    }
    render() {
        const { t, buildings, buildingsLoading, totalPages, page, pageSize, totalElements, history, } = this.props;
        const { search } = this.state;
        return (React.createElement(Fragment, null,
            React.createElement(TitleSection, null,
                React.createElement(Search, { placeholder: t('SEARCH_PLACEHOLDER_BUILDINGS'), defaultValue: search, updateSearch: this.updateSearch.bind(this) })),
            this.state.errorLoading && React.createElement(GoogleLoginWrapper, null),
            React.createElement(LoaderContainer, { className: "section" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "header-panel" },
                        React.createElement("div", { className: "buttons" },
                            React.createElement(Button, { to: routes.buildingAdd, className: "is-primary", icon: React.createElement(FAIcon, { icon: ['fas', 'plus'] }), text: t('ADD_BUILDING') }),
                            React.createElement(ExportButton, { fetchExportData: api.Buildings.export, fileName: "export-buildings" }))),
                    React.createElement(TableFilter, { history: history, type: tableFilterType.BUILDINGS, statusOptions: [
                            {
                                value: statusConstants.ACTIVE,
                                label: t(statusConstants.ACTIVE),
                            },
                            {
                                value: statusConstants.HIDDEN,
                                label: t(statusConstants.HIDDEN),
                            },
                        ] }),
                    React.createElement(Table, { resultsData: { page, totalElements, pageSize }, pages: totalPages, data: buildings, pageSize: pageSize, columns: this.getColumns(), loading: buildingsLoading, search: search ? search : '', tableChange: this.updateParams.bind(this) }))),
            this.renderModals()));
    }
}
const mapStateToProps = state => {
    const { buildings: { buildings, totalPages, buildingsLoading, pageSize, pageNumber, sort, totalElements, }, user: { role, sites }, } = state;
    return {
        buildings,
        buildingsLoading,
        totalPages,
        role,
        pageSize,
        page: pageNumber,
        sorted: sort,
        sites,
        totalElements,
    };
};
const mapDispatchToProps = dispatch => ({
    loadBuildings: conf => dispatch(buildingsActions.loadBuildings(conf)),
    deleteBuilding: (id, conf) => dispatch(buildingsActions.deleteBuilding(id, conf)),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Buildings)));
