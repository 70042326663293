var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { loadingActions } from './loading.actions';
import { errorTypesConstants, sitesConstants } from '../constants';
import { errorActions } from './';
import api from '../api';
const loadSites = params => {
    const { sort } = params;
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(sitesLoading());
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Sites.list(params);
            const { content, number, totalPages, size, totalElements, } = response.data;
            dispatch(sitesSuccess(content, number, totalPages, size, sort, totalElements));
            dispatch(loadingActions.hideLoader());
        }
        catch (error) {
            console.log('500 here');
            dispatch(sitesError(error, loadSites, [params]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const loadSite = ({ code }) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Sites.get(code);
            dispatch(siteSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            error.type = errorTypesConstants.LOAD_RESOURCE;
            dispatch(sitesError(error, loadSite, [{ code }]));
            dispatch(loadingActions.hideLoader());
            throw error;
        }
    });
};
const addSite = data => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(sitesLoading());
        try {
            const response = yield api.Sites.create(data);
            return response.data;
        }
        catch (error) {
            dispatch(sitesError(error, addSite, [data]));
            return error;
        }
    });
};
const deleteSite = (code, conf) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(sitesLoading());
        try {
            const response = yield api.Sites.delete(code);
            dispatch(loadSites(conf));
            return response.data;
        }
        catch (error) {
            dispatch(sitesError(error, deleteSite, [code, conf]));
            return error;
        }
    });
};
const updateSite = (code, data) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(sitesLoading());
        try {
            const response = yield api.Sites.update(code, data);
            return response.data;
        }
        catch (error) {
            dispatch(sitesError(error, updateSite, [code, data]));
            return error;
        }
    });
};
const sitesSuccess = (sites, pageNumber, totalPages, pageSize, sort, totalElements) => ({
    type: sitesConstants.FETCH_SITES,
    sites,
    pageNumber,
    totalPages,
    pageSize,
    sort,
    totalElements,
});
const siteSuccess = site => ({
    type: sitesConstants.FETCH_SINGLE_SITE,
    site,
});
const sitesLoading = () => ({ type: sitesConstants.LOADING_SITES });
const sitesError = (error, actionFunction, actionParams) => {
    return dispatch => {
        dispatch(errorActions.error(error, actionFunction, actionParams, {
            type: sitesConstants.ERROR_SITES,
            error,
        }));
    };
};
export const sitesActions = {
    loadSites,
    addSite,
    deleteSite,
    updateSite,
    loadSite,
};
