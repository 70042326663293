var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { featuresActions } from '../../actions';
import { withNamespaces } from 'react-i18next';
import { ConfirmModal, TitleSection, LabelBlock, Accordion, Auditing, FormSection, LoaderContainer, BackToList, DetailsViewButtons, } from '..';
import { formatRoute } from 'react-router-named-routes';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { errorTypesConstants as err, routes, appConstants, errorConstants, userRoleConstants, } from '../../constants';
import { languages } from '../../enums';
import { userSearchActions } from '../../actions';
import { toastError } from '../../toasts';
import withErrorHandling from '../Common/WithErrorHandling/withErrorHandling';
import { buttonsUtils } from '../../utils';
class FeatureDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmDeleteModal: false,
            showInfoDeleteModal: false,
            isReady: false,
            userNames: [],
        };
        const id = props.match.params.id;
        if (!isNaN(id)) {
            this.id = parseInt(id);
        }
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            const { loadFeature } = this.props;
            try {
                let loadedFeature = yield loadFeature({ id: this.id });
                this.fetchUsersFullNames(loadedFeature);
                this.setState({ isReady: true });
            }
            catch (error) {
                const { t, history, handleError404, featuresError } = this.props;
                if (featuresError && featuresError.type === err.LOAD_RESOURCE) {
                    handleError404({
                        text: {
                            msg: t('ERROR_404_FEATURE'),
                            email: appConstants.DEFAULT_CONTACT_EMAIL,
                        },
                        backLinkPath: this.buildFeaturesListUrl(),
                    });
                }
                else {
                    history.push(this.buildFeaturesListUrl());
                }
            }
        });
    }
    fetchUsersFullNames(loadedFeature) {
        const { auditing } = loadedFeature;
        if (auditing) {
            userSearchActions
                .fetchUserNamesByEmails([auditing.createdBy, auditing.modifiedBy])
                .then(names => this.setState({ userNames: names }));
        }
    }
    renderModals() {
        const { showConfirmDeleteModal, showInfoDeleteModal } = this.state;
        const { t, history, deleteFeature, feature } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(ConfirmModal, { title: t('CONFIRM'), text: t('DELETE_CONFIRM_MESSAGE_FEATURE', {
                    item: feature.name,
                }), onClose: () => this.setState({ showConfirmDeleteModal: false }), isOpen: showConfirmDeleteModal, primaryButton: {
                    label: t('DELETE'),
                    type: 'is-danger',
                    action: (onClose) => __awaiter(this, void 0, void 0, function* () {
                        const featureDeleted = yield deleteFeature(feature.id, {
                            pageNumber: 0,
                        });
                        if (!(featureDeleted instanceof Error)) {
                            this.setState({ showInfoDeleteModal: true });
                        }
                        else if (featureDeleted.response.data.reason ===
                            errorConstants.FEATURE_NOT_FOUND_DELETE) {
                            history.push({
                                pathname: formatRoute(this.buildFeaturesListUrl()),
                                state: { errorModal: featureDeleted.response.data.reason },
                            });
                        }
                        else {
                            toastError(t(featureDeleted.response.data.reason));
                        }
                        onClose();
                    }),
                    icon: React.createElement(FAIcon, { icon: ['far', 'trash-alt'] }),
                }, secondaryButton: {
                    label: t('CANCEL'),
                    type: 'is-light',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t('MODAL_DELETE_FEATURE_INFO', {
                    name: feature.name,
                }), onClose: () => this.setState({ showInfoDeleteModal: false }), isOpen: showInfoDeleteModal, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => {
                        history.push(this.buildFeaturesListUrl());
                        onClose();
                    },
                } })));
    }
    getUserFullName(email) {
        let user = this.state.userNames.find(e => e.email === email);
        if (user) {
            return user.fullName;
        }
    }
    renderButtons() {
        const { feature } = this.props;
        const deleteSettings = buttonsUtils.isDeleteDisabled({
            item: feature,
            hasRoomsTooltip: 'FEATURE_HAS_ROOMS_DELETED',
            allowRemovalTooltip: 'ITEM_USED',
        });
        return (React.createElement(DetailsViewButtons, { editProps: {
                to: formatRoute(routes.featureEdit, {
                    id: feature.id,
                }),
            }, deleteProps: {
                onClick: () => this.setState({
                    showConfirmDeleteModal: true,
                }),
            }, deleteSettings: deleteSettings }));
    }
    buildFeaturesListUrl() {
        return routes.features + this.props.listParams;
    }
    render() {
        const { t, feature, role, lng } = this.props;
        const { isReady } = this.state;
        const { SITE_ADMIN } = userRoleConstants;
        if (isReady) {
            const { auditing } = feature;
            if (auditing) {
                auditing.createdByName = this.getUserFullName(auditing.createdBy);
                auditing.modifiedByName = this.getUserFullName(auditing.modifiedBy);
            }
        }
        return (React.createElement(LoaderContainer, null, isReady && (React.createElement(Fragment, null,
            React.createElement(TitleSection, { isNarrow: false, title: feature.name }),
            React.createElement("section", { className: "section details" },
                React.createElement("div", { className: "container" },
                    React.createElement(FormSection, null,
                        React.createElement("div", { className: "header-panel" },
                            React.createElement("div", { className: "buttons" },
                                React.createElement(BackToList, { to: this.buildFeaturesListUrl(), t: t }),
                                role !== SITE_ADMIN && this.renderButtons())),
                        React.createElement("div", { className: "columns" },
                            React.createElement("div", { className: "column is-4" },
                                React.createElement(LabelBlock, { label: t('CATEGORY') }, feature.category)),
                            React.createElement("div", { className: "column is-4" },
                                React.createElement(LabelBlock, { label: t('NUMBER_OF_ROOMS') },
                                    `${feature.numberOfRooms}`,
                                    React.createElement("small", { className: "is-size-6" }, feature.numberOfRooms > 0 && (React.createElement(Link, { to: `${routes.rooms}?featureIds[]="${feature.id}"&featureName[]="${feature.name}"` },
                                        "(",
                                        t('SEE_ALL_ROOMS'),
                                        ")")))))),
                        React.createElement(LabelBlock, { label: t('NAME') },
                            feature.name,
                            feature.translations && feature.translations.length > 0 && (React.createElement(Accordion, { textShow: t('SHOW_TRANSLATIONS'), textHide: t('HIDE_TRANSLATIONS') }, feature.translations.map((e, index) => {
                                let langName = languages[e.locale]
                                    ? ` (${languages[e.locale].name})`
                                    : '';
                                return (React.createElement(LabelBlock, { key: index, label: `${t('NAME')}${langName}`, className: "m-bottom-1" }, e.name));
                            }))))),
                    React.createElement(Auditing, { t: t, auditing: feature.auditing, role: role, lng: lng }))),
            this.renderModals()))));
    }
}
const mapStateToProps = state => {
    const { user: { role }, features: { feature, featuresError, listParams }, } = state;
    return {
        feature,
        role,
        featuresError,
        listParams,
    };
};
const mapDispatchToProps = dispatch => ({
    loadFeature: conf => dispatch(featuresActions.loadFeature(conf)),
    deleteFeature: (id, conf) => dispatch(featuresActions.deleteFeature(id, conf)),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(withErrorHandling(FeatureDetails))));
