import i18n from '../../../i18n';
let itemsPaginationName;
let componentNode;
let selectedItems;
let totalElements;
function setToFileScope(itemsPaginationNameLocal, componentNodeLocal, selectedItemsLocal, totalElementsLocal) {
    itemsPaginationName = itemsPaginationNameLocal;
    componentNode = componentNodeLocal;
    selectedItems = selectedItemsLocal;
    totalElements = totalElementsLocal;
}
function setSelectionStatistics(componentNodeLocal, selectedItemsLocal, totalElementsLocal, itemsPaginationNameLocal) {
    setToFileScope(itemsPaginationNameLocal, componentNodeLocal, selectedItemsLocal, totalElementsLocal);
    const selectedTextInfoContainer = componentNode.querySelector('#selected-text-info');
    if (selectedItems > 0) {
        buildContainer(selectedTextInfoContainer);
    }
    else if (selectedTextInfoContainer) {
        removeSelectedContainerChilds(selectedTextInfoContainer);
    }
}
function buildStatisticsContainerContent(newSelectedInfoContainer, paginationTopNode) {
    newSelectedInfoContainer.appendChild(getNumberOfSelectedItemsContainer());
    newSelectedInfoContainer.appendChild(getTypeOfSelectedItemsContainer());
    paginationTopNode.prepend(newSelectedInfoContainer);
}
function buildContainer(selectedTextInfoContainer) {
    const paginationTopNode = componentNode.querySelector('.-center');
    const newSelectedTextInfoContainer = prepareSelectedContainer(selectedTextInfoContainer, paginationTopNode);
    buildStatisticsContainerContent(newSelectedTextInfoContainer, paginationTopNode);
}
function createNewContainer(paginationTop) {
    let container;
    container = document.createElement('span');
    container.setAttribute('id', 'selected-text-info');
    paginationTop.style.justifyContent = ' space-between';
    return container;
}
function prepareSelectedContainer(selectedTextInfoContainer, paginationTop) {
    let container;
    if (selectedTextInfoContainer) {
        removeSelectedContainerChilds(selectedTextInfoContainer);
        container = selectedTextInfoContainer;
    }
    else {
        container = createNewContainer(paginationTop);
    }
    return container;
}
function getTypeOfSelectedItemsContainer() {
    const typeOfSelectedItemsContainer = document.createElement('span');
    const typeOfSelectedItemsText = document.createTextNode(` ${i18n.t(itemsPaginationName)} ${i18n.t('ARE_SELECTED')}`);
    typeOfSelectedItemsContainer.append(typeOfSelectedItemsText);
    return typeOfSelectedItemsContainer;
}
function removeSelectedContainerChilds(selectedTextInfoContainer) {
    selectedTextInfoContainer.textContent = '';
}
function getNumberOfSelectedItemsContainer() {
    const numberOfSelectedItemsContainer = document.createElement('span');
    numberOfSelectedItemsContainer.setAttribute('class', 'primary-text-bold');
    const numberOfSelectedItemsText = document.createTextNode(getNumberOfSelectedItemsText());
    numberOfSelectedItemsContainer.append(numberOfSelectedItemsText);
    return numberOfSelectedItemsContainer;
}
function getNumberOfSelectedItemsText() {
    return `${selectedItems} ${i18n.t('TABLE_TEXT_OF')} ${totalElements}`;
}
export const selectTablePaginationTopExtension = {
    setSelectionStatistics: setSelectionStatistics,
};
