var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { roomsUploadConstants } from '../constants';
import api from '../api';
import uniqid from 'uniqid';
const saveRoomImages = (room, data, imageTypeParams) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        const id = uniqid();
        try {
            const files = Array.from(data.entries()).map(entry => entry[1]);
            dispatch(uploadStarted(id, room, files));
            const response = yield api.Rooms.saveImages(room.id, data, updateUploadProgress(id, dispatch), imageTypeParams);
            dispatch(uploadFinished(id));
            return response;
        }
        catch (error) {
            dispatch(imagesError(error, room, id));
            return error;
        }
    });
};
const updateUploadProgress = (roomId, dispatch) => {
    return event => {
        dispatch(uploadProgress(roomId, event));
    };
};
const uploadStarted = (id, room, files) => ({
    type: roomsUploadConstants.UPLOAD_STARTED,
    id,
    room,
    files,
});
const uploadFinished = id => ({
    type: roomsUploadConstants.UPLOAD_FINISHED,
    id,
});
const uploadProgress = (id, event) => ({
    type: roomsUploadConstants.UPDATE_UPLOAD_PROGRESS,
    id,
    total: event.total,
    loaded: event.loaded,
});
const removeFinishedUpload = id => ({
    type: roomsUploadConstants.REMOVE_FINISHED_UPLOAD,
    id,
});
const showUploadPanel = () => ({
    type: roomsUploadConstants.SHOW_UPLOAD_PANEL,
});
const hideUploadPanel = () => ({
    type: roomsUploadConstants.HIDE_UPLOAD_PANEL,
});
const imagesError = (error, room, id) => {
    return {
        type: roomsUploadConstants.ERROR_ROOM_IMAGES,
        error,
        room,
        id,
    };
};
export const roomsUploadActions = {
    saveRoomImages,
    showUploadPanel,
    hideUploadPanel,
    removeFinishedUpload,
};
