var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { featuresConstants, errorTypesConstants } from '../constants';
import { loadingActions } from './loading.actions';
import { errorActions } from './';
import api from '../api';
const loadFeatures = params => {
    const { sort, listParams } = params;
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(featuresLoading());
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Features.list(params);
            const { content, number, totalPages, size, totalElements, } = response.data;
            dispatch(featuresSuccess(content, number, totalPages, size, sort, totalElements, listParams));
            dispatch(loadingActions.hideLoader());
        }
        catch (error) {
            dispatch(featuresError(error, loadFeatures, [params]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const loadFeature = ({ id }) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Features.get(id);
            dispatch(featureSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            error.type = errorTypesConstants.LOAD_RESOURCE;
            dispatch(featuresError(error, loadFeature, [{ id }]));
            dispatch(loadingActions.hideLoader());
            throw error;
        }
    });
};
const addFeature = data => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(featuresLoading());
        try {
            const response = yield api.Features.create(data);
            dispatch(featureAddSuccess());
            return response.data;
        }
        catch (error) {
            dispatch(featuresError(error, addFeature, [data]));
            return error;
        }
    });
};
const deleteFeature = (id, conf) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(featuresLoading());
        try {
            const response = yield api.Features.delete(id);
            dispatch(loadFeatures(conf));
            return response.data;
        }
        catch (error) {
            dispatch(featuresError(error, deleteFeature, [id, conf]));
            return error;
        }
    });
};
const updateFeature = (id, data) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(featuresLoading());
        try {
            const response = yield api.Features.update(id, data);
            dispatch(featureUpdateSuccess());
            return response.data;
        }
        catch (error) {
            dispatch(featuresError(error, updateFeature, [id, data]));
            return error;
        }
    });
};
const featuresSuccess = (features, pageNumber, totalPages, pageSize, sort, totalElements, listParams) => ({
    type: featuresConstants.FETCH_FEATURES,
    features,
    pageNumber,
    totalPages,
    pageSize,
    sort,
    totalElements,
    listParams,
});
const featureSuccess = feature => ({
    type: featuresConstants.FETCH_SINGLE_FEATURE,
    feature,
});
const featureAddSuccess = () => ({
    type: featuresConstants.ADD_FEATURE,
});
const featureUpdateSuccess = () => ({
    type: featuresConstants.UPDATE_FEATURE,
});
const featuresLoading = () => ({ type: featuresConstants.LOADING_FEATURES });
const featuresError = (error, actionFunction, actionParams) => {
    return dispatch => {
        dispatch(errorActions.error(error, actionFunction, actionParams, {
            type: featuresConstants.ERROR_FEATURES,
            error,
        }));
    };
};
export const featuresActions = {
    loadFeatures,
    addFeature,
    deleteFeature,
    updateFeature,
    loadFeature,
};
