import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import Button from '../../Button/Button';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import i18n from '../../../../i18n';
import { Views } from 'react-big-calendar';
export const RANGE = {
    WEEK: 7,
    DAY: 1,
};
function RoomAvailabilityToolbar(props) {
    const getCustomToolbar = () => {
        const goToDayView = () => {
            props.onView('day');
        };
        const goToWeekView = () => {
            props.onView('week');
        };
        const goToBack = () => {
            let mDate = props.date;
            const subtractDays = props.view === Views.WEEK ? RANGE.WEEK : RANGE.DAY;
            const newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() - subtractDays, 1);
            props.onNavigate('prev', newDate);
        };
        const goToNext = () => {
            let mDate = props.date;
            const addDays = props.view === Views.WEEK ? RANGE.WEEK : RANGE.DAY;
            const newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() + addDays, 1);
            props.onNavigate('next', newDate);
        };
        const goToToday = () => {
            props.onNavigate('TODAY');
        };
        const Input = ({ onChange, placeholder, value, isSecure, id, onClick }) => {
            return (React.createElement(Button, { onChange: onChange, placeholder: placeholder, value: value, isSecure: isSecure, id: id, onClick: onClick, icon: React.createElement(FAIcon, { icon: ['fas', 'calendar-alt'] }) }));
        };
        return (React.createElement("div", { className: "room-availability-toolbar" },
            React.createElement("div", null,
                React.createElement("span", { className: "rbc-btn-group" },
                    React.createElement(DatePicker, { withPortal: true, customInput: React.createElement(Input, null), onChange: date => {
                            props.onNavigate('date', moment(date).toDate());
                        } })),
                React.createElement(Button, { icon: React.createElement(FAIcon, { icon: ['fas', 'arrow-left'] }), onClick: goToBack }),
                React.createElement(Button, { text: i18n.t('TODAY'), onClick: goToToday }),
                React.createElement(Button, { icon: React.createElement(FAIcon, { icon: ['fas', 'arrow-right'] }), onClick: goToNext })),
            React.createElement("div", null,
                React.createElement("p", null,
                    props.label,
                    ' ',
                    moment(props.date)
                        .toDate()
                        .getFullYear())),
            React.createElement("div", null,
                React.createElement(Button, { onClick: goToDayView, text: i18n.t('DAY') }),
                React.createElement(Button, { onClick: goToWeekView, text: i18n.t('WEEK') }))));
    };
    return React.createElement(React.Fragment, null, getCustomToolbar());
}
export default RoomAvailabilityToolbar;
