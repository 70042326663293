import React from 'react';
import { Button, TableTooltip } from '../../../index';
import classnames from 'classnames/bind';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { dateTextFormatter } from '../../../../utils/textFormatter.utils';
import { filterActiveAndSortByDate, hasMaintenance, } from '../../../../utils/roomMaintenance.utils';
const RoomsMaintenanceTableTooltip = ({ maintenances, itemId, roomStatus }) => {
    if (!hasMaintenance(maintenances, roomStatus)) {
        return null;
    }
    const maintenancesSorted = filterActiveAndSortByDate(maintenances);
    return (React.createElement(TableTooltip, { key: maintenances[0].roomId, tableTooltipClassName: "rooms-maintenances-tooltip", className: classnames('react-hint', 'tooltip-default-colors'), minWidth: 350, name: `room-maintenances-table-tooltip-${itemId}`, text: React.createElement(Button, { disable: "true", onClick: () => { }, className: "has-default-cursor is-text has-text-danger", icon: React.createElement(FAIcon, { icon: ['far', 'clock'] }) }) },
        React.createElement("div", { className: "has-text-centered" }, maintenancesSorted.map(item => (React.createElement("p", { key: item.id }, `${dateTextFormatter(item['from'])} - 
              ${dateTextFormatter(item['to'])}`))))));
};
export default RoomsMaintenanceTableTooltip;
