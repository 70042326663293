import React from 'react';
import TableResults from '../TableResults/TableResults';
import Select from 'react-select';
const pageSizeOptions = [5, 10, 20, 50, 100, 200, 500, 1000];
const TablePageSize = ({ pageSize, rowsSelectorText, onPageSizeChange, pageSizeOptions, rowsText, resultsData, }) => {
    let options = pageSizeOptions.map(value => ({
        value,
        label: `${value} ${rowsText}`,
    }));
    return (React.createElement("span", { className: "-pageSizeOptions" },
        resultsData && React.createElement(TableResults, Object.assign({}, resultsData)),
        React.createElement(Select, { "aria-label": rowsSelectorText, onChange: e => {
                onPageSizeChange(Number(e.value));
            }, value: options.find(e => e.value === pageSize), className: "react-select", classNamePrefix: "rs", options: options })));
};
export default TablePageSize;
