export const endpoints = {
    url: process.env.REACT_APP_API_SERVER,
    user: '/user',
    entities: '/entities',
    building: '/building',
    site: '/site',
    feature: '/feature',
    roomType: '/roomType',
    room: '/room',
    role: '/userRole',
    userSite: '/userSite',
    currentUser: '/me',
    userSearch: '/userSearch',
    storage: '/storage/resize',
    images: '/images/room',
    featureCategories: '/featureCategories',
    maintenance: '/room/maintenance',
    events: '/events',
    userAuth: '/userAuth',
};
