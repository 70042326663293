import React, { Component } from 'react';
import { scrollTo } from '../../../utils';
import Yup from '../../../yup';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { errorConstants as err } from '../../../constants';
class FormErrorNotification extends Component {
    constructor() {
        super(...arguments);
        this.responseError = null;
        this.submitted = false;
    }
    componentWillUpdate(nextProps) {
        if (this.wasSubmitted(nextProps)) {
            this.submitted = true;
            if (this.props.isValid && nextProps.error) {
                this.responseError = this.parseResponseErrors(nextProps.error);
            }
        }
        if (this.formHasErrorsAfterSubmit(nextProps)) {
            scrollTo(document.documentElement, 100, 400);
            this.submitted = false;
        }
    }
    formHasErrorsAfterSubmit(nextProps) {
        return Boolean(this.submitted && !isEmpty(nextProps.errors));
    }
    wasSubmitted(nextProps) {
        return this.props.isSubmitting === true && nextProps.isSubmitting === false;
    }
    parseResponseErrors(error) {
        const { t, setFieldError } = this.props;
        const fields = [];
        const reason = error.response.data.reason;
        switch (reason) {
            case err.USER_NOT_FOUND:
                setFieldError('email', t(reason));
                fields.push(this.getFieldLabel('email'));
                break;
            case err.FLOOR_NOT_FOUND:
                setFieldError('floorId', t(reason));
                fields.push(this.getFieldLabel('floorId'));
                break;
            case err.ROOM_TYPE_NOT_FOUND:
                setFieldError('typeId', t(reason));
                fields.push(this.getFieldLabel('typeId'));
                break;
            case err.CATEGORY_NOT_FOUND:
                setFieldError('category', t(reason));
                fields.push(this.getFieldLabel('category'));
                break;
            case err.BUILDING_NOT_FOUND:
                setFieldError('buildingId', t(reason));
                fields.push(this.getFieldLabel('buildingId'));
                break;
            case err.SITE_NOT_FOUND:
                setFieldError('siteCode', t(reason));
                fields.push(this.getFieldLabel('siteCode'));
                break;
            case err.FEATURES_NOT_FOUND:
                setFieldError('featureIds', t(reason));
                fields.push('FEATURE');
                break;
            case err.FLOOR_HAS_ROOMS_RENAME:
                setFieldError('floors', t(reason));
                break;
            case err.FEATURE_DUPLICATE:
            case err.BUILDING_DUPLICATE:
            case err.ROOM_TYPE_DUPLICATE:
            case err.ROOM_DUPLICATE:
                setFieldError('name', t(reason));
                break;
        }
        const summary = fields.length > 1
            ? t('ERRORS_SUMMARY_MULTIPLE', {
                fields: fields
                    .map(field => t(field))
                    .join(' ')
                    .toLowerCase(),
            })
            : t('ERRORS_SUMMARY_SINGLE', {
                field: t(fields[0]).toLowerCase(),
            });
        return fields.length > 0 ? summary : 'inline';
    }
    render() {
        const { t, errors, touched, submitCount } = this.props;
        const errorsKeys = Object.keys(errors);
        const touchedKeys = Object.keys(touched);
        const fieldErrors = touchedKeys.filter(value => -1 !== errorsKeys.indexOf(value));
        return submitCount > 0 && fieldErrors.length > 0 ? (React.createElement("div", { className: "notification is-danger" },
            React.createElement("p", null, t(fieldErrors.length > 1 ? 'FORM_FIELDS_ERRORS' : 'FORM_FIELD_ERROR')),
            React.createElement("ul", null,
                fieldErrors.map(fieldName => (React.createElement("li", { key: fieldName }, this.getFieldLabel(fieldName)))),
                this.responseError && this.responseError !== 'inline' && (React.createElement("li", { key: "responseError" }, this.responseError))))) : null;
    }
    getFieldLabel(fieldName) {
        const { t, schema } = this.props;
        try {
            let label = Yup.reach(schema, fieldName).describe().label;
            return t(label);
        }
        catch (error) {
            return '';
        }
    }
}
FormErrorNotification.propTypes = {
    submitCount: PropTypes.number.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
};
FormErrorNotification.defaultProps = {
    errors: {},
    touched: {},
    schema: {},
};
export default FormErrorNotification;
