var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Fragment } from 'react';
import Toggle from 'react-toggle';
const ToggleInfo = (_a) => {
    var { labelOn, labelOff, messageOn, messageOff, checked, children } = _a, props = __rest(_a, ["labelOn", "labelOff", "messageOn", "messageOff", "checked", "children"]);
    var message;
    if ((checked && messageOn) || (!checked && messageOff)) {
        message = (React.createElement("div", { className: "message" },
            React.createElement("div", { className: "message-body" }, checked ? messageOn : messageOff)));
    }
    return (React.createElement(Fragment, null,
        React.createElement("label", { className: "toggle-label" },
            React.createElement(Toggle, Object.assign({ icons: false, className: "toggle", checked: checked }, props)),
            React.createElement("span", null, checked ? labelOn : labelOff)),
        message,
        checked ? React.createElement("div", { className: "content" }, children) : null));
};
export default ToggleInfo;
