import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import Thumb from '../Thumb/Thumb';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import i18n from '../../../../i18n.js';
import { Button } from '../../../';
import { ImageTypeToggle } from '../../ImageTypeToggle/ImageTypeToggle';
const DragHandle = SortableHandle(props => (React.createElement("span", Object.assign({}, props, { tooltip: i18n.t('SORT_TOOLTIP') }),
    React.createElement(FAIcon, { icon: ['fas', 'sort'] }))));
export const SortableMediaItem = SortableElement(({ value, position, onDeleteItem, onToggleItem, disableSort }) => (React.createElement("div", { className: "panel-block sort-item" },
    React.createElement("span", { className: "tag" }, position + 1),
    React.createElement(Thumb, { file: value, size: 40 }),
    React.createElement("section", { className: "modal-card-body add-image-modal \t" },
        React.createElement(ImageTypeToggle, { onToggleItem: onToggleItem, imageType: value.imageType, name: value.name })),
    React.createElement("div", { className: "buttons", style: { marginLeft: 'auto' } },
        React.createElement(Button, { className: "is-text has-text-danger", tooltip: i18n.t('DELETE'), onClick: onDeleteItem, icon: React.createElement(FAIcon, { icon: ['far', 'trash-alt'] }) }),
        !disableSort ? (React.createElement(DragHandle, { className: "button is-light has-text-grey cursor-sort" })) : null))));
