var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import CheckBox from '../../Common/CheckBox/CheckBox';
import ProgressBar from '../../Common/ProgressBar/ProgressBar';
import { Button } from '../../index';
import { saveFileFromResponse } from '../../../utils/export.utils';
import api from '../../../api';
import axios from 'axios';
import progressBarStatus from '../../../enums/progressBarStatus.enum';
const ROOMS_PER_FILE = 2500;
const FILE_NAME = `export-rooms`;
class RoomExportAdminModal extends Component {
    constructor(props) {
        super(props);
        this.handleOnClose = () => {
            if (this.isExportingFile()) {
                this.cancelExport();
            }
            this.props.onClose();
        };
        this.handleSingleChecked = event => {
            let checkboxes = this.state.filesCheckboxes;
            checkboxes.forEach(checkbox => {
                if (checkbox.fileId === event.target.name)
                    checkbox.checked = event.target.checked;
            });
            this.setState({ filesCheckboxes: checkboxes });
        };
        this.handleAllChecked = event => {
            let checkboxes = this.state.filesCheckboxes;
            checkboxes.forEach(checkbox => (checkbox.checked = event.target.checked));
            this.setState({
                checkboxes: checkboxes,
                masterToggleChecked: event.target.checked,
            });
        };
        this.handleCountUpdate = event => {
            const exportFiltered = event.target.checked;
            this.setState({ exportFiltered });
            exportFiltered
                ? this.getRoomsCount(this.props.filters)
                : this.getRoomsCount({});
        };
        this.handleExport = (_) => __awaiter(this, void 0, void 0, function* () {
            const checked = this.getCheckedFiles();
            this.setState({ filesToExport: checked.length, isExporting: true });
            let pageableParams = {};
            if (this.state.exportFiltered) {
                pageableParams = this.props.filters;
            }
            try {
                for (let i = 0; i < checked.length && this.props.isOpen; i++) {
                    const cancelTokenSource = this.setCancelTokenSource();
                    const res = yield api.Rooms.exportGlobal(checked[i].fileId, cancelTokenSource, pageableParams);
                    this.saveExportFile(checked, i, res);
                    if (this.isLastExport(i, checked)) {
                        this.setState({ exportState: progressBarStatus.SUCCESS });
                    }
                }
            }
            catch (error) {
                this.setState({ exportState: progressBarStatus.ERROR });
            }
        });
        this.state = {
            totalRooms: 0,
            totalNumberOfFiles: 0,
            filesToExport: 0,
            exportedFiles: 0,
            isExporting: false,
            exportState: null,
            isExportError: false,
            filesCheckboxes: [],
            cancelTokenSource: null,
            masterToggleChecked: false,
            exportFiltered: false,
        };
    }
    componentDidMount() {
        this.getRoomsCount({});
    }
    getRoomsCount(filters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield api.Rooms.getRoomsCount(filters)
                .then(r => r.data)
                .then(totalRooms => {
                let totalNumberOfFiles = this.getTotalNumberOfFiles(totalRooms);
                const checkboxes = this.createFilesCheckBoxes(totalNumberOfFiles);
                this.setState({
                    totalRooms: totalRooms,
                    totalNumberOfFiles,
                    filesCheckboxes: checkboxes,
                });
            });
        });
    }
    componentDidUpdate(prevProps) {
        if (this.isModalOpened(prevProps)) {
            this.setInitState();
        }
    }
    setInitState() {
        this.getRoomsCount({}).then(r => {
            const checkboxes = this.createFilesCheckBoxes();
            this.setState({
                filesToExport: 0,
                exportedFiles: 0,
                isExporting: false,
                filesCheckboxes: checkboxes,
                exportState: null,
                cancelTokenSource: null,
                masterToggleChecked: false,
                exportFiltered: false,
            });
        });
    }
    isModalOpened(prevProps) {
        return this.props.isOpen !== prevProps.isOpen && this.props.isOpen;
    }
    createFilesCheckBoxes(totalNumberOfFiles) {
        let filesCount = totalNumberOfFiles;
        if (!filesCount) {
            filesCount = this.state.totalNumberOfFiles;
        }
        let checkboxes = [];
        for (let i = 1; i <= filesCount; i++) {
            checkboxes.push({ fileId: i.toString(), checked: false });
        }
        return checkboxes;
    }
    getTotalNumberOfFiles(totalRooms) {
        return Math.ceil(totalRooms / ROOMS_PER_FILE);
    }
    AllNotChecked() {
        return this.state.filesCheckboxes.every(c => c.checked === false);
    }
    cancelExport() {
        const cancelTokenSource = this.state.cancelTokenSource;
        cancelTokenSource.cancel();
    }
    isExportingFile() {
        return this.state.cancelTokenSource;
    }
    isLastExport(i, checked) {
        return i === checked.length - 1;
    }
    saveExportFile(checked, i, res) {
        const fileOrderNumber = parseInt(checked[i].fileId, 10) - 1;
        this.setState({ exportedFiles: i + 1 });
        let fileName = `${FILE_NAME} ${this.getFileRoomsRange(fileOrderNumber)}`;
        saveFileFromResponse(res, fileName);
    }
    getCheckedFiles() {
        let checkboxes = this.state.filesCheckboxes;
        return checkboxes.filter(checkbox => checkbox.checked);
    }
    setCancelTokenSource() {
        const cancelTokenSource = axios.CancelToken.source();
        this.setState({ cancelTokenSource: cancelTokenSource });
        return cancelTokenSource;
    }
    getFileRoomsRange(i) {
        let roomsRangeTo = this.isLastFileInTotal(i)
            ? this.state.totalRooms
            : (i + 1) * ROOMS_PER_FILE;
        return `${1 + i * ROOMS_PER_FILE} - ${roomsRangeTo}`;
    }
    isLastFileInTotal(i) {
        return this.state.totalNumberOfFiles - 1 === i;
    }
    renderProgressStatus() {
        switch (this.state.exportState) {
            case progressBarStatus.SUCCESS:
                return 'Successful export ';
            case progressBarStatus.ERROR:
                return 'Error during export of file ';
            case progressBarStatus.INFO:
            default:
                return 'Exported ';
        }
    }
    render() {
        const { t } = this.props;
        let exportAction;
        if (this.state.isExporting) {
            exportAction = (React.createElement(React.Fragment, null,
                React.createElement("div", { className: this.state.exportState === progressBarStatus.ERROR
                        ? 'block error-text'
                        : 'block' },
                    this.renderProgressStatus(),
                    this.state.exportedFiles,
                    " of ",
                    this.state.filesToExport,
                    " files."),
                React.createElement(ProgressBar, { status: this.state.exportState, value: this.state.exportedFiles, max: this.state.filesToExport })));
        }
        else {
            exportAction = (React.createElement(React.Fragment, null,
                React.createElement(Button, { onClick: this.handleExport, className: "is-primary", text: t('EXPORT_CSV'), disabled: this.AllNotChecked() }),
                React.createElement("section", { className: "modal-card-body  has-text-centered is-size-6" }, "When you first time download multiple files, please accept downloading multiple files (i.e. in chrome after successful downloading of first file should appear close to url bar modal box)")));
        }
        let exportFilteredCheckbox;
        if (this.hasAnyFilterApplied()) {
            exportFilteredCheckbox = (React.createElement("section", { className: "modal-card-body  has-text-centered is-size-6 export-admin-filter-checkbox" },
                React.createElement(CheckBox, { name: "exportFiltered", checked: this.state.exportFiltered, onChange: this.handleCountUpdate, disabled: this.state.isExporting }),
                React.createElement("p", null, t('ADMIN_EXPORT_FILTERED'))));
        }
        return (React.createElement("div", { className: `modal ${this.props.isOpen ? 'is-active' : ''}` },
            React.createElement("div", { className: "modal-background", onClick: this.handleOnClose }),
            React.createElement("div", { className: "modal-content" },
                React.createElement("header", { className: "modal-card-head" },
                    React.createElement("p", { className: "modal-card-title is-center" }, t('EXPORT_TO_CSV')),
                    React.createElement("button", { className: "modal-close", "aria-label": "close", onClick: this.handleOnClose, title: t('CLOSE'), icon: React.createElement(FAIcon, { icon: ['fas', 'times'] }) })),
                React.createElement("section", { className: "modal-card-body has-text-weight-bold has-text-centered is-size-5" }, t('DUE_TO_LARGE_ROOMS_NUMBER_AND_GOOGLE_RESTRICTION_ROOMS_EXPORT_NEEDS_TO_BE_DIVIDED_INTO_MULTIPLE_FILES')),
                exportFilteredCheckbox,
                React.createElement("section", { className: "modal-card-body  has-text-centered is-size-6" },
                    t('IN_EACH_FILE_THERE_WILL_BE', {
                        roomsPerFile: ROOMS_PER_FILE,
                    }),
                    React.createElement("br", null),
                    t('SELECT_FILES_WHICH_YOU_WANT_TO_DOWNLOAD')),
                React.createElement("footer", { className: "modal-card-foot flex-direction-column" },
                    React.createElement("table", { className: "table" },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", null,
                                    React.createElement(CheckBox, { name: "masterToggle", checked: this.state.masterToggleChecked, onChange: this.handleAllChecked, disabled: this.state.isExporting })),
                                React.createElement("th", null, "Select / Unselect All"))),
                        React.createElement("tbody", null, this.state.filesCheckboxes.map((checkBox, i) => {
                            return (React.createElement("tr", { key: checkBox.fileId },
                                React.createElement("td", null,
                                    React.createElement(CheckBox, { name: checkBox.fileId, checked: checkBox.checked, onChange: this.handleSingleChecked, disabled: this.state.isExporting })),
                                React.createElement("td", null,
                                    "File ",
                                    i + 1,
                                    " ",
                                    this.getFileRoomsRange(i))));
                        }))),
                    exportAction))));
    }
    hasAnyFilterApplied() {
        return Object.entries(this.props.filters).length > 0;
    }
}
RoomExportAdminModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    filters: PropTypes.object.isRequired,
};
export default withNamespaces('translations')(RoomExportAdminModal);
