import React, { Component } from 'react';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import './Search.scss';
import debounce from 'awesome-debounce-promise';
import { appConstants } from '../../../constants';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Button from '../Button/Button';
import classnames from 'classnames/bind';
class Search extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            inputValue: this.props.defaultValue,
        };
        this.search = debounce(this.props.updateSearch, this.props.debounceTime);
        this.handleChange = e => {
            const { value } = e.target;
            const { onChange } = this.props;
            this.setState({ inputValue: value }, () => {
                if (this.shouldSearch(value)) {
                    this.search(value);
                }
            });
            if (onChange)
                onChange(e);
        };
        this.onReset = () => {
            const { withButton, updateSearch } = this.props;
            this.setState({ inputValue: '' }, () => {
                if (!withButton && updateSearch) {
                    this.search('');
                }
            });
        };
        this.onKeyPress = e => {
            const { withButton } = this.props;
            if (withButton && e.key === 'Enter') {
                this.search(this.state.inputValue);
            }
        };
    }
    render() {
        const { placeholder, withButton, isLarge } = this.props;
        return (React.createElement("div", { className: "field is-marginless search search-component has-addons" },
            React.createElement("div", { className: "control is-expanded has-icons-left has-icons-right" },
                React.createElement("input", { onChange: this.handleChange, className: classnames('input is-rounded', { 'is-large': isLarge }), value: this.state.inputValue, placeholder: placeholder, onKeyPress: this.onKeyPress, type: "text" }),
                React.createElement("span", { className: "icon is-small is-left" },
                    React.createElement(FAIcon, { icon: ['fas', 'search'], className: "has-text-primary" })),
                this.state.inputValue && this.renderClearButton()),
            withButton && this.state.inputValue !== '' && (React.createElement("div", { className: "control" }, this.renderSearchButton()))));
    }
    shouldSearch(inputValue) {
        const { minLength, updateSearch, withButton } = this.props;
        return (!withButton &&
            updateSearch &&
            (inputValue.length >= minLength || inputValue.length === 0));
    }
    renderSearchButton() {
        const { t, updateSearch, isLarge } = this.props;
        return (React.createElement(Button, { onClick: () => updateSearch && this.search(this.state.inputValue), className: classnames('is-primary is-rounded', { 'is-large': isLarge }), text: t('SEARCH') }));
    }
    renderClearButton() {
        const { t } = this.props;
        return (React.createElement("a", { title: t('CLEAR'), href: null, onClick: this.onReset, className: "icon is-right" },
            React.createElement(FAIcon, { icon: ['fas', 'times'] })));
    }
}
Search.propTypes = {
    updateSearch: PropTypes.func,
    minLength: PropTypes.number,
    debounceTime: PropTypes.number,
    isLarge: PropTypes.bool,
};
Search.defaultProps = {
    minLength: 0,
    defaultValue: '',
    debounceTime: appConstants.DEFAULT_DEBOUNCE_TIME,
    isLarge: false,
};
export default withNamespaces('translations')(Search);
