import { userRoomsConstants } from '../constants';
export const roomsState = {
    rooms: [],
    roomsError: null,
    roomsLoading: false,
    pageNumber: 0,
    pageSize: 20,
    sort: [],
    totalPages: 1,
    totalElements: 0,
};
const userRooms = (state = roomsState, action) => {
    switch (action.type) {
        case userRoomsConstants.FETCH_USER_ROOMS:
            return Object.assign(Object.assign({}, state), { rooms: [...action.rooms], pageNumber: action.pageNumber, pageSize: action.pageSize, sort: action.sort, totalPages: action.totalPages, roomsLoading: false, totalElements: action.totalElements, roomsError: null });
        case userRoomsConstants.ERROR_USER_ROOMS:
            return Object.assign(Object.assign({}, state), { roomsError: action.error, rooms: [], roomsLoading: false });
        case userRoomsConstants.LOADING_USER_ROOMS:
            return Object.assign(Object.assign({}, state), { roomsLoading: true });
        default:
            return state;
    }
};
export default userRooms;
