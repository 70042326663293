import React, { Component } from 'react';
import { InfoTooltip, Tag } from '../../index';
import { withNamespaces } from 'react-i18next';
import { icon_building, icon_users, icon_type, icon_stairs, icon_map, icon_lock, } from '../../../images';
class RoomDetailsIconPanel extends Component {
    getBuildingInfoText(room) {
        const { t } = this.props;
        let array = [];
        if (room.externalLocation) {
            array.push(t('BUILDING_OUTSIDE_CAMPUS'));
        }
        if (room.restrictedAccess) {
            array.push(t('BUILDING_RESTRICTED'));
        }
        return array.join(', ');
    }
    getBuildingTooltipText(room) {
        const { t } = this.props;
        if (room.externalLocation && room.restrictedAccess) {
            return t('EXTERNAL_LOCATION_AND_RESTRICTED_AREA_INFO_TEXT');
        }
        else if (room.externalLocation) {
            return t('EXTERNAL_LOCATION_INFO_TEXT');
        }
        else if (room.restrictedAccess) {
            return t('RESTRICTED_AREA_INFO_TEXT');
        }
        return undefined;
    }
    getFloorSectionText(room) {
        return room.floorSection ? `, ${room.floorSection}` : '';
    }
    render() {
        const { t, room } = this.props;
        return (React.createElement("div", { className: "tags is-right room-details-icon-panel" },
            React.createElement(Tag, { className: "is-size-6", title: t('SITE'), img: icon_map, text: `${room.siteName} (${room.siteCode})` }),
            React.createElement(Tag, { className: "is-size-6", title: t('BUILDING'), img: icon_building, text: room.buildingName, subText: this.getBuildingInfoText(room), component: React.createElement(InfoTooltip, { name: "building-tooltip", text: this.getBuildingTooltipText(room) }) }),
            React.createElement(Tag, { className: "is-size-6", title: t('FLOOR'), img: icon_stairs, text: `${room.floorName}${this.getFloorSectionText(room)}` }),
            React.createElement(Tag, { className: "is-size-6", title: t('CAPACITY'), img: icon_users, text: room.capacity }),
            React.createElement(Tag, { className: "is-size-6", title: t('ROOM_TYPE'), img: icon_type, text: room.type.name, component: React.createElement(InfoTooltip, { name: "roomtype-tooltip", text: room.type.description }) }),
            React.createElement(Tag, { className: "is-size-6", title: t('PRIVACY'), img: icon_lock, text: !room.privacy ? t('PUBLIC') : t('PRIVATE'), component: React.createElement(InfoTooltip, { name: "privacy-tooltip", text: room.privacy
                        ? t('PRIVACY_PRIVATE_INFO_TEXT')
                        : t('PRIVACY_PUBLIC_INFO_TEXT') }) })));
    }
}
export default withNamespaces('translations')(RoomDetailsIconPanel);
