import { statusConstants } from '../constants';
import _ from 'underscore';
import { roomMaintenanceStatusConstants } from '../constants/roomMaintenanceStatus.constants';
export function filterActiveAndSortByDate(maintenances) {
    return _.chain(maintenances)
        .filter(m => m.status !== roomMaintenanceStatusConstants.CANCELED)
        .sortBy(m => {
        return m.from;
    })
        .value();
}
export function hasAnyActive(maintenances) {
    return maintenances && maintenances.length > 0 && isAnyActive(maintenances);
}
export function hasMaintenance(maintenances, status) {
    return hasAnyActive(maintenances) && validStatusForMaintenance(status);
}
function isAnyActive(maintenances) {
    const activeMaintenances = _.filter(maintenances, m => m.status !== roomMaintenanceStatusConstants.CANCELED);
    return activeMaintenances.length > 0;
}
function validStatusForMaintenance(status) {
    return (status === statusConstants.MAINTENANCE || status === statusConstants.ACTIVE);
}
