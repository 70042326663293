var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import withErrorHandling from '../WithErrorHandling/withErrorHandling';
import { AsyncReactSelectExternalValue, Button } from '../../index';
import { Field } from 'formik';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import api from '../../../api';
import FeaturesListModal from '../../FeaturesListModal/FeaturesListModal';
import PropTypes from 'prop-types';
class FeaturesInput extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            selectedFeatures: this.props.initFeatures,
            isModalOpen: false,
        };
        this.openModal = () => {
            this.setState({ isModalOpen: true });
        };
        this.addFeatureToForm = features => {
            this.props.onFeaturesChange(features);
            this.setState({ selectedFeatures: features });
        };
        this.searchFeatures = (searchQuery = '') => __awaiter(this, void 0, void 0, function* () {
            const res = yield api.Features.search(searchQuery);
            return res.data;
        });
    }
    renderModal() {
        const { isModalOpen, selectedFeatures } = this.state;
        return (React.createElement(FeaturesListModal, { isOpen: isModalOpen, onClose: () => {
                this.setState({ isModalOpen: false });
            }, onSubmit: features => {
                console.log(features);
                this.props.onFeaturesChange(features);
                this.setState({
                    isModalOpen: false,
                    selectedFeatures: features,
                });
            }, selectedFeaturesInForm: selectedFeatures }));
    }
    render() {
        const { selectedFeatures } = this.state;
        const { t } = this.props;
        return (React.createElement("div", { className: "feature-input" },
            this.renderModal(),
            React.createElement(Field, { value: selectedFeatures, name: "featureIds", component: AsyncReactSelectExternalValue, isMulti: true, placeholder: t('SELECT_FEATURES_PLACEHOLDER'), getOptionLabel: e => `${e.name} (${e.category})`, getOptionValue: e => e.id, loadOptions: this.searchFeatures, className: "bulk-features", addValue: this.addFeatureToForm }),
            React.createElement(Button, { type: "button", className: "features-list-button", onClick: this.openModal, icon: React.createElement(FAIcon, { icon: ['fas', 'list-alt'] }) })));
    }
}
FeaturesInput.defaultProps = {
    initFeatures: [],
};
FeaturesInput.propTypes = {
    initFeatures: PropTypes.array,
};
export default withNamespaces('translations')(withRouter(withErrorHandling(FeaturesInput)));
