import React from 'react';
import { compose, withProps } from 'recompose';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { marker2x } from '../../../images';
const MapComponent = compose(withProps({
    loadingElement: React.createElement("div", { style: { height: `100%` } }),
    containerElement: React.createElement("div", { className: "map-container" }),
    mapElement: React.createElement("div", { style: { height: `100%` } }),
}), withGoogleMap)(props => (React.createElement(GoogleMap, { defaultZoom: 14, defaultCenter: props.coordinates },
    React.createElement(Marker, { position: props.coordinates, icon: {
            url: marker2x,
            scaledSize: { width: 33, height: 39 },
        } }))));
class Map extends React.PureComponent {
    render() {
        return React.createElement(MapComponent, Object.assign({}, this.props));
    }
}
export default Map;
