const admin = '/admin';
const sites = '/sites';
const buildings = '/buildings';
const features = '/features';
export const rooms = '/rooms';
const roomTypes = '/room-types';
const administrators = '/administrators';
const maintenances = '/maintenances';
export const routes = {
    auth: '/auth',
    logout: '/logout',
    admin: admin,
    siteAdd: `${admin}${sites}/add`,
    siteEdit: `${admin}${sites}/edit/:code`,
    sites: `${admin}${sites}`,
    buildingAdd: `${admin}${buildings}/add`,
    buildingEdit: `${admin}${buildings}/edit/:id`,
    buildingDetails: `${admin}${buildings}/details/:id`,
    buildings: `${admin}${buildings}`,
    featureAdd: `${admin}${features}/add`,
    featureEdit: `${admin}${features}/edit/:id`,
    featureDetails: `${admin}${features}/details/:id`,
    features: `${admin}${features}`,
    roomAdd: `${admin}${rooms}/add`,
    roomEdit: `${admin}${rooms}/edit/:id`,
    roomDuplicate: `${admin}${rooms}/duplicate/:id`,
    roomsBulkEdit: `${admin}${rooms}/bulkEdit`,
    roomDetails: `${admin}${rooms}/details/:id`,
    rooms: `${admin}${rooms}`,
    roomTypeAdd: `${admin}${roomTypes}/add`,
    roomTypeEdit: `${admin}${roomTypes}/edit/:id`,
    roomTypeDetails: `${admin}${roomTypes}/details/:id`,
    roomTypes: `${admin}${roomTypes}`,
    administratorAdd: `${admin}${administrators}/add`,
    administratorEdit: `${admin}${administrators}/edit/:email`,
    administrators: `${admin}${administrators}`,
    userRoomDetails: `${rooms}/details/:id`,
    roomsMaintenances: `${admin}${maintenances}`,
    userRooms: rooms,
    error404: '/404',
};
