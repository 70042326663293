import Yup from '../yup';
const name = Yup.string()
    .label('NAME')
    .max(255, 'FEATURE_NAME_MAX')
    .required('FEATURE_NAME_REQUIRED');
const category = Yup.string()
    .label('CATEGORY')
    .required('FEATURE_CATEGORY_REQUIRED');
const translation = Yup.object().shape({
    name: Yup.string()
        .max(255, 'FEATURE_NAME_MAX')
        .required('FEATURE_TRANSLATION_NAME_REQUIRED'),
    locale: Yup.string().required('FEATURE_TRANSLATION_LANGUAGE_REQUIRED'),
});
const translations = Yup.array()
    .label('TRANSLATIONS')
    .of(translation)
    .unique('locale', 'TRANSLATION_LANGUAGE_UNIQUE');
const FeaturesSchema = Yup.object().shape({
    name,
    category,
    translations,
});
export { FeaturesSchema };
