import React, { Component } from 'react';
import { ConfirmModal } from '../../../../index';
import { withNamespaces } from 'react-i18next';
class DeletedRoomInfoModal extends Component {
    render() {
        const { t, isOpen, onClose, onConfirm, name, isMulti } = this.props;
        return (React.createElement(ConfirmModal, { title: t('DELETE'), text: isMulti
                ? t('MODAL_DELETE_ROOMS_INFO')
                : t('MODAL_DELETE_ROOM_INFO', {
                    name: name,
                }), onClose: () => {
                onClose();
            }, isOpen: isOpen, primaryButton: {
                label: t('OK'),
                type: 'is-primary',
                action: onClose => {
                    onConfirm();
                    onClose();
                },
            } }));
    }
}
export default withNamespaces('translations')(DeletedRoomInfoModal);
