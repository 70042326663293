var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';
function hasIconImage(icon) {
    return icon.props.icon[1];
}
function shouldRenderIcon(icon) {
    return icon && typeof icon !== undefined && hasIconImage(icon) !== undefined;
}
const Button = (_a) => {
    var { text, icon, icon2, className, isLoading } = _a, props = __rest(_a, ["text", "icon", "icon2", "className", "isLoading"]);
    const ButtonOrLink = props.to ? Link : 'button';
    return (React.createElement(ButtonOrLink, Object.assign({ className: classnames('button', className, {
            'is-loading': isLoading,
        }), type: !props.to ? 'button' : undefined }, props),
        icon && React.createElement("span", { className: "icon" }, icon),
        shouldRenderIcon(icon2) && React.createElement("span", { className: "icon" }, icon2),
        text && React.createElement("span", null, text)));
};
export default Button;
