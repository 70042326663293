import { routes } from './routes.constants';
export const resourcesMenu = {
    [routes.sites]: 'SITES',
    [routes.siteAdd]: 'SITES',
    [routes.siteEdit]: 'SITES',
    [routes.buildings]: 'BUILDINGS',
    [routes.buildingAdd]: 'BUILDINGS',
    [routes.buildingEdit]: 'BUILDINGS',
    [routes.buildingDetails]: 'BUILDINGS',
    [routes.rooms]: 'ROOMS',
    [routes.roomAdd]: 'ROOMS',
    [routes.roomEdit]: 'ROOMS',
    [routes.roomDuplicate]: 'ROOMS',
    [routes.roomsBulkEdit]: 'ROOMS',
    [routes.roomDetails]: 'ROOMS',
    [routes.roomTypes]: 'ROOM_TYPES',
    [routes.roomTypeAdd]: 'ROOM_TYPES',
    [routes.roomTypeEdit]: 'ROOM_TYPES',
    [routes.roomTypeDetails]: 'ROOM_TYPES',
    [routes.features]: 'FEATURES',
    [routes.featureAdd]: 'FEATURES',
    [routes.featureEdit]: 'FEATURES',
    [routes.featureDetails]: 'FEATURES',
    [routes.roomsMaintenances]: 'MAINTENANCES',
};
