import { loadingConstants } from '../constants';
const loading = (state = 0, action) => {
    switch (action.type) {
        case loadingConstants.SHOW_LOADER:
            return state + 1;
        case loadingConstants.HIDE_LOADER:
            return state - 1;
        default:
            return state;
    }
};
export default loading;
