var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { Button, ConfirmModal } from '../../../../index';
import DatePicker from 'react-datepicker';
import { changeTime, compareDates, setTime, fixEndDate, } from '../../../../../utils';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { withNamespaces } from 'react-i18next';
import i18n from '../../../../../i18n';
import { roomsMaintenancesActions } from '../../../../../actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toastError } from '../../../../../toasts';
import classnames from 'classnames/bind';
import { trimToMinutes } from '../../../../../utils';
const MAINTENANCE_TITLE = i18n.t('ROOM_MAINTENANCE_REQUESTED_BY_ROOM_ADMINISTRATOR');
class MaintenanceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            maintenanceTitle: MAINTENANCE_TITLE,
            reason: '',
            exportModalInfo: false,
        };
        this.exportAffectedOrganizers = this.exportAffectedOrganizers.bind(this);
    }
    exportAffectedOrganizers() {
        return __awaiter(this, void 0, void 0, function* () {
            const { startDate, endDate } = this.state;
            const { roomIds, exportAffectedOrganizers } = this.props;
            this.setState({ exportModalInfo: true });
            const response = yield exportAffectedOrganizers(startDate, endDate, roomIds);
            if (response instanceof Error) {
                if (response.response.data.reason) {
                    toastError(response.response.data.reason);
                }
                else {
                    toastError('ERROR_EXPORT_FILE');
                }
            }
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { editMaintenance, isOpen } = this.props;
        if (editMaintenance && prevProps.editMaintenance !== editMaintenance) {
            this.setState({
                startDate: new Date(trimToMinutes(editMaintenance.from)),
                endDate: new Date(trimToMinutes(editMaintenance.to)),
                maintenanceTitle: editMaintenance.title,
                reason: editMaintenance.reason,
            });
        }
        else if (isOpen && prevProps.isOpen !== isOpen) {
            this.setState({
                startDate: null,
                endDate: null,
                maintenanceTitle: MAINTENANCE_TITLE,
                reason: '',
            });
        }
    }
    render() {
        const { t, isOpen, onClose, onSet, editMaintenance, bulkEdit } = this.props;
        const { startDate, endDate, reason, maintenanceTitle } = this.state;
        return (React.createElement(Fragment, null,
            React.createElement(ConfirmModal, { title: t('MAINTENANCE'), text: React.createElement(Fragment, null,
                    React.createElement("div", { className: "field is-horizontal" },
                        React.createElement("div", { className: "field-label is-small width-200" },
                            React.createElement("label", { className: "label" },
                                t('MAINTENANCE_FROM'),
                                ":")),
                        React.createElement("div", { className: "field-body" },
                            React.createElement("div", { className: "field has-addons" },
                                React.createElement("div", { className: "control is-expanded" },
                                    React.createElement(DatePicker, { disabled: editMaintenance || bulkEdit, withPortal: true, showTimeSelect: true, timeFormat: "h:mm aa", timeIntervals: 60, timeCaption: t('TIME'), minTime: !compareDates(startDate, new Date())
                                            ? setTime(new Date(), 0, 0)
                                            : new Date(), maxTime: compareDates(startDate, endDate || new Date())
                                            ? setTime(new Date(), 23, 0)
                                            : endDate || setTime(new Date(), 23, 0), className: "input", minDate: new Date(), maxDate: endDate, selected: startDate, onChange: startDate => this.setState({
                                            startDate: new Date(trimToMinutes(startDate)),
                                        }), dateFormat: "d-MMM-yyyy h:mm aa" })),
                                React.createElement("div", { className: "control" },
                                    React.createElement(Button, { disabled: editMaintenance || bulkEdit, onClick: () => this.setState({ startDate: null, endDate: null }), className: "button", icon: React.createElement(FAIcon, { icon: ['fas', 'times'] }) }))))),
                    React.createElement("div", { className: "field is-horizontal" },
                        React.createElement("div", { className: "field-label is-small width-200" },
                            React.createElement("label", { className: "label" },
                                t('MAINTENANCE_TO'),
                                ":")),
                        React.createElement("div", { className: "field-body" },
                            React.createElement("div", { className: "field has-addons" },
                                React.createElement("div", { className: "control is-expanded" },
                                    React.createElement(DatePicker, { withPortal: true, selectsEnd: true, showTimeSelect: true, timeFormat: "h:mm aa", timeIntervals: 60, timeCaption: t('TIME'), minTime: compareDates(startDate, endDate)
                                            ? setTime(new Date(), 0, 0)
                                            : changeTime(startDate, +1), maxTime: setTime(startDate, 23, 0), disabled: !startDate || editMaintenance || bulkEdit, className: "input", selected: endDate, minDate: startDate, onChange: endDate => {
                                            const date = compareDates(startDate, endDate)
                                                ? endDate
                                                : fixEndDate(startDate, endDate);
                                            this.setState({
                                                endDate: new Date(trimToMinutes(date)),
                                            });
                                        }, dateFormat: "d-MMM-yyyy h:mm aa" })),
                                React.createElement("div", { className: "control" },
                                    React.createElement(Button, { disabled: !startDate || editMaintenance || bulkEdit, className: "button", onClick: () => this.setState({ endDate: null }), icon: React.createElement(FAIcon, { icon: ['fas', 'times'] }) }))))),
                    React.createElement("div", { className: "field is-horizontal" },
                        React.createElement("div", { className: "field-label is-small width-200" },
                            React.createElement("label", { className: "label" },
                                t('TITLE'),
                                ":")),
                        React.createElement("div", { className: "field-body" },
                            React.createElement("textarea", { className: "textarea", value: maintenanceTitle, onChange: event => this.setState({ maintenanceTitle: event.target.value }), rows: "1" }))),
                    React.createElement("div", { className: "field is-horizontal" },
                        React.createElement("div", { className: "field-label is-small width-200" },
                            React.createElement("label", { className: "label" },
                                t('REASON_MAX_1000'),
                                ":")),
                        React.createElement("div", { className: "field-body" },
                            React.createElement("textarea", { className: "textarea", value: reason, maxLength: "1000", onChange: event => this.setState({ reason: event.target.value }) }))),
                    React.createElement(Button, { className: classnames('is-light', {}), text: i18n.t('GET_AFFECTED_EVENTS'), disabled: !startDate || !endDate, icon: React.createElement(FAIcon, { icon: ['fas', 'file-export'] }), onClick: this.exportAffectedOrganizers })), onClose: () => onClose(), isOpen: isOpen, primaryButton: {
                    label: t('MAINTENANCE_SET'),
                    type: 'is-primary',
                    props: {
                        disabled: (!startDate || !endDate) && !bulkEdit,
                    },
                    action: onClose => {
                        const data = {
                            startDate,
                            endDate,
                            reason,
                            maintenanceTitle,
                        };
                        onSet(data);
                        onClose({ preserveEdit: true });
                    },
                }, secondaryButton: {
                    label: t('CANCEL'),
                    type: 'is-light',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t('MODAL_INFO_EXPORT_AFFECTED_EVENTS'), onClose: () => {
                    this.setState({ exportModalInfo: false });
                }, isOpen: this.state.exportModalInfo, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } })));
    }
}
const mapDispatchToProps = dispatch => ({
    exportAffectedOrganizers: (startDate, endDate, ids) => dispatch(roomsMaintenancesActions.exportAffectedOrganizersByMaintenance(startDate, endDate, ids)),
});
MaintenanceModal.defaultProps = {
    bulkEdit: false,
};
export default withNamespaces('translations')(withRouter(connect(null, mapDispatchToProps)(MaintenanceModal)));
