import React, { Fragment } from 'react';
const TitleSection = ({ title, subtitle, small, children, contentBottom, isNarrow = true, }) => {
    const titleFragment = (React.createElement(Fragment, null,
        React.createElement("h1", { className: "title is-marginless" }, title),
        subtitle ? React.createElement("h3", { className: "subtitle" }, subtitle) : null,
        small ? React.createElement("small", { className: "has-text-grey-light" }, small) : null));
    const titleIsNarrow = isNarrow ? (React.createElement("div", { className: "column is-narrow is-narrow-tablet" }, titleFragment)) : (titleFragment);
    return (React.createElement("section", { id: "title-section", className: "section" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "columns is-gapless is-marginless" },
                title && titleIsNarrow,
                React.createElement("div", { className: "column is-clearfix" }, children)),
            React.createElement("div", null, contentBottom))));
};
export default TitleSection;
