import { TableHeaderCell } from '../../../components';
import { Link } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { routes } from '../../../constants';
import React from 'react';
import i18n from '../../../i18n';
import { renderRow, renderRowWithOldState } from './roomsBulkTable.utils';
export function toRoomListRowDto(room) {
    return {
        roomId: room.id,
        roomName: room.name,
        roomCapacity: room.capacity,
        roomTypeName: room.type.name,
        roomTypeId: room.type.id,
        roomDescription: room.localDescription,
        privacy: room.privacy,
        oldState: room.oldState,
    };
}
export function toBulkRoomUpdateDto(room) {
    return {
        roomId: room.roomId,
        capacity: room.roomCapacity,
        localDescription: room.roomDescription,
        typeId: room.roomTypeId,
        privacy: room.privacy,
    };
}
export function getColumns(lastAction) {
    return [
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('NAME') }),
            accessor: 'roomName',
            filterable: false,
            maxWidth: 235,
            Cell: cellInfo => (React.createElement(Link, { to: formatRoute(routes.roomDetails, {
                    id: cellInfo.original.roomId,
                }) }, cellInfo.original.roomName)),
        },
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('CAPACITY') }),
            accessor: 'roomCapacity',
            filterable: false,
            sortable: false,
            maxWidth: 150,
            Cell: cellInfo => renderCapacityCell(cellInfo, lastAction),
        },
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('ROOM_TYPE') }),
            accessor: 'roomTypeName',
            filterable: false,
            sortable: false,
            maxWidth: 240,
            Cell: cellInfo => renderRoomTypeCell(cellInfo, lastAction),
        },
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('DESCRIPTION') }),
            accessor: 'roomDescription',
            filterable: false,
            sortable: false,
            maxWidth: 480,
            Cell: cellInfo => renderDescriptionCell(cellInfo, lastAction),
        },
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('BOOKING_METHOD') }),
            id: 'bookingMethod',
            accessor: row => ({
                privacy: row.privacy,
            }),
            Cell: cellInfo => renderRoomBookingTypeCell(cellInfo, lastAction),
            filterable: false,
            sortable: false,
            maxWidth: 235,
        },
    ];
}
function renderCapacityCell(cellInfo, lastAction) {
    const oldState = cellInfo.original.oldState;
    const actualValue = cellInfo.original.roomCapacity;
    if (oldState) {
        const oldValue = cellInfo.original.oldState.capacity;
        return renderRowWithOldState(oldValue, actualValue, lastAction);
    }
    else {
        return renderRow(actualValue);
    }
}
function renderRoomTypeCell(cellInfo, lastAction) {
    const oldState = cellInfo.original.oldState;
    const actualValue = cellInfo.original.roomTypeName;
    if (oldState) {
        const oldValue = cellInfo.original.oldState.type.name;
        return renderRowWithOldState(oldValue, actualValue, lastAction);
    }
    else {
        return renderRow(actualValue);
    }
}
function renderDescriptionCell(cellInfo, lastAction) {
    const oldState = cellInfo.original.oldState;
    const actualValue = cellInfo.original.roomDescription;
    if (oldState) {
        const oldValue = cellInfo.original.oldState.localDescription;
        return renderRowWithOldState(oldValue, actualValue, lastAction);
    }
    else {
        return renderRow(actualValue);
    }
}
function renderRoomBookingTypeCell(cellInfo, lastAction) {
    const oldState = cellInfo.original.oldState;
    const actualValue = getBookingMethodCellValue(cellInfo.original.privacy);
    if (oldState) {
        const oldValue = getBookingMethodCellValue(cellInfo.original.oldState.privacy);
        return renderRowWithOldState(oldValue, actualValue, lastAction);
    }
    else {
        return renderRow(actualValue);
    }
}
function getBookingMethodCellValue(privacy) {
    return privacy === true ? i18n.t('PRIVATE_ROOM') : i18n.t('PUBLIC_ROOM');
}
