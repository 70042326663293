import { ConfirmModal } from '../../../../../index';
import i18n from '../../../../../../i18n';
import React from 'react';
import { toFullDateTime } from '../../../../../../utils/dateFormatter.utils';
const RoomAvailabilityEventModal = props => {
    const { event, onClose } = props;
    if (!event) {
        return null;
    }
    return (React.createElement(ConfirmModal, { title: event.title, text: React.createElement("div", { className: "event-modal-body" },
            React.createElement("div", { className: "event-modal-row" },
                React.createElement("p", null,
                    i18n.t('START_TIME'),
                    ": "),
                React.createElement("p", null, toFullDateTime(event.start))),
            React.createElement("div", { className: "event-modal-row" },
                React.createElement("p", null,
                    i18n.t('END_TIME'),
                    ": "),
                React.createElement("p", null, toFullDateTime(event.end))),
            React.createElement("div", { className: "event-modal-row" },
                React.createElement("p", null,
                    i18n.t('ORGANIZER'),
                    ": "),
                React.createElement("p", null, event.organizer))), onClose: () => {
            onClose();
        }, isOpen: event, primaryButton: null, footerClassName: "footer-no-margin-bottom", headerClassName: "event-modal-header", customModalClassName: "event-modal" }));
};
export default RoomAvailabilityEventModal;
