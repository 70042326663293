var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Accordion, Table } from '../../index';
import RoomsClickableLinkBulkEditForm from './RoomsClickableLinkBulkEditForm/RoomsClickableLinkBulkEditForm';
import { roomsActions } from '../../../actions';
import { getColumns, toRoomListRowDto, } from '../../../utils/RoomsUtils/RoomsBulkTableUtils/roomsBulkClickableLinks.utils';
import { clickableLinkActionsConstants as bulkAction } from '../../../constants/roomsBulk.constants';
class RoomsClickableLinkBulkEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rooms: this.props.rooms,
            lastChangeRooms: 0,
            lastRoomsState: [],
            lastFeatureIds: [],
            lastAction: null,
        };
        this.handleAdd = this.handleAdd.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRemoveAll = this.handleRemoveAll.bind(this);
        this.handleUndo = this.handleUndo.bind(this);
    }
    handleAdd(values, resetForm) {
        return __awaiter(this, void 0, void 0, function* () {
            const { bulkRoomClickableLinkAdd, selectedRoomsIds } = this.props;
            const isSuccessful = this.updateState(yield bulkRoomClickableLinkAdd(selectedRoomsIds, values.name, values.url), bulkAction.ADD);
            if (isSuccessful) {
                resetForm();
            }
        });
    }
    handleRemove(values, resetForm) {
        return __awaiter(this, void 0, void 0, function* () {
            const { bulkRoomClickableLinkRemove, selectedRoomsIds } = this.props;
            const isSuccessful = this.updateState(yield bulkRoomClickableLinkRemove(selectedRoomsIds, values.name, values.url), bulkAction.REMOVE);
            if (isSuccessful) {
                resetForm();
            }
        });
    }
    handleRemoveAll() {
        return __awaiter(this, void 0, void 0, function* () {
            const { bulkRoomClickableLinkRemoveAll, selectedRoomsIds } = this.props;
            this.updateState(yield bulkRoomClickableLinkRemoveAll(selectedRoomsIds), bulkAction.REMOVE_ALL);
        });
    }
    handleUndo() {
        return __awaiter(this, void 0, void 0, function* () {
            this.updateState(yield this.props.bulkRoomClickableLinkUndo(this.state.lastRoomsState), bulkAction.UNDO);
        });
    }
    updateState(updatedRooms, action) {
        if (updatedRooms && !(updatedRooms instanceof Error)) {
            const roomsFreshState = updatedRooms.map(room => {
                return toRoomListRowDto(room);
            });
            if (action === bulkAction.UNDO) {
                this.setState({
                    rooms: roomsFreshState,
                    lastRoomsState: [],
                    lastAction: null,
                });
            }
            else {
                this.setState({
                    rooms: roomsFreshState,
                    lastRoomsState: [...this.state.rooms],
                    lastAction: action,
                });
            }
            return true;
        }
        return false;
    }
    render() {
        const { t } = this.props;
        const { lastAction } = this.state;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "header-panel" },
                    React.createElement(Accordion, { textShow: t('CLICKABLE_LINKS'), textHide: t('CLICKABLE_LINKS'), isOpen: true, className: "accordion-section-title" },
                        React.createElement(RoomsClickableLinkBulkEditForm, { lastRoomsState: this.state.lastRoomsState, onUndo: this.handleUndo, onAdd: this.handleAdd, onRemove: this.handleRemove, onRemoveAll: this.handleRemoveAll }),
                        React.createElement(Table, { data: this.state.rooms, columns: getColumns(lastAction), showPaginationTop: false, showPaginationBottom: false }))))));
    }
}
const mapDispatchToProps = dispatch => ({
    bulkRoomClickableLinkAdd: (ids, linkName, linkUrl) => dispatch(roomsActions.bulkRoomClickableLinkAdd(ids, linkName, linkUrl)),
    bulkRoomClickableLinkRemove: (ids, linkName, linkUrl) => dispatch(roomsActions.bulkRoomClickableLinkRemove(ids, linkName, linkUrl)),
    bulkRoomClickableLinkRemoveAll: ids => dispatch(roomsActions.bulkRoomClickableLinkRemoveAll(ids)),
    bulkRoomClickableLinkUndo: rooms => dispatch(roomsActions.bulkRoomClickableLinkUndo(rooms)),
});
export default withNamespaces('translations')(connect(null, mapDispatchToProps)(RoomsClickableLinkBulkEdit));
