var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Accordion, Button, FormControl, FormErrorNotification, ReactSelect, Table, } from '../../index';
import { Field, Form, Formik } from 'formik';
import api from '../../../api';
import { roomsActions } from '../../../actions';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames/bind';
import { deleteKeyIfBlankOrWhitespace } from '../../../utils/object.utils';
import { getColumns, toRoomListRowDto, toBulkRoomUpdateDto, } from '../../../utils/RoomsUtils/RoomsBulkTableUtils/roomsBulkAttributes.utils';
import { bulkBaseActionConstants as bulkAction } from '../../../constants/roomsBulk.constants';
class RoomAttributesBulkEdit extends Component {
    constructor(props) {
        super(props);
        this.fetchRoomTypes = (searchQuery = '') => __awaiter(this, void 0, void 0, function* () {
            const res = yield api.RoomTypes.search(searchQuery);
            return res.data;
        });
        this.onSetIsSubmitTypeApply = isSubmitApply => () => {
            this.setState({ isSubmitApply: isSubmitApply });
        };
        const { t } = this.props;
        this.state = {
            rooms: this.props.rooms,
            roomTypes: [],
            bookingMethods: [
                {
                    private: true,
                    name: t('PRIVATE_ROOM'),
                },
                {
                    private: false,
                    name: t('PUBLIC_ROOM'),
                },
            ],
            lastRoomsState: [],
            lastAction: null,
        };
    }
    getInitialFormState() {
        return {
            capacity: '',
            typeId: '',
            localDescription: '',
            privacy: '',
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.loadData();
        });
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            this.setState({
                roomTypes: Object.values(yield this.fetchRoomTypes()),
            });
        });
    }
    onRoomsUpdate(updatedRooms, isUndo) {
        if (updatedRooms && !(updatedRooms instanceof Error)) {
            const roomsFreshState = updatedRooms.map(room => {
                return toRoomListRowDto(room);
            });
            if (isUndo) {
                this.setState({
                    lastRoomsState: [],
                    rooms: roomsFreshState,
                    lastAction: bulkAction.UNDO,
                });
            }
            else {
                this.setState({
                    lastRoomsState: [...this.state.rooms],
                    rooms: roomsFreshState,
                    lastAction: bulkAction.APPLY,
                });
            }
            return true;
        }
        return false;
    }
    onChangeApply(values, resetForm) {
        return __awaiter(this, void 0, void 0, function* () {
            const { bulkRoomAttributeUpdate } = this.props;
            const requestData = this.props.selectedRoomsIds.map(roomId => {
                return Object.assign({ roomId }, values);
            });
            const isSuccessful = this.onRoomsUpdate(yield bulkRoomAttributeUpdate(requestData), false);
            if (isSuccessful) {
                resetForm();
            }
        });
    }
    onSubmit(values, { resetForm }) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.state.isSubmitApply) {
                const valuesToSave = deleteKeyIfBlankOrWhitespace(values);
                this.onChangeApply(valuesToSave, resetForm);
            }
            else {
                this.onUndoClicked(resetForm);
            }
        });
    }
    onUndoClicked(resetForm) {
        return __awaiter(this, void 0, void 0, function* () {
            const { bulkRoomAttributeUpdate } = this.props;
            const requestData = this.state.lastRoomsState.map(room => {
                return toBulkRoomUpdateDto(room);
            });
            const isSuccessful = this.onRoomsUpdate(yield bulkRoomAttributeUpdate(requestData), true);
            if (isSuccessful) {
                resetForm();
            }
        });
    }
    render() {
        const { t } = this.props;
        const { lastAction } = this.state;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "content" },
                    React.createElement(Accordion, { textShow: t('ROOM_ATTRIBUTE'), textHide: t('ROOM_ATTRIBUTE'), isOpen: true, className: "accordion-section-title" },
                        React.createElement(Formik, { enableReinitialize: true, initialValues: this.getInitialFormState(), onSubmit: this.onSubmit.bind(this) }, form => {
                            const { isSubmitting, errors, touched, dirty } = form;
                            return (React.createElement(Form, { className: "bulk-room-attributes-wrapper" },
                                React.createElement(FormErrorNotification, Object.assign({ t: t }, form)),
                                React.createElement("div", { className: "bulk-button-wrapper" },
                                    React.createElement(Button, { type: "submit", className: "is-primary", text: t('APPLY_ALL'), isLoading: isSubmitting, disabled: !dirty, onClick: this.onSetIsSubmitTypeApply(true) }),
                                    React.createElement(Button, { className: "is-grey", icon: React.createElement(FAIcon, { icon: ['fas', 'undo'] }), tooltip: t('UNDO_LAST_CHANGE'), disabled: this.state.lastRoomsState &&
                                            this.state.lastRoomsState.length === 0, type: "submit", onClick: this.onSetIsSubmitTypeApply(false) })),
                                React.createElement(FormControl, { label: t('CAPACITY') },
                                    React.createElement(Field, { className: classnames('input', 'grid-align-bottom', {
                                            'is-danger': errors.capacity && touched.capacity,
                                        }), type: "number", name: "capacity", min: "0" })),
                                React.createElement(FormControl, { label: t('ROOM_TYPE') },
                                    React.createElement(Field, { name: "typeId", component: ReactSelect, placeholder: "", getOptionLabel: e => e.name, getOptionValue: e => e.id, options: Object.values(this.state.roomTypes) })),
                                React.createElement(FormControl, { label: t('DESCRIPTION') },
                                    React.createElement(Field, { className: "input", name: "localDescription" })),
                                React.createElement(FormControl, { label: t('BOOKING_METHOD') },
                                    React.createElement(Field, { name: "privacy", placeholder: "", component: ReactSelect, getOptionLabel: e => e.name, getOptionValue: e => e.private, options: Object.values(this.state.bookingMethods) }))));
                        }),
                        React.createElement(Table, { data: this.state.rooms, columns: getColumns(lastAction), showPaginationTop: false, showPaginationBottom: false }))))));
    }
}
const mapDispatchToProps = dispatch => ({
    bulkRoomAttributeUpdate: data => dispatch(roomsActions.bulkRoomAttributeUpdate(data)),
});
export default withNamespaces('translations')(connect(null, mapDispatchToProps)(RoomAttributesBulkEdit));
