var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { loadingActions } from './loading.actions';
import { errorActions } from './';
import api from '../api';
import { userRoomsConstants } from '../constants';
const loadUserRooms = params => {
    const { sort } = params;
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(userRoomsLoading());
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.userRoomsList(params);
            const { content, number, totalPages, size, totalElements, } = response.data;
            dispatch(userRoomsSuccess(content, number, totalPages, size, sort, totalElements));
            dispatch(loadingActions.hideLoader());
        }
        catch (error) {
            dispatch(userRoomsError(error, loadUserRooms, [params]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const userRoomsSuccess = (rooms, pageNumber, totalPages, pageSize, sort, totalElements, listParams) => ({
    type: userRoomsConstants.FETCH_USER_ROOMS,
    rooms,
    pageNumber,
    totalPages,
    pageSize,
    sort,
    totalElements,
    listParams,
});
const userRoomsError = (error, actionFunction, actionParams) => {
    return dispatch => {
        dispatch(errorActions.error(error, actionFunction, actionParams, {
            type: userRoomsConstants.ERROR_USER_ROOMS,
            error,
        }));
    };
};
const userRoomsLoading = () => ({
    type: userRoomsConstants.LOADING_USER_ROOMS,
});
export const userRoomsActions = {
    loadUserRooms,
};
