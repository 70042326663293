var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { userConstants, endpoints, userRoleConstants } from '../constants';
import axios from 'axios';
import { errorActions } from './';
import api from '../api';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
const loginStatus = () => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        const isLogged = true;
        dispatch({
            type: userConstants.UPDATE_USER_LOGIN_STATUS,
            isLogged,
        });
    });
};
const loadUserFromJWT = (encodedUser, role) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        let user = jwt_decode(encodedUser);
        console.log(user);
        let userData = {
            photo: user.picture,
            email: user.email,
            firstname: user.given_name,
            lastname: user.family_name,
            fullname: user.name,
        };
        setAuthDefaults();
        if (role) {
            userData.role = role;
        }
        else {
            yield dispatch(userRole(userData.email));
        }
        yield dispatch({
            type: userConstants.UPDATE_USER_DATA,
            userData: Object.assign(Object.assign({}, userData), { isLogged: true }),
        });
    });
};
const handleUnauthorized = () => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(clearUser());
    });
};
const clearUser = () => {
    Cookies.remove('CRMS_USER');
    return dispatch => {
        const userData = {
            photo: null,
            email: null,
            firstname: null,
            lastname: null,
            fullname: null,
            role: null,
            isLogged: false,
        };
        dispatch({
            type: userConstants.UPDATE_USER_DATA,
            userData,
        });
    };
};
export const newTokenRequest = (code, reloadWindow) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield api.Token.newTokenRequest(code);
            let idToken = response.data.idToken;
            let role = response.data.role;
            Cookies.set('CRMS_USER', idToken, {
                expires: 365,
            });
            if (idToken) {
                yield dispatch(loadUserFromJWT(idToken, role));
            }
            if (reloadWindow) {
                window.location.reload();
            }
        }
        catch (error) {
            console.log(error);
        }
    });
};
const setAuthDefaults = () => {
    axios.defaults.headers.common['Content-Type'] =
        'application/json; charset=utf-8';
    axios.defaults.baseURL = endpoints.url;
    axios.defaults.withCredentials = true;
};
const userRole = email => {
    const { USER, SITE_ADMIN } = userRoleConstants;
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield api.CurrentUser.getRole();
            if (response.headers['content-type'] === 'text/html; charset=UTF-8') {
                dispatch(userRole(email));
            }
            else {
                dispatch(getUserRole(response.data.role ? response.data.role : USER));
            }
            if (response.data.role === SITE_ADMIN)
                yield dispatch(userSites(email));
        }
        catch (error) {
            if (error.response.status !== 404)
                dispatch(userError(error, userRole, [email]));
        }
    });
};
export const getRole = (email) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield api.CurrentUser.getRole();
        return response.data;
    }
    catch (error) {
        console.log(error);
    }
    try {
        const response = yield api.Admins.get(encodeURIComponent(email));
        return response.data;
    }
    catch (error) {
        console.log(error);
    }
});
const userSites = email => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield api.Admins.get(encodeURIComponent(email));
            dispatch(getUserSites(response.data.sites));
        }
        catch (error) {
            error.response && error.response.status && error.response.status === 404
                ? dispatch(getUserSites([]))
                : dispatch(userError(error, userSites, email));
        }
    });
};
const updateUserFilters = (page, filters) => ({
    type: userConstants.UPDATE_USER_FILTERS,
    page,
    filters,
});
const logout = () => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(clearUser());
    });
};
const userError = (userError, actionFunction, actionParams) => {
    return dispatch => {
        dispatch(errorActions.error(userError, actionFunction, actionParams, {
            type: userConstants.ERROR_USER,
            userError,
        }));
    };
};
const getUserRole = role => ({
    type: userConstants.GET_USER_ROLE,
    role,
});
const getUserSites = sites => ({
    type: userConstants.GET_USER_SITES,
    sites,
});
const toggleUserView = () => ({
    type: userConstants.TOGGLE_USER_VIEW,
});
export const userActions = {
    loginStatus,
    clearUser,
    logout,
    userRole,
    userSites,
    updateUserFilters,
    toggleUserView,
    newTokenRequest,
    loadUserFromJWT,
    handleUnauthorized,
};
