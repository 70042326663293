import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { ConfirmModal } from '../../../../index';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import TagsWrappedList from '../../../TagsWrappedList/TagsWrappedList';
class RestoreRoomModal extends Component {
    render() {
        const { t, isOpen, onRestore, onClose, names } = this.props;
        let confirmText = React.createElement("p", null, t('RESTORE_CONFIRM_MESSAGE_ROOM'));
        if (names && names.length > 1) {
            confirmText = React.createElement("p", null, t('RESTORE_CONFIRM_MESSAGE_ROOMS'));
        }
        return (React.createElement(ConfirmModal, { title: t('RESTORE'), text: React.createElement("div", null,
                confirmText,
                React.createElement(TagsWrappedList, { names: names })), onClose: () => {
                onClose();
            }, isOpen: isOpen, primaryButton: {
                label: t('RESTORE'),
                type: 'is-primary',
                action: onClose => {
                    onRestore();
                    onClose();
                },
                icon: React.createElement(FAIcon, { icon: ['fas', 'undo'] }),
            }, secondaryButton: {
                label: t('CANCEL'),
                type: 'is-light',
                action: onClose => onClose(),
            } }));
    }
}
RestoreRoomModal.propTypes = {
    names: PropTypes.array,
};
export default withNamespaces('translations')(RestoreRoomModal);
