import React from 'react';
import { withNamespaces } from 'react-i18next';
import { ConfirmModal } from '../../../../index';
const RestoredRoomInfoModal = ({ isMulti, name, t, isOpen, onClose }) => {
    return (React.createElement(ConfirmModal, { title: t('RESTORED'), text: isMulti
            ? t('MODAL_RESTORE_ROOMS_INFO')
            : t('MODAL_RESTORE_ROOM_INFO', {
                name: name,
            }), onClose: () => onClose(), isOpen: isOpen, primaryButton: {
            label: t('OK'),
            type: 'is-primary',
            action: onClose => {
                onClose();
            },
        } }));
};
export default withNamespaces('translations')(RestoredRoomInfoModal);
