import { adminsConstants } from '../constants';
export const adminsState = {
    admins: [],
    admin: null,
    adminsError: null,
    adminsLoading: false,
    pageNumber: 0,
    pageSize: 20,
    sort: null,
    totalPages: 1,
    totalElements: 0,
};
const admins = (state = adminsState, action) => {
    switch (action.type) {
        case adminsConstants.FETCH_ADMINS:
            return Object.assign(Object.assign({}, state), { admins: [...action.admins], pageNumber: action.pageNumber, totalPages: action.totalPages, pageSize: action.pageSize, sort: action.sort, adminsError: null, adminsLoading: false, totalElements: action.totalElements });
        case adminsConstants.FETCH_SINGLE_ADMIN:
            return Object.assign(Object.assign({}, state), { admin: action.admin, adminsError: null, adminsLoading: false });
        case adminsConstants.ERROR_ADMINS:
            return Object.assign(Object.assign({}, state), { adminsError: action.error, adminsLoading: false });
        case adminsConstants.LOADING_ADMINS:
            return Object.assign(Object.assign({}, state), { adminsLoading: true });
        default:
            return state;
    }
};
export default admins;
