import React from 'react';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import i18n from '../../../../i18n';
const Thumb = ({ file, showFileName, size, onRemove }) => {
    return (file && (React.createElement("div", { className: "thumb" },
        onRemove && (React.createElement("span", { className: "thumb-delete", title: i18n.t('DELETE'), onClick: onRemove },
            React.createElement(FAIcon, { icon: ['far', 'trash-alt'] }))),
        React.createElement("div", { className: "thumb-img", style: { width: size, height: size } },
            React.createElement("img", { src: file.preview, alt: "" })),
        showFileName && (React.createElement("label", { className: "has-text-grey", style: { width: size } }, file.name)))));
};
export default Thumb;
