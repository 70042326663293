import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import RootPath from '../RootPath/RootPath';
import Admin from '../Admin/Admin';
import { routes } from '../../../../constants';
import { scrollTo } from '../../../../utils';
class Routes extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location)
            this.onRouteChanged();
    }
    onRouteChanged() {
        scrollTo(document.documentElement);
    }
    render() {
        return (React.createElement(Switch, null,
            React.createElement(Route, { path: routes.admin, component: Admin }),
            React.createElement(Route, { path: "/", component: RootPath }),
            React.createElement(Redirect, { to: "/" })));
    }
}
export default withRouter(Routes);
