import { TableHeaderCell } from '../../../components';
import { Link } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { routes } from '../../../constants';
import React from 'react';
import i18n from '../../../i18n';
import { featureActionsConstants as action } from '../../../constants/roomsBulk.constants';
import { differenceBy } from 'lodash';
import classnames from 'classnames/bind';
export function toRoomListRowDto(room) {
    return {
        roomId: room.id,
        roomName: room.name,
        roomFeatures: room.features,
        oldState: room.oldState,
    };
}
export function getColumns(lastAction) {
    return [
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('NAME') }),
            accessor: 'roomName',
            filterable: false,
            maxWidth: 235,
            Cell: cellInfo => (React.createElement(Link, { to: formatRoute(routes.roomDetails, {
                    id: cellInfo.original.roomId,
                }) }, cellInfo.original.roomName)),
        },
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('FEATURES_AND_SERVICES') }),
            id: 'roomFeatures',
            filterable: false,
            sortable: false,
            minWidth: 150,
            Cell: cellInfo => {
                return renderFeatures(cellInfo, lastAction);
            },
        },
    ];
}
function renderFeatures(cellInfo, lastAction) {
    const actualValue = cellInfo.original;
    const oldState = actualValue.oldState;
    if (oldState && lastAction !== action.UNDO) {
        return renderListWithOldState(actualValue, oldState, lastAction);
    }
    else {
        return renderList(actualValue.roomFeatures, '');
    }
}
function renderListWithOldState(actualValue, oldState, lastAction) {
    let differenceList = [];
    if (lastAction === action.ADD) {
        differenceList = differenceBy(actualValue.roomFeatures, oldState.features, 'id');
    }
    else if (lastAction === action.REMOVE) {
        differenceList = differenceBy(oldState.features, actualValue.roomFeatures, 'id');
    }
    if (differenceList) {
        if (lastAction === action.ADD) {
            return renderWithAddedList(differenceList, actualValue, lastAction);
        }
        else if (lastAction === action.REMOVE) {
            return renderWithRemovedList(actualValue, differenceList);
        }
    }
    else {
        return renderList(actualValue.roomFeatures, '');
    }
}
function renderWithRemovedList(actualValue, removedList) {
    return (React.createElement(React.Fragment, null,
        renderList(actualValue.roomFeatures, ''),
        renderList(removedList, 'error-text-bold-striked')));
}
function renderList(list, additionalStyle) {
    return (React.createElement("div", { className: "input-tag__container" }, list
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(feature => renderFeature(feature.name, additionalStyle))));
}
function renderFeature(featureName, additionalStyle) {
    return (React.createElement("div", { className: classnames('input-tag__item__wrapper', additionalStyle), key: featureName + 'wrapper' },
        React.createElement("div", { className: "tag__item", key: featureName }, featureName)));
}
function renderWithAddedList(difference, actualValue, lastAction) {
    const differencesIds = difference.map(d => d.id);
    return (React.createElement("div", { className: "input-tag__container" }, actualValue.roomFeatures
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(feature => renderAddFeature(feature, lastAction, differencesIds))));
}
function renderAddFeature(feature, lastAction, differencesIds) {
    let additionalStyle = '';
    const isOldStateFeature = differencesIds.includes(feature.id);
    if (isOldStateFeature) {
        additionalStyle = 'primary-text-bold';
    }
    return renderFeature(feature.name, additionalStyle);
}
