var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { imageConstants } from '../constants';
const openFullScreen = (imagesLoaded, index) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch({
            type: imageConstants.OPEN_FULL_SCREEN,
            imagesLoaded,
            index,
        });
    });
};
const closeFullScreen = () => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch({
            type: imageConstants.CLOSE_FULL_SCREEN,
        });
    });
};
export const imageActions = {
    openFullScreen,
    closeFullScreen,
};
