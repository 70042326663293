import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { ExportButton } from '../../index';
import api from '../../../api';
class RoomExportSiteAdminModal extends Component {
    render() {
        const { t } = this.props;
        return (React.createElement("div", { className: `modal ${this.props.isOpen ? 'is-active' : ''}` },
            React.createElement("div", { className: "modal-background", onClick: () => this.props.onClose() }),
            React.createElement("div", { className: "modal-content" },
                React.createElement("header", { className: "modal-card-head" },
                    React.createElement("p", { className: "modal-card-title is-center" }, t('EXPORT_TO_CSV')),
                    React.createElement("button", { className: "modal-close", "aria-label": "close", onClick: () => this.props.onClose(), title: t('CLOSE'), icon: React.createElement(FAIcon, { icon: ['fas', 'times'] }) })),
                React.createElement("section", { className: "modal-card-body  has-text-centered is-size-5" },
                    t('SITE_ADMIN_EXPORT_TEXT'),
                    React.createElement("div", { className: "has-text-weight-bold" }, t('SITE_ADMIN_EXPORT_TEXT_NOTE'))),
                React.createElement("section", { className: "modal-card-body has-text-centered" },
                    React.createElement(ExportButton, { fetchExportData: api.Rooms.export, fileName: "export-rooms", text: "EXPORT_ALL" }),
                    React.createElement(ExportButton, { filters: this.props.filters, fetchExportData: api.Rooms.exportFiltered, fileName: "export-rooms", text: "EXPORT_FILTERED" })))));
    }
}
RoomExportSiteAdminModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};
export default withNamespaces('translations')(RoomExportSiteAdminModal);
