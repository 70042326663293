import { roomsMaintenancesConstants } from '../constants/roomsMaintenances.constants';
export const roomsMaintenancesState = {
    maintenances: [],
    maintenancesError: null,
    maintenancesLoading: false,
    pageNumber: 0,
    pageSize: 20,
    sort: [],
    totalPages: 1,
    maintenance: null,
    totalElements: 0,
    listParams: '',
};
const roomsMaintenances = (state = roomsMaintenancesState, action) => {
    switch (action.type) {
        case roomsMaintenancesConstants.FETCH_MAINTENANCES:
            return Object.assign(Object.assign({}, state), { maintenances: [...action.maintenances], maintenancesError: null, maintenancesLoading: false, pageNumber: action.pageNumber, pageSize: action.pageSize, sort: action.sort, totalPages: action.totalPages, totalElements: action.totalElements, listParams: action.listParams || '' });
        case roomsMaintenancesConstants.ERROR_ROOMS_MAINTENANCES:
            return Object.assign(Object.assign({}, state), { maintenancesError: action.error, maintenancesLoading: false });
        default:
            return state;
    }
};
export default roomsMaintenances;
