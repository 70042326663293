var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from 'react';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { withNamespaces } from 'react-i18next';
import ReactTable from 'react-table';
import { scrollTo } from '../../../utils';
import TablePageSize from './TablePageSize/TablePageSize';
import ReactDOM from 'react-dom';
import { selectTablePaginationTopExtension } from './SelectTablePaginationTopExtension';
import { userRoleConstants } from '../../../constants';
import { isRoomAdmin } from '../../../utils/admin.utils';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
class SelectTable extends Component {
    constructor(props) {
        super(props);
        this.toggleAll = this.toggleAll.bind(this);
        this.state = {
            selectAll: false,
            selectedIds: [],
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { resultsData, itemsPaginationName, parentSelectedIds } = this.props;
        const { selectedIds } = this.state;
        if (prevProps.location.search !== this.props.location.search ||
            this.onParentCancel(prevProps, parentSelectedIds)) {
            this.resetSelection();
        }
        if (prevProps.search !== this.props.search) {
            const currentState = this.getTableState();
            this.fetchData(Object.assign({}, currentState), this.table);
            this.resetSelection();
        }
        if (prevProps.selectedIds !== selectedIds) {
            const componentNode = ReactDOM.findDOMNode(this);
            selectTablePaginationTopExtension.setSelectionStatistics(componentNode, selectedIds.length, resultsData.totalElements, itemsPaginationName);
        }
    }
    onParentCancel(prevProps, parentSelectedIds) {
        return (prevProps.parentSelectedIds.length !== parentSelectedIds.length &&
            parentSelectedIds.length === 0);
    }
    componentDidMount() {
        const node = ReactDOM.findDOMNode(this);
        const firstTableHeader = node.querySelector('.rt-th');
        firstTableHeader.style.textAlign = 'center';
    }
    getTableState() {
        const instance = this.table;
        return Object.assign(Object.assign({}, instance.getResolvedState()), { page: instance.getStateOrProp('page'), pageSize: instance.getStateOrProp('pageSize'), filter: instance.getStateOrProp('filter') });
    }
    fetchData(state, instance) {
        if (this.props.fetchData) {
            const { search } = this.props;
            this.props.fetchData(Object.assign(Object.assign({}, state), { search }), instance);
        }
    }
    getColumns() {
        const { dataColumns } = this.props;
        return [this.getCheckbox(), ...dataColumns];
    }
    getCheckbox() {
        return {
            Header: (React.createElement("input", { type: "checkbox", className: "table-masterCheckbox", checked: this.state.selectAll, onChange: this.toggleAll })),
            accessor: 'check',
            width: 50,
            sortable: false,
            filterable: false,
            Cell: cellInfo => this.renderCheckbox(cellInfo),
        };
    }
    renderCheckbox(row) {
        const { role, userSitesCodes } = this.props;
        const { siteCode, id } = row.original;
        if (isRoomAdmin(siteCode, role, userSitesCodes)) {
            return (React.createElement("input", { type: "checkbox", className: "table-checkbox", checked: this.state.selectedIds.includes(id), onChange: () => this.toggleRow(id, row.original) }));
        }
        return null;
    }
    toggleRow(id) {
        let selectedIds = [...this.state.selectedIds];
        const keyIndex = selectedIds.indexOf(id);
        if (keyIndex >= 0) {
            selectedIds = [
                ...selectedIds.slice(0, keyIndex),
                ...selectedIds.slice(keyIndex + 1),
            ];
            if (selectedIds.length === 0) {
                this.setState({ selectAll: false });
            }
        }
        else {
            selectedIds.push(id);
        }
        this.setState({ selectedIds });
        this.props.selectedIdsChange(selectedIds);
    }
    toggleAll() {
        const selectAll = !this.state.selectAll;
        let selectedIds = [];
        if (selectAll) {
            selectedIds = this.getItemsIds();
        }
        this.setState({ selectAll, selectedIds });
        this.props.selectedIdsChange(selectedIds);
    }
    getItemsIds() {
        const { ADMIN } = userRoleConstants;
        const { role, keyField, data, userSitesCodes, siteCodePropertyName, } = this.props;
        const items = [...data];
        if (role === ADMIN) {
            return items.map(i => i[keyField]);
        }
        return items
            .filter(i => userSitesCodes.includes(i[siteCodePropertyName]))
            .map(i => i[keyField]);
    }
    resetSelection() {
        const selectAll = false;
        const selectedIds = [];
        this.setState({ selectAll, selectedIds });
        this.props.selectedIdsChange(selectedIds);
    }
    render() {
        const { selectedIds } = this.state;
        const _a = this.props, { t, pageSize, resultsData, tableChange } = _a, props = __rest(_a, ["t", "pageSize", "resultsData", "tableChange"]);
        const page = (resultsData && resultsData.page) || 0;
        return (React.createElement("div", { className: "table-wrapper" },
            React.createElement(ReactTable, Object.assign({ manual: true, onFetchData: this.fetchData.bind(this), onPageChange: page => {
                    tableChange({ page });
                    scrollTo(document.documentElement, 0, 200);
                }, onPageSizeChange: (pageSize, page) => tableChange({ page, pageSize }), onSortedChange: (newSorted, column, sort) => tableChange({ sorted: newSorted }), minRows: 0, page: page, resizable: false, showPaginationTop: true, showPaginationBottom: props.data.length !== 0, pageText: t('TABLE_TEXT_GO_TO_PAGE'), ofText: t('TABLE_TEXT_OF'), rowsText: t('TABLE_TEXT_ROWS'), loadingText: t('LOADING'), noDataText: t('TABLE_TEXT_NO_ROWS_FOUND'), columns: this.getColumns(), selectedIds: selectedIds, ref: table => {
                    this.table = table;
                }, previousText: React.createElement(FAIcon, { className: "has-text-primary", icon: ['fas', 'angle-left'], title: t('TABLE_TEXT_PREVIOUS') }), nextText: React.createElement(FAIcon, { className: "has-text-primary", icon: ['fas', 'angle-right'], title: t('TABLE_TEXT_NEXT') }), renderPageSizeOptions: pageSizeOptionsProps => (React.createElement(TablePageSize, Object.assign({}, pageSizeOptionsProps, { pageSize: pageSize, resultsData: resultsData }))), NoDataComponent: () => (React.createElement("div", { className: "notification has-text-centered" }, t('TABLE_TEXT_NO_ROWS_FOUND'))) }, props))));
    }
}
SelectTable.defaultProps = {
    tableChange: () => { },
};
const mapStateToProps = state => {
    const { user: { role, sitesCodes }, } = state;
    return {
        role,
        userSitesCodes: sitesCodes,
    };
};
export default withNamespaces('translations')(withRouter(connect(mapStateToProps)(SelectTable)));
