import i18n from '../i18n';
const status = {
    ACTIVE: i18n.t('ACTIVE'),
    NOT_YET_ACTIVE: i18n.t('NOT_YET_ACTIVE'),
    DELETED: i18n.t('DELETED'),
    MAINTENANCE: i18n.t('MAINTENANCE'),
    AVAILABLE_FROM: i18n.t('AVAILABLE_FROM'),
    AVAILABLE_UNTIL: i18n.t('AVAILABLE_UNTIL'),
};
export default status;
