import { toast } from 'bulma-toast';
import i18n from './i18n';
const defaultErrorMessage = i18n.t('SOME_ERROR_OCCURRED');
function isNotValid(message) {
    return !(typeof message === 'string' || message instanceof HTMLElement);
}
export const toastError = message => {
    if (isNotValid(message)) {
        message = defaultErrorMessage;
    }
    toast({
        message,
        type: 'is-danger',
        dismissible: true,
        closeOnClick: true,
        position: 'bottom-right',
        duration: 30000,
    });
};
toastError.defaultProps = {
    message: defaultErrorMessage,
};
