import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { userActions } from '../../../../actions';
import FlagIcon from '../../../../FlagIcon';
import { connect } from 'react-redux';
import { userRoleConstants, routes } from '../../../../constants';
import { AdminToggle } from '../../../';
import history from '../../../../history';
const ToggleUserView = props => {
    const { role } = props;
    return role === userRoleConstants.ADMIN ||
        role === userRoleConstants.SITE_ADMIN ? (React.createElement(AdminToggle, Object.assign({}, props))) : null;
};
const ProfileMenu = ({ t, isLogged, firstname, lastname, photo, email, lang, role, isUserView, toggleUserView, }) => {
    const { changeLanguage, languages, selected } = lang;
    const auth = () => {
        history.push('/');
    };
    return isLogged ? (React.createElement(Fragment, null,
        React.createElement("div", { className: "navbar-item" },
            React.createElement(ToggleUserView, { role: role, isUserView: isUserView, toggleUserView: toggleUserView, selectedLanguage: selected })),
        React.createElement("div", { className: "navbar-item has-dropdown is-hoverable profile-menu" },
            React.createElement("span", { className: "navbar-link" },
                React.createElement("div", { className: "media" },
                    React.createElement("div", { className: "media-left" },
                        React.createElement("figure", { className: "image is-24x24" },
                            React.createElement("img", { src: photo, className: "is-rounded", alt: firstname && lastname ? `${firstname} ${lastname}` : email }))),
                    React.createElement("div", { className: "media-content" }, firstname && lastname ? `${firstname} ${lastname}` : email))),
            React.createElement("div", { className: "navbar-dropdown" },
                React.createElement("div", { className: "navbar-item" },
                    React.createElement("p", { className: "has-text-grey-light" },
                        t('SELECT_LANGUAGE'),
                        ":")),
                Object.keys(languages).map(key => (React.createElement("a", { key: languages[key].locale, className: `navbar-item ${selected && selected.locale === key ? 'is-active' : ''}`, onClick: () => changeLanguage(key) },
                    React.createElement(FlagIcon, { code: languages[key].flagCode }),
                    t(languages[key].name)))),
                React.createElement("hr", { className: "dropdown-divider" }),
                React.createElement(Link, { to: routes.logout, className: "navbar-item has-text-danger" }, t('LOG_OUT')))))) : (React.createElement("a", { onClick: () => auth(), className: "navbar-item" }, t('LOG_IN')));
};
ProfileMenu.propTypes = {
    t: PropTypes.func.isRequired,
    isLogged: PropTypes.bool.isRequired,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    photo: PropTypes.string,
    email: PropTypes.string,
    language: PropTypes.shape({
        languages: PropTypes.object.isRequired,
        changeLanguage: PropTypes.func.isRequired,
        selected: PropTypes.object,
    }),
};
ProfileMenu.defaultProps = {
    firstname: null,
    lastname: null,
    photo: null,
    email: '',
};
export default ProfileMenu;
