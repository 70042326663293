var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { adminsActions } from '../../actions';
import { withNamespaces } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { errorTypesConstants as err, userRoleConstants, routes, errorConstants, } from '../../constants';
import { Button, ErrorMsg, FormControl, TitleSection, UserSearch, FormButtons, ToggleInfo, FormErrorNotification, LoaderContainer, ConfirmModal, SiteSelect, BackToList, } from '..';
import { AdminsSchema } from '../../validations';
import classnames from 'classnames/bind';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import withErrorHandling from '../Common/WithErrorHandling/withErrorHandling';
import { updateErrorMessagesOnLanguageChange } from '../../utils/formik.utils';
import i18n from '../../i18n';
class AdministratorEdit extends Component {
    constructor(props) {
        super(props);
        this.emptyFormState = {
            email: '',
            role: '',
            siteCodes: [],
        };
        this.state = {
            isReady: false,
            itemToAdd: null,
            itemToEdit: null,
            itemConflict: false,
            accessDenied: false,
        };
        const email = props.match.params.email;
        if (email) {
            this.email = decodeURIComponent(email);
        }
    }
    isEdit() {
        return this.email !== undefined;
    }
    isEditReady() {
        return this.isEdit() && this.state.isReady;
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.loadData();
        });
    }
    componentWillUnmount() {
        i18n.off('languageChanged');
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            const { loadAdmin } = this.props;
            if (this.isEdit()) {
                try {
                    yield loadAdmin({ email: this.email });
                    this.setState({ isReady: true });
                }
                catch (error) {
                    const { t, history, handleError404, adminsError } = this.props;
                    if (adminsError && adminsError.type === err.LOAD_RESOURCE) {
                        handleError404({
                            text: t('ERROR_404_ADMINISTRATORS'),
                            backLinkPath: routes.administrators,
                        });
                    }
                    else {
                        history.push(routes.administrators);
                    }
                }
            }
            else {
                this.setState({ isReady: true });
            }
        });
    }
    getInitialValues() {
        const { role } = this.props;
        if (this.isEdit())
            return this.props.admin;
        else {
            this.emptyFormState.role = role;
            return this.emptyFormState;
        }
    }
    onSubmit(values, { setSubmitting }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { addAdmin, updateAdmin, history } = this.props;
            if (values.role === userRoleConstants.ADMIN) {
                delete values.siteCodes;
            }
            const savedAdministrator = this.isEdit()
                ? yield updateAdmin(this.email, values)
                : yield addAdmin(values);
            setSubmitting(false);
            if (!(savedAdministrator instanceof Error)) {
                const modal = this.isEdit()
                    ? { itemToEdit: Object.assign({}, values) }
                    : { itemToAdd: Object.assign(Object.assign({}, values), { email: savedAdministrator.email }) };
                this.setState(modal);
            }
            else if (savedAdministrator.response.data.reason ===
                errorConstants.USER_NOT_FOUND_UPDATE ||
                savedAdministrator.response.data.reason ===
                    errorConstants.USER_NOT_FOUND_DELETE) {
                history.push({
                    pathname: routes.administrators,
                    state: { errorModal: savedAdministrator.response.data.reason },
                });
            }
            else if (savedAdministrator.response.data.reason === errorConstants.ACCESS_DENIED) {
                this.setState({ accessDenied: true });
            }
            else if (savedAdministrator.response.data.reason === errorConstants.CONFLICT) {
                this.loadData();
                this.setState({ itemConflict: true });
            }
        });
    }
    renderSiteSearch({ errors, touched, setFieldValue, values }) {
        return (React.createElement(FormControl, null,
            React.createElement(Field, { name: "siteCodes", isMulti: true, component: SiteSelect, userSitesOnly: true, defaultValue: this.isEdit() && values.sites }),
            React.createElement(ErrorMsg, { name: "siteCodes" })));
    }
    renderSiteAdminForm(form) {
        const { setFieldValue, values } = form;
        const { t, sites } = this.props;
        if (sites.length > 1) {
            return (React.createElement(Fragment, null,
                React.createElement(FormControl, { label: t('SITE'), required: true },
                    React.createElement("p", null, t('ADMINS_MULTIPLE_SITE_ADMIN'))),
                React.createElement("div", { className: "columns" },
                    React.createElement("div", { className: "column is-4" }, this.renderSiteSearch(form)))));
        }
        else {
            sites.length &&
                values.siteCodes[0] !== sites[0].code &&
                setFieldValue('siteCodes', [sites[0].code]);
            return (React.createElement(FormControl, { label: t('SITE'), required: true },
                React.createElement("p", null, t('ADMINS_SINGLE_SITE_ADMIN', {
                    site: sites.length && sites[0].name,
                    code: sites.length && sites[0].code,
                }))));
        }
    }
    renderAdminForm(form) {
        const { setFieldValue, values } = form;
        const { t } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement("div", { className: "columns" },
                React.createElement("div", { className: "column is-4" },
                    React.createElement(FormControl, { label: t('SITE'), required: true },
                        React.createElement(ToggleInfo, { name: "role", labelOn: t('ADMINS_ADD_GLOBAL_ADMIN'), labelOff: t('ADMINS_ADD_LOCAL_ADMIN'), checked: values.role === userRoleConstants.ADMIN, onChange: e => setFieldValue('role', values.role === userRoleConstants.ADMIN
                                ? userRoleConstants.SITE_ADMIN
                                : userRoleConstants.ADMIN) })))),
            values.role === userRoleConstants.SITE_ADMIN && (React.createElement("div", { className: "columns" },
                React.createElement("div", { className: "column is-8" }, this.renderSiteSearch(form))))));
    }
    renderModals() {
        const { itemToEdit, itemToAdd, itemConflict, accessDenied } = this.state;
        const { t, history } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(ConfirmModal, { text: t('MODAL_ADD_ADMINISTRATOR_CONFIRM', {
                    email: itemToAdd ? itemToAdd.email : '',
                }), onClose: () => {
                    this.setState({ itemToAdd: null });
                    history.push(routes.administrators);
                }, isOpen: itemToAdd != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { title: t('EDIT'), text: t('MODAL_EDIT_ADMINISTRATOR_CONFIRM', {
                    email: itemToEdit ? itemToEdit.email : '',
                }), onClose: () => {
                    this.setState({ itemToEdit: null });
                    history.push(routes.administrators);
                }, isOpen: itemToEdit != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { title: t('ACCESS_DENIED'), text: t('ACCESS_DENIED_LOST_RIGHTS'), onClose: () => history.push(routes.logout), isOpen: accessDenied, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { title: t('CONFLICT'), text: t('ADMIN_EDIT_CONFLICT'), onClose: () => this.setState({ itemConflict: false }), isOpen: itemConflict, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } })));
    }
    render() {
        const { t, role, admin } = this.props;
        if (this.isEditReady()) {
            admin.siteCodes = admin.sites.map(e => e.code);
        }
        return (React.createElement(Fragment, null,
            React.createElement(TitleSection, { title: this.isEdit() ? t('EDIT_ADMINISTRATOR') : t('ADD_ADMINISTRATOR') }),
            React.createElement(LoaderContainer, { className: "section" },
                this.state.isReady && (React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "header-panel" },
                        React.createElement("div", { className: "buttons" },
                            React.createElement(BackToList, { to: routes.administrators, t: t }))),
                    React.createElement("div", { className: "content" },
                        React.createElement(Formik, { enableReinitialize: true, initialValues: this.getInitialValues(), validationSchema: AdminsSchema, onSubmit: this.onSubmit.bind(this) }, form => {
                            const { isSubmitting, errors, touched, values, setFieldError, setFieldTouched, } = form;
                            updateErrorMessagesOnLanguageChange(errors, setFieldError, setFieldTouched);
                            return (React.createElement(Form, null,
                                React.createElement(FormErrorNotification, Object.assign({ t: t, schema: AdminsSchema }, form)),
                                React.createElement("p", null, t('REQUIRED_FIELDS_TEXT')),
                                React.createElement("div", { className: "columns" },
                                    React.createElement("div", { className: "column is-4" },
                                        React.createElement(FormControl, { label: t('NAME'), required: true },
                                            React.createElement(Field, { name: "email", component: UserSearch, placeholder: t('SELECT_ADMINISTRATOR_PLACEHOLDER'), isDisabled: this.isEdit(), defaultValue: this.isEdit() && { email: values.email }, className: classnames({
                                                    'is-danger': errors.email && touched.email,
                                                }) }),
                                            React.createElement(ErrorMsg, { name: "email" })))),
                                role === userRoleConstants.ADMIN
                                    ? this.renderAdminForm(form)
                                    : this.renderSiteAdminForm(form),
                                React.createElement(FormButtons, null,
                                    React.createElement(Button, { type: "submit", className: "is-primary", isLoading: isSubmitting, disabled: isSubmitting, text: t('SAVE'), icon: React.createElement(FAIcon, { icon: ['fas', 'check'] }) }),
                                    React.createElement(Button, { to: routes.administrators, className: "is-light", text: t('CANCEL') }))));
                        })))),
                this.renderModals())));
    }
}
const mapStateToProps = state => {
    const { user: { role, sites }, admins: { admin }, } = state;
    return {
        role,
        sites,
        admin,
    };
};
const mapDispatchToProps = dispatch => ({
    addAdmin: data => dispatch(adminsActions.addAdmin(data)),
    updateAdmin: (email, data) => dispatch(adminsActions.updateAdmin(email, data)),
    loadAdmin: conf => dispatch(adminsActions.loadAdmin(conf)),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(withErrorHandling(AdministratorEdit))));
