import i18n from '../../../../i18n';
import PropTypes from 'prop-types';
const TableResults = ({ page, pageSize, totalElements }) => {
    let offset = page * pageSize;
    let start = Math.min(totalElements, offset + 1);
    let end = Math.min(totalElements, offset + pageSize);
    return formatResults(start, end, totalElements);
};
const formatResults = (start, end, total) => {
    return `${start} - ${end} ${i18n.t('TABLE_TEXT_OF')} ${total}`;
};
TableResults.propTypes = {
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalElements: PropTypes.number.isRequired,
};
export default TableResults;
