var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { Accordion, Table } from '../../index';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import ReactDOM from 'react-dom';
import RoomsOwnershipBulkEditForm from './RoomsOwnershipBulkEditForm/RoomOwnershipBulkEditForm';
import { roomsActions } from '../../../actions';
import { connect } from 'react-redux';
import withErrorHandling from '../../Common/WithErrorHandling/withErrorHandling';
import { getColumns, toBulkRoomUpdateDto, toRoomListRowDto, } from '../../../utils/RoomsUtils/RoomsBulkTableUtils/roomsBulkOwnership.utils';
import { bulkBaseActionConstants as bulkAction } from '../../../constants/roomsBulk.constants';
class RoomsOwnershipBulkEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rooms: this.props.rooms,
            lastRoomsState: [],
            lastAction: null,
        };
    }
    onChangeApply(values, resetForm) {
        return __awaiter(this, void 0, void 0, function* () {
            const requestData = this.props.selectedRoomsIds.map(roomId => {
                return Object.assign({ roomId }, values);
            });
            const isSuccessful = this.onRoomsUpdate(yield this.props.bulkRoomOwnershipUpdate(requestData), false);
            if (isSuccessful) {
                yield this.resetForm(requestData, resetForm);
            }
        });
    }
    onRoomsUpdate(updatedRooms, isUndo) {
        if (updatedRooms && !(updatedRooms instanceof Error)) {
            const roomsFreshState = updatedRooms.map(room => {
                return toRoomListRowDto(room);
            });
            if (isUndo) {
                this.setState({
                    lastRoomsState: [],
                    rooms: roomsFreshState,
                    lastAction: bulkAction.UNDO,
                });
            }
            else {
                this.setState({
                    lastRoomsState: [...this.state.rooms],
                    rooms: roomsFreshState,
                    lastAction: bulkAction.APPLY,
                });
            }
            return true;
        }
        return false;
    }
    onUndoClicked(resetForm) {
        return __awaiter(this, void 0, void 0, function* () {
            const { bulkRoomOwnershipUpdate } = this.props;
            const requestData = this.state.lastRoomsState.map(room => {
                return toBulkRoomUpdateDto(room);
            });
            const isSuccessful = this.onRoomsUpdate(yield bulkRoomOwnershipUpdate(requestData), true);
            if (isSuccessful) {
                this.resetForm(requestData, resetForm);
            }
        });
    }
    resetForm(requestData, resetForm) {
        if (requestData[0].googleOwner) {
            this.cleanFieldManually('.bulk-googleOwner');
        }
        if (requestData[0].owner) {
            this.cleanFieldManually('.bulk-roomOwner');
        }
        resetForm();
    }
    cleanFieldManually(fieldClassName) {
        const node = ReactDOM.findDOMNode(this);
        const firstTableHeader = node.querySelector(fieldClassName);
        const clearActionField = firstTableHeader.querySelector('.rs__clear-indicator');
        this.simulateMouseClick(clearActionField);
    }
    simulateMouseClick(element) {
        if (element) {
            const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
            mouseClickEvents.forEach(mouseEventType => element.dispatchEvent(new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1,
            })));
        }
    }
    render() {
        const { t } = this.props;
        const { lastAction } = this.state;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "header-panel" },
                    React.createElement(Accordion, { textShow: t('ROOM_OWNERSHIP'), textHide: t('ROOM_OWNERSHIP'), isOpen: true, className: "accordion-section-title" },
                        React.createElement(RoomsOwnershipBulkEditForm, { rooms: this.props.rooms, onChangeApply: this.onChangeApply.bind(this), onUndoClicked: this.onUndoClicked.bind(this), lastRoomsState: this.state.lastRoomsState }),
                        React.createElement(Table, { data: this.state.rooms, columns: getColumns(lastAction), showPaginationTop: false, showPaginationBottom: false }))))));
    }
}
const mapDispatchToProps = dispatch => ({
    bulkRoomOwnershipUpdate: data => dispatch(roomsActions.bulkRoomOwnershipUpdate(data)),
});
export default withNamespaces('translations')(withRouter(connect(null, mapDispatchToProps)(withErrorHandling(RoomsOwnershipBulkEdit))));
