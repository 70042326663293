var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { adminsActions } from '../../actions';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { Button, ConfirmModal, Table, LoaderContainer, TableHeaderCell, TableFilter, } from '..';
import { userRoleConstants, routes, urlDefaultStateConstants, errorConstants, } from '../../constants';
import { formatRoute } from 'react-router-named-routes';
import { pathUtils } from '../../utils';
import { toastError } from '../../toasts';
import tableFilterType from '../../enums/tableFilterType.enum';
class Administrators extends Component {
    constructor(props) {
        super(props);
        this.state = Object.assign(Object.assign({ itemToDelete: null, itemDeletedInfo: null, itemToDeleteNotFound: false, errorModal: null }, urlDefaultStateConstants), pathUtils.parseUrlParams(props.history.location.search));
    }
    fetchData(state) {
        const { history } = this.props;
        const parsedParams = pathUtils.parseUrlParams(history.location.search);
        const updatedState = Object.assign(Object.assign({}, state), parsedParams);
        this.fetchWithDataPrepared(updatedState);
    }
    fetchWithDataPrepared(updatedState) {
        const { loadAdmins } = this.props;
        const { page, pageSize, sorted, search, filter, buildingIds, roomTypeIds, featureIds, siteCodes, admin, } = updatedState;
        loadAdmins({
            pageNumber: page,
            pageSize,
            sort: sorted,
            filter,
            search,
            buildingIds,
            roomTypeIds,
            featureIds,
            siteCodes,
            admin,
        });
    }
    fetchUrlParams() {
        const props = Object.assign(Object.assign({}, urlDefaultStateConstants), pathUtils.parseUrlParams(this.props.location.search));
        this.fetchData(props);
    }
    componentDidMount() {
        const { location } = this.props;
        if (location.state && location.state.errorModal) {
            this.setState({ errorModal: location.state.errorModal });
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
        }
    }
    updateParams(params) {
        const { history } = this.props;
        const parsedParams = pathUtils.parseUrlParams(history.location.search);
        const updatedState = Object.assign(Object.assign({}, parsedParams), params);
        const url = pathUtils.prepareUrl(updatedState);
        history.push(`?${url}`);
    }
    deletePageParam() {
        const { history } = this.props;
        let parsedParams = pathUtils.parseUrlParams(history.location.search);
        parsedParams.page = 0;
        const url = pathUtils.prepareUrl(parsedParams);
        history.push(`?${url}`);
    }
    renderSites(row) {
        const { SITE_ADMIN, ADMIN } = userRoleConstants;
        const { t } = this.props;
        switch (row.value) {
            case SITE_ADMIN:
                return row.original.sites
                    .map(site => `${site.name} (${site.code})`)
                    .join(', ');
            case ADMIN:
                return t('ALL');
            default:
                return null;
        }
    }
    renderButtons(row) {
        const { SITE_ADMIN, ADMIN } = userRoleConstants;
        const { t, role, sites, email } = this.props;
        const rowSites = row.original.sites.map(site => site.code);
        const adminSites = sites.map(site => site.code);
        const isCurrentAdmin = email === row.original.email;
        if (role === ADMIN ||
            (role === SITE_ADMIN &&
                row.original.role !== ADMIN &&
                rowSites.every(site => adminSites.indexOf(site) > -1)))
            return (React.createElement("div", { className: "buttons is-right" },
                React.createElement(Button, { to: formatRoute(routes.administratorEdit, {
                        email: row.original.email,
                    }), className: "is-text has-text-primary", tooltip: t('EDIT'), icon: React.createElement(FAIcon, { icon: ['far', 'edit'] }) }),
                React.createElement(Button, { className: "is-text has-text-primary", tooltip: t(isCurrentAdmin ? 'DELETE_YOURSELF' : 'DELETE'), disabled: isCurrentAdmin, icon: React.createElement(FAIcon, { icon: ['far', 'trash-alt'] }), onClick: () => this.setState({
                        itemToDelete: row.original,
                    }) })));
        return null;
    }
    getColumns() {
        const { t } = this.props;
        return [
            {
                Header: React.createElement(TableHeaderCell, { text: t('NAME') }),
                accessor: 'email',
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('SITE') }),
                accessor: 'role',
                Cell: row => this.renderSites.bind(this)(row),
            },
            {
                accessor: 'id',
                sortable: false,
                width: 100,
                className: 'td-actions',
                Cell: cellInfo => this.renderButtons(cellInfo),
            },
        ];
    }
    renderModals() {
        const { itemToDelete, itemDeletedInfo, itemToDeleteNotFound, errorModal, } = this.state;
        const { t, deleteAdmin, pageNumber, pageSize, sort } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(ConfirmModal, { title: t('CONFIRM'), text: t('DELETE_CONFIRM_MESSAGE_ADMINISTRATOR', {
                    item: itemToDelete ? itemToDelete.email : '',
                }), onClose: () => this.setState({ itemToDelete: null }), isOpen: itemToDelete != null, primaryButton: {
                    label: t('DELETE'),
                    type: 'is-danger',
                    action: (onClose) => __awaiter(this, void 0, void 0, function* () {
                        const adminDeleted = yield deleteAdmin(itemToDelete.email, {
                            pageNumber,
                            pageSize,
                            sort,
                        });
                        if (!(adminDeleted instanceof Error)) {
                            this.setState({ itemDeletedInfo: Object.assign({}, itemToDelete) });
                        }
                        else {
                            if (adminDeleted.response.data.reason ===
                                errorConstants.USER_NOT_FOUND_DELETE) {
                                this.setState({ itemToDeleteNotFound: true });
                            }
                            else {
                                toastError(t(adminDeleted.response.data.reason));
                            }
                        }
                        onClose();
                    }),
                    icon: React.createElement(FAIcon, { icon: ['far', 'trash-alt'] }),
                }, secondaryButton: {
                    label: t('CANCEL'),
                    type: 'is-light',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t('MODAL_DELETE_ADMINISTRATOR_INFO', {
                    email: itemDeletedInfo ? itemDeletedInfo.email : '',
                }), onClose: () => this.setState({ itemDeletedInfo: null }), isOpen: itemDeletedInfo != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t('USER_NOT_FOUND_DELETE'), onClose: () => this.setState({ itemToDeleteNotFound: false }), isOpen: itemToDeleteNotFound, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t(errorModal), onClose: () => {
                    pathUtils.clearLocationState('errorModal');
                    this.setState({ errorModal: null });
                }, isOpen: errorModal !== null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } })));
    }
    render() {
        const { t, admins, adminsLoading, totalPages, page, pageSize, totalElements, history, } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(LoaderContainer, { className: "section" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "header-panel" },
                        React.createElement("div", { className: "buttons" },
                            React.createElement(Button, { to: routes.administratorAdd, className: "is-primary", icon: React.createElement(FAIcon, { icon: ['fas', 'plus'] }), text: t('ADD_ADMINISTRATOR') }))),
                    React.createElement(TableFilter, { type: tableFilterType.ADMINISTRATORS, history: history, fetchWithDataPrepared: this.fetchWithDataPrepared.bind(this) }),
                    React.createElement(Table, { resultsData: { page, totalElements, pageSize }, pages: totalPages, data: admins, pageSize: pageSize, columns: this.getColumns(), loading: adminsLoading, fetchData: this.fetchData.bind(this), tableChange: this.updateParams.bind(this) }))),
            this.renderModals()));
    }
}
const mapStateToProps = state => {
    const { admins: { admins, adminsLoading, totalPages, pageSize, pageNumber, sort, totalElements, }, user: { role, sites, email }, } = state;
    return {
        admins,
        totalPages,
        adminsLoading,
        pageSize,
        page: pageNumber,
        sort,
        role,
        sites,
        totalElements,
        email,
    };
};
const mapDispatchToProps = dispatch => ({
    loadAdmins: conf => dispatch(adminsActions.loadAdmins(conf)),
    deleteAdmin: (id, conf) => dispatch(adminsActions.deleteAdmin(id, conf)),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Administrators)));
