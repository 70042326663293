import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute, Landing, GlobalLoader } from '../../../';
import { userRoleConstants, routes } from '../../../../constants';
import { Buildings, BuildingsEdit, BuildingDetails, Sites, SitesEdit, Features, FeatureEdit, FeatureDetails, Rooms, RoomEdit, RoomsBulkEdit, RoomTypes, RoomTypeEdit, RoomTypeDetails, RoomDetails, Administrators, AdministratorEdit, RoomsMaintenances, } from './AdminComponents';
const { USER, SITE_ADMIN, ADMIN } = userRoleConstants;
const Admin = () => (React.createElement(Suspense, { fallback: React.createElement(GlobalLoader, { active: "true" }) },
    React.createElement(Switch, null,
        React.createElement(PrivateRoute, { exact: true, path: routes.admin, component: Landing, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.siteAdd, component: SitesEdit, accessLevel: ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.siteEdit, component: SitesEdit, accessLevel: ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.sites, component: Sites, accessLevel: ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.buildingAdd, component: BuildingsEdit, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.buildingEdit, component: BuildingsEdit, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.buildingDetails, component: BuildingDetails, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.buildings, component: Buildings, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.featureAdd, component: FeatureEdit, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.featureEdit, component: FeatureEdit, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.featureDetails, component: FeatureDetails, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.features, component: Features, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.roomAdd, component: RoomEdit, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.roomEdit, component: RoomEdit, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.roomDuplicate, component: RoomEdit, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.roomsBulkEdit, component: RoomsBulkEdit, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.rooms, component: Rooms, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.roomTypeAdd, component: RoomTypeEdit, accessLevel: ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.roomTypeEdit, component: RoomTypeEdit, accessLevel: ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.roomTypeDetails, component: RoomTypeDetails, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.roomTypes, component: RoomTypes, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.roomDetails, component: RoomDetails, accessLevel: USER }),
        React.createElement(PrivateRoute, { exact: true, path: routes.administrators, component: Administrators, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.administratorAdd, component: AdministratorEdit, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.administratorEdit, component: AdministratorEdit, accessLevel: SITE_ADMIN }),
        React.createElement(PrivateRoute, { exact: true, path: routes.roomsMaintenances, component: RoomsMaintenances, accessLevel: SITE_ADMIN }))));
export default Admin;
