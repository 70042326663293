var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { userRoleConstants } from '../../../constants';
import { RestrictedArea } from '../../';
import { withNamespaces } from 'react-i18next';
class PrivateRoute extends Component {
    hasAccess(role) {
        const { accessLevel } = this.props;
        const levels = Object.values(userRoleConstants);
        return (role === accessLevel || levels.indexOf(role) > levels.indexOf(accessLevel));
    }
    render() {
        const _a = this.props, { component: Component, isLogged, role, t } = _a, rest = __rest(_a, ["component", "isLogged", "role", "t"]);
        return (React.createElement(Route, Object.assign({}, rest, { render: props => {
                if (isLogged)
                    return this.hasAccess.bind(this)(role) ? (React.createElement(Component, Object.assign({}, props))) : (React.createElement(RestrictedArea, { message: t('NO_PERMISSION_MESSAGE') }));
                else
                    return (React.createElement(RestrictedArea, { message: t('ONLY_LOGGED_IN_USER_MESSAGE') }));
            } })));
    }
}
const mapStateToProps = state => {
    const { user: { isLogged, role }, } = state;
    return {
        isLogged,
        role,
    };
};
export default withNamespaces('translations')(connect(mapStateToProps)(PrivateRoute));
