import * as React from 'react';
import { routes } from '../../../constants';
const withErrorHandling = Component => {
    return class extends React.Component {
        constructor() {
            super(...arguments);
            this.handleError404 = ({ title, text, backLinkPath }) => {
                const { history } = this.props;
                history.push({
                    pathname: routes.error404,
                    state: {
                        title: title,
                        text: text,
                        backLinkPath: backLinkPath,
                    },
                });
            };
        }
        render() {
            return React.createElement(Component, Object.assign({ handleError404: this.handleError404 }, this.props));
        }
    };
};
export default withErrorHandling;
