import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { BackToList, LoaderContainer, TitleSection } from '../index';
import { connect } from 'react-redux';
import { routes } from '../../constants';
import RoomAttributesBulkEdit from './RoomAttributesBulkEdit/RoomAttributesBulkEdit';
import RoomOwnershipBulkEdit from './RoomsOwnershipBulkEdit/RoomsOwnershipBulkEdit';
import RoomsFeaturesBulkEdit from './RoomsFeaturesBulkEdit/RoomsFeaturesBulkEdit';
import { roomsActions } from '../../actions';
import RoomsClickableLinkBulkEdit from './RoomsClickableLinkBulkEdit/RoomsClickableLinkBulkEdit';
class RoomsBulkEdit extends Component {
    componentDidMount() {
        this.props.clearPushToBulkFlag();
    }
    render() {
        const { t, listParams, selectedRooms, selectedRoomsIds } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(TitleSection, null,
                React.createElement("div", null,
                    t('YOU_ARE_EDITING'),
                    ' ',
                    React.createElement("span", { className: "primary-text-bold" }, selectedRooms.length),
                    ' ',
                    selectedRooms.length === 1 ? t('ROOM') : t('ROOMS_LOWER_CASE')),
                React.createElement("div", { className: "buttons" },
                    React.createElement(BackToList, { to: routes.rooms + listParams, t: t }))),
            React.createElement(LoaderContainer, { className: "section" },
                React.createElement(RoomAttributesBulkEdit, { rooms: selectedRooms, selectedRoomsIds: selectedRoomsIds }),
                React.createElement(RoomOwnershipBulkEdit, { rooms: selectedRooms, selectedRoomsIds: selectedRoomsIds }),
                React.createElement(RoomsFeaturesBulkEdit, { rooms: selectedRooms, selectedRoomsIds: selectedRoomsIds }),
                React.createElement(RoomsClickableLinkBulkEdit, { rooms: selectedRooms, selectedRoomsIds: selectedRoomsIds }))));
    }
}
const mapStateToProps = state => {
    const { rooms: { rooms, listParams, selectedRooms, selectedRoomsIds }, } = state;
    return {
        rooms,
        listParams,
        selectedRooms,
        selectedRoomsIds,
    };
};
const mapDispatchToProps = dispatch => ({
    clearPushToBulkFlag: () => dispatch(roomsActions.clearPushToBulkFlag()),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(RoomsBulkEdit)));
