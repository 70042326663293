import { userRoleConstants } from '../constants';
export function isRoomAdmin(siteCode, role, userSitesCodes) {
    return isGlobalAdmin(role) || isSiteAdmin(siteCode, role, userSitesCodes);
}
export function isGlobalAdmin(role) {
    return role === userRoleConstants.ADMIN;
}
export function isSiteAdmin(siteCode, role, userSitesCodes) {
    const { SITE_ADMIN } = userRoleConstants;
    return role === SITE_ADMIN && userSitesCodes.includes(siteCode);
}
