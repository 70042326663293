var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from 'react';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { withNamespaces } from 'react-i18next';
import ReactTable from 'react-table';
import { scrollTo } from '../../../utils';
import TablePageSize from './TablePageSize/TablePageSize';
class Table extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.search !== this.props.search) {
            const currentState = this.getTableState();
            this.fetchData(Object.assign({}, currentState), this.table);
        }
    }
    getTableState() {
        const instance = this.table;
        return Object.assign(Object.assign({}, instance.getResolvedState()), { page: instance.getStateOrProp('page'), pageSize: instance.getStateOrProp('pageSize'), filter: instance.getStateOrProp('filter') });
    }
    fetchData(state, instance) {
        if (this.props.fetchData) {
            const { search } = this.props;
            this.props.fetchData(Object.assign(Object.assign({}, state), { search }), instance);
        }
    }
    render() {
        const _a = this.props, { t, pageSize, resultsData, tableChange } = _a, props = __rest(_a, ["t", "pageSize", "resultsData", "tableChange"]);
        const page = (resultsData && resultsData.page) || 0;
        return (React.createElement("div", { className: "table-wrapper" },
            React.createElement(ReactTable, Object.assign({ manual: true, onFetchData: this.fetchData.bind(this), onPageChange: page => {
                    tableChange({ page });
                    scrollTo(document.documentElement, 0, 200);
                }, onPageSizeChange: (pageSize, page) => tableChange({ page, pageSize }), onSortedChange: (newSorted, column, sort) => tableChange({ sorted: newSorted }), minRows: 0, page: page, resizable: false, showPaginationTop: true, showPaginationBottom: props.data.length !== 0, pageText: t('TABLE_TEXT_GO_TO_PAGE'), ofText: t('TABLE_TEXT_OF'), rowsText: t('TABLE_TEXT_ROWS'), loadingText: t('LOADING'), noDataText: t('TABLE_TEXT_NO_ROWS_FOUND'), ref: table => {
                    this.table = table;
                }, previousText: React.createElement(FAIcon, { className: "has-text-primary", icon: ['fas', 'angle-left'], title: t('TABLE_TEXT_PREVIOUS') }), nextText: React.createElement(FAIcon, { className: "has-text-primary", icon: ['fas', 'angle-right'], title: t('TABLE_TEXT_NEXT') }), renderPageSizeOptions: pageSizeOptionsProps => (React.createElement(TablePageSize, Object.assign({}, pageSizeOptionsProps, { pageSize: pageSize, resultsData: resultsData }))), NoDataComponent: () => (React.createElement("div", { className: "notification has-text-centered" }, t('TABLE_TEXT_NO_ROWS_FOUND'))) }, props))));
    }
}
Table.defaultProps = {
    tableChange: () => { },
};
export default withNamespaces('translations')(Table);
