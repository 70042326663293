import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Auth, UserRoomDetails, PrivateRoute, UserRooms, Logout, Error404Page, } from '../../../';
import { userRoleConstants, routes } from '../../../../constants';
import { Home } from '../../../Home/Home';
const { USER } = userRoleConstants;
const RootPath = () => (React.createElement(Switch, null,
    React.createElement(Route, { exact: true, path: "/", component: Home }),
    React.createElement(Route, { exact: true, path: routes.logout, component: Logout }),
    React.createElement(Route, { exact: true, path: routes.auth, component: Auth }),
    React.createElement(PrivateRoute, { exact: true, path: routes.userRooms, component: UserRooms, accessLevel: USER }),
    React.createElement(PrivateRoute, { exact: true, path: routes.userRoomDetails, component: UserRoomDetails, accessLevel: USER }),
    React.createElement(PrivateRoute, { exact: true, path: routes.error404, component: Error404Page, accessLevel: USER })));
export default RootPath;
