var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sitesActions } from '../../actions';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { Button, ConfirmModal, TitleSection, Table, Search, LoaderContainer, TableHeaderCell, ExportButton, } from '../';
import { userRoleConstants, routes, urlDefaultStateConstants, errorConstants, } from '../../constants';
import { formatRoute } from 'react-router-named-routes';
import { toastError } from '../../toasts';
import api from '../../api';
import { pathUtils } from '../../utils';
import { GoogleLoginWrapper } from '../Common/GoogleLoginWrapper/GoogleLoginWrapper';
class Sites extends Component {
    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.state = Object.assign(Object.assign({ itemToDelete: null, itemToDeleteNotFound: false, search: '', errorModal: null }, urlDefaultStateConstants), pathUtils.parseUrlParams(props.history.location.search));
    }
    componentDidMount() {
        const props = Object.assign(Object.assign({}, urlDefaultStateConstants), pathUtils.parseUrlParams(this.props.location.search));
        this.fetchData(props);
        const { location } = this.props;
        if (location.state && location.state.errorModal) {
            this.setState({ errorModal: location.state.errorModal });
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            const props = Object.assign(Object.assign({}, urlDefaultStateConstants), pathUtils.parseUrlParams(this.props.location.search));
            this.fetchData(props);
        }
    }
    fetchData(state, instance) {
        return __awaiter(this, void 0, void 0, function* () {
            const { loadSites, history, t } = this.props;
            const { page, pageSize, sorted, search } = pathUtils.updateState(state, history.location.search);
            const response = yield loadSites({
                pageNumber: page,
                pageSize,
                sort: sorted,
                search,
            });
            if (response instanceof Error) {
                toastError(t(response.response.data.reason));
                this.setState({ errorLoading: true });
            }
        });
    }
    updateParams(params) {
        const { history } = this.props;
        const url = pathUtils.prepareUrl(pathUtils.updateState(history.location.search, params));
        history.push(`?${url}`);
    }
    getColumns() {
        const { t, role } = this.props;
        return [
            {
                Header: React.createElement(TableHeaderCell, { text: t('CODE') }),
                accessor: 'code',
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('NAME') }),
                accessor: 'name',
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('CITY') }),
                accessor: 'city',
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('COUNTRY_CODE') }),
                accessor: 'countryCode',
                sortable: false,
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('TIME_ZONE') }),
                accessor: 'timezone',
                sortable: false,
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('ADMINISTRATORS') }),
                accessor: 'siteAdminsCounter',
                sortable: false,
                Cell: cellInfo => (React.createElement(Link, { to: `${routes.administrators}?siteCodes[]="${cellInfo.original.code}"&siteName[]="${cellInfo.original.name}"` }, cellInfo.original.siteAdminsCounter)),
            },
            {
                accessor: 'id',
                width: 120,
                sortable: false,
                className: 'td-actions',
                Cell: cellInfo => userRoleConstants.ADMIN === role && (React.createElement("div", { className: "buttons is-right" },
                    React.createElement(Button, { to: formatRoute(routes.siteEdit, {
                            code: cellInfo.original.code,
                        }), className: "is-text has-text-primary", tooltip: t('EDIT'), icon: React.createElement(FAIcon, { icon: ['far', 'edit'] }) }),
                    React.createElement(Button, { className: "is-text has-text-primary", icon: React.createElement(FAIcon, { icon: ['far', 'trash-alt'] }), tooltip: cellInfo.original.used
                            ? t('SITE_HAS_BUILDINGS_OR_USERS')
                            : t('DELETE'), disabled: cellInfo.original.used, onClick: () => this.setState({
                            itemToDelete: cellInfo.original,
                        }) }))),
            },
        ];
    }
    updateSearch(search) {
        this.updateParams({ search });
        this.setState({ search, errorLoading: false });
    }
    renderModals() {
        const { itemToDelete, itemToDeleteNotFound, itemDeletedInfo, errorModal, } = this.state;
        const { t, deleteSite, page, pageSize, sort } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(ConfirmModal, { title: t('CONFIRM'), text: t('DELETE_CONFIRM_MESSAGE_SITE', {
                    item: itemToDelete ? itemToDelete.name : '',
                }), onClose: () => this.setState({ itemToDelete: null }), isOpen: itemToDelete != null, primaryButton: {
                    label: t('DELETE'),
                    type: 'is-danger',
                    action: (onClose) => __awaiter(this, void 0, void 0, function* () {
                        const deletedSite = yield deleteSite(itemToDelete.code, {
                            page,
                            pageSize,
                            sort,
                        });
                        if (!(deletedSite instanceof Error)) {
                            this.setState({ itemDeletedInfo: Object.assign({}, itemToDelete) });
                        }
                        else {
                            if (deletedSite.response.data.reason ===
                                errorConstants.SITE_NOT_FOUND_DELETE) {
                                this.fetchData(urlDefaultStateConstants);
                                this.setState({ itemToDeleteNotFound: true });
                            }
                            else {
                                toastError(t(deletedSite.response.data.reason));
                            }
                        }
                        onClose();
                    }),
                    icon: React.createElement(FAIcon, { icon: ['far', 'trash-alt'] }),
                }, secondaryButton: {
                    label: t('CANCEL'),
                    type: 'is-light',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t('MODAL_DELETE_SITE_INFO', {
                    name: itemDeletedInfo ? itemDeletedInfo.name : '',
                }), onClose: () => this.setState({ itemDeletedInfo: null }), isOpen: itemDeletedInfo != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t('SITE_NOT_FOUND_DELETE'), onClose: () => this.setState({ itemToDeleteNotFound: false }), isOpen: itemToDeleteNotFound, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t(errorModal), onClose: () => {
                    pathUtils.clearLocationState('errorModal');
                    this.setState({ errorModal: null });
                }, isOpen: errorModal !== null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } })));
    }
    render() {
        const { t, sites, sitesLoading, totalPages, page, pageSize, totalElements, } = this.props;
        const { search } = this.state;
        return (React.createElement(LoaderContainer, null,
            React.createElement(TitleSection, null,
                React.createElement(Search, { placeholder: t('SEARCH_PLACEHOLDER_SITES'), defaultValue: search, updateSearch: this.updateSearch.bind(this) })),
            this.state.errorLoading && React.createElement(GoogleLoginWrapper, null),
            React.createElement("section", { className: "section" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "header-panel" },
                        React.createElement("div", { className: "buttons" },
                            React.createElement(Button, { to: routes.siteAdd, className: "is-primary", icon: React.createElement(FAIcon, { icon: ['fas', 'plus'] }), text: t('ADD_SITE') }),
                            React.createElement(ExportButton, { fetchExportData: api.Sites.export, fileName: "export-sites" }))),
                    React.createElement(Table, { resultsData: { page, totalElements, pageSize }, pages: totalPages, data: sites, columns: this.getColumns(), loading: sitesLoading, search: search ? search : '', pageSize: pageSize, tableChange: this.updateParams.bind(this) }))),
            this.renderModals()));
    }
}
const mapStateToProps = state => {
    const { sites: { sites, sitesLoading, totalPages, pageNumber, pageSize, sort, totalElements, }, user: { role }, } = state;
    return {
        sites,
        sitesLoading,
        totalPages,
        page: pageNumber,
        pageSize,
        sorted: sort,
        role,
        totalElements,
    };
};
const mapDispatchToProps = dispatch => ({
    loadSites: conf => dispatch(sitesActions.loadSites(conf)),
    deleteSite: (id, conf) => dispatch(sitesActions.deleteSite(id, conf)),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Sites)));
