var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { ErrorMsg, FormControl, ReactSelect } from '../..';
import { Field, getIn } from 'formik';
import classnames from 'classnames/bind';
import { languages } from '../../../enums';
import { enumToArray } from '../../../utils/array.utils';
import Repeater from '../Repeater/Repeater';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
const LANGUAGES = enumToArray(omit(languages, 'en'), { specificProp: 'name' });
class Translations extends Component {
    render() {
        const _a = this.props, { t, field, form, item, itemDefaultValues } = _a, props = __rest(_a, ["t", "field", "form", "item", "itemDefaultValues"]);
        let items = form.values[field.name];
        return (React.createElement(Repeater, Object.assign({ form: form, field: field, labelAdd: t('ADD_TRANSLATION'), itemDefaultValues: Object.assign({ locale: '' }, itemDefaultValues), itemRemovable: true, showAddButton: items.length < LANGUAGES.length, item: (dataItem, index, arrayHelpers) => {
                let tName = `${field.name}[${index}]locale`;
                return (React.createElement("div", { className: "notification" },
                    React.createElement("div", { className: "columns" },
                        React.createElement("div", { className: "column is-6" },
                            React.createElement(FormControl, { label: t('LANGUAGE'), required: true },
                                React.createElement(Field, { name: tName, component: ReactSelect, options: LANGUAGES, placeholder: t('SELECT_LANGUAGE_PLACEHOLDER'), className: classnames({
                                        'is-danger': getIn(form.errors, tName) &&
                                            getIn(form.touched, tName),
                                    }) }),
                                React.createElement(ErrorMsg, { name: tName })))),
                    item(dataItem, index, arrayHelpers)));
            } }, props)));
    }
}
Translations.propTypes = {
    itemDefaultValues: PropTypes.object.isRequired,
    item: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
};
export default withNamespaces('translations')(Translations);
