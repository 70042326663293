var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classnames from 'classnames/bind';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import { AsyncReactSelect } from '../..';
import api from '../../../api';
import { userRoleConstants } from '../../../constants';
class SiteSelect extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            defaultValue: undefined,
            showAsLabel: false,
        };
        this.fetchSites = (searchQuery = '') => __awaiter(this, void 0, void 0, function* () {
            const { userSitesOnly } = this.props;
            if (!searchQuery && this.isSiteAdmin()) {
                return this.props.userSites;
            }
            const res = yield api.Sites.search(searchQuery, userSitesOnly);
            return res.data;
        });
    }
    componentWillMount() {
        this.setSiteDefaultValue();
    }
    setSiteDefaultValue() {
        const { field, form } = this.props;
        if (this.shouldSetLocalAdminSingleSite()) {
            this.setLocalAdminSingleSite();
        }
        else if (field.value) {
            this.setState({
                defaultValue: { code: field.value, name: form.values.siteName },
            });
        }
    }
    setLocalAdminSingleSite() {
        const { field, form, userSites, onChange } = this.props;
        this.setState({ defaultValue: userSites[0], showAsLabel: true }, () => {
            form.values[field.name] = userSites[0].code;
            if (!field.value && onChange) {
                onChange(userSites[0]);
            }
        });
    }
    adminHasOneSite() {
        const { userSites } = this.props;
        return this.isSiteAdmin() && userSites.length === 1;
    }
    isSiteAdmin() {
        const { userRole } = this.props;
        return userRole === userRoleConstants.SITE_ADMIN;
    }
    shouldSetLocalAdminSingleSite() {
        const { userSites, field } = this.props;
        return !!(this.adminHasOneSite() &&
            (!field.value || field.value === userSites[0].code));
    }
    clearFields(fields) {
        fields.forEach(f => this.props.form.setFieldValue(f, ''));
    }
    render() {
        const { t, field, form, form: { errors, touched }, onChange, } = this.props;
        return (React.createElement(AsyncReactSelect, Object.assign({ defaultOptions: true, getOptionLabel: e => `${e.name} (${e.code})`, getOptionValue: e => e.code, loadOptions: this.fetchSites, onChange: site => {
                if (site) {
                    form.setFieldValue('siteCode', site.code);
                    form.setFieldValue('siteName', site.name);
                }
                else {
                    this.clearFields(['siteCode', 'siteName']);
                }
                if (onChange) {
                    onChange(site);
                }
            }, isDisabled: this.state.showAsLabel, placeholder: t('SELECT_SITE_PLACEHOLDER'), defaultValue: this.state.defaultValue, className: classnames({
                'is-danger': errors[field.name] && touched[field.name],
                'as-label': this.state.showAsLabel,
            }) }, this.props)));
    }
}
const mapStateToProps = state => {
    const { user: { role, sites }, } = state;
    return {
        userRole: role,
        userSites: sites,
    };
};
export default withNamespaces('translations')(connect(mapStateToProps, null)(SiteSelect));
