import React from 'react';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';
const Tile = ({ size, title, desc, img, path }) => {
    const ConditionalLink = path ? Link : 'div';
    return (React.createElement(ConditionalLink, { to: path, className: classnames('tile', 'is-parent', `is-${size}`) },
        React.createElement("div", { className: "tile is-child" },
            React.createElement("p", { className: "subtitle has-text-centered is-marginless" }, title),
            React.createElement("figure", { className: "image is-2by1" },
                React.createElement("img", { src: img, alt: title })),
            desc && (React.createElement("p", { className: "tile-footer has-text-primary has-text-centered tile-desc" }, desc)))));
};
Tile.defaultProps = {
    size: 3,
};
export default Tile;
