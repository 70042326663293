var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { NavLink } from 'react-router-dom';
import { userRoleConstants } from '../../../../constants';
const roles = Object.values(userRoleConstants);
const MenuLink = (_a) => {
    var { children, role, accessLevel } = _a, props = __rest(_a, ["children", "role", "accessLevel"]);
    return role === accessLevel ||
        roles.indexOf(accessLevel) < roles.indexOf(role) ? (React.createElement(NavLink, Object.assign({}, props, { onClick: e => {
            e.target.blur();
            return props.onClick && props.onClick(e);
        } }), children)) : null;
};
export default MenuLink;
