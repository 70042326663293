var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from 'react';
import Repeater from '../Repeater/Repeater';
import { ErrorMsg, FormControl } from '../../index';
import { Field, getIn } from 'formik';
import classnames from 'classnames/bind';
import { withNamespaces } from 'react-i18next';
class ClickableLinks extends Component {
    render() {
        const _a = this.props, { t, field, form, item, itemDefaultValues } = _a, props = __rest(_a, ["t", "field", "form", "item", "itemDefaultValues"]);
        return (React.createElement(Repeater, Object.assign({ form: form, field: field, labelAdd: t('ADD_CLICKABLE_LINK'), itemDefaultValues: Object.assign({}, itemDefaultValues), itemRemovable: true, showAddButton: true, item: (dataItem, index, arrayHelpers) => {
                let urlElementName = `${field.name}[${index}]url`;
                return (React.createElement("div", { className: "notification" },
                    React.createElement("div", { className: "columns" },
                        React.createElement("div", { className: "column" },
                            React.createElement(FormControl, { label: t('CLICKABLE_LINK_URL'), required: true },
                                React.createElement(Field, { name: urlElementName, type: "text", placeholder: t('CLICKABLE_LINK_URL'), className: classnames('input', {
                                        'is-danger': getIn(form.errors, urlElementName) &&
                                            getIn(form.touched, urlElementName),
                                    }) }),
                                React.createElement(ErrorMsg, { name: urlElementName })))),
                    item(dataItem, index, arrayHelpers)));
            } }, props)));
    }
}
export default withNamespaces('translations')(ClickableLinks);
