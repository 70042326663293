var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { roomTypesActions } from '../../actions';
import { withNamespaces } from 'react-i18next';
import { ConfirmModal, TitleSection, LabelBlock, Accordion, Auditing, FormSection, LoaderContainer, BackToList, DetailsViewButtons, } from '..';
import { formatRoute } from 'react-router-named-routes';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { errorTypesConstants as err, userRoleConstants, routes, appConstants, errorConstants, } from '../../constants';
import { languages } from '../../enums';
import { userSearchActions } from '../../actions';
import { toastError } from '../../toasts';
import withErrorHandling from '../Common/WithErrorHandling/withErrorHandling';
import { buttonsUtils } from '../../utils';
class RoomTypeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmDeleteModal: false,
            showInfoDeleteModal: false,
            isReady: false,
            userNames: [],
        };
        const id = props.match.params.id;
        if (!isNaN(id)) {
            this.id = parseInt(id);
        }
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            const { loadRoomType } = this.props;
            try {
                let loadedRoomType = yield loadRoomType({ id: this.id });
                this.fetchUsersFullNames(loadedRoomType);
                this.setState({ isReady: true });
            }
            catch (error) {
                const { t, history, handleError404, roomTypesError } = this.props;
                if (roomTypesError && roomTypesError.type === err.LOAD_RESOURCE) {
                    handleError404({
                        text: {
                            msg: t('ERROR_404_ROOM_TYPE'),
                            email: appConstants.DEFAULT_CONTACT_EMAIL,
                        },
                        backLinkPath: this.buildRoomTypesListUrl(),
                    });
                }
                else {
                    history.push(this.buildRoomTypesListUrl());
                }
            }
        });
    }
    fetchUsersFullNames(loadedRoomType) {
        const { auditing } = loadedRoomType;
        if (auditing) {
            userSearchActions
                .fetchUserNamesByEmails([auditing.createdBy, auditing.modifiedBy])
                .then(names => this.setState({ userNames: names }));
        }
    }
    renderModals() {
        const { showConfirmDeleteModal, showInfoDeleteModal } = this.state;
        const { t, history, deleteRoomType, roomType } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(ConfirmModal, { title: t('CONFIRM'), text: t('DELETE_CONFIRM_MESSAGE_ROOM_TYPE', {
                    item: roomType.name,
                }), onClose: () => this.setState({ showConfirmDeleteModal: false }), isOpen: showConfirmDeleteModal, primaryButton: {
                    label: t('DELETE'),
                    type: 'is-danger',
                    action: (onClose) => __awaiter(this, void 0, void 0, function* () {
                        const roomTypeDeleted = yield deleteRoomType(roomType.id, {
                            pageNumber: 0,
                        });
                        if (!(roomTypeDeleted instanceof Error)) {
                            this.setState({ showInfoDeleteModal: true });
                        }
                        else if (roomTypeDeleted.response.data.reason ===
                            errorConstants.ROOM_TYPE_NOT_FOUND_DELETE) {
                            history.push({
                                pathname: formatRoute(routes.roomTypes),
                                state: { errorModal: roomTypeDeleted.response.data.reason },
                            });
                        }
                        else {
                            toastError(t(roomTypeDeleted.response.data.reason));
                        }
                        onClose();
                    }),
                    icon: React.createElement(FAIcon, { icon: ['far', 'trash-alt'] }),
                }, secondaryButton: {
                    label: t('CANCEL'),
                    type: 'is-light',
                    action: onClose => onClose(),
                } })));
    }
    getUserFullName(email) {
        let user = this.state.userNames.find(e => e.email === email);
        if (user) {
            return user.fullName;
        }
    }
    renderButtons() {
        const { role, roomType } = this.props;
        const deleteSettings = buttonsUtils.isDeleteDisabled({
            item: roomType,
            hasRoomsTooltip: 'ROOM_TYPE_HAS_ROOMS',
            allowRemovalTooltip: 'ITEM_USED',
        });
        if (role === userRoleConstants.ADMIN)
            return (React.createElement(DetailsViewButtons, { editProps: {
                    to: formatRoute(routes.roomTypeEdit, {
                        id: roomType.id,
                    }),
                }, deleteProps: {
                    onClick: () => this.setState({
                        showConfirmDeleteModal: true,
                    }),
                }, deleteSettings: deleteSettings }));
        return null;
    }
    buildRoomTypesListUrl() {
        return routes.roomTypes + this.props.listParams;
    }
    render() {
        const { t, roomType, role, lng } = this.props;
        const { isReady } = this.state;
        if (isReady) {
            const { auditing } = roomType;
            if (auditing) {
                auditing.createdByName = this.getUserFullName(auditing.createdBy);
                auditing.modifiedByName = this.getUserFullName(auditing.modifiedBy);
            }
        }
        return (React.createElement(LoaderContainer, null, isReady && (React.createElement(Fragment, null,
            React.createElement(TitleSection, { isNarrow: false, title: roomType.name }),
            React.createElement("section", { className: "section details" },
                React.createElement("div", { className: "container" },
                    React.createElement(FormSection, null,
                        React.createElement("div", { className: "header-panel" },
                            React.createElement("div", { className: "buttons" },
                                React.createElement(BackToList, { to: this.buildRoomTypesListUrl(), t: t }),
                                this.renderButtons())),
                        React.createElement("div", { className: "columns" },
                            React.createElement("div", { className: "column is-4" },
                                React.createElement(LabelBlock, { label: t('NAME') },
                                    roomType.name,
                                    roomType.translations &&
                                        roomType.translations.length > 0 && (React.createElement(Accordion, { textShow: t('SHOW_TRANSLATIONS'), textHide: t('HIDE_TRANSLATIONS') }, roomType.translations.map((e, index) => {
                                        let langName = languages[e.locale]
                                            ? ` (${languages[e.locale].name})`
                                            : '';
                                        return (React.createElement(LabelBlock, { key: index, label: `${t('NAME')}${langName}`, className: "m-bottom-1" }, e.name));
                                    }))))),
                            React.createElement("div", { className: "column is-4" },
                                React.createElement(LabelBlock, { label: t('NUMBER_OF_ROOMS') },
                                    `${roomType.numberOfRooms}`,
                                    React.createElement("small", { className: "is-size-6" }, roomType.numberOfRooms > 0 && (React.createElement(Link, { to: `${routes.rooms}?roomTypeIds[]="${roomType.id}"&roomTypeName[]="${roomType.name}"` },
                                        "(",
                                        t('SEE_ALL_ROOMS'),
                                        ")")))))),
                        React.createElement("div", { className: "columns" },
                            React.createElement("div", { className: "column is-4" },
                                React.createElement(LabelBlock, { label: t('DESCRIPTION') },
                                    roomType.description,
                                    roomType.translations &&
                                        roomType.translations.length > 0 && (React.createElement(Accordion, { textShow: t('SHOW_TRANSLATIONS'), textHide: t('HIDE_TRANSLATIONS') }, roomType.translations.map((e, index) => {
                                        let langName = languages[e.locale]
                                            ? ` (${languages[e.locale].name})`
                                            : '';
                                        return (React.createElement(LabelBlock, { key: index, label: `${t('DESCRIPTION')}${langName}`, className: "m-bottom-1" }, e.description));
                                    }))))))),
                    React.createElement(Auditing, { t: t, auditing: roomType.auditing, role: role, lng: lng }))),
            this.renderModals()))));
    }
}
const mapStateToProps = state => {
    const { user: { role }, roomTypes: { roomType, roomTypesError, listParams }, } = state;
    return {
        roomType,
        role,
        roomTypesError,
        listParams,
    };
};
const mapDispatchToProps = dispatch => ({
    loadRoomType: conf => dispatch(roomTypesActions.loadRoomType(conf)),
    deleteRoomType: (id, conf) => dispatch(roomTypesActions.deleteRoomType(id, conf)),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(withErrorHandling(RoomTypeDetails))));
