import { roomAvailabilityConstants } from '../constants/roomAvailability.constants';
export const roomAvailabilityState = {
    events: [],
    isLoading: false,
};
const events = (state = roomAvailabilityState, action) => {
    switch (action.type) {
        case roomAvailabilityConstants.FETCH_EVENTS_SUCCESS:
            return Object.assign(Object.assign({}, state), { events: action.events });
        case roomAvailabilityConstants.RESET_EVENTS:
            return Object.assign(Object.assign({}, state), { events: [] });
        case roomAvailabilityConstants.SHOW_LOADER:
            return Object.assign(Object.assign({}, state), { isLoading: true });
        case roomAvailabilityConstants.HIDE_LOADER:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        default:
            return state;
    }
};
export default events;
