import { TableTooltip, ToggleInfo } from '../../index';
import i18n from '../../../i18n';
import { roomImageTypeConstants } from '../../../constants/roomImageType.constants';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
export const ImageTypeToggle = ({ onToggleItem, imageType, is360image, name = 'uploader', }) => {
    const is360 = is360image || imageType === roomImageTypeConstants.VIEW_360;
    return (React.createElement(React.Fragment, null,
        React.createElement(ToggleInfo, { name: i18n.t('IMAGE_TYPE'), labelOn: i18n.t('360_IMAGE'), labelOff: i18n.t('FLAT_IMAGE'), onChange: onToggleItem, checked: is360 }),
        is360 && (React.createElement(TableTooltip, { className: classnames('react-hint', 'tooltip-default-colors'), minWidth: 400, name: `image-type-toggle-${name}`, icon: React.createElement(FAIcon, { icon: ['fas', 'info-circle'] }) },
            React.createElement("p", null, i18n.t('360_FORMAT_1')),
            React.createElement("p", null, i18n.t('360_FORMAT_2')),
            React.createElement("p", null, i18n.t('360_FORMAT_3'))))));
};
ImageTypeToggle.propTypes = {
    onToggleItem: PropTypes.func,
    imageType: PropTypes.string,
    is360image: PropTypes.bool,
    name: PropTypes.string,
};
