import { Button } from '../../../index';
import i18n from '../../../../i18n';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
export const MaintenanceListRowButtons = props => {
    const { isPendingDeletion, cancelMaintenance, editMaintenance } = props;
    return (React.createElement("div", { className: "buttons is-right" },
        React.createElement(Button, { className: `is-text ${isPendingDeletion ? 'has-text-grey' : 'has-text-danger'}`, tooltip: isPendingDeletion
                ? i18n.t('ROOM_IS_PENDING_DELETION')
                : i18n.t('EDIT'), disabled: isPendingDeletion, icon: React.createElement(FAIcon, { icon: ['far', 'edit'] }), onClick: editMaintenance }),
        React.createElement(Button, { className: `is-text ${isPendingDeletion ? 'has-text-grey' : 'has-text-danger'}`, tooltip: isPendingDeletion
                ? i18n.t('ROOM_IS_PENDING_DELETION')
                : i18n.t('MAINTENANCE_CANCEL'), disabled: isPendingDeletion, icon: React.createElement(FAIcon, { icon: ['far', 'trash-alt'] }), onClick: cancelMaintenance })));
};
