const isDeleteDisabled = ({ item, hasRoomsTooltip, allowRemovalTooltip }) => {
    const deleteSettings = {
        icon: ['far', 'trash-alt'],
        disabled: false,
        tooltip: 'DELETE',
    };
    if (item.numberOfRooms > 0) {
        deleteSettings.disabled = true;
        deleteSettings.tooltip = hasRoomsTooltip;
        return deleteSettings;
    }
    if (!item.allowRemoval) {
        deleteSettings.disabled = true;
        deleteSettings.tooltip = allowRemovalTooltip;
        deleteSettings.icon = ['fas', 'ban'];
        return deleteSettings;
    }
    return deleteSettings;
};
export const buttonsUtils = {
    isDeleteDisabled,
};
