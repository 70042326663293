import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Formik } from 'formik';
import { Button, FormControl } from '../../../index';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import FeaturesInput from '../../../Common/FeaturesInput/FeaturesInput';
import { featureActionsConstants as bulkAction } from '../../../../constants/roomsBulk.constants';
class RoomsFeaturesBulkEditForm extends Component {
    constructor(props) {
        super(props);
        this.emptyFormState = {
            featureIds: [],
        };
        this.onSetIsSubmitTypeApply = isSubmitApply => () => {
            this.setState({ isSubmitApply: isSubmitApply });
        };
        this.onButtonClicked = buttonAction => () => {
            this.setState({
                buttonTypeAction: buttonAction,
            });
        };
        this.anyFeatureSelected = () => {
            const { selectedFeatures } = this.state;
            return selectedFeatures && selectedFeatures.length === 0;
        };
        this.state = {
            buttonTypeAction: null,
            selectedFeatures: [],
        };
    }
    render() {
        const { t, lastRoomsState } = this.props;
        const { selectedFeatures } = this.state;
        return (React.createElement(React.Fragment, null,
            React.createElement(Formik, { initialValues: this.emptyFormState, onSubmit: (values, { resetForm }) => {
                    values.featureIds = selectedFeatures.map(f => f.id);
                    switch (this.state.buttonTypeAction) {
                        case bulkAction.ADD:
                            this.props.onAdd(values.featureIds, resetForm, bulkAction.ADD);
                            break;
                        case bulkAction.REMOVE:
                            this.props.onRemove(values.featureIds, resetForm, bulkAction.REMOVE);
                            break;
                        case bulkAction.UNDO:
                            this.props.onUndo(resetForm);
                    }
                } }, ({ handleSubmit, dirty }) => (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement("div", { className: "bulk-room-features-wrapper" },
                    React.createElement("div", { className: "features-form-wrapper" },
                        React.createElement(FormControl, { className: "grid-align-bottom", label: t('FEATURES') },
                            React.createElement(FeaturesInput, { onFeaturesChange: features => {
                                    this.setState({
                                        selectedFeatures: features,
                                    });
                                } }))),
                    React.createElement("div", { className: "bulk-button-wrapper" },
                        React.createElement(Button, { type: "submit", className: "is-grey", icon: React.createElement(FAIcon, { icon: ['fas', 'undo'] }), tooltip: t('UNDO_LAST_CHANGE'), disabled: !lastRoomsState, onClick: this.onButtonClicked(bulkAction.UNDO) }),
                        React.createElement(Button, { type: "submit", className: "is-primary apply-button", text: t('ADD'), disabled: !dirty && selectedFeatures.length === 0, onClick: this.onButtonClicked(bulkAction.ADD) }),
                        React.createElement(Button, { type: "submit", className: "is-primary apply-button", text: t('REMOVE'), disabled: !dirty && selectedFeatures.length === 0, onClick: this.onButtonClicked(bulkAction.REMOVE) }))))))));
    }
}
export default withNamespaces('translations')(RoomsFeaturesBulkEditForm);
