import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Field, Formik } from 'formik';
import { Button, FormControl } from '../../../index';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { clickableLink } from '../../../../validations/shared/clickableLink';
import classnames from 'classnames/bind';
import CustomErrorMsg from './../../../Common/Form/ErrorMsg/CustomErrorMsg';
import { clickableLinkActionsConstants as bulkAction } from '../../../../constants/roomsBulk.constants';
class RoomsClickableLinkBulkEditForm extends Component {
    constructor() {
        super(...arguments);
        this.emptyFormState = {
            name: '',
            url: '',
        };
        this.state = {
            buttonTypeAction: null,
        };
        this.onButtonClicked = buttonAction => () => {
            this.setState({
                buttonTypeAction: buttonAction,
            });
        };
    }
    render() {
        const { t, lastRoomsState, onRemoveAll, onAdd, onRemove, onUndo, } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Formik, { initialValues: this.emptyFormState, validationSchema: clickableLink, onSubmit: (values, { resetForm }) => {
                    switch (this.state.buttonTypeAction) {
                        case bulkAction.ADD:
                            onAdd(values, resetForm);
                            break;
                        case bulkAction.REMOVE:
                            onRemove(values, resetForm);
                            break;
                    }
                } }, ({ handleSubmit, dirty, errors, isValid, touched }) => (React.createElement("form", { onSubmit: handleSubmit, className: "bulk-room-clickable_link-wrapper" },
                React.createElement(FormControl, { className: "grid-align-bottom support-contact-label", label: t('LINK_NAME') },
                    React.createElement(Field, { className: classnames('input', {
                            'is-danger': errors.name && touched.name,
                        }), type: "text", name: "name" }),
                    React.createElement(CustomErrorMsg, { name: errors.name, touched: touched.name })),
                React.createElement(FormControl, { className: "grid-align-bottom support-contact-label", label: t('LINK_URL') },
                    React.createElement(Field, { className: classnames('input', {
                            'is-danger': errors.url && touched.url,
                        }), type: "text", name: "url" }),
                    React.createElement(CustomErrorMsg, { name: errors.url, touched: touched.url })),
                React.createElement("div", { className: "bulk-button-wrapper padding-bottom " },
                    React.createElement(Button, { className: "is-grey", icon: React.createElement(FAIcon, { icon: ['fas', 'undo'] }), tooltip: t('UNDO_LAST_CHANGE'), disabled: lastRoomsState && lastRoomsState.length === 0, onClick: () => onUndo() }),
                    React.createElement(Button, { type: "submit", className: "is-primary apply-button", text: t('ADD'), disabled: !(isValid && dirty), onClick: this.onButtonClicked(bulkAction.ADD) }),
                    React.createElement(Button, { type: "submit", className: "is-primary apply-button", text: t('REMOVE'), disabled: !(isValid && dirty), onClick: this.onButtonClicked(bulkAction.REMOVE) }),
                    React.createElement(Button, { className: "is-danger apply-button", text: t('REMOVE_ALL'), onClick: () => onRemoveAll(bulkAction.REMOVE_ALL) })))))));
    }
}
export default withNamespaces('translations')(RoomsClickableLinkBulkEditForm);
