import queryString from 'query-string';
import history from '../history';
const preparePageableParams = ({ pageNumber, pageSize, sort, filter, search, buildingIds, floorIds, capacityFrom, capacityTo, roomTypeIds, featureIds, siteCodes, status, googleOwner, googleOwnerVisible, roomOwner, contactInformationVisible, privacy, admin, }) => {
    const params = new URLSearchParams();
    if (pageNumber) {
        params.append('page', pageNumber);
    }
    if (pageSize) {
        params.append('size', pageSize);
    }
    if (sort) {
        sort.forEach(e => {
            params.append('sort', `${e.id},${e.desc ? 'desc' : 'asc'}`);
        });
    }
    if (filter) {
        let filters = '';
        for (let key in filter) {
            if (filter[key].length > 0)
                filters += `${key},${filter[key].join(',')};`;
        }
        if (filters !== '')
            params.append('filter', filters);
    }
    if (search) {
        params.append('search', search);
    }
    if (buildingIds) {
        if (Array.isArray(buildingIds)) {
            for (let id of buildingIds) {
                params.append('buildingIds', id);
            }
        }
        else {
            params.append('buildingIds', buildingIds);
        }
    }
    if (floorIds) {
        if (Array.isArray(floorIds)) {
            for (let id of floorIds) {
                params.append('floorIds', id);
            }
        }
        else {
            params.append('floorIds', floorIds);
        }
    }
    if (capacityFrom) {
        params.append('capacityFrom', capacityFrom);
    }
    if (capacityTo) {
        params.append('capacityTo', capacityTo);
    }
    if (roomTypeIds) {
        if (Array.isArray(roomTypeIds)) {
            for (let id of roomTypeIds) {
                params.append('roomTypeIds', id);
            }
        }
        else {
            params.append('roomTypeIds', roomTypeIds);
        }
    }
    if (featureIds) {
        if (Array.isArray(featureIds)) {
            for (let id of featureIds) {
                params.append('featureIds', id);
            }
        }
        else {
            params.append('featureIds', featureIds);
        }
    }
    if (siteCodes) {
        if (Array.isArray(siteCodes)) {
            for (let id of siteCodes) {
                params.append('siteCodes', id);
            }
        }
        else {
            params.append('siteCodes', siteCodes);
        }
    }
    if (status) {
        params.append('status', status);
    }
    if (googleOwner) {
        params.append('googleOwner', googleOwner);
    }
    if (googleOwnerVisible !== undefined && googleOwnerVisible !== null) {
        params.append('googleOwnerVisible', googleOwnerVisible);
    }
    if (roomOwner) {
        params.append('owner', roomOwner);
    }
    if (admin) {
        params.append('email', admin);
    }
    if (contactInformationVisible !== undefined &&
        contactInformationVisible !== null) {
        params.append('contactInformationVisible', contactInformationVisible);
    }
    if (privacy !== undefined && privacy !== null) {
        params.append('privacy', privacy);
    }
    return params;
};
const parseUrlParams = params => {
    const parsedParams = queryString.parse(params, { arrayFormat: 'bracket' });
    for (const key of Object.keys(parsedParams)) {
        if (Array.isArray(parsedParams[key])) {
            parsedParams[key] = parsedParams[key].map(ele => JSON.parse(ele));
        }
    }
    return parsedParams;
};
const getParamsSerializer = params => {
    return queryString.stringify(params);
};
const prepareUrl = params => {
    for (const key of Object.keys(params)) {
        if (Array.isArray(params[key])) {
            params[key] = params[key].map(ele => JSON.stringify(ele));
        }
    }
    return queryString.stringify(params, { arrayFormat: 'bracket' });
};
const prepareImageResizeParams = (width = '2560', height = '2560', crop = 'false') => {
    const params = new URLSearchParams();
    params.append('width', width);
    params.append('height', height);
    params.append('crop', crop);
    return params;
};
const prepareEmailsParams = emails => {
    let params = new URLSearchParams();
    emails.forEach(email => params.append('email', email));
    return params;
};
const updateState = (prevState, updateState) => {
    const parsedPrevState = typeof prevState === 'string' ? parseUrlParams(prevState) : prevState;
    const parsedUpdateState = typeof updateState === 'string' ? parseUrlParams(updateState) : updateState;
    if (!parsedUpdateState.page)
        parsedUpdateState.page = 0;
    return Object.assign(Object.assign({}, parsedPrevState), parsedUpdateState);
};
const linkParams = to => {
    if (to === history.location.pathname)
        return {
            pathname: to,
            search: history.location.search,
        };
    return to;
};
const clearLocationState = (...stateKeys) => {
    if (!history.location.state) {
        return;
    }
    if (!stateKeys.length) {
        stateKeys = Object.keys(history.location.state);
    }
    const newState = history.location.state;
    stateKeys.forEach(key => delete newState[key]);
    history.replace({
        state: Object.assign({}, newState),
    });
};
export const pathUtils = {
    preparePageableParams,
    parseUrlParams,
    prepareUrl,
    prepareImageResizeParams,
    prepareEmailsParams,
    updateState,
    linkParams,
    clearLocationState,
    getParamsSerializer,
};
