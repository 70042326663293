export const bulkBaseActionConstants = {
    UNDO: 'UNDO',
    APPLY: 'APPLY',
};
export const featureActionsConstants = {
    UNDO: 'UNDO',
    ADD: 'ADD',
    REMOVE: 'REMOVE',
};
export const clickableLinkActionsConstants = Object.assign(Object.assign({}, featureActionsConstants), { REMOVE_ALL: 'REMOVE_ALL' });
