import { roomsUploadConstants } from '../constants';
export const roomsUploadState = {
    isVisible: false,
    uploads: [],
};
const roomsUpload = (state = roomsUploadState, action) => {
    switch (action.type) {
        case roomsUploadConstants.UPLOAD_STARTED:
            return reduceUploadStarted(state, action);
        case roomsUploadConstants.UPLOAD_FINISHED:
            return reduceUploadFinished(state, action);
        case roomsUploadConstants.UPDATE_UPLOAD_PROGRESS:
            return reduceUploadProgress(state, action);
        case roomsUploadConstants.SHOW_UPLOAD_PANEL:
            return Object.assign(Object.assign({}, state), { isVisible: true });
        case roomsUploadConstants.HIDE_UPLOAD_PANEL:
            return Object.assign(Object.assign({}, state), { isVisible: false });
        case roomsUploadConstants.REMOVE_FINISHED_UPLOAD:
            return reduceRemoveFinishedUpload(state, action);
        case roomsUploadConstants.ERROR_ROOM_IMAGES:
            return reduceUploadFinished(state, action, true);
        default:
            return state;
    }
};
const reduceUploadStarted = (state, action) => {
    const newUpload = {
        id: action.id,
        roomId: action.room.id,
        roomName: action.room.name,
        files: action.files,
        finished: false,
        error: false,
        progress: {
            total: 1,
            loaded: 0,
        },
    };
    return Object.assign(Object.assign({}, state), { isVisible: true, uploads: [newUpload, ...state.uploads] });
};
const reduceUploadFinished = (state, action, error = false) => {
    return Object.assign(Object.assign({}, state), { isVisible: true, uploads: state.uploads.map(upload => {
            if (upload.id === action.id) {
                return Object.assign(Object.assign({}, upload), { finished: true, error });
            }
            return upload;
        }) });
};
const reduceUploadProgress = (state, action) => {
    return Object.assign(Object.assign({}, state), { uploads: state.uploads.map(upload => {
            if (upload.id === action.id) {
                return Object.assign(Object.assign({}, upload), { progress: {
                        total: action.total,
                        loaded: action.loaded,
                    } });
            }
            return upload;
        }) });
};
const reduceRemoveFinishedUpload = (state, action) => {
    return Object.assign(Object.assign({}, state), { uploads: state.uploads.filter(upload => upload.id !== action.id) });
};
export default roomsUpload;
