import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pathUtils } from '../../../utils';
import history from '../../../history';
import Select from 'react-select';
import { FormControl } from '../../';
class TableSort extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            sort: 'asc',
        };
        const { sorted } = pathUtils.parseUrlParams(history.location.search);
        if (sorted) {
            this.state = {
                value: sorted[0].id,
                sort: sorted[0].desc ? 'desc' : 'asc',
            };
        }
    }
    sortBy(selected) {
        this.setState({ value: selected ? selected.value : null, sort: 'desc' }, () => this.props.onChange({
            value: selected ? selected.value : null,
            sort: 'asc',
        }));
    }
    sortType(selected) {
        this.setState({ sort: selected.value }, () => {
            this.props.onChange(this.state);
        });
    }
    render() {
        const { fields, t } = this.props;
        const { value, sort } = this.state;
        return (React.createElement("div", { className: "columns table-sort" },
            React.createElement("div", { className: "column is-2" },
                React.createElement(FormControl, { label: t('SORT_BY') },
                    React.createElement(Select, { placeholder: t('SELECT'), isClearable: true, isSearchable: false, options: fields, onChange: this.sortBy.bind(this), defaultValue: value && fields.find(field => field.value === value) }))),
            value && (React.createElement("div", { className: "column is-2" },
                React.createElement(FormControl, { label: t('SORT_TYPE') },
                    React.createElement(Select, { isSearchable: false, options: [
                            { value: 'asc', label: t('ASC') },
                            { value: 'desc', label: t('DESC') },
                        ], onChange: this.sortType.bind(this), defaultValue: sort && { value: sort, label: t(sort.toUpperCase()) } }))))));
    }
}
const fieldPropType = PropTypes.exact({
    value: PropTypes.string,
    label: PropTypes.string,
});
TableSort.propTypes = {
    fields: PropTypes.arrayOf(fieldPropType).isRequired,
    onChange: PropTypes.func.isRequired,
};
export default TableSort;
