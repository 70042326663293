var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { loadingActions } from './loading.actions';
import { roomsConstants, errorTypesConstants, statusConstants, } from '../constants';
import { errorActions } from './';
import api from '../api';
import { enrichWithId } from '../utils/RoomsUtils/rooms.utils';
const loadRooms = params => {
    const { sort, listParams } = params;
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(roomsLoading());
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.list(params);
            const { content, number, totalPages, size, totalElements, } = response.data;
            const rooms = content.map(room => {
                return enrichWithId(room);
            });
            dispatch(roomsSuccess(rooms, number, totalPages, size, sort, totalElements, listParams));
            dispatch(loadingActions.hideLoader());
        }
        catch (error) {
            dispatch(roomsError(error, loadRooms, [params]));
            dispatch(resetRooms());
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
function responseWithOrderedLinks(response) {
    return Object.assign(Object.assign({}, response.data), { clickableLinks: response.data.clickableLinks.sort((a, b) => a.positionNumber > b.positionNumber ? 1 : -1) });
}
const loadRoom = ({ id }) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        if (!id)
            return;
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.get(id);
            dispatch(roomSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return responseWithOrderedLinks(response);
        }
        catch (error) {
            error.type = errorTypesConstants.LOAD_RESOURCE;
            dispatch(roomsError(error, loadRoom, [{ id }]));
            dispatch(loadingActions.hideLoader());
            throw error;
        }
    });
};
const loadRoomAuditlog = ({ id, cursor }) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        if (!cursor) {
            dispatch(roomAuditlogSuccess(null));
        }
        if (!id)
            return;
        const response = yield api.Rooms.auditlog(id, cursor);
        dispatch(roomAuditlogSuccess(response.data));
        return response.data;
    });
};
const addRoom = data => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(roomsLoading());
        try {
            const response = yield api.Rooms.create(data);
            dispatch(roomAddSuccess());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, addRoom, [data]));
            return error;
        }
    });
};
const deleteRoom = (id, conf) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(roomsLoading());
        try {
            const response = yield api.Rooms.delete(id);
            dispatch(loadRooms(conf));
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, deleteRoom, [id, conf]));
            return error;
        }
    });
};
const deleteRoomsBulk = ids => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.deleteBulk(ids);
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, setMaintenanceBulk, [{ ids }]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const updateBulkStatus = ids => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.updateBulkStatus(ids);
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, updateBulkStatus, [{ ids }]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const restoreRoom = (id, conf) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(roomsLoading());
        try {
            const room = yield api.Rooms.get(id);
            const response = yield api.Rooms.updateState(id, {
                status: statusConstants.ACTIVE,
                version: room.data.version,
            });
            dispatch(loadRooms(conf));
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, restoreRoom, [id, conf]));
            return error;
        }
    });
};
const updateRoom = (id, data) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(roomsLoading());
        try {
            const response = yield api.Rooms.update(id, data);
            dispatch(roomUpdateSuccess());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, updateRoom, [id, data]));
            return error;
        }
    });
};
const updateSelectedRooms = (rooms, ids) => ({
    type: roomsConstants.UPDATE_SELECTED_ROOMS,
    rooms,
    ids,
});
const bulkRoomAttributeUpdate = data => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.bulkUpdateRoomsAttributes(data);
            dispatch(bulkRoomsAttributesUpdateSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, bulkRoomAttributeUpdate, [data]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const bulkRoomFeaturesAdd = (featureIds, roomIds) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.bulkRoomFeaturesAdd(featureIds, roomIds);
            dispatch(bulkRoomsFeaturesAddSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, bulkRoomFeaturesAdd, []));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const bulkRoomFeaturesRemove = (featureIds, roomIds) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.bulkRoomFeaturesRemove(featureIds, roomIds);
            dispatch(bulkRoomsFeaturesRemoveSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, bulkRoomFeaturesRemove, []));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const bulkRoomFeaturesUndo = rooms => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.bulkRoomFeaturesUndo(rooms);
            dispatch(bulkRoomFeaturesUndoSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, bulkRoomFeaturesUndo, []));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const updateState = ({ id, version }, status) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.updateState(id, { status, version });
            dispatch(roomStateSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, updateState, [{ id }]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const bulkRoomOwnershipUpdate = data => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.bulkOwnership(data);
            dispatch(bulkRoomsOwnershipUpdateSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, bulkRoomOwnershipUpdate, [data]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const bulkRoomClickableLinkAdd = (ids, linkName, linkUrl) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.bulkClickableLinkAdd(ids, linkName, linkUrl);
            dispatch(bulkRoomClickableLinkAddSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, bulkRoomClickableLinkAdd, [ids, linkName, linkUrl]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const bulkRoomClickableLinkRemove = (ids, linkName, linkUrl) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.bulkRoomClickableLinkRemove(ids, linkName, linkUrl);
            dispatch(bulkRoomClickableLinkRemoveSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, bulkRoomClickableLinkRemove, [ids, linkName, linkUrl]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const bulkRoomClickableLinkRemoveAll = ids => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.bulkRoomClickableLinkRemoveAll(ids);
            dispatch(bulkRoomClickableLinkRemoveAllSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, bulkRoomClickableLinkRemoveAll, [ids]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const bulkRoomClickableLinkUndo = rooms => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.bulkRoomClickableLinkUndo(rooms);
            dispatch(bulkRoomClickableLinkUndoSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, bulkRoomClickableLinkUndo, [rooms]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const createMaintenance = (id, data) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.createMaintenance(id, data);
            dispatch(loadRoom({ id }));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, createMaintenance, [{ id, data }]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const setMaintenanceBulk = (ids, data) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.setMaintenanceBulk(ids, data);
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, setMaintenanceBulk, [{ ids, data }]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const cancelMaintenanceBulk = ids => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.cancelMaintenanceBulk(ids);
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, cancelMaintenanceBulk, [{ ids }]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const cancelMaintenance = (id, data) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.cancelMaintenance(id, data);
            dispatch(loadRoom({ id }));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsError(error, cancelMaintenance, [{ id, data }]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const roomsSuccess = (rooms, pageNumber, totalPages, pageSize, sort, totalElements, listParams) => ({
    type: roomsConstants.FETCH_ROOMS,
    rooms,
    pageNumber,
    totalPages,
    pageSize,
    sort,
    totalElements,
    listParams,
});
const roomSuccess = room => ({
    type: roomsConstants.FETCH_SINGLE_ROOM,
    room,
});
const roomAuditlogSuccess = roomAuditlog => ({
    type: roomsConstants.FETCH_SINGLE_ROOM_AUDITLOG,
    roomAuditlog,
});
const roomStateSuccess = room => ({
    type: roomsConstants.UPDATE_ROOM_STATE,
    room,
});
const resetRooms = () => ({
    type: roomsConstants.RESET_ROOMS,
});
const roomsLoading = () => ({ type: roomsConstants.LOADING_ROOMS });
const roomsError = (error, actionFunction, actionParams) => {
    return dispatch => {
        dispatch(errorActions.error(error, actionFunction, actionParams, {
            type: roomsConstants.ERROR_ROOMS,
            error,
        }));
    };
};
const roomAddSuccess = () => ({
    type: roomsConstants.ADD_ROOM,
});
const roomUpdateSuccess = () => ({
    type: roomsConstants.UPDATE_ROOM,
});
const bulkRoomsAttributesUpdateSuccess = rooms => ({
    type: roomsConstants.BULK_ROOMS_ATTRIBUTES_UPDATE,
    rooms,
});
const bulkRoomsFeaturesAddSuccess = rooms => ({
    type: roomsConstants.BULK_ROOMS_FEATURES_ADD,
    rooms,
});
const bulkRoomsFeaturesRemoveSuccess = rooms => ({
    type: roomsConstants.BULK_ROOMS_FEATURES_REMOVE,
    rooms,
});
const bulkRoomFeaturesUndoSuccess = rooms => ({
    type: roomsConstants.BULK_ROOMS_FEATURES_UNDO,
    rooms,
});
const bulkRoomsOwnershipUpdateSuccess = rooms => ({
    type: roomsConstants.BULK_ROOMS_OWNERSHIP_UPDATE,
    rooms,
});
const bulkRoomClickableLinkAddSuccess = rooms => ({
    type: roomsConstants.BULK_ROOMS_CLICKABLE_LINK_ADD,
    rooms,
});
const bulkRoomClickableLinkRemoveSuccess = rooms => ({
    type: roomsConstants.BULK_ROOMS_CLICKABLE_LINK_REMOVE,
    rooms,
});
const bulkRoomClickableLinkRemoveAllSuccess = rooms => ({
    type: roomsConstants.BULK_ROOMS_CLICKABLE_LINK_REMOVE_ALL,
    rooms,
});
const bulkRoomClickableLinkUndoSuccess = rooms => ({
    type: roomsConstants.BULK_ROOMS_CLICKABLE_LINK_UNDO,
    rooms,
});
const clearPushToBulkFlag = () => ({
    type: roomsConstants.CLEAR_PUSH_TO_BULK_FLAG,
});
export const roomsActions = {
    loadRoom,
    loadRoomAuditlog,
    loadRooms,
    addRoom,
    deleteRoom,
    restoreRoom,
    updateRoom,
    updateState,
    createMaintenance,
    cancelMaintenance,
    bulkRoomOwnershipUpdate,
    bulkRoomAttributeUpdate,
    bulkRoomFeaturesAdd,
    bulkRoomFeaturesRemove,
    bulkRoomFeaturesUndo,
    updateSelectedRooms,
    clearPushToBulkFlag,
    setMaintenanceBulk,
    cancelMaintenanceBulk,
    deleteRoomsBulk,
    updateBulkStatus,
    bulkRoomClickableLinkAdd,
    bulkRoomClickableLinkRemove,
    bulkRoomClickableLinkRemoveAll,
    bulkRoomClickableLinkUndo,
};
