var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import ConfirmModal from './../Common/Modal/ConfirmModal/ConfirmModal';
import api from '../../api';
import _ from 'underscore';
import { Accordion, Search } from '../index';
import CheckBox from '../Common/CheckBox/CheckBox';
import { includesCaseInsensitivity } from '../../utils';
class FeaturesListModal extends Component {
    constructor(props) {
        super(props);
        this.onSelectionChange = event => {
            let checkedCategory, checkedIdx, newSelectedFeatures;
            [checkedCategory, checkedIdx] = event.target.name.split('---');
            let newFeaturesState = Object.assign({}, this.state.features);
            for (const [category, categoryFeatures] of Object.entries(newFeaturesState)) {
                if (category === checkedCategory) {
                    newSelectedFeatures = this.updateSelected(categoryFeatures, checkedIdx);
                }
            }
            this.setState({
                features: newFeaturesState,
                selectedFeatures: newSelectedFeatures,
            });
        };
        this.onSubmit = () => {
            this.props.onSubmit(this.state.selectedFeatures);
        };
        this.isAccordionOpen = category => {
            return (category.some(f => f.checked === true) || this.state.search.length > 0);
        };
        this.onClose = () => {
            this.setState({ features: null });
            this.props.onClose();
        };
        this.updateSearch = search => {
            let allFeaturesList = JSON.parse(JSON.stringify(this.state.allFeatures));
            const filteredFeatures = {};
            Object.keys(allFeaturesList).forEach(category => {
                const categoryName = category;
                if (this.matchCategory(categoryName, search)) {
                    filteredFeatures[categoryName] = allFeaturesList[category];
                }
                else {
                    this.findMatchedFeatures(allFeaturesList, category, search, filteredFeatures, categoryName);
                }
            });
            this.setState({ search: search, features: filteredFeatures });
        };
        this.state = {
            fetchedFeatures: {},
            features: {},
            allFeatures: {},
            selectedFeatures: [],
            search: '',
        };
    }
    componentDidMount() {
        this.getFeatures();
    }
    getFeatures() {
        return __awaiter(this, void 0, void 0, function* () {
            yield api.Features.searchAll()
                .then(r => r.data.map(f => this.addCheckedFalse(f)))
                .then(r => _.groupBy(r, feature => feature.category))
                .then(r => this.setState({ fetchedFeatures: r }));
        });
    }
    componentDidUpdate(prevProps) {
        const { isOpen } = this.props;
        if (prevProps.isOpen !== isOpen && isOpen) {
            this.onFeaturesOpen();
        }
    }
    onFeaturesOpen() {
        const { selectedFeaturesInForm } = this.props;
        const { fetchedFeatures } = this.state;
        let featuresMapped = JSON.parse(JSON.stringify(fetchedFeatures));
        Object.keys(featuresMapped).forEach(category => {
            featuresMapped[category].map(f => this.mapChecked(f, selectedFeaturesInForm));
        });
        this.setState({
            features: featuresMapped,
            allFeatures: featuresMapped,
            selectedFeatures: selectedFeaturesInForm,
            search: '',
        });
    }
    mapChecked(feature, selectedFeaturesInForm) {
        const data = selectedFeaturesInForm.find(function (ele) {
            return ele.id === feature.id;
        });
        if (data) {
            feature.checked = true;
        }
        return feature;
    }
    addCheckedFalse(feature) {
        return Object.assign(Object.assign({}, feature), { checked: false });
    }
    updateSelected(features, i) {
        let newSelectionState;
        features[i].checked = !features[i].checked;
        this.selectInAllFeatures(features, i);
        if (features[i].checked) {
            newSelectionState = [...this.state.selectedFeatures, features[i]];
        }
        else {
            newSelectionState = [...this.state.selectedFeatures];
            const index = newSelectionState.findIndex(feature => feature.id === features[i].id);
            if (index > -1) {
                newSelectionState.splice(index, 1);
            }
        }
        return newSelectionState;
    }
    selectInAllFeatures(features, i) {
        let allFeaturesCp = Object.assign({}, this.state.allFeatures);
        allFeaturesCp[features[i].category].forEach(f => {
            if (f.id === features[i].id) {
                f.checked = features[i].checked;
            }
        });
        this.setState({ allFeatures: allFeaturesCp });
    }
    findMatchedFeatures(allFeaturesList, category, search, filteredFeatures, categoryName) {
        const filtered = allFeaturesList[category].filter(f => {
            return this.matchFeatureOrChecked(f, search);
        });
        if (filtered.length > 0) {
            filteredFeatures[categoryName] = filtered;
        }
    }
    matchFeatureOrChecked(f, search) {
        return includesCaseInsensitivity(f.name, search) || f.checked;
    }
    matchCategory(categoryName, search) {
        return includesCaseInsensitivity(categoryName, search);
    }
    render() {
        const { t, isOpen } = this.props;
        const { features, search } = this.state;
        return isOpen && features ? (React.createElement(ConfirmModal, { bodyClassName: "features-tree", isOpen: isOpen, onClose: this.onClose, title: t('ROOM_FEATURES'), primaryButton: {
                label: t('SELECT_WITHOUT_DOTS'),
                type: 'is-primary',
                action: onClose => {
                    this.onSubmit();
                    this.onClose();
                },
            } },
            React.createElement("div", { className: "search-wrapper" },
                React.createElement(Search, { placeholder: t('SEARCH_BY_FEATURE_OR_CATEGORY'), defaultValue: search, updateSearch: this.updateSearch })),
            Object.keys(features)
                .sort()
                .map(category => (React.createElement(Accordion, { iconChevronInverseAndRight: true, textHide: category, textShow: category, isOpen: this.isAccordionOpen(features[category], search, category), key: category, fasIcon: "folder", className: "category-wrapper", buttonClassName: "flex-left" }, features[category].map((feature, i) => (React.createElement("div", { className: "feature-row", key: `feature-row-${category}---${feature.id}` },
                React.createElement(CheckBox, { className: "feature-checkbox", name: `${category}---${i}`, key: `${category}----checkbox---${feature.id}`, checked: feature.checked, onChange: this.onSelectionChange }),
                React.createElement("span", null, feature.name))))))))) : null;
    }
}
export default withNamespaces('translations')(FeaturesListModal);
