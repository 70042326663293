import { TableHeaderCell } from '../../../components';
import { Link } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { routes } from '../../../constants';
import React from 'react';
import i18n from '../../../i18n';
import { clickableLinkActionsConstants as action } from '../../../constants/roomsBulk.constants';
export function toRoomListRowDto(room) {
    return {
        roomId: room.id,
        roomName: room.name,
        clickableLinks: room.clickableLinks,
        oldState: room.oldState,
    };
}
export function getColumns(lastAction) {
    return [
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('NAME') }),
            accessor: 'roomName',
            filterable: false,
            maxWidth: 235,
            Cell: cellInfo => (React.createElement(Link, { to: formatRoute(routes.roomDetails, {
                    id: cellInfo.original.roomId,
                }) }, cellInfo.original.roomName)),
        },
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('LINK_NAME') }),
            accessor: 'linkName',
            filterable: false,
            maxWidth: 235,
            Cell: cellInfo => {
                return render(cellInfo, lastAction, propertyKey.NAME);
            },
        },
        {
            Header: React.createElement(TableHeaderCell, { text: i18n.t('LINK_URL') }),
            accessor: 'linkUrl',
            filterable: false,
            Cell: cellInfo => {
                return render(cellInfo, lastAction, propertyKey.URL);
            },
        },
    ];
}
function render(cellInfo, lastAction, propertyKey) {
    const actualValue = cellInfo.original;
    const oldState = actualValue.oldState;
    if (oldState && lastAction !== action.UNDO) {
        return getWithOldState(actualValue, oldState, lastAction, propertyKey);
    }
    else {
        return getUrls(actualValue, propertyKey);
    }
}
function getUrls(actualValue, key) {
    return actualValue.clickableLinks.map(link => {
        if (key === propertyKey.URL) {
            return getItem(actualValue.roomId, link.url);
        }
        return getItem(actualValue.roomId, link.name);
    });
}
function getWithOldState(actualValue, oldState, lastAction, key) {
    if (lastAction === action.ADD) {
        return actualValue.clickableLinks.map(actualLink => {
            const isSame = oldState.clickableLinks.find(oldStateLink => oldStateLink[key] === actualLink[key]);
            if (!isSame) {
                return getItem(actualValue.roomId, actualLink[key], 'primary-text-bold');
            }
            return getItem(actualValue.roomId, actualLink[key]);
        });
    }
    else if (lastAction === action.REMOVE) {
        return oldState.clickableLinks.map(oldLink => {
            const isSame = actualValue.clickableLinks.find(actual => oldLink[key] === actual[key]);
            if (!isSame) {
                return getItem(actualValue.roomId, oldLink[key], 'error-text-bold-striked');
            }
            return getItem(actualValue.roomId, oldLink[key], '');
        });
    }
    else if (lastAction === action.REMOVE_ALL) {
        return oldState.clickableLinks.map(oldLink => {
            return getItem(actualValue.roomId, oldLink[key], 'error-text-bold-striked');
        });
    }
    else {
        return getUrls(actualValue, key);
    }
}
function getItem(roomId, value, additionalStyle) {
    return (React.createElement("p", { className: additionalStyle, key: `${roomId}-${value}` }, value));
}
const propertyKey = {
    NAME: 'name',
    URL: 'url',
};
