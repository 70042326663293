import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Button } from '../../../';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames/bind';
const ModalButton = ({ label, type, icon = null, action, onClose, props }) => (React.createElement(Button, Object.assign({ className: type, icon: icon, onClick: () => action(onClose), text: label }, props)));
const ConfirmModal = ({ title, customModalClassName, headerClassName, primaryButton, secondaryButton, text, bodyClassName, children, onClose, isOpen, t, footerClassName, }) => {
    return (React.createElement("div", { className: `modal ${isOpen ? 'is-active' : ''}` },
        React.createElement("div", { className: "modal-background", onClick: onClose }),
        React.createElement("div", { className: `modal-content ${customModalClassName}` },
            React.createElement("header", { className: "modal-card-head" },
                title && (React.createElement("p", { className: `modal-card-title is-center ${headerClassName}` }, title)),
                React.createElement("button", { className: "modal-close", "aria-label": "close", onClick: onClose, title: t('CLOSE'), icon: React.createElement(FAIcon, { icon: ['fas', 'times'] }) })),
            React.createElement("section", { className: classnames('modal-card-body has-text-weight-bold has-text-centered is-size-5', bodyClassName) },
                children,
                text),
            React.createElement("footer", { className: classnames('modal-card-foot', footerClassName) },
                primaryButton && (React.createElement(ModalButton, Object.assign({}, primaryButton, { onClose: onClose }))),
                secondaryButton && (React.createElement(ModalButton, Object.assign({}, secondaryButton, { onClose: onClose })))))));
};
ConfirmModal.defaultProps = {
    primaryButton: {
        label: 'OK',
        type: 'is-primary',
        action: () => { },
        icon: null,
        bodyClassName: '',
        footerClassName: '',
        customModalClassName: '',
    },
};
const ModalButtonShape = {
    label: PropTypes.text,
    type: PropTypes.string,
    icon: PropTypes.element,
    action: PropTypes.func,
    props: PropTypes.object,
};
ConfirmModal.propTypes = {
    title: PropTypes.string,
    customModalClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    footerClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    primaryButton: PropTypes.shape(ModalButtonShape).isRequired,
    secondaryButton: PropTypes.shape(ModalButtonShape),
};
export default withNamespaces('translations')(ConfirmModal);
