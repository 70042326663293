import React from 'react';
import { MenuLink } from '..';
import { userRoleConstants, routes } from '../../../../constants';
import { pathUtils } from '../../../../utils';
const UserMenu = ({ t, isLogged, userRole, isUserView }) => {
    const { USER, SITE_ADMIN, ADMIN } = userRoleConstants;
    if (isLogged &&
        (userRole === USER ||
            (isUserView && (userRole === SITE_ADMIN || userRole === ADMIN))))
        return (React.createElement("div", { className: "navbar-item" },
            React.createElement(MenuLink, { to: pathUtils.linkParams(routes.userRooms), className: "navbar-link is-arrowless", activeClassName: "is-active", role: userRole, accessLevel: USER }, t('ROOMS'))));
    return null;
};
export default UserMenu;
