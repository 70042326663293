import React, { Component } from 'react';
import Button from '../Button/Button';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { saveFileFromResponse } from '../../../utils/export.utils';
import { withNamespaces } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { toastError } from '../../../toasts';
class ExportButton extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false };
    }
    onClick() {
        this.setState({ loading: true }, () => {
            this.props
                .fetchExportData(this.props.filters)
                .then(res => {
                saveFileFromResponse(res, this.props.fileName);
                this.setState({ loading: false });
            })
                .catch(err => {
                toastError(err.response.data.reason);
                this.setState({ loading: false });
            });
        });
    }
    render() {
        const { t } = this.props;
        return (React.createElement(Button, { onClick: this.onClick.bind(this), className: "is-primary mgh-small", icon: React.createElement(FAIcon, { icon: ['fas', 'file-export'] }), text: t(this.props.text), isLoading: this.state.loading }));
    }
}
ExportButton.propTypes = {
    fetchExportData: PropTypes.func.isRequired,
    fileName: PropTypes.string,
    text: PropTypes.string,
    filters: PropTypes.object,
};
ExportButton.defaultProps = {
    text: 'EXPORT_CSV',
};
export default withNamespaces('translations')(connect()(ExportButton));
