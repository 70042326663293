import { toDateTimeWithoutYear, toHoursAndMins } from '../dateFormatter.utils';
import moment from 'moment';
function displayTime(start, end) {
    return `${toHoursAndMins(start)} - ${toHoursAndMins(end)}`;
}
function displayDateTime(start, end) {
    return `${toDateTimeWithoutYear(start)} - ${toDateTimeWithoutYear(end)}`;
}
function isSameDay(start, end) {
    return moment(start).isSame(end, 'day');
}
export function getDateTime(start, end) {
    return isSameDay(start, end)
        ? displayTime(start, end)
        : displayDateTime(start, end);
}
