var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { Trans, withNamespaces } from 'react-i18next';
import Thumb from './Thumb/Thumb';
import Dropzone from 'react-dropzone';
import { arrayMove } from 'react-sortable-hoc';
import { SortableMediaList } from './SortableMediaList/SortableMediaList';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { endpoints } from '../../../constants';
import { Button } from '../../';
import { toastError } from '../../../toasts';
import { formatBytes, pathUtils } from '../../../utils';
import imageCompression from 'browser-image-compression';
import { roomImageTypeConstants } from '../../../constants/roomImageType.constants';
import { ImageTypeToggle } from '../ImageTypeToggle/ImageTypeToggle';
class MediaUploader extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            showAddModal: false,
            initialData: this.props.initialData,
            uploadedFiles: [],
            files: [],
            fileCompressing: false,
            is360image: false,
        };
        this.dropzoneRef = React.createRef();
        this.onDropAccepted = acceptedFiles => {
            acceptedFiles.map((file, i) => {
                if (file.size > this.props.maxSize) {
                    this.compress(file);
                }
                else {
                    this.addImage(acceptedFiles[i]);
                }
            });
        };
        this.onDropRejected = rejectedFiles => {
            const { t, maxSize, accept } = this.props;
            rejectedFiles.forEach(file => {
                if (!accept.includes(file.type)) {
                    toastError(t('FILE_WRONG_FORMAT'));
                }
                if (file.size > maxSize) {
                    toastError(t('FILE_TOO_BIG', {
                        fileName: file.name,
                        fileSize: formatBytes(file.size),
                        maxSize: formatBytes(maxSize),
                    }));
                }
            });
        };
        this.closeModal = () => {
            this.setState({ showAddModal: false, uploadedFiles: [] });
        };
        this.openModal = () => {
            this.setState({ showAddModal: true });
        };
        this.onModalSubmit = () => {
            const { onChange } = this.props;
            this.setState({
                files: [...this.state.files, ...this.state.uploadedFiles],
                uploadedFiles: [],
            }, () => {
                onChange(this.state.files);
            });
            this.closeModal();
        };
        this.onSortEnd = ({ oldIndex, newIndex }) => {
            this.setState({
                files: arrayMove(this.state.files, oldIndex, newIndex),
            }, () => {
                this.props.onChange(this.state.files);
            });
        };
        this.onToggleItem = index => {
            const files = [...this.state.files];
            files[index].imageType = this.toggleImage(files, index);
            this.setState({ files }, () => {
                this.props.onChange(this.state.files);
            });
        };
        this.onDeleteItem = index => {
            const files = [...this.state.files];
            files.splice(index, 1);
            this.setState({ files }, () => {
                this.props.onChange(this.state.files);
            });
        };
        this.deleteItemFromBrowse = index => {
            const files = [...this.state.uploadedFiles];
            files.splice(index, 1);
            this.setState({ uploadedFiles: files });
        };
        this.renderDropzone = ({ isDragActive, isDragReject, acceptedFiles, open }) => {
            const { t } = this.props;
            if (isDragReject || isDragActive) {
                return React.createElement("div", { className: "info" }, t('Drop files in here'));
            }
            else if (this.state.uploadedFiles.length === 0) {
                return (React.createElement("div", { className: "info no-opacity" },
                    React.createElement(Trans, { defaults: "DRAG_AND_DROP_FILES_TEXT", components: [
                            React.createElement("a", { href: null, className: "has-text-weight-bold", onClick: () => open() }, "univers"),
                        ] }),
                    React.createElement("div", { className: "is-size-5" }, t('DRAG_AND_DROP_SCALE_SIZE_TEXT', {
                        maxSize: formatBytes(1000),
                    }))));
            }
            return this.state.uploadedFiles.map((file, i) => (React.createElement(Thumb, { key: i, file: file, showFileName: true, onRemove: () => this.deleteItemFromBrowse(i) })));
        };
    }
    componentDidMount() {
        if (this.state.initialData && this.state.initialData.length > 0) {
            this.setState({
                files: this.prepareFilesFromInitialData(this.state.initialData),
            }, () => this.props.onChange(this.state.files));
        }
    }
    componentWillUnmount() {
        this.state.files.forEach(file => file instanceof File &&
            file.preview &&
            URL.revokeObjectURL(file.preview));
    }
    prepareFilesFromInitialData(initialData) {
        const preparedFiles = [];
        initialData.sort(function (a, b) {
            return a.order - b.order;
        });
        initialData.forEach(obj => {
            let blobFile = new Blob([obj.id]);
            blobFile.name = obj.name;
            blobFile.imageType = obj.type;
            preparedFiles.push(this.setFilePreview(blobFile, this.prepareThumbImagePath(obj.name)));
        });
        return preparedFiles;
    }
    setFilePreview(file, preview) {
        return Object.assign(file, { preview });
    }
    prepareThumbImagePath(image) {
        const params = pathUtils.prepareImageResizeParams(100, 100, true);
        return `${endpoints.url}${endpoints.storage}/${image}?${params}`;
    }
    imageTypeToggleHandler() {
        const is360 = !this.state.is360image;
        if (this.state.uploadedFiles.length > 0) {
            let files = [...this.state.uploadedFiles];
            files.forEach(f => {
                f.imageType = this.getImageType(is360);
            });
            this.setState({ uploadedFiles: files, is360image: is360 });
        }
        else {
            this.setState({ is360image: is360 });
        }
    }
    render() {
        const { title, disableSort } = this.props;
        const { files, showAddModal } = this.state;
        return (React.createElement("div", { className: "media-uploader" },
            files.length > 0 && (React.createElement("div", { className: "panel" },
                React.createElement(SortableMediaList, { items: files, onSortEnd: this.onSortEnd, useDragHandle: true, onDeleteItem: this.onDeleteItem, onToggleItem: this.onToggleItem, disableSort: disableSort }))),
            React.createElement("div", { className: "buttons is-marginless" },
                React.createElement(Button, { className: "is-text has-text-primary is-marginless link-button", onClick: this.openModal, text: title, icon: React.createElement(FAIcon, { icon: ['fas', 'plus'] }) })),
            showAddModal && this.renderModal()));
    }
    renderModal() {
        const { t, title, accept } = this.props;
        const { fileCompressing, is360image } = this.state;
        return (React.createElement("div", { className: "modal is-active modal-media-uploader" },
            React.createElement("div", { className: "modal-background" }),
            React.createElement("div", { className: "modal-content" },
                React.createElement("header", { className: "modal-card-head" },
                    React.createElement("div", { className: "modal-card-title" }, title),
                    React.createElement("button", { className: "modal-close", "aria-label": "close", onClick: this.closeModal, title: t('CLOSE'), icon: React.createElement(FAIcon, { icon: ['fas', 'times'] }) })),
                React.createElement("section", { className: "modal-card-body add-image-modal \t" },
                    React.createElement(ImageTypeToggle, { onToggleItem: this.imageTypeToggleHandler.bind(this), is360image: is360image })),
                React.createElement("section", { className: "modal-card-body is-paddingless" },
                    React.createElement(Dropzone, { disabled: fileCompressing, ref: this.dropzoneRef, className: "dropzone", activeClassName: "active", onDropAccepted: this.onDropAccepted, onDropRejected: this.onDropRejected, accept: accept, children: this.renderDropzone, disableClick: true })),
                React.createElement("footer", { className: "modal-card-foot" },
                    React.createElement(Button, { className: "is-primary", onClick: this.onModalSubmit, icon: React.createElement(FAIcon, { icon: ['fas', 'plus'] }), text: t('ADD'), isLoading: this.state.fileCompressing }),
                    React.createElement(Button, { className: "is-light", onClick: this.closeModal, text: t('CANCEL') }),
                    this.state.uploadedFiles.length > 0 && (React.createElement(Button, { className: "is-text has-text-primary", onClick: () => this.dropzoneRef.current.open(), text: t('BROWSE_TO_ADD_MORE') }))))));
    }
    addImage(file) {
        file.imageType = this.getImageType(this.state.is360image);
        this.setFilePreview(file, URL.createObjectURL(file));
        this.setState(state => ({
            uploadedFiles: [...state.uploadedFiles, file],
            fileCompressing: false,
        }));
    }
    getImageType(is360) {
        return is360
            ? roomImageTypeConstants.VIEW_360
            : roomImageTypeConstants.PICTURE;
    }
    compress(imageFile) {
        return __awaiter(this, void 0, void 0, function* () {
            this.setState({ fileCompressing: true });
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            };
            try {
                const compressed = yield imageCompression(imageFile, options);
                this.addImage(compressed);
            }
            catch (error) {
                toastError(error);
                this.setState({ fileCompressing: false });
            }
        });
    }
    toggleImage(files, index) {
        return files[index].imageType === roomImageTypeConstants.PICTURE
            ? roomImageTypeConstants.VIEW_360
            : roomImageTypeConstants.PICTURE;
    }
}
MediaUploader.propTypes = {
    initialData: PropTypes.array,
    onChange: PropTypes.func,
    title: PropTypes.string.isRequired,
    disableSort: PropTypes.bool,
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    maxSize: PropTypes.number,
};
export default withNamespaces('translations')(MediaUploader);
