var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { roomTypesActions } from '../../actions';
import { withNamespaces } from 'react-i18next';
import { Formik, Form, Field, getIn } from 'formik';
import { Button, ErrorMsg, FormControl, TitleSection, FormButtons, Translations, FormErrorNotification, LoaderContainer, ConfirmModal, BackToList, } from '../';
import { RoomTypesSchema } from '../../validations';
import classnames from 'classnames/bind';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { formatRoute } from 'react-router-named-routes';
import { languages } from '../../enums';
import { errorTypesConstants as err, routes, appConstants, errorConstants, } from '../../constants';
import withErrorHandling from '../Common/WithErrorHandling/withErrorHandling';
import { updateErrorMessagesOnLanguageChange } from '../../utils/formik.utils';
import i18n from '../../i18n';
class RoomTypeEdit extends Component {
    constructor(props) {
        super(props);
        this.emptyFormState = {
            name: '',
            description: '',
            translations: [],
        };
        this.state = {
            isReady: false,
            itemToAdd: null,
            itemToEdit: null,
            itemConflict: false,
            accessDenied: false,
        };
        const editId = props.match.params.id;
        if (!isNaN(editId)) {
            this.id = parseInt(editId);
        }
    }
    isEdit() {
        return this.id !== undefined;
    }
    isEditReady() {
        return this.isEdit() && this.state.isReady;
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.loadData();
        });
    }
    componentWillUnmount() {
        i18n.off('languageChanged');
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            const { loadRoomType } = this.props;
            if (this.isEdit()) {
                try {
                    yield loadRoomType({ id: this.id });
                    this.setState({ isReady: true });
                }
                catch (error) {
                    const { t, history, handleError404, roomTypesError } = this.props;
                    if (roomTypesError && roomTypesError.type === err.LOAD_RESOURCE) {
                        handleError404({
                            text: {
                                msg: t('ERROR_404_ROOM_TYPE'),
                                email: appConstants.DEFAULT_CONTACT_EMAIL,
                            },
                            backLinkPath: this.buildRoomTypesListUrl(),
                        });
                    }
                    else {
                        history.push(this.buildRoomTypesListUrl());
                    }
                }
            }
            else {
                this.setState({ isReady: true });
            }
        });
    }
    getInitialValues() {
        return this.isEdit() ? this.props.roomType : this.emptyFormState;
    }
    onSubmit(values, { setSubmitting }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { addRoomType, updateRoomType, history } = this.props;
            const savedRoomType = this.isEdit()
                ? yield updateRoomType(this.id, values)
                : yield addRoomType(values);
            setSubmitting(false);
            if (!(savedRoomType instanceof Error)) {
                const modal = this.isEdit()
                    ? { itemToEdit: Object.assign({}, values) }
                    : { itemToAdd: Object.assign(Object.assign({}, values), { id: savedRoomType.id }) };
                this.setState(modal);
            }
            else if (savedRoomType.response.data.reason ===
                errorConstants.ROOM_TYPE_NOT_FOUND_UPDATE ||
                savedRoomType.response.data.reason ===
                    errorConstants.ROOM_TYPE_NOT_FOUND_DELETE) {
                history.push({
                    pathname: this.buildRoomTypesListUrl(),
                    state: { errorModal: savedRoomType.response.data.reason },
                });
            }
            else if (savedRoomType.response.data.reason === errorConstants.ACCESS_DENIED) {
                this.setState({ accessDenied: true });
            }
            else if (savedRoomType.response.data.reason === errorConstants.CONFLICT) {
                this.loadData();
                this.setState({ itemConflict: true });
            }
        });
    }
    renderModals() {
        const { itemToEdit, itemToAdd, itemConflict, accessDenied } = this.state;
        const { t, history } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(ConfirmModal, { text: t('MODAL_ADD_ROOM_TYPE_CONFIRM', {
                    name: itemToAdd ? itemToAdd.name : '',
                }), onClose: () => {
                    this.setState({ itemToAdd: null });
                    history.push(this.buildRoomTypesListUrl());
                }, isOpen: itemToAdd != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                }, secondaryButton: {
                    label: t('SEE_ROOM_TYPE_PREVIEW'),
                    type: 'is-light',
                    action: () => {
                        history.push(formatRoute(routes.roomTypeDetails, {
                            id: itemToAdd.id,
                        }));
                    },
                } }),
            React.createElement(ConfirmModal, { title: t('EDIT'), text: t('MODAL_EDIT_ROOM_TYPE_CONFIRM', {
                    name: itemToEdit ? itemToEdit.name : '',
                }), onClose: () => {
                    this.setState({ itemToEdit: null });
                    history.push(this.buildRoomTypesListUrl());
                }, isOpen: itemToEdit != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                }, secondaryButton: {
                    label: t('SEE_ROOM_TYPE_PREVIEW'),
                    type: 'is-light',
                    action: () => {
                        history.push(formatRoute(routes.roomTypeDetails, {
                            id: itemToEdit.id,
                        }));
                    },
                } }),
            React.createElement(ConfirmModal, { title: t('ACCESS_DENIED'), text: t('ACCESS_DENIED_LOST_RIGHTS'), onClose: () => history.push(routes.logout), isOpen: accessDenied, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { title: t('CONFLICT'), text: t('ROOM_TYPE_EDIT_CONFLICT'), onClose: () => this.setState({ itemConflict: false }), isOpen: itemConflict, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } })));
    }
    buildRoomTypesListUrl() {
        return routes.roomTypes + this.props.listParams;
    }
    render() {
        const { t, roomType, roomTypesError } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(TitleSection, { title: this.isEditReady()
                    ? `${roomType.name} (${t('EDIT_ROOM_TYPE')})`
                    : t('ADD_ROOM_TYPE'), isNarrow: false }),
            React.createElement(LoaderContainer, { className: "section" }, this.state.isReady && (React.createElement("div", { className: "container" },
                React.createElement("div", { className: "header-panel" },
                    React.createElement("div", { className: "buttons" },
                        React.createElement(BackToList, { to: this.buildRoomTypesListUrl(), t: t }))),
                React.createElement("div", { className: "content" },
                    React.createElement(Formik, { enableReinitialize: true, initialValues: this.getInitialValues(), validationSchema: RoomTypesSchema, onSubmit: this.onSubmit.bind(this) }, form => {
                        const { isSubmitting, errors, touched, setFieldError, setFieldTouched, } = form;
                        updateErrorMessagesOnLanguageChange(errors, setFieldError, setFieldTouched);
                        return (React.createElement(Form, null,
                            React.createElement(FormErrorNotification, Object.assign({ t: t, schema: RoomTypesSchema, error: roomTypesError }, form)),
                            React.createElement("p", null, t('REQUIRED_FIELDS_TEXT')),
                            React.createElement("div", { className: "columns" },
                                React.createElement("div", { className: "column is-4" },
                                    React.createElement(FormControl, { label: `${t('NAME')} (${t('ENGLISH')})`, required: true },
                                        React.createElement("p", null, t('FIELD_ROOM_TYPE_NAME_DESCRIPTION')),
                                        React.createElement(Field, { className: classnames('input', {
                                                'is-danger': errors.name && touched.name,
                                            }), type: "text", placeholder: t('FIELD_ROOM_TYPE_NAME_PLACEHOLDER'), name: "name" }),
                                        React.createElement(ErrorMsg, { name: "name" })))),
                            React.createElement("div", { className: "columns" },
                                React.createElement("div", { className: "column is-8" },
                                    React.createElement(FormControl, { label: `${t('DESCRIPTION')} (${t('ENGLISH')})`, required: true },
                                        React.createElement(Field, { name: "description", component: "textarea", maxLength: 255, placeholder: t('FIELD_ROOM_TYPE_DESCRIPTION_PLACEHOLDER'), className: classnames('textarea', {
                                                'is-danger': errors.description && touched.description,
                                            }) }),
                                        React.createElement(ErrorMsg, { name: "description" })),
                                    React.createElement(Field, { name: "translations", label: t('TRANSLATIONS'), component: Translations, itemDefaultValues: { name: '', description: '' }, item: (dataItem, index) => {
                                            let tName = `translations[${index}]name`;
                                            let tDescription = `translations[${index}]description`;
                                            let langName = languages[dataItem.locale]
                                                ? ` (${languages[dataItem.locale].name})`
                                                : '';
                                            return (React.createElement(Fragment, null,
                                                React.createElement(FormControl, { label: `${t('NAME')}${langName}`, required: true },
                                                    React.createElement(Field, { type: "input", name: tName, placeholder: `${t('FIELD_ROOM_TYPE_TRANSLATION_NAME_PLACEHOLDER')}${langName}`, className: classnames('input', {
                                                            'is-danger': getIn(errors, tName) &&
                                                                getIn(touched, tName),
                                                        }) }),
                                                    React.createElement(ErrorMsg, { name: tName })),
                                                React.createElement(FormControl, { label: `${t('DESCRIPTION')}${langName}`, required: true },
                                                    React.createElement(Field, { component: "textarea", maxLength: 255, name: tDescription, placeholder: `${t('FIELD_ROOM_TYPE_TRANSLATION_DESCRIPTION_PLACEHOLDER')}${langName}`, className: classnames('textarea', {
                                                            'is-danger': getIn(errors, tDescription) &&
                                                                getIn(touched, tDescription),
                                                        }) }),
                                                    React.createElement(ErrorMsg, { name: tDescription }))));
                                        } }))),
                            React.createElement(FormButtons, null,
                                React.createElement(Button, { type: "submit", className: "is-primary", isLoading: isSubmitting, disabled: isSubmitting, text: t('SAVE'), icon: React.createElement(FAIcon, { icon: ['fas', 'check'] }) }),
                                React.createElement(Button, { to: this.buildRoomTypesListUrl(), className: "is-light", text: t('CANCEL') }))));
                    }))))),
            this.renderModals()));
    }
}
const mapStateToProps = state => {
    const { roomTypes: { roomType, roomTypesError, listParams }, } = state;
    return {
        roomType,
        roomTypesError,
        listParams,
    };
};
const mapDispatchToProps = dispatch => ({
    addRoomType: data => dispatch(roomTypesActions.addRoomType(data)),
    loadRoomType: data => dispatch(roomTypesActions.loadRoomType(data)),
    updateRoomType: (id, data) => dispatch(roomTypesActions.updateRoomType(id, data)),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(withErrorHandling(RoomTypeEdit))));
