import React, { Component } from 'react';
import { GlobalLoader } from './components';
import loadIcons from './loadIcons';
import scriptLoader from 'react-async-script-loader';
import { GoogleOAuthProvider } from '@react-oauth/google';
class GoogleLoader extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            googleLoaded: false,
        };
    }
    loadGoogle() {
        loadIcons();
        this.setState({ googleLoaded: true });
    }
    componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
        if (isScriptLoaded && !this.props.isScriptLoaded) {
            if (isScriptLoadSucceed) {
                this.loadGoogle();
            }
            else {
                this.props.onError();
            }
        }
    }
    componentDidMount() {
        const { isScriptLoaded, isScriptLoadSucceed } = this.props;
        if (isScriptLoaded && isScriptLoadSucceed)
            this.loadGoogle();
    }
    render() {
        return this.state.googleLoaded ? (this.props.children) : (React.createElement(GlobalLoader, { active: true }));
    }
}
export default scriptLoader([
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=geometry,drawing,places`,
])(GoogleLoader);
