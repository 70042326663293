var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { Router, Link, matchPath } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { connect } from 'react-redux';
import './App.scss';
import { withNamespaces } from 'react-i18next';
import ReactGa from 'react-ga';
import { roche_logo } from '../../images';
import { AdminMenu, ProfileMenu, UserMenu, Routes, Footer } from './components';
import { GlobalLoader, UploadPanel } from '../';
import { Tooltip } from '../index';
import { languages } from '../../enums';
import { routes, userRoleConstants } from '../../constants';
import { userActions } from '../../actions';
import history from '../../history';
import FullScreenImage from '../FullScreenImage/FullScreenImage';
import Cookies from 'js-cookie';
class App extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            selectedLanguage: languages[this.props.i18n.language] || languages['en'],
            showMobileMenu: false,
            globalLoader: true,
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function* () {
            const { isLogged, loadUserFromJWT } = this.props;
            if (isLogged && prevProps.isLogged !== isLogged) {
                let encoderUser = Cookies.get('CRMS_USER');
                if (encoderUser) {
                    yield loadUserFromJWT(encoderUser);
                }
            }
        });
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            const { isLogged, loadUserFromJWT } = this.props;
            let encoderUser = Cookies.get('CRMS_USER');
            if (!isLogged && encoderUser) {
                yield loadUserFromJWT(encoderUser);
            }
            this.toggleViewForUserRole();
            this.updateLoader.bind(this)();
            if (history.location.pathname === '/') {
                ReactGa.pageview('/', [], `${window.location.host}/`);
            }
        });
    }
    toggleViewForUserRole() {
        const { toggleUserView } = this.props;
        const isAdminPatch = matchPath(history.location.pathname, {
            path: '/admin',
            exact: false,
            strict: false,
        });
        if (!isAdminPatch)
            toggleUserView();
    }
    updateLoader() {
        return __awaiter(this, void 0, void 0, function* () {
            const { role, isLogged } = this.props;
            const { globalLoader } = this.state;
            if (globalLoader) {
                if ((!isLogged && !role) || (isLogged && role)) {
                    this.setState({ globalLoader: false });
                }
                else if ((!isLogged && role) || (isLogged && !role)) {
                }
                else {
                    this.setState({ globalLoader: true });
                }
            }
        });
    }
    selectLanguage(lang) {
        const { i18n } = this.props;
        i18n.changeLanguage(lang);
        this.setState({
            selectedLanguage: languages[lang],
        });
    }
    toggleMobileMenu() {
        this.setState(state => ({
            showMobileMenu: !state.showMobileMenu,
        }));
    }
    toggleUserView() {
        return __awaiter(this, void 0, void 0, function* () {
            const { isUserView, toggleUserView } = this.props;
            yield toggleUserView();
            history.push(isUserView ? '/' : routes.admin);
        });
    }
    isAdmin() {
        const { role } = this.props;
        return (role === userRoleConstants.SITE_ADMIN || role === userRoleConstants.ADMIN);
    }
    render() {
        const { isLogged, t, firstname, lastname, photo, email, role, loading, isUserView, } = this.props;
        const { showMobileMenu, globalLoader } = this.state;
        return (React.createElement(Router, { history: history },
            React.createElement(LastLocationProvider, null,
                React.createElement(Fragment, null,
                    React.createElement("div", { className: "wrapper" },
                        React.createElement("section", { className: "header" },
                            React.createElement("div", { className: "container" },
                                React.createElement("nav", { className: "navbar is-transparent", role: "navigation", "aria-label": "main navigation" },
                                    React.createElement("div", { className: "navbar-brand is-transparent" },
                                        React.createElement(Link, { to: "/", className: "navbar-item has-text-weight-bold" }, "CREMS"),
                                        React.createElement("a", { role: "button", className: `navbar-burger burger ${showMobileMenu ? 'is-active' : ''}`, "aria-label": "menu", "aria-expanded": "false", "data-target": "menu", onClick: this.toggleMobileMenu.bind(this) },
                                            React.createElement("span", { "aria-hidden": "true" }),
                                            React.createElement("span", { "aria-hidden": "true" }),
                                            React.createElement("span", { "aria-hidden": "true" }))),
                                    React.createElement("div", { id: "menu", className: `navbar-menu ${showMobileMenu ? 'is-active' : ''}` },
                                        React.createElement("div", { className: "navbar-start" },
                                            React.createElement(UserMenu, { isLogged: isLogged, userRole: role, t: t, isUserView: isUserView }),
                                            React.createElement(AdminMenu, { isLogged: isLogged, userRole: role, t: t, isUserView: isUserView, history: history })),
                                        React.createElement("div", { className: "navbar-end" },
                                            React.createElement(ProfileMenu, { t: t, isLogged: isLogged, firstname: firstname, lastname: lastname, photo: photo, email: email, lang: {
                                                    languages,
                                                    changeLanguage: this.selectLanguage.bind(this),
                                                    selected: this.state.selectedLanguage,
                                                }, role: role, isUserView: isUserView, toggleUserView: this.toggleUserView.bind(this) }),
                                            React.createElement("div", { className: "navbar-item logo" },
                                                React.createElement("figure", { className: "image roche_logo" },
                                                    React.createElement("img", { src: roche_logo, alt: "Roche" })))))))),
                        React.createElement("div", { className: loading > 0 ? 'is-loading' : null },
                            React.createElement(Routes, null))),
                    React.createElement(Footer, null),
                    React.createElement(Tooltip, { attribute: "tooltip" }),
                    React.createElement(FullScreenImage, null),
                    this.isAdmin() && React.createElement(UploadPanel, null)))));
    }
}
const mapStateToProps = state => {
    const { user: { isLogged, email, role, firstname, lastname, photo, isUserView }, loading, } = state;
    return {
        isLogged,
        email,
        loading,
        role,
        firstname,
        lastname,
        photo,
        isUserView,
    };
};
const mapDispatchToProps = dispatch => ({
    loginStatus: () => dispatch(userActions.loginStatus()),
    loadUserFromJWT: encodedUser => dispatch(userActions.loadUserFromJWT(encodedUser)),
    toggleUserView: () => dispatch(userActions.toggleUserView()),
});
export default withNamespaces('translations')(connect(mapStateToProps, mapDispatchToProps)(App));
