import * as moment from 'moment-timezone';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import ReactTable from 'react-table';
import { FormSection, LabelBlock } from '../../';
import { routes, userRoleConstants } from '../../../constants';
import './Auditing.scss';
const dateFormat = 'D-MMM-YYYY, HH:mm:ss [GMT]Z';
const Auditing = ({ t, type, auditing, auditlog, auditlogFieldMap, fetchAuditlog, role, lng, }) => {
    const { USER } = userRoleConstants;
    moment.locale(lng);
    return role !== USER && auditing ? (React.createElement(FormSection, null,
        React.createElement("div", { className: "columns" },
            React.createElement("div", { className: "column is-4" },
                React.createElement(LabelBlock, { label: t('ADDED') }, auditing.createDate
                    ? moment(auditing.createDate).format(dateFormat)
                    : '-')),
            React.createElement("div", { className: "column is-5" },
                React.createElement(LabelBlock, { label: t('ADDED_BY') }, getModifyingUserComponent(auditing.createdBy, auditing.createdByName)))),
        React.createElement("div", { className: "columns" },
            React.createElement("div", { className: "column is-4" },
                React.createElement(LabelBlock, { label: t('LAST_MODIFIED') }, auditing.updateDate
                    ? moment(auditing.updateDate).format(dateFormat)
                    : '-')),
            React.createElement("div", { className: "column is-5" },
                React.createElement(LabelBlock, { label: t('LAST_MODIFIED_BY') }, getModifyingUserComponent(auditing.modifiedBy, auditing.modifiedByName)))),
        auditlog && (React.createElement("div", { className: "columns" },
            React.createElement("div", { className: "column is-12" },
                React.createElement(LabelBlock, { label: t('AUDITING_CHANGES') },
                    React.createElement(ReactTable, { style: {
                            overflowY: 'scroll',
                            minHeight: '72px',
                            maxHeight: '320px',
                            fontSize: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            paddingLeft: '8px',
                            paddingRight: '8px',
                            backgroundColor: '#fff',
                        }, className: "auditlog", manual: true, sortable: false, showPagination: false, minRows: 0, columns: [
                            {
                                Header: t('AUDITING_UPDATE'),
                                id: 'update',
                                accessor: row => ({
                                    event: row.event,
                                    updatedDate: row.updatedDate,
                                    modifiedBy: row.modifiedBy,
                                }),
                                Cell: props => getAuditlogUpdateCell(props, t),
                                width: 320,
                            },
                            {
                                Header: t('AUDITING_FIELDS'),
                                id: 'state',
                                accessor: row => ({
                                    event: row.event,
                                    before: row.oldState || {},
                                    after: row.newState || {},
                                }),
                                Cell: props => getAuditlogChangesCell(props, auditlogFieldMap, type, t),
                            },
                        ], data: auditlog.content }),
                    React.createElement("div", { style: { textAlign: 'right' } }, auditlog.last ? (t('NO_MORE_RESULTS')) : (React.createElement("a", { onClick: () => fetchAuditlog(auditlog.cursor) }, t('AUDITING_LOAD_OLDER_ENTRIES')))))))))) : null;
};
function getModifyingUserComponent(modifyingUserId, modifyingUserName) {
    if (!modifyingUserId) {
        return '-';
    }
    if (!isEmail(modifyingUserId)) {
        return modifyingUserId;
    }
    else {
        return (React.createElement("a", { href: `mailto:${modifyingUserId}` }, modifyingUserName || modifyingUserId));
    }
}
function isEmail(userId) {
    return userId.includes('@');
}
function getAuditlogUpdateCell(props, t) {
    const value = props.value;
    let eventDescription = '';
    switch (value.event) {
        case 'UPDATE':
            eventDescription = t('AUDITING_WAS_UPDATED');
            break;
        case 'DELETE':
            eventDescription = t('AUDITING_WAS_REMOVED');
            break;
        case 'INSERT':
            eventDescription = t('AUDITING_WAS_ADDED');
            break;
        default:
    }
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "auditlog-text" },
            "at ",
            moment(value.updatedDate).format(dateFormat)),
        React.createElement("div", { className: "auditlog-text" },
            eventDescription,
            " by\u00A0",
            React.createElement("a", { href: 'mailto:' + value.modifiedBy }, value.modifiedBy))));
}
function getAuditlogChangesCell(props, auditlogFieldMap, type, t) {
    const value = props.value;
    if (value.event === 'DELETE') {
        return;
    }
    const before = value.before;
    const beforeKeys = Object.keys(before);
    const after = value.after;
    const afterKeys = Object.keys(after);
    const allKeys = beforeKeys
        .concat(afterKeys)
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort((a, b) => ('' + a).localeCompare(b));
    return (React.createElement("table", { className: "auditlog-text" },
        React.createElement("tbody", null, allKeys.map((key, i) => (React.createElement(Fragment, { key: key },
            React.createElement("tr", { style: i > 0 ? { borderTop: '1px solid #ccc' } : {} },
                React.createElement("td", { rowSpan: 2, style: {
                        verticalAlign: 'middle',
                        padding: '4px',
                        width: '20%',
                        border: 0,
                    } },
                    key,
                    auditlogFieldMap[key] && (React.createElement(Fragment, null,
                        React.createElement("br", null),
                        "(",
                        auditlogFieldMap[key],
                        ")"))),
                React.createElement("td", { className: "auditlog-prev" }, formatValue(type, key, before[key], t))),
            React.createElement("tr", { style: { borderTop: '1px solid #e9e9e9' } },
                React.createElement("td", { className: "auditlog-next" }, formatValue(type, key, after[key], t)))))))));
}
function formatValue(type, key, value, t) {
    if (value === null ||
        value === undefined ||
        value === '' ||
        value.length === 0) {
        return t('AUDITING_EMPTY');
    }
    try {
        if (key === 'localDescription') {
            value = value.toString();
        }
        else {
            value = JSON.parse(value);
        }
    }
    catch (e) {
    }
    if (Array.isArray(value)) {
        if (value.length === 0) {
            return t('AUDITING_EMPTY');
        }
        return (React.createElement(Fragment, null,
            "[\u00A0",
            value
                .map(e => formatValue(type, key, e, t))
                .reduce((array, e, i) => {
                if (i > 0) {
                    array.push(', ');
                }
                array.push(e);
                return array;
            }, []),
            "\u00A0]"));
    }
    const typeOfValue = typeof value;
    switch (typeOfValue) {
        case 'string':
            return `"${value}"`;
        case 'boolean':
            if (type === 'ROOM') {
                switch (key) {
                    case 'privacy':
                        return value ? 'PRIVATE' : 'PUBLIC';
                    case 'restrictedAccess':
                        return value ? 'RESTRICTED' : 'NON-RESTRICTED';
                    default:
                }
            }
            return value ? 'TRUE' : 'FALSE';
        case 'number':
            if (type === 'ROOM') {
                switch (key) {
                    case 'features':
                        return (React.createElement(Link, { to: formatRoute(routes.featureDetails, {
                                id: value,
                            }) }, value));
                    case 'type':
                        return (React.createElement(Link, { to: formatRoute(routes.roomTypeDetails, {
                                id: value,
                            }) }, value));
                    default:
                }
            }
            return value;
        case 'object':
        default:
    }
    return JSON.stringify(value, null, 2);
}
export default Auditing;
