import { RANGE } from '../RoomAvailabilityToolbar/RoomAvailabilityToolbar';
import { Views } from 'react-big-calendar';
import { getWeekRange } from '../../../../utils';
export const AVAILABILITY_ACTIONS = {
    SHOW_SELECTED_EVENT_MODAL: 'SHOW_SELECTED_EVENT_MODAL',
    HIDE_SELECTED_EVENT_MODAL: 'HIDE_SELECTED_EVENT_MODAL',
    SET_RANGE: 'SET_RANGE',
    SET_SELECTED_SLOT: 'SET_SELECTED_SLOT',
    RESET_SELECTED_SLOT: 'RESET_SELECTED_SLOT',
    SET_SELECTED_DAY: 'SET_SELECTED_DAY',
};
function isSameWeek(oldStartRange, newStartRange) {
    return oldStartRange.getTime() === newStartRange.getTime();
}
export const roomAvailabilityReducer = (state, action) => {
    switch (action.type) {
        case AVAILABILITY_ACTIONS.SHOW_SELECTED_EVENT_MODAL: {
            return Object.assign(Object.assign({}, state), { eventModal: action.event });
        }
        case AVAILABILITY_ACTIONS.HIDE_SELECTED_EVENT_MODAL: {
            return Object.assign(Object.assign({}, state), { eventModal: null });
        }
        case AVAILABILITY_ACTIONS.SET_SELECTED_SLOT: {
            return Object.assign(Object.assign({}, state), { selectedSlot: action.selectedSlot });
        }
        case AVAILABILITY_ACTIONS.RESET_SELECTED_SLOT: {
            return Object.assign(Object.assign({}, state), { selectedSlot: null });
        }
        case AVAILABILITY_ACTIONS.SET_SELECTED_DAY: {
            const { viewState } = state;
            const selectedDay = action.selectedDay;
            const newWeeklyRange = getWeekRange(selectedDay);
            if (viewState === Views.DAY) {
                const oldStartRange = new Date(state.dateWeeklyRange.startDate);
                const newStartRange = new Date(newWeeklyRange.startDate);
                if (isSameWeek(oldStartRange, newStartRange)) {
                    return Object.assign(Object.assign({}, state), { selectedDay: action.selectedDay });
                }
            }
            return Object.assign(Object.assign({}, state), { selectedDay: action.selectedDay, dateWeeklyRange: newWeeklyRange });
        }
        case AVAILABILITY_ACTIONS.SET_RANGE: {
            const rangeArray = action.rangeArray;
            const range = rangeArray.length === RANGE.DAY ? RANGE.DAY : RANGE.WEEK;
            const viewState = range === RANGE.WEEK ? Views.WEEK : Views.DAY;
            return Object.assign(Object.assign({}, state), { range: range, viewState: viewState });
        }
        default:
            return state;
    }
};
