var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { buildingsActions, sitesActions } from '../../actions';
import { withNamespaces, Trans } from 'react-i18next';
import { Formik, Form, Field, getIn } from 'formik';
import { ErrorMsg, FormControl, TitleSection, Button, ToggleInfo, FormButtons, FormErrorNotification, LoaderContainer, ConfirmModal, SiteSelect, BackToList, LocationSelector, Repeater, } from '../';
import { BuildingsSchema } from '../../validations';
import classnames from 'classnames/bind';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { formatRoute } from 'react-router-named-routes';
import withErrorHandling from '../Common/WithErrorHandling/withErrorHandling';
import { errorTypesConstants as err, routes, appConstants, errorConstants, } from '../../constants';
import i18n from '../../i18n';
import { updateErrorMessagesOnLanguageChange } from '../../utils/formik.utils';
class BuildingsEdit extends Component {
    constructor(props) {
        super(props);
        this.emptyFormState = {
            name: '',
            siteCode: '',
            externalLocation: false,
            latitude: '',
            longitude: '',
            floors: [
                {
                    name: '',
                    editable: true,
                },
            ],
            _hidden_coords: '',
        };
        this.state = {
            isReady: false,
            itemToAdd: null,
            itemToEdit: null,
            simulatedGoogleName: 'N/A',
            isMapOpen: false,
            itemConflict: false,
            accessDenied: false,
        };
        const editId = props.match.params.id;
        if (!isNaN(editId)) {
            this.id = parseInt(editId);
        }
    }
    isEdit() {
        return this.id !== undefined;
    }
    isEditReady() {
        return this.isEdit() && this.state.isReady;
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.loadData();
        });
    }
    componentWillUnmount() {
        i18n.off('languageChanged');
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            const { loadBuilding } = this.props;
            if (this.isEdit()) {
                try {
                    yield loadBuilding({ id: this.id });
                    this.setState({
                        isReady: true,
                        simulatedGoogleName: this.getSimulatedGoogleName(this.props.building),
                    });
                }
                catch (error) {
                    const { t, history, handleError404, buildingsError } = this.props;
                    if (buildingsError && buildingsError.type === err.LOAD_RESOURCE) {
                        handleError404({
                            text: {
                                msg: t('ERROR_404_BUILDING'),
                                email: appConstants.DEFAULT_CONTACT_EMAIL,
                            },
                            backLinkPath: this.buildBuildingListUrl(),
                        });
                    }
                    else {
                        history.push(this.buildBuildingListUrl());
                    }
                }
            }
            else {
                this.setState({ isReady: true });
            }
        });
    }
    getInitialValues() {
        return this.isEdit()
            ? Object.assign(Object.assign({}, this.props.building), { latitude: !this.props.building.latitude || this.props.building.latitude === 0
                    ? this.props.building.siteLatitude
                    : this.props.building.latitude, longitude: !this.props.building.longitude ||
                    this.props.building.longitude === 0
                    ? this.props.building.siteLongitude
                    : this.props.building.longitude, _hidden_coords: `${this.props.building.latitude},${this.props.building.longitude}` }) : this.emptyFormState;
    }
    onSubmit(values, { setSubmitting }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { addBuilding, updateBuilding, history } = this.props;
            const savedBuilding = this.isEdit()
                ? yield updateBuilding(this.id, values)
                : yield addBuilding(values);
            setSubmitting(false);
            if (!(savedBuilding instanceof Error)) {
                const modal = this.isEdit()
                    ? { itemToEdit: Object.assign({}, values) }
                    : { itemToAdd: Object.assign(Object.assign({}, values), { id: savedBuilding.id }) };
                this.setState(modal);
            }
            else if (savedBuilding.response.data.reason ===
                errorConstants.BUILDING_NOT_FOUND_UPDATE ||
                savedBuilding.response.data.reason ===
                    errorConstants.BUILDING_NOT_FOUND_DELETE) {
                history.push({
                    pathname: this.buildBuildingListUrl(),
                    state: { errorModal: savedBuilding.response.data.reason },
                });
            }
            else if (savedBuilding.response.data.reason === errorConstants.ACCESS_DENIED) {
                this.setState({ accessDenied: true });
            }
            else if (savedBuilding.response.data.reason === errorConstants.CONFLICT) {
                this.loadData();
                this.setState({ itemConflict: true });
            }
        });
    }
    updateSimulatedGoogleName() {
        this.setState({
            simulatedGoogleName: this.getSimulatedGoogleName.apply(this, arguments),
        });
    }
    getSimulatedGoogleName() {
        const values = Array.from(arguments).reduce((value, arg) => Object.assign(value, arg), {});
        const name = values.name;
        const siteName = values.siteName;
        const siteCode = values.siteCode;
        if (!(name && siteName && siteCode)) {
            return 'N/A';
        }
        return `${name} - ${siteName} (${siteCode})`;
    }
    renderModals() {
        const { itemToEdit, itemToAdd, itemConflict, accessDenied } = this.state;
        const { t, history } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(ConfirmModal, { title: t('ADD_BUILDING'), text: t('MODAL_ADD_BUILDING_CONFIRM', {
                    name: itemToAdd ? itemToAdd.name : '',
                }), onClose: () => {
                    this.setState({ itemToAdd: null });
                    history.push(this.buildBuildingListUrl());
                }, isOpen: itemToAdd != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                }, secondaryButton: {
                    label: t('SEE_BUILDING_PREVIEW'),
                    type: 'is-light',
                    action: () => {
                        history.push(formatRoute(routes.buildingDetails, {
                            id: itemToAdd.id,
                        }));
                    },
                } }),
            React.createElement(ConfirmModal, { title: t('EDIT'), text: t('MODAL_EDIT_BUILDING_CONFIRM', {
                    name: itemToEdit ? itemToEdit.name : '',
                }), onClose: () => {
                    this.setState({ itemToEdit: null });
                    history.push(this.buildBuildingListUrl());
                }, isOpen: itemToEdit != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                }, secondaryButton: {
                    label: t('SEE_BUILDING_PREVIEW'),
                    type: 'is-light',
                    action: () => {
                        history.push(formatRoute(routes.buildingDetails, {
                            id: itemToEdit.id,
                        }));
                    },
                } }),
            React.createElement(ConfirmModal, { title: t('ACCESS_DENIED'), text: t('ACCESS_DENIED_LOST_RIGHTS'), onClose: () => history.push(routes.logout), isOpen: accessDenied, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { title: t('CONFLICT'), text: t('BUILDING_EDIT_CONFLICT'), onClose: () => this.setState({ itemConflict: false }), isOpen: itemConflict, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } })));
    }
    toggleMapModal() {
        this.setState(state => ({
            isMapOpen: !state.isMapOpen,
        }));
    }
    onSortEnd({ newIndex, oldIndex }, arrayHelpers) {
        arrayHelpers.move(oldIndex, newIndex);
    }
    buildBuildingListUrl() {
        return routes.buildings + this.props.listParams;
    }
    render() {
        const { t, building, buildingsError, loadSite } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(TitleSection, { title: this.isEditReady()
                    ? `${building.name} (${t('EDIT_BUILDING')})`
                    : t('ADD_BUILDING'), isNarrow: false }),
            React.createElement(LoaderContainer, { className: "section" }, this.state.isReady && (React.createElement("div", { className: "container" },
                React.createElement("div", { className: "header-panel" },
                    React.createElement("div", { className: "buttons" },
                        React.createElement(BackToList, { to: this.buildBuildingListUrl(), t: t }))),
                React.createElement("div", { className: "content" },
                    React.createElement(Formik, { enableReinitialize: true, initialValues: this.getInitialValues(), validationSchema: BuildingsSchema, onSubmit: (values, actions) => {
                            this.onSubmit.bind(this)(values, actions);
                        } }, form => {
                        const { isSubmitting, values, handleChange, errors, touched, setFieldValue, setFieldError, setFieldTouched, } = form;
                        updateErrorMessagesOnLanguageChange(errors, setFieldError, setFieldTouched);
                        return (React.createElement(Form, null,
                            React.createElement(FormErrorNotification, Object.assign({ t: t, error: buildingsError, schema: BuildingsSchema }, form)),
                            React.createElement("p", null, t('REQUIRED_FIELDS_TEXT')),
                            React.createElement("div", { className: "columns" },
                                React.createElement("div", { className: "column is-4" },
                                    React.createElement(FormControl, { label: t('NAME'), required: true },
                                        React.createElement(Field, { className: classnames('input', {
                                                'is-danger': errors.name && touched.name,
                                            }), type: "text", placeholder: t('FIELD_BUILDING_NAME_PLACEHOLDER'), name: "name", onChange: e => {
                                                handleChange(e);
                                                this.updateSimulatedGoogleName(values, {
                                                    name: e.target.value,
                                                });
                                            } }),
                                        React.createElement(ErrorMsg, { name: "name" }))),
                                React.createElement("div", { className: "column is-6" },
                                    React.createElement(FormControl, { label: t('FULL_NAME') },
                                        React.createElement(Field, { className: classnames('input'), type: "text", disabled: true, value: this.state.simulatedGoogleName })))),
                            React.createElement("div", { className: "columns" },
                                React.createElement("div", { className: "column is-4" },
                                    React.createElement(FormControl, { label: t('SITE'), required: true },
                                        React.createElement(Field, { name: "siteCode", component: SiteSelect, userSitesOnly: true, onChange: (site) => __awaiter(this, void 0, void 0, function* () {
                                                setFieldValue('siteName', site ? site.name : null);
                                                this.updateSimulatedGoogleName(values, {
                                                    siteCode: site ? site.code : null,
                                                    siteName: site ? site.name : null,
                                                });
                                                if (site) {
                                                    const siteData = yield loadSite(site.code);
                                                    setFieldValue('_hidden_coords', `${siteData.latitude},${siteData.longitude}`);
                                                    setFieldValue('latitude', siteData.latitude);
                                                    setFieldValue('longitude', siteData.longitude);
                                                }
                                                else {
                                                    setFieldValue('_hidden_coords', '');
                                                    setFieldValue('latitude', '');
                                                    setFieldValue('longitude', '');
                                                }
                                            }) }),
                                        React.createElement(ErrorMsg, { name: "siteCode" }))),
                                React.createElement("div", { className: "column is-4" },
                                    React.createElement(FormControl, { className: "without-label" },
                                        React.createElement(ToggleInfo, { name: "externalLocation", labelOn: React.createElement(Trans, { defaults: "BUILDING_IS_INSIDE", components: [React.createElement("strong", null, "univers")] }), labelOff: React.createElement(Trans, { defaults: "BUILDING_IS_OUTSIDE", components: [React.createElement("strong", null, "univers")] }), checked: !values.externalLocation, onChange: e => {
                                                setFieldValue('externalLocation', !values.externalLocation);
                                            } })))),
                            React.createElement("div", { className: "columns" },
                                React.createElement("div", { className: "column is-8" },
                                    React.createElement(FormControl, { label: t('LOCATION') },
                                        React.createElement("div", { className: "box" },
                                            React.createElement("div", { className: "columns" },
                                                React.createElement("div", { className: "column is-6" },
                                                    React.createElement(FormControl, { label: t('LATITUDE') },
                                                        React.createElement(Field, { className: classnames('input', {
                                                                'is-danger': errors.latitude && touched.latitude,
                                                            }), type: "text", placeholder: t('LATITUDE'), name: "latitude", disabled: true }),
                                                        React.createElement(ErrorMsg, { name: "latitude" }))),
                                                React.createElement("div", { className: "column is-6" },
                                                    React.createElement(FormControl, { label: t('LONGITUDE') },
                                                        React.createElement(Field, { className: classnames('input', {
                                                                'is-danger': errors.longitude &&
                                                                    touched.longitude,
                                                            }), type: "text", placeholder: t('LONGITUDE'), name: "longitude", disabled: true }),
                                                        React.createElement(ErrorMsg, { name: "longitude" })))),
                                            React.createElement("div", { className: "columns" },
                                                React.createElement("div", { className: "column is-8" },
                                                    React.createElement("a", { onClick: this.toggleMapModal.bind(this) }, t('LOCATION_SEARCH')))))))),
                            React.createElement("div", { className: "columns" },
                                React.createElement("div", { className: "column is-4" },
                                    React.createElement(FormControl, { label: t('FLOORS'), required: true, className: "floors" },
                                        React.createElement("p", null, t('FIELD_FLOORS_DESCRIPTION')),
                                        React.createElement(Field, { component: Repeater, name: "floors", itemDefaultValues: this.emptyFormState.floors[0], isSortable: true, onSortEnd: this.onSortEnd, labelAdd: t('ADD_FLOOR'), item: (dataItem, index, arrayHelpers, DragHandle) => {
                                                let fName = `floors[${index}]name`;
                                                return (React.createElement("div", { className: "field" },
                                                    React.createElement("div", { className: "control has-icons-right" },
                                                        React.createElement(Field, { disabled: !dataItem.editable, type: "text", name: fName, placeholder: t('FLOOR_NAME'), className: classnames('input', {
                                                                'is-danger': getIn(errors, fName) &&
                                                                    getIn(touched, fName),
                                                            }) }),
                                                        React.createElement(ErrorMsg, { name: fName }),
                                                        DragHandle && React.createElement(DragHandle, { t: t }),
                                                        React.createElement(Button, { className: "icon is-right is-text has-text-primary", tooltip: t(dataItem.editable
                                                                ? 'DELETE'
                                                                : 'FLOOR_HAS_ROOMS_REMOVE'), icon: React.createElement(FAIcon, { icon: ['far', 'trash-alt'], className: "has-text-primary" }), disabled: !dataItem.editable, onClick: () => arrayHelpers.remove(index) }))));
                                            } })))),
                            React.createElement(FormButtons, null,
                                React.createElement(Button, { type: "submit", className: "is-primary", isLoading: isSubmitting, disabled: isSubmitting, text: t('SAVE'), icon: React.createElement(FAIcon, { icon: ['fas', 'check'] }) }),
                                React.createElement(Button, { to: this.buildBuildingListUrl(), className: "is-light", text: t('CANCEL') })),
                            React.createElement(LocationSelector, { placeholder: t('LOCATION_PLACEHOLDER'), onClose: this.toggleMapModal.bind(this), isOpen: this.state.isMapOpen, coords: values._hidden_coords, locationSelected: location => {
                                    let lat = '';
                                    let lng = '';
                                    if (location.lat || location.lng) {
                                        lat = parseFloat(location.lat.toFixed(7));
                                        lng = parseFloat(location.lng.toFixed(7));
                                    }
                                    lat !== 0 && lng !== 0
                                        ? setFieldValue('_hidden_coords', `${lat},${lng}`)
                                        : setFieldValue('_hidden_coords', '');
                                    setFieldValue('latitude', lat);
                                    setFieldValue('longitude', lng);
                                } })));
                    }))))),
            this.renderModals()));
    }
}
const mapStateToProps = state => {
    const { buildings: { building, buildingsError, listParams }, } = state;
    return {
        building,
        buildingsError,
        listParams,
    };
};
const mapDispatchToProps = dispatch => ({
    addBuilding: data => dispatch(buildingsActions.addBuilding(data)),
    updateBuilding: (id, data) => dispatch(buildingsActions.updateBuilding(id, data)),
    loadBuilding: id => dispatch(buildingsActions.loadBuilding(id)),
    loadSite: code => dispatch(sitesActions.loadSite({ code })),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(withErrorHandling(BuildingsEdit))));
