import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { TitleSection } from '../';
class Auth extends Component {
    render() {
        const { t } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(TitleSection, { title: t('AUTHORIZATION') }),
            React.createElement("section", { className: "section" },
                React.createElement("div", { className: "container" },
                    React.createElement("h1", null,
                        t('AUTHORIZATION_IN_PROGRESS'),
                        "...")))));
    }
}
const mapStateToProps = state => {
    const { user: { isLogged }, } = state;
    return {
        isLogged,
    };
};
export default withNamespaces('translations')(withRouter(connect(mapStateToProps)(Auth)));
