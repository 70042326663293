import React from 'react';
import Toggle from 'react-toggled';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames/bind';
import i18n from '../../../i18n';
const AdminToggle = ({ isUserView, toggleUserView, selectedLanguage }) => (React.createElement(Toggle, { onToggle: on => toggleUserView(on), defaultOn: isUserView }, ({ on, getTogglerProps, toggle }) => (React.createElement("button", Object.assign({ className: classnames('AdminToggle', `AdminToggle--${selectedLanguage.locale}`, {
        'AdminToggle--on': on,
    }), onClick: toggle }, getTogglerProps()),
    React.createElement("p", null, on ? i18n.t('USER') : i18n.t('ADMIN')),
    React.createElement("div", { className: "AdminToggle__Icon" },
        React.createElement(FAIcon, { icon: on ? ['far', 'user'] : ['fas', 'user-astronaut'] }))))));
export default AdminToggle;
