import { isMobileOrTablet } from './';
Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1)
        return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
};
export const scrollTo = (element, to = 0, duration = 0) => {
    if (isMobileOrTablet) {
        document.body.scrollTop = document.documentElement.scrollTop = to;
    }
    else {
        const start = element.scrollTop;
        const change = to - start;
        const increment = 20;
        let currentTime = 0;
        const animateScroll = () => {
            currentTime += increment;
            const val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration)
                setTimeout(animateScroll, increment);
        };
        animateScroll();
    }
};
