import { roomsConstants } from '../constants';
export const roomsState = {
    rooms: [],
    roomsError: null,
    roomsLoading: false,
    pageNumber: 0,
    pageSize: 20,
    sort: [],
    totalPages: 1,
    room: null,
    totalElements: 0,
    listParams: '',
    selectedRooms: [],
    selectedRoomsIds: [],
    shouldPushToBulk: false,
};
const rooms = (state = roomsState, action) => {
    switch (action.type) {
        case roomsConstants.FETCH_ROOMS:
            return Object.assign(Object.assign({}, state), { rooms: [...action.rooms], pageNumber: action.pageNumber, pageSize: action.pageSize, sort: action.sort, totalPages: action.totalPages, roomsLoading: false, totalElements: action.totalElements, roomsError: null, listParams: action.listParams || '' });
        case roomsConstants.RESET_ROOMS:
            return Object.assign(Object.assign({}, state), { rooms: [], roomsLoading: false, pageNumber: 0, pageSize: 20, totalPages: 1, totalElements: 0 });
        case roomsConstants.FETCH_SINGLE_ROOM:
            return Object.assign(Object.assign({}, state), { room: action.room, roomsError: null, roomsLoading: false });
        case roomsConstants.FETCH_SINGLE_ROOM_AUDITLOG:
            if (!action.roomAuditlog) {
                return Object.assign(Object.assign({}, state), { roomAuditlog: null });
            }
            if (state.roomAuditlog && state.roomAuditlog.content) {
                const oldContent = state.roomAuditlog.content;
                const existingIds = oldContent.map(entry => entry.id);
                const newContent = action.roomAuditlog.content.filter(entry => existingIds.indexOf(entry.id) < 0);
                action.roomAuditlog.content = state.roomAuditlog.content.concat(newContent);
            }
            return Object.assign(Object.assign({}, state), { roomAuditlog: action.roomAuditlog });
        case roomsConstants.ADD_ROOM:
        case roomsConstants.UPDATE_ROOM:
            return Object.assign(Object.assign({}, state), { roomsError: null, roomsLoading: false });
        case roomsConstants.UPDATE_ROOM_STATE:
            return Object.assign(Object.assign({}, state), { room: Object.assign(Object.assign({}, state.room), { status: action.room.status }), roomsError: null, roomsLoading: false });
        case roomsConstants.ERROR_ROOMS:
            return Object.assign(Object.assign({}, state), { roomsError: action.error, roomsLoading: false });
        case roomsConstants.LOADING_ROOMS:
            return Object.assign(Object.assign({}, state), { roomsLoading: true });
        case roomsConstants.UPDATE_SELECTED_ROOMS:
            return Object.assign(Object.assign({}, state), { selectedRooms: action.rooms, selectedRoomsIds: action.ids, shouldPushToBulk: true });
        case roomsConstants.CLEAR_PUSH_TO_BULK_FLAG:
            return Object.assign(Object.assign({}, state), { shouldPushToBulk: false });
        default:
            return state;
    }
};
export default rooms;
