import React, { Fragment } from 'react';
import { MenuLink } from '..';
import { matchPath } from 'react-router';
import { userRoleConstants, routes, resourcesMenu, } from '../../../../constants';
import history from '../../../../history';
import { pathUtils } from '../../../../utils';
const prepareAdminMenuName = path => {
    for (const key of Object.keys(resourcesMenu)) {
        if (matchPath(path, {
            path: key,
            exact: true,
            strict: false,
        }))
            return resourcesMenu[key];
    }
    return 'MENU_RESOURCES';
};
const AdminMenu = ({ t, isLogged, userRole, isUserView }) => {
    const { SITE_ADMIN, ADMIN } = userRoleConstants;
    if (isLogged &&
        !isUserView &&
        (userRole === SITE_ADMIN || userRole === ADMIN))
        return (React.createElement(Fragment, null,
            React.createElement("div", { className: "navbar-item has-dropdown is-hoverable item-resources" },
                React.createElement("span", { className: "navbar-link selected-page" }, t(prepareAdminMenuName(history.location.pathname))),
                React.createElement("div", { className: "navbar-dropdown" },
                    React.createElement(MenuLink, { to: pathUtils.linkParams(routes.sites), className: "navbar-item", activeClassName: "is-active", role: userRole, accessLevel: ADMIN }, t('SITES')),
                    React.createElement(MenuLink, { to: pathUtils.linkParams(routes.buildings), className: "navbar-item", activeClassName: "is-active", role: userRole, accessLevel: SITE_ADMIN }, t('BUILDINGS')),
                    React.createElement(MenuLink, { to: pathUtils.linkParams(routes.rooms), className: "navbar-item", activeClassName: "is-active", role: userRole, accessLevel: SITE_ADMIN }, t('ROOMS')),
                    React.createElement(MenuLink, { to: pathUtils.linkParams(routes.roomTypes), className: "navbar-item", activeClassName: "is-active", role: userRole, accessLevel: SITE_ADMIN }, t('ROOM_TYPES')),
                    React.createElement(MenuLink, { to: pathUtils.linkParams(routes.features), className: "navbar-item", activeClassName: "is-active", role: userRole, accessLevel: SITE_ADMIN }, t('FEATURES')),
                    React.createElement(MenuLink, { to: pathUtils.linkParams(routes.roomsMaintenances), className: "navbar-item", activeClassName: "is-active", role: userRole, accessLevel: SITE_ADMIN }, t('MAINTENANCES')))),
            React.createElement("div", { className: "navbar-item" },
                React.createElement(MenuLink, { to: pathUtils.linkParams(routes.administrators), className: "navbar-link is-arrowless", activeClassName: "is-active", role: userRole, accessLevel: SITE_ADMIN }, t('ADMINISTRATORS')))));
    return null;
};
export default AdminMenu;
