import Yup from '../yup';
const name = Yup.string()
    .label('NAME')
    .max(100, 'ROOM_TYPE_NAME_MAX')
    .required('ROOM_TYPE_NAME_REQUIRED');
const description = Yup.string()
    .label('DESCRIPTION')
    .required('ROOM_TYPE_DESCRIPTION_REQUIRED');
const translation = Yup.object().shape({
    name: Yup.string()
        .max(100, 'ROOM_TYPE_NAME_MAX')
        .required('ROOM_TYPE_TRANSLATION_NAME_REQUIRED'),
    locale: Yup.string().required('ROOM_TYPE_TRANSLATION_LANGUAGE_REQUIRED'),
    description: Yup.string().required('ROOM_TYPE_TRANSLATION_DESCRIPTION_REQUIRED'),
});
const translations = Yup.array()
    .label('TRANSLATIONS')
    .of(translation)
    .unique('locale', 'TRANSLATION_LANGUAGE_UNIQUE');
const RoomTypesSchema = Yup.object().shape({
    name,
    description,
    translations,
});
export { RoomTypesSchema };
