var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { withNamespaces } from 'react-i18next';
import { Field } from 'formik';
import { ReactSelect, FormControl, ErrorMsg, SiteSelect } from '../../index';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import api from '../../../api';
class SiteBuildingFloorSelect extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            buildings: [],
            floors: [],
        };
        this.fetchBuildings = (site) => __awaiter(this, void 0, void 0, function* () {
            const siteCode = (site && site.code) || this.props.form.values.siteCode;
            if (siteCode) {
                const res = yield yield api.Buildings.getAllInSite(siteCode);
                this.setState({
                    buildings: res.data,
                });
                return res.data;
            }
        });
        this.fetchFloors = (building) => __awaiter(this, void 0, void 0, function* () {
            const buildingId = (building && building.id) || this.props.form.values.buildingId;
            if (buildingId) {
                const res = yield api.Buildings.get(buildingId);
                const floors = res.data.floors;
                this.setState({
                    floors,
                });
                return floors;
            }
        });
        this.onSiteSelected = (site) => __awaiter(this, void 0, void 0, function* () {
            const { form, onSiteChange } = this.props;
            if (site) {
                form.setFieldValue('siteCode', site.code);
                form.setFieldValue('siteName', site.name);
            }
            else {
                this.clearFields(['siteCode', 'siteName']);
            }
            yield this.fetchBuildings(site);
            this.clearFields(['buildingId', 'floorId', 'floorSection']);
            if (onSiteChange) {
                onSiteChange(site);
            }
        });
        this.onBuildingSelected = (building) => __awaiter(this, void 0, void 0, function* () {
            const { form, onBuildingChange } = this.props;
            if (building) {
                form.setFieldValue('buildingId', building.id);
                form.setFieldValue('buildingName', building.name);
            }
            else {
                this.clearFields(['buildingId', 'buildingName']);
            }
            yield this.fetchFloors(building);
            this.clearFields(['floorId', 'floorSection']);
            if (onBuildingChange) {
                onBuildingChange(building);
            }
        });
        this.onFloorSelected = floor => {
            this.clearFields(['floorSection']);
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.fetchBuildings();
            yield this.fetchFloors();
        });
    }
    clearFields(fields) {
        fields.forEach(f => this.props.form.setFieldValue(f, ''));
    }
    render() {
        const { t, form } = this.props;
        const { errors, touched, values } = form;
        return (React.createElement(Fragment, null,
            React.createElement("div", { className: "columns" },
                React.createElement("div", { className: "column is-4" },
                    React.createElement(FormControl, { label: t('SITE'), required: true },
                        React.createElement(Field, { name: "siteCode", component: SiteSelect, userSitesOnly: true, onChange: this.onSiteSelected }),
                        React.createElement(ErrorMsg, { name: "siteCode" }))),
                values.siteCode && (React.createElement("div", { className: "column is-4" },
                    React.createElement(FormControl, { label: t('BUILDING'), required: true },
                        React.createElement(Field, { name: "buildingId", component: ReactSelect, getOptionLabel: e => e.name, getOptionValue: e => e.id, options: this.state.buildings, onChange: this.onBuildingSelected, placeholder: t('SELECT_BUILDING_PLACEHOLDER'), className: classnames({
                                'is-danger': errors.buildingId && touched.buildingId,
                            }) }),
                        React.createElement(ErrorMsg, { name: "buildingId" })))),
                values.siteCode && values.buildingId && (React.createElement("div", { className: "column is-2" },
                    React.createElement(FormControl, { label: t('FLOOR'), required: true },
                        React.createElement(Field, { name: "floorId", component: ReactSelect, getOptionLabel: e => e.name, getOptionValue: e => e.id, options: this.state.floors, onChange: this.onFloorSelected, placeholder: t('SELECT_FLOOR_PLACEHOLDER'), className: classnames({
                                'is-danger': errors.floorId && touched.floorId,
                            }) }),
                        React.createElement(ErrorMsg, { name: "floorId" })))),
                values.siteCode && values.buildingId && values.floorId && (React.createElement("div", { className: "column is-2" },
                    React.createElement(FormControl, { label: t('FLOOR_SECTION') },
                        React.createElement(Field, { type: "text", name: "floorSection", placeholder: t('FIELD_FLOOR_SECTION_PLACEHOLDER'), className: classnames('input', {
                                'is-danger': errors.floorSection && touched.floorSection,
                            }) }),
                        React.createElement(ErrorMsg, { name: "floorSection" })))))));
    }
}
SiteBuildingFloorSelect.propTypes = {
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
};
export default withNamespaces('translations')(SiteBuildingFloorSelect);
