import React from 'react';
import { TableTooltip } from '../../../index';
import classnames from 'classnames/bind';
const ExpandTextTableTooltip = ({ text, minTextLengthToShowTooltip, itemId, }) => {
    if (shouldNotRenderTableTooltip(text, minTextLengthToShowTooltip)) {
        return text;
    }
    return (React.createElement(TableTooltip, { className: classnames('react-hint', 'tooltip-default-colors'), minWidth: text.length > 200 ? 700 : 300, name: `table-tooltip-${itemId}`, text: text }, text));
};
function shouldNotRenderTableTooltip(text, minTextLengthToShowTooltip) {
    return text && text.length < minTextLengthToShowTooltip;
}
export default ExpandTextTableTooltip;
