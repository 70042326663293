import React, { Component, Fragment } from 'react';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../index';
import { roomsUploadActions } from '../../../actions/index';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
class UploadItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            removing: false,
        };
    }
    render() {
        const { upload } = this.props;
        const { removing } = this.state;
        return (React.createElement("div", { className: classnames('panel-block', {
                removing,
            }) },
            React.createElement("div", { className: "columns is-vcentered is-gapless UploadPanel__upload-columns" },
                React.createElement("div", { className: "column is-two-fifths" },
                    React.createElement("strong", null, upload.roomName),
                    React.createElement("ul", null, upload.files.map((f, i) => (React.createElement("li", { tooltip: f.name, key: i }, f.name))))),
                React.createElement("div", { className: "column" }, this.renderProgressColumn()))));
    }
    renderProgressColumn() {
        const { upload } = this.props;
        return upload.finished ? (this.renderUploadFinished()) : (React.createElement("progress", { className: "progress is-success", value: this.calculateProgress(upload), max: "100" }));
    }
    renderUploadFinished() {
        const { upload, t } = this.props;
        return (React.createElement("div", { className: "UploadPanel__upload-progress" }, !upload.error ? (React.createElement(Fragment, null,
            React.createElement("span", null, t('FILES_UPLOADED')),
            this.renderRemoveButton(['fas', 'check']))) : (React.createElement(Fragment, null,
            React.createElement("span", { className: "UploadPanel__error" }, t('UPLOAD_IMAGE_FILE_NOT_FOUND')),
            this.renderRemoveButton(['fas', 'times'])))));
    }
    renderRemoveButton(icon) {
        const { upload, t, removeFinishedUpload } = this.props;
        const { removing } = this.state;
        return (React.createElement(Button, { onClick: () => {
                this.setState({ removing: true });
                setTimeout(() => removeFinishedUpload(upload.id), 300);
            }, disabled: removing, className: "is-text has-text-primary is-pulled-right", tooltip: t('CLEAR'), icon: React.createElement(FAIcon, { icon: icon }) }));
    }
    calculateProgress(upload) {
        return (upload.progress.loaded / upload.progress.total) * 100;
    }
}
UploadItem.propTypes = {
    upload: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
    const { roomsUpload: { isVisible, uploads }, } = state;
    return {
        isVisible,
        uploads,
    };
};
const mapDispatchToProps = dispatch => ({
    removeFinishedUpload: id => dispatch(roomsUploadActions.removeFinishedUpload(id)),
});
export default withNamespaces('translations')(connect(mapStateToProps, mapDispatchToProps)(UploadItem));
