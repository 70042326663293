import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Tile, TileBox, TileContainer, TileRow, ToggleInfo, LoaderContainer, } from '..';
import { routes } from '../../constants';
import { tile_admins, tile_buildings, tile_features, tile_room_types, tile_rooms, tile_locations, } from '../../images';
class LandingAdmin extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            skipLanding: false,
        };
    }
    render() {
        const { t } = this.props;
        return (React.createElement(LoaderContainer, { className: "section" },
            React.createElement("div", { className: "container landing" },
                React.createElement("h1", { className: "landing-header" }, t('LANDING_ADMIN_HEADER')),
                React.createElement(TileContainer, null,
                    React.createElement(TileRow, null,
                        React.createElement(Tile, { title: t('ROOMS'), img: tile_rooms, desc: t('GO_TO_ROOMS'), path: routes.rooms }),
                        React.createElement(Tile, { title: t('ROOM_FEATURES'), img: tile_features, desc: t('GO_TO_ROOM_FEATURES'), path: routes.features }),
                        React.createElement(Tile, { title: t('ROOM_TYPES'), img: tile_room_types, desc: t('GO_TO_ROOM_TYPES'), path: routes.roomTypes }),
                        React.createElement(Tile, { title: t('SITES'), img: tile_locations, desc: t('GO_TO_SITE_LIST'), path: routes.sites })),
                    React.createElement(TileRow, null,
                        React.createElement(Tile, { title: t('BUILDINGS'), img: tile_buildings, desc: t('GO_TO_BUILDINGS'), path: routes.buildings }),
                        React.createElement(Tile, { title: t('ADMINISTRATOR_ROLES'), img: tile_admins, desc: t('GO_TO_ADMINISTRATORS'), path: routes.administrators }),
                        React.createElement(TileBox, { size: 6, isWhite: true, hasBorder: true, className: "skip-landing" },
                            React.createElement("div", { className: "field" },
                                React.createElement(ToggleInfo, { name: "skipLanding", labelOn: t('LANDING_SKIP_LABEL'), labelOff: t('LANDING_SKIP_LABEL'), onChange: e => {
                                        localStorage.setItem('skipLanding', JSON.stringify(e.target.checked));
                                        this.setState({ skipLanding: e.target.checked });
                                    }, checked: this.state.skipLanding }))))))));
    }
}
export default withNamespaces('translations')(withRouter(LandingAdmin));
