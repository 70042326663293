import { bulkBaseActionConstants as bulkAction } from '../../../constants/roomsBulk.constants';
import React from 'react';
export function renderRowWithOldState(oldValue, actualValue, lastAction) {
    if (lastAction !== bulkAction.UNDO && actualValue !== oldValue) {
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "primary-text-bold" }, actualValue),
            React.createElement("p", { className: "error-text-bold" },
                "(",
                oldValue,
                ")")));
    }
    else {
        return renderRow(actualValue);
    }
}
export function renderRow(actualValue) {
    return React.createElement("span", null, actualValue);
}
