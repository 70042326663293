var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from 'react';
import ReactHintFactory from 'react-hint';
const ReactHint = ReactHintFactory(React);
class Tooltip extends Component {
    render() {
        const _a = this.props, { name, events, position, children } = _a, props = __rest(_a, ["name", "events", "position", "children"]);
        return (React.createElement(ReactHint, Object.assign({ attribute: name, events: { hover: true }, position: position, ref: ref => (this.instance = ref), onRenderContent: children && (() => children) }, props)));
    }
}
export default Tooltip;
