var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { buildingsActions, userSearchActions } from '../../actions';
import { Auditing, BackToList, ConfirmModal, DetailsViewButtons, FormSection, LabelBlock, LoaderContainer, TitleSection, } from '..';
import { Trans, withNamespaces } from 'react-i18next';
import { formatRoute } from 'react-router-named-routes';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { appConstants, errorConstants, errorTypesConstants as err, routes, userRoleConstants, } from '../../constants';
import { toastError } from '../../toasts';
import withErrorHandling from '../Common/WithErrorHandling/withErrorHandling';
import { buttonsUtils } from '../../utils';
class BuildingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmDeleteModal: false,
            showInfoDeleteModal: false,
            isReady: false,
            userNames: [],
        };
        const id = props.match.params.id;
        if (!isNaN(id)) {
            this.id = parseInt(id);
        }
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            const { loadBuilding, adminView } = this.props;
            try {
                let loadedBuilding = yield loadBuilding({ id: this.id });
                if (adminView && this.isBuildingAdmin(loadedBuilding)) {
                    this.fetchAuditlog(this.id);
                }
                this.fetchUsersFullNames(loadedBuilding);
                this.setState({ isReady: true });
            }
            catch (error) {
                const { t, history, handleError404, buildingsError } = this.props;
                if (buildingsError && buildingsError.type === err.LOAD_RESOURCE) {
                    handleError404({
                        text: {
                            msg: t('ERROR_404_BUILDING'),
                            email: appConstants.DEFAULT_CONTACT_EMAIL,
                        },
                        backLinkPath: this.buildBuildingsListUrl(),
                    });
                }
                else {
                    history.push(this.buildBuildingsListUrl());
                }
            }
        });
    }
    fetchUsersFullNames(loadedBuilding) {
        const { auditing } = loadedBuilding;
        if (auditing) {
            userSearchActions
                .fetchUserNamesByEmails([auditing.createdBy, auditing.modifiedBy])
                .then(names => this.setState({ userNames: names }));
        }
    }
    fetchAuditlog(id, cursor) {
        const { loadBuildingAuditlog } = this.props;
        loadBuildingAuditlog({ id, cursor });
    }
    isBuildingAdmin(building) {
        const { ADMIN, SITE_ADMIN } = userRoleConstants;
        const { role, sites } = this.props;
        const { siteName, siteCode } = building;
        const adminSites = sites.map(site => `${site.name} (${site.code})`);
        return (role === ADMIN ||
            (role === SITE_ADMIN && adminSites.includes(`${siteName} (${siteCode})`)));
    }
    renderButtons() {
        const { SITE_ADMIN, ADMIN } = userRoleConstants;
        const { role, sites, building } = this.props;
        const adminSites = sites.map(site => site.code);
        const deleteSettings = buttonsUtils.isDeleteDisabled({
            item: building,
            hasRoomsTooltip: 'BUILDING_HAS_ROOMS',
            allowRemovalTooltip: 'ITEM_USED',
        });
        if (role === ADMIN ||
            (role === SITE_ADMIN && adminSites.includes(building.siteCode)))
            return (React.createElement(DetailsViewButtons, { editProps: {
                    to: formatRoute(routes.buildingEdit, {
                        id: building.id,
                    }),
                }, deleteProps: {
                    onClick: () => this.setState({
                        showConfirmDeleteModal: true,
                    }),
                }, deleteSettings: deleteSettings }));
    }
    renderModals() {
        const { showConfirmDeleteModal, showInfoDeleteModal } = this.state;
        const { t, history, deleteBuilding, building } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(ConfirmModal, { title: t('CONFIRM'), text: t('DELETE_CONFIRM_MESSAGE_BUILDING', {
                    item: building.name,
                }), onClose: () => this.setState({ showConfirmDeleteModal: false }), isOpen: showConfirmDeleteModal, primaryButton: {
                    label: t('DELETE'),
                    type: 'is-danger',
                    action: (onClose) => __awaiter(this, void 0, void 0, function* () {
                        const deletedBuilding = yield deleteBuilding(building.id, {
                            pageNumber: 0,
                        });
                        if (!(deletedBuilding instanceof Error)) {
                            this.setState({ showInfoDeleteModal: true });
                        }
                        else if (deletedBuilding.response.data.reason ===
                            errorConstants.BUILDING_NOT_FOUND_DELETE) {
                            history.push({
                                pathname: formatRoute(routes.buildings),
                                state: { errorModal: deletedBuilding.response.data.reason },
                            });
                        }
                        else {
                            toastError(t(deletedBuilding.response.data.reason));
                        }
                        onClose();
                    }),
                    icon: React.createElement(FAIcon, { icon: ['far', 'trash-alt'] }),
                }, secondaryButton: {
                    label: t('CANCEL'),
                    type: 'is-light',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t('MODAL_DELETE_BUILDING_INFO', {
                    name: building.name,
                }), onClose: () => this.setState({ showInfoDeleteModal: false }), isOpen: showInfoDeleteModal, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => {
                        history.push(this.buildBuildingsListUrl());
                        onClose();
                    },
                } })));
    }
    buildBuildingsListUrl() {
        return routes.buildings + this.props.listParams;
    }
    getUserFullName(email) {
        let user = this.state.userNames.find(e => e.email === email);
        if (user) {
            return user.fullName;
        }
    }
    render() {
        const { t, building, buildingAuditlog, role, lng } = this.props;
        const { isReady } = this.state;
        if (isReady) {
            const { auditing } = building;
            if (auditing) {
                auditing.createdByName = this.getUserFullName(auditing.createdBy);
                auditing.modifiedByName = this.getUserFullName(auditing.modifiedBy);
            }
        }
        return (React.createElement(LoaderContainer, null, isReady && (React.createElement(Fragment, null,
            React.createElement(TitleSection, { title: building.name, isNarrow: false }),
            React.createElement("section", { className: "section details" },
                React.createElement("div", { className: "container" },
                    React.createElement(FormSection, null,
                        React.createElement("div", { className: "header-panel" },
                            React.createElement("div", { className: "buttons" },
                                React.createElement(BackToList, { to: this.buildBuildingsListUrl(), t: t }),
                                this.renderButtons())),
                        React.createElement("div", { className: "columns" },
                            React.createElement("div", { className: "column is-4" },
                                React.createElement(LabelBlock, { label: t('FULL_NAME') }, building.googleName)),
                            React.createElement("div", { className: "column is-4" },
                                React.createElement(LabelBlock, { label: t('NUMBER_OF_ROOMS') },
                                    `${building.numberOfRooms}`,
                                    React.createElement("small", { className: "is-size-6" }, building.numberOfRooms > 0 && (React.createElement(Link, { to: `${routes.rooms}?siteCodes[]="${building.siteCode}"&siteName[]="${building.siteName}"&buildingIds[]="${building.id}"&buildingName[]="${building.name}"` },
                                        "(",
                                        t('SEE_ALL_ROOMS'),
                                        ")")))))),
                        React.createElement("div", { className: "columns" },
                            React.createElement("div", { className: "column is-4" },
                                React.createElement(LabelBlock, { label: t('SITE') }, building.siteName)),
                            React.createElement("div", { className: "column is-4" },
                                React.createElement(LabelBlock, { label: t('CAMPUS_INFORMATION') },
                                    React.createElement(Trans, { defaults: building.externalLocation
                                            ? 'BUILDING_IS_OUTSIDE'
                                            : 'BUILDING_IS_INSIDE', components: [React.createElement("strong", null, "univers")] })))),
                        React.createElement("div", { className: "columns" },
                            React.createElement("div", { className: "column is-4" },
                                React.createElement(LabelBlock, { label: t('LATITUDE') }, building.latitude)),
                            React.createElement("div", { className: "column is-4" },
                                React.createElement(LabelBlock, { label: t('LONGITUDE') }, building.longitude))),
                        React.createElement("div", { className: "columns" },
                            React.createElement("div", { className: "column is-4" },
                                React.createElement(LabelBlock, { label: t('FLOOR_LIST') }, building.floors.map(floor => floor.name).join(', ')))),
                        React.createElement("div", { className: "columns" },
                            React.createElement("div", { className: "column is-4" },
                                React.createElement(LabelBlock, { label: t('STATUS') }, t(building.status))))),
                    React.createElement(Auditing, { t: t, auditing: building.auditing, auditlog: buildingAuditlog, fetchAuditlog: cursor => this.fetchAuditlog(building.id, cursor), auditlogFieldMap: {
                            name: t('SHORT_NAME'),
                            googleName: t('FULL_NAME'),
                            latitude: t('LATITUDE'),
                            longitude: t('LONGITUDE'),
                            externalLocation: t('CAMPUS_INFORMATION'),
                            site: t('SITE'),
                            floors: t('FLOORS'),
                            status: t('STATUS'),
                        }, role: role, lng: lng }))),
            this.renderModals()))));
    }
}
const mapStateToProps = state => {
    const { buildings: { building, buildingsError, listParams, buildingAuditlog }, user: { role, sites }, } = state;
    return {
        building,
        buildingAuditlog,
        role,
        sites,
        buildingsError,
        listParams,
    };
};
const mapDispatchToProps = dispatch => ({
    loadBuildingAuditlog: conf => dispatch(buildingsActions.loadBuildingAuditlog(conf)),
    loadBuilding: conf => dispatch(buildingsActions.loadBuilding(conf)),
    deleteBuilding: (id, conf) => dispatch(buildingsActions.deleteBuilding(id, conf)),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(withErrorHandling(BuildingDetails))));
