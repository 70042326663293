import Yup from '../yup';
import { clickableLink } from './shared/clickableLink';
const name = Yup.string()
    .label('SHORT_NAME')
    .max(45, 'ROOM_NAME_MAX')
    .required('FIELD_SHORT_NAME_REQUIRED');
const siteCode = Yup.string()
    .label('SITE')
    .required('FIELD_SITE_REQUIRED');
const buildingId = Yup.string()
    .label('BUILDING')
    .when('siteCode', {
    is: siteCode => siteCode,
    then: Yup.string().required('FIELD_BUILDING_REQUIRED'),
});
const floorId = Yup.string()
    .label('FLOOR')
    .when(['siteCode', 'buildingId'], {
    is: (siteCode, buildingId) => buildingId && siteCode,
    then: Yup.string().required('FIELD_FLOOR_REQUIRED'),
});
const floorSection = Yup.string()
    .nullable()
    .label('FLOOR_SECTION')
    .max(15, 'ROOM_FLOOR_SECTION_MAX')
    .notRequired();
const restrictedAccess = Yup.boolean();
const capacity = Yup.number()
    .label('CAPACITY')
    .min(1, 'FIELD_CAPACITY_NUMBER_MIN')
    .max(10000, 'ROOM_CAPACITY_MAX')
    .required('FIELD_CAPACITY_REQUIRED')
    .integer('FIELD_CAPACITY_NUMBER');
const typeId = Yup.string()
    .label('ROOM_TYPE')
    .required('FIELD_ROOM_TYPE_REQUIRED');
const localDescription = Yup.string()
    .nullable()
    .label('DESCRIPTION')
    .when('translations', {
    is: val => val && val.length > 0,
    then: Yup.string().required('FIELD_ROOM_DESCRIPTION_REQUIRED_IF_TRANSLATION_ADDED'),
    otherwise: Yup.string().notRequired(),
});
const translation = Yup.object().shape({
    description: Yup.string().required('ROOM_TRANSLATION_DESCRIPTION_REQUIRED'),
    locale: Yup.string().required('ROOM_TRANSLATION_LANGUAGE_REQUIRED'),
});
const translations = Yup.array()
    .label('TRANSLATIONS')
    .of(translation)
    .unique('locale', 'TRANSLATION_LANGUAGE_UNIQUE');
const clickableLinks = Yup.array()
    .label('CLICKABLE_LINKS')
    .max(3, 'LIMIT_OF_CLICKABLE_LINKS_EXCEEDED')
    .of(clickableLink)
    .unique('url', 'CLICKABLE_LINK_URL_UNIQUE');
const contactInformation = Yup.string()
    .label('SUPPORT_CONTACT')
    .trim()
    .max(255, 'CONTACT_INFORMATION_MAX')
    .notRequired();
const googleOwner = Yup.string()
    .label('GOOGLE_OWNER')
    .required('FIELD_GOOGLE_OWNER_REQUIRED');
const owner = Yup.string()
    .label('ROOM_OWNER')
    .required('FIELD_OWNER_REQUIRED');
const privacy = Yup.boolean();
const featureIds = Yup.array()
    .label('FEATURES')
    .notRequired();
const RoomsSchema = Yup.object().shape({
    name,
    clickableLinks,
    localDescription,
    translations,
    typeId,
    siteCode,
    restrictedAccess,
    buildingId,
    floorId,
    floorSection,
    capacity,
    contactInformation,
    googleOwner,
    owner,
    privacy,
    featureIds,
});
export { RoomsSchema };
