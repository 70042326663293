import React from 'react';
import PropTypes from 'prop-types';
import { error_img } from '../../images';
const ErrorPage = ({ title, children }) => (React.createElement("div", { className: "error-page" },
    React.createElement("div", { className: "error-page__content error-page__content--left" },
        React.createElement("img", { className: "error-page__icon", alt: "error", src: error_img })),
    React.createElement("div", { className: "error-page__content error-page__content--right" },
        React.createElement("h1", { className: "title" }, title),
        children)));
ErrorPage.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
};
export default ErrorPage;
