export function enumToArray(enumObj, { keyName = 'value', valueName = 'label', specificProp }) {
    return Object.keys(enumObj).map(k => ({
        [keyName]: k,
        [valueName]: !specificProp ? enumObj[k] : enumObj[k][specificProp],
    }));
}
export function paginateArray(array, pageSize) {
    return array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / pageSize);
        if (!resultArray[chunkIndex])
            resultArray[chunkIndex] = [];
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);
}
export function countOccurrences(item, array) {
    return array.reduce((sum, val) => sum + (val === item), 0);
}
