import React, { Component } from 'react';
import Button from '../Button/Button';
import classnames from 'classnames/bind';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
class Accordion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen,
        };
    }
    componentDidUpdate(prevProps) {
        const { isOpen } = this.props;
        if (prevProps.isOpen !== isOpen) {
            this.setState({ isOpen });
        }
    }
    render() {
        const { t, children, textShow, textHide, className, fasIcon, buttonClassName, iconChevronInverseAndRight, } = this.props;
        const { isOpen } = this.state;
        const tShow = textShow ? textShow : t('SHOW');
        const tHide = textHide ? textHide : t('HIDE');
        return (React.createElement("div", { className: classnames('accordion', className) },
            React.createElement(Button, { className: classnames('is-text has-text-primary link-button', buttonClassName), text: isOpen ? tHide : tShow, icon: iconChevronInverseAndRight ? (React.createElement(FAIcon, { icon: ['fas', isOpen ? 'chevron-down' : 'chevron-right'], size: "xs", color: "grey" })) : (React.createElement(FAIcon, { icon: ['fas', isOpen ? 'chevron-up' : 'chevron-down'] })), icon2: React.createElement(FAIcon, { icon: ['fas', fasIcon], size: "2x" }), onClick: () => this.setState(prev => ({
                    isOpen: !prev.isOpen,
                })) }),
            isOpen && React.createElement("div", { className: "content" }, children)));
    }
}
Accordion.propTypes = {
    textShow: PropTypes.string,
    textHide: PropTypes.string,
    isOpen: PropTypes.bool,
};
export default withNamespaces('translations')(Accordion);
