import { ConfirmModal } from '../../../../../index';
import i18n from '../../../../../../i18n';
import TagsWrappedList from '../../../../TagsWrappedList/TagsWrappedList';
import { toFullDateTime } from '../../../../../../utils/dateFormatter.utils';
import React from 'react';
import { toGoogleCalendarCreateEventDate } from '../../../../../../utils';
import { useDispatch } from 'react-redux';
import { createEventLog } from '../../../../../../actions';
export const CreateEventCalendarModal = ({ isOpen, data, room, onClose }) => {
    if (!isOpen) {
        return null;
    }
    const dispatch = useDispatch();
    const { startDate, endDate } = data;
    const { buildingLatitude, buildingLongitude, calendarId, roomId } = room;
    function redirectToGoogleCalendar() {
        const gCalStartDate = toGoogleCalendarCreateEventDate(startDate);
        const gCalEndDate = toGoogleCalendarCreateEventDate(endDate);
        let url = `https://calendar.google.com/calendar/u/0/r/eventedit?add=${calendarId}&dates=${gCalStartDate}/${gCalEndDate}`;
        if (buildingLongitude && buildingLatitude) {
            const location = `&location=${buildingLatitude},${buildingLongitude}`;
            url = url + location;
        }
        window.open(url);
        dispatch(createEventLog(roomId));
    }
    return (React.createElement(ConfirmModal, { title: i18n.t('CREATE_CALENDAR_EVENT_MODAL'), text: React.createElement("div", null,
            i18n.t('CREATE_CALENDAR_EVENT_MODAL_TEXT'),
            React.createElement("div", { className: "create-calendar-event-modal" },
                React.createElement(TagsWrappedList, { names: [toFullDateTime(startDate)] }),
                React.createElement("p", null, " - "),
                React.createElement(TagsWrappedList, { names: [toFullDateTime(endDate)] }))), onClose: close => onClose(close), isOpen: isOpen, primaryButton: {
            label: i18n.t('CREATE'),
            type: 'is-primary',
            action: onClose => {
                redirectToGoogleCalendar();
                onClose({ closeParent: true });
            },
        }, secondaryButton: {
            label: i18n.t('CANCEL'),
            type: 'is-light',
            action: onClose => onClose(),
        } }));
};
