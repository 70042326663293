var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Select from 'react-select';
import classnames from 'classnames/bind';
import i18n from '../../../../i18n';
const getValueProperty = (option, getOptionValue) => {
    return getOptionValue ? getOptionValue(option) : option;
};
const getSelectValue = (isMulti, options, currentValue, getOptionValue) => {
    if (isMulti) {
        const values = currentValue.map(v => getValueProperty(v, getOptionValue));
        return options.filter(option => values.includes(getValueProperty(option, getOptionValue)));
    }
    return (options.find(option => getValueProperty(option, getOptionValue) === currentValue) || '');
};
const StandaloneReactSelect = (_a) => {
    var { options, onChange, value, isMulti, getOptionValue, className } = _a, props = __rest(_a, ["options", "onChange", "value", "isMulti", "getOptionValue", "className"]);
    return (React.createElement(Select, Object.assign({ classNamePrefix: "rs", options: options, getOptionValue: getOptionValue, isMulti: isMulti, placeholder: i18n.t('SELECT'), loadingMessage: () => i18n.t('LOADING'), noOptionsMessage: () => i18n.t('NO_OPTIONS'), value: getSelectValue(isMulti, options, value, getOptionValue), onChange: option => {
            if (onChange)
                onChange(option, value);
        }, className: classnames('react-select', className) }, props)));
};
export default StandaloneReactSelect;
