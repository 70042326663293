export const roomsConstants = {
    FETCH_ROOMS: 'FETCH_ROOMS',
    RESET_ROOMS: 'RESET_ROOMS',
    ADD_ROOM: 'ADD_ROOM',
    DELETE_ROOM: 'DELETE_ROOM',
    RESTORE_ROOM: 'RESTORE_ROOM',
    UPDATE_ROOM: 'UPDATE_ROOM',
    UPDATE_ROOM_STATE: 'UPDATE_ROOM_STATE',
    UPDATE_SELECTED_ROOMS: 'UPDATE_SELECTED_ROOMS',
    LOADING_ROOMS: 'LOADING_ROOMS',
    FETCH_SINGLE_ROOM: 'FETCH_SINGLE_ROOM',
    FETCH_SINGLE_ROOM_AUDITLOG: 'FETCH_SINGLE_ROOM_AUDITLOG',
    ERROR_ROOMS: 'ERROR_ROOMS',
    BULK_ROOMS_ATTRIBUTES_UPDATE: 'BULK_ROOM_ATTRIBUTES_UPDATE',
    BULK_ROOMS_OWNERSHIP_UPDATE: 'BULK_ROOMS_OWNERSHIP_UPDATE',
    BULK_ROOMS_FEATURES_ADD: 'BULK_ROOMS_FEATURES_ADD',
    BULK_ROOMS_FEATURES_REMOVE: 'BULK_ROOMS_FEATURES_REMOVE',
    BULK_ROOMS_FEATURES_UNDO: 'BULK_ROOMS_FEATURES_UNDO',
    CLEAR_PUSH_TO_BULK_FLAG: 'CLEAR_PUSH_TO_BULK_FLAG',
    BULK_ROOMS_CLICKABLE_LINK_ADD: 'BULK_ROOMS_CLICKABLE_LINK_ADD',
    BULK_ROOMS_CLICKABLE_LINK_REMOVE: 'BULK_ROOMS_CLICKABLE_LINK_REMOVE',
    BULK_ROOMS_CLICKABLE_LINK_REMOVE_ALL: 'BULK_ROOMS_CLICKABLE_LINK_REMOVE_ALL',
    BULK_ROOMS_CLICKABLE_LINK_UNDO: 'BULK_ROOMS_CLICKABLE_LINK_UNDO',
};
