import React from 'react';
import classnames from 'classnames/bind';
const TileBox = ({ size, isWhite, hasBorder, children, className }) => (React.createElement("div", { className: classnames('tile', 'is-parent', `is-${size}`) },
    React.createElement("div", { className: classnames('tile is-child', {
            'has-background-white': isWhite,
            'has-border': hasBorder,
        }, className) }, children)));
TileBox.defaultProps = {
    size: 3,
};
export default TileBox;
