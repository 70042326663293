import axios from 'axios';
import { endpoints } from './constants';
import { pathUtils } from './utils';
const Rooms = {
    list: params => axios.get(`${endpoints.room}/roomList`, {
        params: pathUtils.preparePageableParams(params),
    }),
    userRoomsList: params => axios.get(`${endpoints.room}/userRoomList`, {
        params: pathUtils.preparePageableParams(params),
    }),
    get: id => axios.get(`${endpoints.room}/${id}/view`),
    auditlog: (id, cursor) => axios.get(`${endpoints.room}/${id}/auditlog`, {
        params: { cursor, sort: 'updatedDate,desc', size: 2 },
    }),
    create: data => axios.post(endpoints.room, data),
    delete: id => axios.delete(`${endpoints.room}/${id}`),
    deleteBulk: ids => axios.delete(`${endpoints.room}/bulkDelete?roomIds=${ids}`),
    updateBulkStatus: data => axios.patch(`${endpoints.room}/bulkStatus`, data),
    update: (id, data) => axios.put(`${endpoints.room}/${id}`, data),
    saveImages: (roomId, data, onUploadProgress, imageTypeParams) => axios.post(`${endpoints.images}/${roomId}?${imageTypeParams}`, data, {
        onUploadProgress,
    }),
    exportFiltered: params => axios.get(`${endpoints.room}/export`, {
        params: pathUtils.preparePageableParams(params),
    }),
    export: () => axios.get(`${endpoints.room}/export`),
    getRoomsCount: params => axios.get(`${endpoints.room}/count`, {
        params: pathUtils.preparePageableParams(params),
    }),
    exportGlobal: (id, cancelTokenSource, pageableParams) => axios.get(`${endpoints.room}/export/global/${id}`, {
        cancelToken: cancelTokenSource.token,
        params: pathUtils.preparePageableParams(pageableParams),
    }),
    exportAffectedOrganizers: (startDate, endDate, ids) => axios.get(`${endpoints.maintenance}/exportAffectedOrganizers?roomIds=${ids}`, {
        params: {
            startDate,
            endDate,
        },
    }),
    updateState: (id, data) => axios.patch(`${endpoints.room}/${id}/updateState`, data),
    createMaintenance: (id, data) => axios.post(`${endpoints.maintenance}/${id}`, data),
    setMaintenanceBulk: (ids, data) => axios.post(`${endpoints.maintenance}/bulk?roomIds=${ids}`, data),
    cancelMaintenanceBulk: ids => axios.delete(`${endpoints.maintenance}/bulk?roomIds=${ids}`),
    cancelMaintenance: (id, data) => axios.delete(`${endpoints.maintenance}/${id}`, { data }),
    patchMaintenance: (roomId, data) => axios.patch(`${endpoints.maintenance}/${roomId}`, data),
    bulkUpdateRoomsAttributes: data => axios.patch(`${endpoints.room}/bulkAttributes`, data),
    bulkOwnership: data => axios.patch(`${endpoints.room}/bulkOwnership`, data),
    bulkRoomFeaturesAdd: (featureIds, roomIds) => axios.patch(`${endpoints.room}/bulkAddFeatures?roomIds=${roomIds}`, featureIds),
    bulkRoomFeaturesRemove: (featureIds, roomIds) => axios.patch(`${endpoints.room}/bulkRemoveFeatures?roomIds=${roomIds}`, featureIds),
    bulkRoomFeaturesUndo: rooms => axios.patch(`${endpoints.room}/bulkFeaturesUndo`, rooms),
    bulkClickableLinkAdd: (roomIds, linkName, linkUrl) => axios.patch(`${endpoints.room}/clickableLink?roomIds=${roomIds}`, {
        name: linkName,
        url: linkUrl,
    }),
    bulkRoomClickableLinkRemove: (roomIds, linkName, linkUrl) => axios.delete(`${endpoints.room}/clickableLink?roomIds=${roomIds}`, {
        data: {
            name: linkName,
            url: linkUrl,
        },
    }),
    bulkRoomClickableLinkRemoveAll: roomIds => axios.delete(`${endpoints.room}/allClickableLink?roomIds=${roomIds}`),
    bulkRoomClickableLinkUndo: data => axios.patch(`${endpoints.room}/clickableLinkUndo`, data),
};
const Buildings = {
    list: params => axios.get(`${endpoints.building}/buildingList`, {
        params: pathUtils.preparePageableParams(params),
    }),
    get: id => axios.get(`${endpoints.building}/${id}/view`),
    auditlog: (id, cursor) => axios.get(`${endpoints.building}/${id}/auditlog`, {
        params: { cursor, sort: 'updatedDate,desc', size: 2 },
    }),
    create: data => axios.post(endpoints.building, data),
    delete: id => axios.delete(`${endpoints.building}/${id}`),
    update: (id, data) => axios.put(`${endpoints.building}/${id}`, data),
    getAllInSite: siteCode => axios.get(`${endpoints.building}/search/site/${siteCode}`),
    export: () => axios.get(`${endpoints.building}/export`),
};
const RoomTypes = {
    list: params => axios.get(`${endpoints.roomType}/roomTypeList`, {
        params: pathUtils.preparePageableParams(params),
    }),
    get: id => axios.get(`${endpoints.roomType}/${id}/view`),
    create: data => axios.post(endpoints.roomType, data),
    delete: id => axios.delete(`${endpoints.roomType}/${id}`),
    update: (id, data) => axios.put(`${endpoints.roomType}/${id}`, data),
    search: searchQuery => axios.get(`${endpoints.roomType}/search`, {
        params: { searchQuery },
    }),
    export: () => axios.get(`${endpoints.roomType}/export`),
};
const Features = {
    list: params => axios.get(`${endpoints.feature}/featureList`, {
        params: pathUtils.preparePageableParams(params),
    }),
    listAll: () => axios.get(`${endpoints.feature}/featureList`),
    get: id => axios.get(`${endpoints.feature}/${id}/view`),
    getAll: id => axios.get(`${endpoints.feature}/${id}/view`),
    create: data => axios.post(endpoints.feature, data),
    delete: id => axios.delete(`${endpoints.feature}/${id}`),
    update: (id, data) => axios.put(`${endpoints.feature}/${id}`, data),
    search: searchQuery => axios.get(`${endpoints.feature}/search`, {
        params: { searchQuery },
    }),
    searchAll: () => axios.get(`${endpoints.feature}/search?searchQuery=`),
    getCategories: () => axios.get(`${endpoints.featureCategories}`),
    export: () => axios.get(`${endpoints.feature}/export`),
};
const Sites = {
    list: params => axios.get(endpoints.site, {
        params: pathUtils.preparePageableParams(params),
    }),
    get: code => axios.get(`${endpoints.site}/${code}`),
    create: data => axios.post(endpoints.site, data),
    delete: code => axios.delete(`${endpoints.site}/${code}`),
    update: (code, data) => axios.put(`${endpoints.site}/${code}`, data),
    search: (searchQuery, userSitesOnly) => axios.get(`${endpoints.site}/search`, {
        params: { searchQuery, userSitesOnly },
    }),
    export: () => axios.get(`${endpoints.site}/export`),
};
const Admins = {
    list: params => axios.get(endpoints.user, {
        params: pathUtils.preparePageableParams(params),
    }),
    get: email => axios.get(`${endpoints.user}/${email}`),
    create: data => axios.post(endpoints.user, data),
    delete: email => axios.delete(`${endpoints.user}/${email}`),
    update: (email, data) => axios.put(`${endpoints.user}/${email}`, data),
    search: searchQuery => axios.get(`${endpoints.user}/search`, {
        params: { searchQuery },
    }),
    startsWithSearch: email => axios.get(`${endpoints.user}/contains/${email}`),
};
const CurrentUser = {
    getRole: () => axios.get(`${endpoints.currentUser}/role`),
};
const Token = {
    newTokenRequest: code => {
        axios.defaults.headers.common['Content-Type'] =
            'application/json; charset=utf-8';
        axios.defaults.baseURL = endpoints.url;
        axios.defaults.withCredentials = true;
        return axios.post(`${endpoints.userAuth}/newToken`, { token: code }, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        });
    },
};
const UserSearch = {
    getByEmails: emails => axios.get(`${endpoints.userSearch}/byEmails`, {
        params: pathUtils.prepareEmailsParams(emails),
    }),
    search: (q, size) => axios.get(endpoints.userSearch, {
        params: { q, size },
    }),
    searchIncludeGroups: (q, size) => axios.get(`${endpoints.userSearch}/includeGroups`, {
        params: { q, size },
    }),
};
const RoomMaintenance = {
    list: params => axios.get(`${endpoints.maintenance}/list`, {
        params: pathUtils.preparePageableParams(params),
    }),
    cancelMaintenanceBulk: ids => axios.delete(`${endpoints.maintenance}/bulk?maintenanceIds=${ids}`),
    editMaintenanceBulk: (ids, data) => axios.patch(`${endpoints.maintenance}/bulk?maintenanceIds=${ids}`, data),
};
const Events = {
    list: (roomId, data) => axios.get(`${endpoints.events}/${roomId}`, {
        params: data,
    }),
    createEventLog: roomId => axios.post(`${endpoints.events}/${roomId}/createEventLog`),
};
export default {
    Rooms,
    RoomMaintenance,
    RoomTypes,
    Features,
    Buildings,
    Sites,
    UserSearch,
    Admins,
    CurrentUser,
    Events,
    Token,
};
