import React, { Fragment } from 'react';
import { Button } from '../../';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { statusConstants } from '../../../constants';
import i18n from '../../../i18n';
import { getRoomButtons, getRoomPendingDeletionButtons, } from './RoomDetailsViewButtons';
const DetailsViewButtons = ({ editProps, activateProps, restoreRoomProps, deleteProps, deleteSettings, maintenanceProps, maintenanceCancelProps, duplicateProps, roomDetailsViewData: room, }) => {
    if (deleteSettings)
        var { icon, tooltip, disabled } = deleteSettings;
    const buttons = getButtons(editProps, icon, tooltip, disabled, deleteProps, restoreRoomProps, activateProps, maintenanceProps, maintenanceCancelProps, duplicateProps, room);
    return (React.createElement(Fragment, null,
        React.createElement("span", { className: "action-buttons" }, buttons)));
};
function getButtons(editProps, icon, tooltip, disabled, deleteProps, restoreRoomProps, activateProps, maintenanceProps, maintenanceCancelProps, duplicateProps, room) {
    let sharedButtons = getSharedButtons(editProps, icon, tooltip, disabled, deleteProps);
    if (!room) {
        return sharedButtons;
    }
    if (room.status === statusConstants.PENDING_DELETION) {
        return getRoomPendingDeletionButtons(restoreRoomProps, duplicateProps);
    }
    else {
        let roomButtons = getRoomButtons(room, restoreRoomProps, activateProps, maintenanceProps, maintenanceCancelProps, duplicateProps);
        return [...sharedButtons, ...roomButtons];
    }
}
function getSharedButtons(editProps, icon, tooltip, disabled, deleteProps) {
    let editButton = getEditButton(editProps);
    let deleteButton = getDeleteButton(icon, tooltip, disabled, deleteProps);
    return [editButton, deleteButton];
}
function getEditButton(editProps) {
    return (React.createElement(Button, Object.assign({ icon: React.createElement(FAIcon, { icon: ['far', 'edit'] }), className: "is-primary", text: i18n.t('EDIT') }, editProps)));
}
function getDeleteButton(icon, tooltip, disabled, deleteProps) {
    return (React.createElement(Button, Object.assign({ className: "is-danger", text: i18n.t('DELETE'), icon: React.createElement(FAIcon, { icon: icon ? icon : ['far', 'trash-alt'] }), tooltip: tooltip ? i18n.t(tooltip) : null, disabled: disabled }, deleteProps)));
}
DetailsViewButtons.propTypes = {
    editProps: PropTypes.object,
    deleteProps: PropTypes.object,
    deleteSettings: PropTypes.object,
};
DetailsViewButtons.defaultProps = {
    roomDetailsViewData: false,
};
export default DetailsViewButtons;
