import moment from 'moment';
export const toFullDateTime = date => {
    return date ? moment(date).format('DD-MMM-YYYY, hh:mm a') : '';
};
export const toDateTimeWithoutYear = date => {
    return date
        ? moment(date)
            .format('DD-MMM, hh:mm a')
            .replace(':00', '')
        : '';
};
export const toHoursAndMins = date => {
    return date
        ? moment(date)
            .format('h:mm a')
            .replace(':00', '')
        : '';
};
