export const errorConstants = {
    ERROR: 'ERROR',
    CONFLICT: 'CONFLICT',
    SHOW_ERROR: 'SHOW_ERROR',
    USER_NOT_FOUND: 'USER_NOT_FOUND',
    FLOOR_NOT_FOUND: 'FLOOR_NOT_FOUND',
    ROOM_TYPE_NOT_FOUND: 'ROOM_TYPE_NOT_FOUND',
    CATEGORY_NOT_FOUND: 'CATEGORY_NOT_FOUND',
    BUILDING_NOT_FOUND: 'BUILDING_NOT_FOUND',
    SITE_NOT_FOUND: 'SITE_NOT_FOUND',
    SITES_NOT_FOUND: 'SITES_NOT_FOUND',
    FLOOR_HAS_ROOMS_RENAME: 'FLOOR_HAS_ROOMS_RENAME',
    FEATURE_DUPLICATE: 'FEATURE_DUPLICATE',
    BUILDING_DUPLICATE: 'BUILDING_DUPLICATE',
    ROOM_TYPE_DUPLICATE: 'ROOM_TYPE_DUPLICATE',
    ROOM_DUPLICATE: 'ROOM_DUPLICATE',
    FEATURES_NOT_FOUND: 'FEATURES_NOT_FOUND',
    UNSUPPORTED_FORMAT_TO_RESIZE: 'UNSUPPORTED_FORMAT_TO_RESIZE',
    ROOM_TYPE_NOT_FOUND_DELETE: 'ROOM_TYPE_NOT_FOUND_DELETE',
    FEATURE_NOT_FOUND_DELETE: 'FEATURE_NOT_FOUND_DELETE',
    BUILDING_NOT_FOUND_DELETE: 'BUILDING_NOT_FOUND_DELETE',
    ROOM_NOT_FOUND: 'ROOM_NOT_FOUND',
    ROOM_NOT_FOUND_DELETE: 'ROOM_NOT_FOUND_DELETE',
    USER_NOT_FOUND_DELETE: 'USER_NOT_FOUND_DELETE',
    SITE_NOT_FOUND_DELETE: 'SITE_NOT_FOUND_DELETE',
    ROOM_NOT_FOUND_UPDATE: 'ROOM_NOT_FOUND_UPDATE',
    BUILDING_NOT_FOUND_UPDATE: 'BUILDING_NOT_FOUND_UPDATE',
    ROOM_TYPE_NOT_FOUND_UPDATE: 'ROOM_TYPE_NOT_FOUND_UPDATE',
    SITE_NOT_FOUND_UPDATE: 'SITE_NOT_FOUND_UPDATE',
    FEATURE_NOT_FOUND_UPDATE: 'FEATURE_NOT_FOUND_UPDATE',
    USER_NOT_FOUND_UPDATE: 'USER_NOT_FOUND_UPDATE',
    ERROR_ROOM_IMAGES: 'ERROR_ROOM_IMAGES',
    USER_DUPLICATE: 'USER_DUPLICATE',
    ACCESS_DENIED: 'ACCESS_DENIED',
    ROOM_MAINTENANCE_NOT_FOUND: 'ROOM_MAINTENANCE_NOT_FOUND',
};
