var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from '../api';
const fetchUserNamesByEmails = (userEmails) => __awaiter(void 0, void 0, void 0, function* () {
    let emails = new Set(userEmails.filter(Boolean));
    if (!emails.length) {
        return [];
    }
    const res = yield api.UserSearch.getByEmails(emails);
    return res.data;
});
const fetchUsers = (size, q = '') => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield api.UserSearch.search(q, size);
    return res.data;
});
const fetchAdmins = (email = '') => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield api.Admins.startsWithSearch(email);
    return res.data;
});
const fetchUsersOrGroups = (size, q = '') => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield api.UserSearch.searchIncludeGroups(q, size);
    return res.data;
});
export const userSearchActions = {
    fetchUserNamesByEmails,
    fetchUsers,
    fetchUsersOrGroups,
    fetchAdmins,
};
