import i18n from '../i18n';
export function updateErrorMessagesOnLanguageChange(errors, setFieldError, setFieldTouched) {
    i18n.off('languageChanged');
    i18n.on('languageChanged', () => {
        Object.keys(errors).forEach(fieldKey => {
            if (Array.isArray(errors[fieldKey])) {
                errors[fieldKey].forEach((arrItem, i) => {
                    Object.keys(arrItem).forEach(arrItemKey => {
                        setFieldError(`${fieldKey}[${i}]${arrItemKey}`);
                        setFieldTouched(`${fieldKey}[${i}]${arrItemKey}`);
                    });
                });
            }
            else {
                setFieldError(fieldKey);
                setFieldTouched(fieldKey);
            }
        });
    });
}
