import { combineReducers } from 'redux';
import user from './user.reducer';
import buildings from './buildings.reducer';
import sites from './sites.reducer';
import features from './features.reducer';
import roomTypes from './roomTypes.reducer';
import rooms from './rooms.reducer';
import userRooms from './userRooms.reducer';
import loading from './loading.reducer';
import admins from './admins.reducer';
import roomsUpload from './roomsUpload.reducer';
import roomsMaintenances from './roomMaintenance.reducer';
import image from './image.reducer';
import roomAvailability from './roomAvailability.reducer';
export default combineReducers({
    user,
    buildings,
    sites,
    features,
    roomTypes,
    rooms,
    userRooms,
    loading,
    admins,
    roomsUpload,
    roomsMaintenances,
    image,
    roomAvailability,
});
