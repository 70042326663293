import React from 'react';
import { ConfirmModal } from './../../../../index';
import i18n from './../../../../../i18n';
import RoomAvailability from '../../../RoomAvailability/RoomAvailability';
const RoomAvailabilityModal = props => {
    const { onClose, room } = props;
    const title = room
        ? `${room.roomName} (${room.siteName} - ${room.buildingName} - ${room.floorName})`
        : null;
    return (React.createElement(ConfirmModal, { t: i18n.t, title: title, onClose: () => {
            onClose();
        }, isOpen: room, fullScreen: true, customModalClassName: "full-screen-modal", bodyClassName: "full-screen-body", primaryButton: null },
        React.createElement(RoomAvailability, { room: room, onCloseModal: onClose })));
};
export default RoomAvailabilityModal;
