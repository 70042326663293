var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { ErrorMsg, FormControl, Button } from '../..';
import { FieldArray } from 'formik';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { sortableContainer, sortableElement, sortableHandle, } from 'react-sortable-hoc';
const DragHandle = sortableHandle(({ t }) => (React.createElement(Button, { className: "icon is-right is-text has-text-primary floor-order", tooltip: t('CHANGE_FLOOR_ORDER'), icon: React.createElement(FAIcon, { icon: ['fas', 'grip-lines'], className: "has-text-primary" }) })));
const SortableItem = sortableElement(({ item, idx, itemRemovable, arrayHelpers, dataItem }) => (React.createElement("div", { className: "repeater-item", key: idx },
    itemRemovable && this.renderRemoveButton(arrayHelpers, idx),
    item(dataItem, idx, arrayHelpers, DragHandle))));
const SortableContainer = sortableContainer(({ label, children }) => {
    return (React.createElement(FormControl, { label: label, className: classnames({ 'm-top-2': label }) }, children));
});
class Repeater extends Component {
    renderStatic({ item, label, items, itemRemovable, arrayHelpers }) {
        return (React.createElement(FormControl, { label: label, className: classnames({ 'm-top-2': label }) }, items.map((dataItem, index) => (React.createElement("div", { className: "repeater-item", key: index },
            itemRemovable && this.renderRemoveButton(arrayHelpers, index),
            item(dataItem, index, arrayHelpers))))));
    }
    renderSortable({ item, label, items, itemRemovable, arrayHelpers }) {
        return (React.createElement(SortableContainer, { label: label, onSortEnd: params => this.props.onSortEnd(params, arrayHelpers), useDragHandle: true }, items.map((dataItem, index) => (React.createElement(SortableItem, { key: index, index: index, idx: index, item: item, itemRemovable: itemRemovable, arrayHelpers: arrayHelpers, dataItem: dataItem })))));
    }
    render() {
        const _a = this.props, { itemRemovable, field, form, item, label, isSortable, showAddButton = true } = _a, props = __rest(_a, ["itemRemovable", "field", "form", "item", "label", "isSortable", "showAddButton"]);
        let items = form.values[field.name];
        return (React.createElement(FieldArray, Object.assign({ name: field.name, render: arrayHelpers => (React.createElement("div", { className: "repeater" },
                items &&
                    items.length > 0 &&
                    (isSortable
                        ? this.renderSortable({
                            item,
                            label,
                            items,
                            itemRemovable,
                            arrayHelpers,
                        })
                        : this.renderStatic({
                            item,
                            label,
                            items,
                            itemRemovable,
                            arrayHelpers,
                        })),
                typeof form.errors[field.name] === 'string' && (React.createElement(ErrorMsg, { name: field.name })),
                showAddButton && (React.createElement("div", { className: "buttons" }, this.renderAddItemButton(arrayHelpers))))) }, props)));
    }
    renderAddItemButton(arrayHelpers) {
        const { t, labelAdd, itemDefaultValues } = this.props;
        return (React.createElement(Button, { className: "link-button is-text has-text-primary", onClick: () => arrayHelpers.push(Object.assign({}, itemDefaultValues)), icon: React.createElement(FAIcon, { icon: ['fas', 'plus'] }), text: labelAdd ? labelAdd : t('ADD') }));
    }
    renderRemoveButton(arrayHelpers, index) {
        const { t } = this.props;
        return (React.createElement("button", { onClick: () => arrayHelpers.remove(index), className: "button remove-button has-text-danger is-medium", type: "button", "aria-label": "Delete", title: t('DELETE') },
            React.createElement(FAIcon, { icon: ['far', 'trash-alt'] })));
    }
}
Repeater.propTypes = {
    itemDefaultValues: PropTypes.object.isRequired,
    item: PropTypes.func.isRequired,
    itemRemovable: PropTypes.bool,
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
};
export default withNamespaces('translations')(Repeater);
