var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { roomsActions } from '../../actions';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as moment from 'moment-timezone';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import { Button, ConfirmModal, LoaderContainer, RoomRowInfo, Search, TableFilter, TableHeaderCell, TableTooltip, TitleSection, } from '../';
import { errorConstants, routes, statusConstants, urlDefaultStateConstants, userRoleConstants, } from '../../constants';
import { formatRoute } from 'react-router-named-routes';
import { pathUtils } from '../../utils';
import { toastError } from '../../toasts';
import RoomExportAdminModal from './RoomExportAdminModal/RoomExportAdminModal';
import SelectTable from '../Common/Table/SelectTable';
import DeleteRoomModal from '../Common/Modal/Room/DeleteRoomModal/DeleteRoomModal';
import DeletedRoomInfoModal from '../Common/Modal/Room/DeletedRoomInfoModal/DeletedRoomInfoModal';
import RestoreRoomModal from '../Common/Modal/Room/RestoreRoomModal/RestoreRoomModal';
import RoomsBulkActions from './RoomsBulkActions/RoomsBulkActions';
import RestoredRoomInfoModal from '../Common/Modal/Room/RestoredRoomInfoModal/RestoredRoomInfoModal';
import { filterActiveAndSortByDate, hasMaintenance, } from '../../utils/roomMaintenance.utils';
import RoomsMaintenanceTableTooltip from '../Common/TableTooltip/RoomsMaintenanceTableTooltip/RoomsMaintenanceTableTooltip';
import exportRole from '../../enums/exportRole.enum';
import RoomExportSiteAdminModal from './RoomExportSiteAdminModal/RoomExportSiteAdminModal';
import tableFilterType from '../../enums/tableFilterType.enum';
import { GoogleLoginWrapper } from '../Common/GoogleLoginWrapper/GoogleLoginWrapper';
const dateFormat = 'D-MMM';
class Rooms extends Component {
    constructor(props) {
        super(props);
        this.getTrProps = (state, rowInfo) => {
            if (rowInfo) {
                return {
                    className: rowInfo.row.roomStatus === statusConstants.PENDING_DELETION
                        ? 'tr-grey'
                        : '',
                };
            }
            return {};
        };
        this.exportForRoleAdmin = () => {
            this.setState({ roomsExport: exportRole.ADMIN });
        };
        this.exportForRoleSiteAdmin = () => {
            this.setState({ roomsExport: exportRole.SITE_ADMIN });
        };
        this.fetchData = this.fetchData.bind(this);
        this.deleteHandler = this.deleteHandler.bind(this);
        this.restoreHandler = this.restoreHandler.bind(this);
        this.state = Object.assign(Object.assign({ itemToDelete: null, itemToEdit: null, itemToRestore: null, itemDeleted: null, itemRestored: null, itemToRestoreNotFound: false, exportRoomsAdmin: null, roomsExport: null, itemToDeleteNotFound: false, bulkActionType: null, bulkWarningType: null, errorModal: null, showMaintenanceModal: false, selectAll: false, selectedIds: [], selectedRooms: [], adminSites: props.sites.map(site => `${site.name} (${site.code})`) }, urlDefaultStateConstants), pathUtils.parseUrlParams(props.history.location.search));
    }
    fetchData(state) {
        return __awaiter(this, void 0, void 0, function* () {
            this.setState({ errorLoading: false });
            const { loadRooms, history, t } = this.props;
            const parsedParams = pathUtils.parseUrlParams(history.location.search);
            const updatedState = Object.assign(Object.assign({}, state), parsedParams);
            const { page, pageSize, sorted, search, filter, buildingIds, floorIds, capacityFrom, capacityTo, roomTypeIds, featureIds, siteCodes, status, googleOwner, googleOwnerVisible, roomOwner, contactInformationVisible, privacy, } = updatedState;
            let response = yield loadRooms({
                pageNumber: page,
                pageSize,
                sort: sorted,
                filter,
                search,
                buildingIds,
                floorIds,
                capacityFrom,
                capacityTo,
                roomTypeIds,
                featureIds,
                siteCodes,
                status,
                googleOwner,
                googleOwnerVisible,
                roomOwner,
                contactInformationVisible,
                privacy,
                listParams: history.location.search,
            });
            if (response instanceof Error) {
                toastError(t(response.response.data.reason));
                this.setState({ errorLoading: true });
            }
        });
    }
    fetchUrlParams() {
        const props = Object.assign(Object.assign({}, urlDefaultStateConstants), pathUtils.parseUrlParams(this.props.location.search));
        this.fetchData(props);
    }
    componentDidMount() {
        this.fetchUrlParams();
        const { location } = this.props;
        if (location.state && location.state.errorModal) {
            this.setState({ errorModal: location.state.errorModal });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.search !== prevProps.location.search) {
            this.fetchUrlParams();
            this.resetSelection();
        }
        if (this.areUserSitesFetched(prevProps)) {
            const adminSites = this.props.sites.map(site => `${site.name} (${site.code})`);
            this.setState({ adminSites });
        }
    }
    areUserSitesFetched(prevProps) {
        return (Array.isArray(this.props.sites) &&
            this.props.sites.length !== prevProps.sites.length);
    }
    updateParams(params) {
        const { history } = this.props;
        const url = pathUtils.prepareUrl(pathUtils.updateState(history.location.search, params));
        history.push(`?${url}`);
        this.resetSelection();
    }
    resetSelection() {
        const selectAll = false;
        const selectedIds = [];
        const selectedRooms = [];
        this.setState({ selectAll, selectedIds, selectedRooms });
    }
    renderButtons(row) {
        const { SITE_ADMIN, ADMIN } = userRoleConstants;
        const { t, role } = this.props;
        const { adminSites } = this.state;
        const { siteName, siteCode, maintenances, roomStatus } = row.original;
        let buttons;
        if (roomStatus === statusConstants.PENDING_DELETION) {
            buttons = (React.createElement(React.Fragment, null,
                React.createElement(Button, { onClick: () => this.setState({
                        itemToRestore: row.original,
                    }), className: "is-text has-text-primary", tooltip: t('RESTORE'), icon: React.createElement(FAIcon, { icon: ['fas', 'undo'] }) })));
        }
        else {
            buttons = (React.createElement(React.Fragment, null,
                React.createElement(RoomsMaintenanceTableTooltip, { roomStatus: roomStatus, maintenances: maintenances, itemId: `m-title-${row.original.roomId}` }),
                React.createElement(Button, { onClick: () => this.setState({
                        itemToEdit: row.original,
                    }), className: "is-text has-text-primary", tooltip: t('EDIT'), icon: React.createElement(FAIcon, { icon: ['far', 'edit'] }) }),
                React.createElement(Button, { className: "is-text has-text-primary", tooltip: t('DELETE'), icon: React.createElement(FAIcon, { icon: ['far', 'trash-alt'] }), onClick: () => this.setState({
                        itemToDelete: row.original,
                    }) })));
        }
        if (role === ADMIN ||
            (role === SITE_ADMIN && adminSites.includes(`${siteName} (${siteCode})`)))
            return React.createElement("div", { className: "buttons is-right" }, buttons);
        return (React.createElement(RoomsMaintenanceTableTooltip, { roomStatus: roomStatus, maintenances: maintenances, itemId: `m-title-${row.original.roomId}` }));
    }
    buildMaintenanceTooltip(t, maintenances) {
        const filteredMaintenances = filterActiveAndSortByDate(maintenances);
        let maintenancesString = ``;
        filteredMaintenances.forEach(m => {
            maintenancesString += ` ${moment(m.from).format(dateFormat)} - ${moment(m.to).format(dateFormat)}, `;
        });
        maintenancesString = maintenancesString.slice(0, -2);
        return `${t('MAINTENANCE')}: ${maintenancesString}`;
    }
    renderStatus(row) {
        const { t } = this.props;
        const color = this.getStatusColor(row.original.roomStatus);
        return (React.createElement("span", { className: "icon-with-text" },
            React.createElement("span", { className: "icon" },
                React.createElement(FAIcon, { icon: ['fas', 'circle'], color: color, style: { width: '0.5em' } })),
            React.createElement("span", { className: "text color-black" }, t(row.value))));
    }
    getStatusColor(status) {
        switch (status) {
            case statusConstants.ACTIVE:
                return 'limegreen';
            case statusConstants.NOT_YET_ACTIVE:
                return 'yellow';
            case statusConstants.MAINTENANCE:
                return 'red';
            case statusConstants.PENDING_DELETION:
                return 'grey';
            default:
                return 'white';
        }
    }
    getColumns() {
        const { t } = this.props;
        return [
            {
                Header: React.createElement(TableHeaderCell, { text: t('NAME') }),
                accessor: 'roomName',
                filterable: false,
                Cell: cellInfo => (React.createElement(Link, { to: formatRoute(routes.roomDetails, {
                        id: cellInfo.original.roomId,
                    }) }, cellInfo.original.roomName)),
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('CAPACITY') }),
                accessor: 'roomCapacity',
                filterable: false,
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('SITE') }),
                title: () => t('SITE'),
                accessor: 'siteName',
                Cell: cellInfo => `${cellInfo.original.siteName} (${cellInfo.original.siteCode})`,
                filterable: false,
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('BUILDING') }),
                accessor: 'buildingName',
                filterable: false,
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('FLOOR') }),
                accessor: 'floorName',
                filterable: false,
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('FEATURES_AND_SERVICES') }),
                id: 'roomFeatures',
                filterable: false,
                sortable: false,
                minWidth: 150,
                Cell: cellInfo => {
                    const r = cellInfo.original;
                    return this.shouldRenderTableTooltip(r) ? (React.createElement(TableTooltip, { minWidth: 350, name: `table-tooltip-${r.roomId}`, text: r.roomFeatures
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(feature => feature.name)
                            .join(', '), icon: React.createElement(FAIcon, { icon: ['far', 'eye'] }) },
                        React.createElement(RoomRowInfo, { room: r, isStatic: true }))) : null;
                },
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('STATUS') }),
                minWidth: 120,
                accessor: 'roomStatus',
                filterable: false,
                Cell: cellInfo => this.renderStatus(cellInfo),
            },
            {
                accessor: 'roomId',
                width: 150,
                sortable: false,
                filterable: false,
                className: 'td-actions',
                Cell: cellInfo => this.renderButtons(cellInfo),
            },
        ];
    }
    shouldRenderTableTooltip(room) {
        return (room.roomFeatures.length ||
            room.roomImages.length ||
            (room.buildingLatitude !== null && room.buildingLongitude !== null));
    }
    updateSearch(search) {
        this.updateParams({ search });
        this.setState({ search, errorLoading: false });
    }
    deleteHandler() {
        return __awaiter(this, void 0, void 0, function* () {
            const { t, deleteRoom, page, pageSize, sort } = this.props;
            const { itemToDelete } = this.state;
            const deletedRoom = yield deleteRoom(itemToDelete.roomId, {
                page,
                pageSize,
                sort,
            });
            if (!(deletedRoom instanceof Error)) {
                this.setState({ itemDeleted: Object.assign({}, itemToDelete) });
            }
            else {
                if (deletedRoom.response.data.reason ===
                    errorConstants.ROOM_NOT_FOUND_DELETE) {
                    this.fetchData(urlDefaultStateConstants);
                    this.setState({ itemToDeleteNotFound: true });
                }
                else {
                    toastError(t(deletedRoom.response.data.reason));
                }
            }
        });
    }
    restoreHandler() {
        return __awaiter(this, void 0, void 0, function* () {
            const { t, restoreRoom, page, pageSize, sort } = this.props;
            const { itemToRestore } = this.state;
            const restoredRoom = yield restoreRoom(itemToRestore.roomId, {
                page,
                pageSize,
                sort,
            });
            if (!(restoredRoom instanceof Error)) {
                this.setState({ itemRestored: Object.assign({}, itemToRestore) });
            }
            else {
                if (restoredRoom.response.data.reason === errorConstants.ROOM_NOT_FOUND ||
                    restoredRoom.response.data.reason ===
                        errorConstants.ROOM_NOT_FOUND_UPDATE) {
                    this.fetchData(urlDefaultStateConstants);
                    this.setState({ itemToRestoreNotFound: true });
                }
                else {
                    toastError(t(restoredRoom.response.data.reason));
                }
            }
        });
    }
    onSelectedIdChange(selectedIds) {
        const selectedRooms = this.props.rooms.filter(room => selectedIds.includes(room.id));
        this.setState({ selectedIds, selectedRooms });
    }
    renderModals() {
        const { t, history, role } = this.props;
        const { itemToDelete, itemToEdit, itemToRestore, itemDeleted, itemRestored, itemToRestoreNotFound, itemToDeleteNotFound, errorModal, roomsExport, } = this.state;
        return (React.createElement(Fragment, null,
            React.createElement(ConfirmModal, { title: t('EDIT'), text: t('MODAL_EDIT_ROOM_INFO', {
                    name: itemToEdit ? itemToEdit.roomName : '',
                }), onClose: () => this.setState({ itemToEdit: null }), isOpen: itemToEdit != null, primaryButton: {
                    label: t('EDIT'),
                    type: 'is-primary',
                    action: onClose => {
                        history.push(formatRoute(routes.roomEdit, {
                            id: itemToEdit.roomId,
                        }));
                        onClose();
                    },
                    icon: React.createElement(FAIcon, { icon: ['far', 'edit'] }),
                } }),
            React.createElement(DeletedRoomInfoModal, { name: itemToDelete ? itemToDelete.roomName : '', isOpen: itemDeleted != null, onClose: () => this.setState({ itemDeleted: null }), onConfirm: () => this.setState({ itemDeleted: null }) }),
            React.createElement(RestoredRoomInfoModal, { name: itemRestored ? itemRestored.roomName : '', isOpen: itemRestored != null, onClose: () => {
                    this.setState({ itemRestored: null });
                } }),
            React.createElement(DeleteRoomModal, { roomsNames: itemToDelete ? itemToDelete.roomName.split() : [], isOpen: itemToDelete != null, onClose: () => this.setState({ itemToDelete: null }), onDelete: this.deleteHandler }),
            React.createElement(RestoreRoomModal, { names: itemToRestore ? itemToRestore.roomName.split() : [], isOpen: itemToRestore != null, onClose: () => this.setState({ itemToRestore: null }), onRestore: this.restoreHandler }),
            React.createElement(ConfirmModal, { text: t('ROOM_NOT_FOUND_DELETE'), onClose: () => this.setState({ itemToDeleteNotFound: false }), isOpen: itemToDeleteNotFound, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t('ROOM_NOT_FOUND_DELETE'), onClose: () => this.setState({ itemToRestoreNotFound: false }), isOpen: itemToRestoreNotFound, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t(errorModal), onClose: () => {
                    pathUtils.clearLocationState('errorModal');
                    this.setState({ errorModal: null });
                }, isOpen: errorModal !== null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(RoomExportAdminModal, { filters: pathUtils.parseUrlParams(history.location.search), isOpen: roomsExport !== null && roomsExport === exportRole.ADMIN, onClose: () => {
                    this.setState({ roomsExport: null });
                } }),
            React.createElement(RoomExportSiteAdminModal, { filters: pathUtils.parseUrlParams(history.location.search), isOpen: roomsExport !== null && roomsExport === exportRole.SITE_ADMIN, role: role, onClose: () => {
                    this.setState({ roomsExport: null });
                } })));
    }
    render() {
        const { t, rooms, roomsLoading, totalPages, page, pageSize, totalElements, history, } = this.props;
        const { search, selectedIds, selectedRooms } = this.state;
        let bulkActionButtons;
        let exportButton;
        let addButton;
        if (selectedIds.length !== 0) {
            bulkActionButtons = (React.createElement(RoomsBulkActions, { selectedRoomsIds: selectedIds, selectedRooms: selectedRooms, rooms: rooms, fetchRooms: () => {
                    this.resetSelection();
                    this.fetchData({});
                } }));
        }
        else {
            addButton = (React.createElement(Button, { to: routes.roomAdd, className: "is-primary", icon: React.createElement(FAIcon, { icon: ['fas', 'plus'] }), text: t('ADD_ROOM') }));
            if (this.props.role === 'ROLE_ADMIN') {
                exportButton = (React.createElement(Button, { onClick: this.exportForRoleAdmin, className: "is-primary", icon: React.createElement(FAIcon, { icon: ['fas', 'file-export'] }), text: t('EXPORT_CSV') }));
            }
            else {
                exportButton = (React.createElement(Button, { onClick: this.exportForRoleSiteAdmin, className: "is-primary", icon: React.createElement(FAIcon, { icon: ['fas', 'file-export'] }), text: t('EXPORT_CSV') }));
            }
        }
        return (React.createElement(Fragment, null,
            React.createElement(TitleSection, null,
                React.createElement(Search, { placeholder: t('SEARCH_PLACEHOLDER_ROOMS'), defaultValue: search, updateSearch: this.updateSearch.bind(this) })),
            React.createElement(LoaderContainer, { className: "section" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "header-panel is-clearfix" },
                        React.createElement("div", { className: "buttons" },
                            bulkActionButtons,
                            addButton,
                            exportButton),
                        React.createElement("div", { className: "is-pulled-right" })),
                    this.state.errorLoading && React.createElement(GoogleLoginWrapper, null),
                    React.createElement(TableFilter, { type: tableFilterType.ROOMS, history: history, statusOptions: [
                            {
                                value: statusConstants.NOT_YET_ACTIVE,
                                label: t(statusConstants.NOT_YET_ACTIVE),
                            },
                            {
                                value: statusConstants.ACTIVE,
                                label: t(statusConstants.ACTIVE),
                            },
                            {
                                value: statusConstants.MAINTENANCE,
                                label: t(statusConstants.MAINTENANCE),
                            },
                            {
                                value: statusConstants.PENDING_DELETION,
                                label: t(statusConstants.PENDING_DELETION),
                            },
                        ], privacyOptions: [
                            { value: true, label: t('PRIVATE_ROOM') },
                            { value: false, label: t('PUBLIC_ROOM') },
                        ], visibilityOptions: [
                            { value: true, label: t('VISIBLE') },
                            { value: false, label: t('HIDDEN') },
                        ] }),
                    React.createElement(SelectTable, { itemsPaginationName: "ROOMS_LOWER_CASE", keyField: "roomId", resultsData: { page, totalElements, pageSize }, pages: totalPages, data: rooms, pageSize: pageSize, dataColumns: this.getColumns(), tableChange: this.updateParams.bind(this), siteCodePropertyName: "siteCode", selectedIdsChange: this.onSelectedIdChange.bind(this), parentSelectedIds: selectedIds, filterable: true, loading: roomsLoading, search: search ? search : '', getTrProps: this.getTrProps }))),
            this.renderModals()));
    }
}
const mapStateToProps = state => {
    const { rooms: { rooms, roomsLoading, totalPages, pageSize, pageNumber, sort, totalElements, }, user: { role, sites }, } = state;
    return {
        rooms,
        roomsLoading,
        totalPages,
        role,
        pageSize,
        page: pageNumber,
        sort,
        sites,
        totalElements,
    };
};
const mapDispatchToProps = dispatch => ({
    loadRooms: conf => dispatch(roomsActions.loadRooms(conf)),
    deleteRoom: (id, conf) => dispatch(roomsActions.deleteRoom(id, conf)),
    restoreRoom: (id, conf) => dispatch(roomsActions.restoreRoom(id, conf)),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Rooms)));
