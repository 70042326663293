var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Landing } from '../';
import i18n from '../../i18n';
import { useGoogleLogin } from '@react-oauth/google';
import { userActions } from '../../actions';
export const Home = () => {
    const dispatch = useDispatch();
    const { isLogged } = useSelector(state => state.user);
    const login = useGoogleLogin({
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/cloud_search.query',
        onSuccess: (response) => __awaiter(void 0, void 0, void 0, function* () {
            dispatch(userActions.newTokenRequest(response.code));
        }),
        onError: errorResponse => console.log(errorResponse),
    });
    return isLogged ? (React.createElement(Landing, null)) : (React.createElement("section", { className: "home hero is-primary is-fullheight-with-navbar is-fullheight-view" },
        React.createElement("div", { className: "hero-body" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "content-wrap" },
                    React.createElement("h1", { className: "title" }, i18n.t('HOME_VIEW_TITLE')),
                    React.createElement("h2", { className: "subtitle" }, i18n.t('HOME_VIEW_SUBTITLE')),
                    React.createElement(Button, { text: i18n.t('LOG_IN'), className: "is-white is-large has-text-primary", onClick: () => login() }))))));
};
