export const buildingsConstants = {
    FETCH_BUILDINGS: 'FETCH_BUILDINGS',
    FETCH_SINGLE_BUILDING: 'FETCH_SINGLE_BUILDING',
    ADD_BUILDING: 'ADD_BUILDING',
    DELETE_BUILDING: 'DELETE_BUILDING',
    UPDATE_BUILDING: 'UPDATE_BUILDING',
    LOADING_BUILDINGS: 'LOADING_BUILDINGS',
    ERROR_BUILDINGS: 'ERROR_BUILDINGS',
    FETCH_SINGLE_BUILDING_AUDITLOG: 'FETCH_SINGLE_BUILDING_AUDITLOG',
};
