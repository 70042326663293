import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { userActions } from '../../actions';
import { GlobalLoader } from '../';
class Logout extends Component {
    componentWillMount() {
        this.props.logout();
    }
    render() {
        return !this.props.isLogged ? (React.createElement(Redirect, { to: "/" })) : (React.createElement(GlobalLoader, { active: true }));
    }
}
const mapStateToProps = state => {
    const { user: { isLogged }, } = state;
    return {
        isLogged,
    };
};
const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(userActions.logout()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
