var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { buildingsConstants, errorTypesConstants } from '../constants';
import { loadingActions } from './loading.actions';
import { errorActions } from './';
import api from '../api';
const loadBuildings = params => {
    const { sort, listParams } = params;
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(buildingsLoading());
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Buildings.list(params);
            const { content, number, totalPages, size, totalElements, } = response.data;
            dispatch(buildingsSuccess(content, number, totalPages, size, sort, totalElements, listParams));
            dispatch(loadingActions.hideLoader());
        }
        catch (error) {
            dispatch(buildingsError(error, loadBuildings, [params]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const loadBuilding = ({ id }) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Buildings.get(id);
            dispatch(buildingSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            error.type = errorTypesConstants.LOAD_RESOURCE;
            dispatch(buildingsError(error, loadBuilding, [{ id }]));
            dispatch(loadingActions.hideLoader());
            throw error;
        }
    });
};
const loadBuildingAuditlog = ({ id, cursor }) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        if (!cursor) {
            dispatch(buildingAuditlogSuccess(null));
        }
        if (!id)
            return;
        const response = yield api.Buildings.auditlog(id, cursor);
        dispatch(buildingAuditlogSuccess(response.data));
        return response.data;
    });
};
const addBuilding = data => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(buildingsLoading());
        try {
            const response = yield api.Buildings.create(data);
            dispatch(buildingAddSuccess());
            return response.data;
        }
        catch (error) {
            error.type = errorTypesConstants.ADD_RESOURCE;
            dispatch(buildingsError(error, addBuilding, [data]));
            return error;
        }
    });
};
const deleteBuilding = (id, conf) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(buildingsLoading());
        try {
            const response = yield api.Buildings.delete(id);
            dispatch(loadBuildings(conf));
            return response.data;
        }
        catch (error) {
            dispatch(buildingsError(error, deleteBuilding, [id, conf]));
            return error;
        }
    });
};
const updateBuilding = (id, data) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(buildingsLoading());
        try {
            const response = yield api.Buildings.update(id, data);
            dispatch(buildingUpdateSuccess());
            return response.data;
        }
        catch (error) {
            error.type = errorTypesConstants.EDIT_RESOURCE;
            dispatch(buildingsError(error, updateBuilding, [id, data]));
            return error;
        }
    });
};
const buildingsSuccess = (buildings, pageNumber, totalPages, pageSize, sort, totalElements, listParams) => ({
    type: buildingsConstants.FETCH_BUILDINGS,
    buildings,
    pageNumber,
    totalPages,
    pageSize,
    sort,
    totalElements,
    listParams,
});
const buildingSuccess = building => ({
    type: buildingsConstants.FETCH_SINGLE_BUILDING,
    building,
});
const buildingAuditlogSuccess = buildingAuditlog => ({
    type: buildingsConstants.FETCH_SINGLE_BUILDING_AUDITLOG,
    buildingAuditlog,
});
const buildingAddSuccess = () => ({
    type: buildingsConstants.ADD_BUILDING,
});
const buildingUpdateSuccess = () => ({
    type: buildingsConstants.UPDATE_BUILDING,
});
const buildingsLoading = () => ({ type: buildingsConstants.LOADING_BUILDINGS });
const buildingsError = (error, actionFunction, actionParams) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(errorActions.error(error, actionFunction, actionParams, {
            type: buildingsConstants.ERROR_BUILDINGS,
            error,
        }));
    });
};
export const buildingsActions = {
    loadBuildings,
    addBuilding,
    deleteBuilding,
    updateBuilding,
    loadBuilding,
    loadBuildingAuditlog,
};
