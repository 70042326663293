import React, { Component } from 'react';
import Lightbox from '../Common/Lightbox/Lightbox';
import { pathUtils } from '../../utils';
import { endpoints } from '../../constants';
import { imageActions } from '../../actions/image.actions';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { roomImageTypeConstants } from '../../constants/roomImageType.constants';
class FullScreenImage extends Component {
    prepareImagePath(imageName) {
        const { imgMaxWidth, imgMaxHeight, imgCrop } = this.props;
        const resizeParams = pathUtils.prepareImageResizeParams(imgMaxWidth, imgMaxHeight, imgCrop);
        return `${endpoints.url}${endpoints.storage}/${imageName}?${resizeParams}`;
    }
    render() {
        const { fullScreenImages: images, fullScreenImagesIndex } = this.props;
        if (images && images.length > 0) {
            if (images.length === 1) {
                const image = {
                    url: this.prepareImagePath(images[0].name),
                    isVrView: images[0].type === roomImageTypeConstants.VIEW_360,
                };
                return (React.createElement(Lightbox, { image: image, allowRotate: false, alt: "", ref: img => (this.img = img), onClose: () => this.props.closeFullScreen() }));
            }
            const imagesPath = images.map(img => {
                return {
                    url: this.prepareImagePath(img.name),
                    isVrView: img.type === roomImageTypeConstants.VIEW_360,
                };
            });
            return (React.createElement(Lightbox, { startIndex: fullScreenImagesIndex, images: imagesPath, allowRotate: false, alt: "", ref: img => (this.img = img), onClose: () => this.props.closeFullScreen() }));
        }
        return null;
    }
}
const mapStateToProps = state => {
    const { image: { fullScreenImages, fullScreenImagesIndex }, } = state;
    return {
        fullScreenImages,
        fullScreenImagesIndex,
    };
};
const mapDispatchToProps = dispatch => ({
    closeFullScreen: () => dispatch(imageActions.closeFullScreen()),
});
export default withNamespaces('translations')(connect(mapStateToProps, mapDispatchToProps)(FullScreenImage));
