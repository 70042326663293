var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { loadingActions } from './loading.actions';
import api from '../api';
import { roomsMaintenancesConstants } from '../constants/roomsMaintenances.constants';
import { errorActions } from './error.actions';
import { roomsActions as roomActions } from './rooms.actions';
const loadMaintenances = params => {
    const { sort, listParams } = params;
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(maintenanceLoading());
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.RoomMaintenance.list(params);
            const { content, number, totalPages, size, totalElements, } = response.data;
            dispatch(roomsMaintenancesSuccess(content, number, totalPages, size, sort, totalElements, listParams));
            dispatch(loadingActions.hideLoader());
        }
        catch (error) {
            dispatch(roomsMaintenanceError(error, cancelMaintenance, [[params]]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const cancelMaintenance = (roomId, data, roomView) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.cancelMaintenance(roomId, data);
            dispatch(loadingActions.hideLoader());
            dispatch(maintenanceCancelSuccess());
            if (roomView && roomView.roomDetailsView) {
                dispatch(roomActions.loadRoom({ id: roomId }));
            }
            return response.data;
        }
        catch (error) {
            dispatch(roomsMaintenanceError(error, cancelMaintenance, [{ roomId, data }]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const patchMaintenance = (roomId, data, roomView) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.patchMaintenance(roomId, data);
            dispatch(loadingActions.hideLoader());
            dispatch(maintenancePatchSuccess());
            if (roomView && roomView.roomDetailsView) {
                dispatch(roomActions.loadRoom({ id: roomId }));
            }
            return response.data;
        }
        catch (error) {
            dispatch(roomsMaintenanceError(error, maintenancePatchSuccess, [{ data }]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const cancelMaintenanceBulk = ids => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.RoomMaintenance.cancelMaintenanceBulk(ids);
            dispatch(maintenanceCancelSuccess());
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsMaintenanceError(error, cancelMaintenance, [{ ids }]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const editMaintenanceBulk = (ids, data) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.RoomMaintenance.editMaintenanceBulk(ids, data);
            dispatch(maintenanceEditSuccess());
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(roomsMaintenanceError(error, editMaintenanceBulk, [{ ids }]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const exportAffectedOrganizersByMaintenance = (startDate, endDate, ids) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Rooms.exportAffectedOrganizers(startDate, endDate, ids);
            dispatch(exportAffectedOrganizersSuccess());
            dispatch(loadingActions.hideLoader());
            return response;
        }
        catch (error) {
            const errorData = { startDate, endDate, ids };
            dispatch(roomsMaintenanceError(error, exportAffectedOrganizersByMaintenance, [
                errorData,
            ]));
            return error;
        }
    });
};
const roomsMaintenanceError = (error, actionFunction, actionParams) => {
    return dispatch => {
        dispatch(errorActions.error(error, actionFunction, actionParams, {
            type: roomsMaintenancesConstants.ERROR_ROOMS_MAINTENANCES,
            error,
        }));
    };
};
const maintenanceLoading = () => ({
    type: roomsMaintenancesConstants.LOADING_MAINTENANCES,
});
const maintenanceCancelSuccess = () => ({
    type: roomsMaintenancesConstants.CANCEL_MAINTENANCE,
});
const maintenanceEditSuccess = () => ({
    type: roomsMaintenancesConstants.BULK_EDIT_MAINTENANCE,
});
const maintenancePatchSuccess = () => ({
    type: roomsMaintenancesConstants.PATCH_MAINTENANCE,
});
const exportAffectedOrganizersSuccess = () => ({
    type: roomsMaintenancesConstants.EXPORT_AFFECTED_ORGANIZERS_SUCCESS,
});
const roomsMaintenancesSuccess = (maintenances, pageNumber, totalPages, pageSize, sort, totalElements, listParams) => ({
    type: roomsMaintenancesConstants.FETCH_MAINTENANCES,
    maintenances,
    pageNumber,
    totalPages,
    pageSize,
    sort,
    totalElements,
    listParams,
});
export const roomsMaintenancesActions = {
    loadMaintenances,
    patchMaintenance,
    cancelMaintenance,
    cancelMaintenanceBulk,
    editMaintenanceBulk,
    exportAffectedOrganizersByMaintenance,
};
