var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { featuresActions } from '../../actions';
import { withNamespaces } from 'react-i18next';
import { Formik, Form, Field, getIn } from 'formik';
import { Button, ErrorMsg, FormControl, ReactSelect, TitleSection, FormButtons, Translations, FormErrorNotification, LoaderContainer, ConfirmModal, BackToList, } from '../';
import { FeaturesSchema } from '../../validations';
import classnames from 'classnames/bind';
import { routes, errorTypesConstants as err, appConstants, errorConstants, } from '../../constants';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { formatRoute } from 'react-router-named-routes';
import { languages } from '../../enums';
import api from '../../api';
import withErrorHandling from '../Common/WithErrorHandling/withErrorHandling';
import i18n from '../../i18n';
import { updateErrorMessagesOnLanguageChange } from '../../utils/formik.utils';
class FeatureEdit extends Component {
    constructor(props) {
        super(props);
        this.emptyFormState = {
            category: '',
            name: '',
            translations: [],
        };
        this.state = {
            isReady: false,
            itemToAdd: null,
            itemToEdit: null,
            categories: [],
            itemConflict: false,
            accessDenied: false,
        };
        const editId = props.match.params.id;
        if (!isNaN(editId)) {
            this.id = parseInt(editId);
        }
    }
    isEdit() {
        return this.id !== undefined;
    }
    isEditReady() {
        return this.isEdit() && this.state.isReady;
    }
    fetchCategories() {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield api.Features.getCategories();
            return res.data;
        });
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.loadData();
        });
    }
    componentWillUnmount() {
        i18n.off('languageChanged');
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            const { loadFeature } = this.props;
            this.setState({ categories: yield this.fetchCategories() });
            if (this.isEdit()) {
                try {
                    yield loadFeature({ id: this.id });
                    this.setState({ isReady: true });
                }
                catch (error) {
                    const { t, history, handleError404, featuresError } = this.props;
                    if (featuresError && featuresError.type === err.LOAD_RESOURCE) {
                        handleError404({
                            text: {
                                msg: t('ERROR_404_FEATURE'),
                                email: appConstants.DEFAULT_CONTACT_EMAIL,
                            },
                            backLinkPath: this.buildFeaturesListUrl(),
                        });
                    }
                    else {
                        history.push(this.buildFeaturesListUrl());
                    }
                }
            }
            else {
                this.setState({ isReady: true });
            }
        });
    }
    getInitialValues() {
        return this.isEditReady() ? this.props.feature : this.emptyFormState;
    }
    onSubmit(values, { setSubmitting }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { addFeature, updateFeature, history } = this.props;
            const savedFeature = this.isEdit()
                ? yield updateFeature(this.id, values)
                : yield addFeature(values);
            setSubmitting(false);
            if (!(savedFeature instanceof Error)) {
                const modal = this.isEdit()
                    ? { itemToEdit: Object.assign({}, values) }
                    : { itemToAdd: Object.assign(Object.assign({}, values), { id: savedFeature.id }) };
                this.setState(modal);
            }
            else if (savedFeature.response.data.reason ===
                errorConstants.FEATURE_NOT_FOUND_UPDATE ||
                savedFeature.response.data.reason ===
                    errorConstants.ROOM_TYPE_NOT_FOUND_DELETE) {
                history.push({
                    pathname: this.buildFeaturesListUrl(),
                    state: { errorModal: savedFeature.response.data.reason },
                });
            }
            else if (savedFeature.response.data.reason === errorConstants.ACCESS_DENIED) {
                this.setState({ accessDenied: true });
            }
            else if (savedFeature.response.data.reason === errorConstants.CONFLICT) {
                this.loadData();
                this.setState({ itemConflict: true });
            }
        });
    }
    renderModals() {
        const { itemToEdit, itemToAdd, itemConflict, accessDenied } = this.state;
        const { t, history } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(ConfirmModal, { title: t('ADD_FEATURE'), text: t('MODAL_ADD_FEATURE_CONFIRM', {
                    name: itemToAdd ? itemToAdd.name : '',
                }), onClose: () => {
                    this.setState({ itemToAdd: null });
                    history.push(this.buildFeaturesListUrl());
                }, isOpen: itemToAdd != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                }, secondaryButton: {
                    label: t('SEE_FEATURE_PREVIEW'),
                    type: 'is-light',
                    action: () => {
                        history.push(formatRoute(routes.featureDetails, {
                            id: itemToAdd.id,
                        }));
                    },
                } }),
            React.createElement(ConfirmModal, { title: t('EDIT'), text: t('MODAL_EDIT_FEATURE_CONFIRM', {
                    name: itemToEdit ? itemToEdit.name : '',
                }), onClose: () => {
                    this.setState({ itemToEdit: null });
                    history.push(this.buildFeaturesListUrl());
                }, isOpen: itemToEdit != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                }, secondaryButton: {
                    label: t('SEE_FEATURE_PREVIEW'),
                    type: 'is-light',
                    action: () => {
                        history.push(formatRoute(routes.featureDetails, {
                            id: itemToEdit.id,
                        }));
                    },
                } }),
            React.createElement(ConfirmModal, { title: t('ACCESS_DENIED'), text: t('ACCESS_DENIED_LOST_RIGHTS'), onClose: () => history.push(routes.logout), isOpen: accessDenied, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { title: t('CONFLICT'), text: t('FEATURE_EDIT_CONFLICT'), onClose: () => this.setState({ itemConflict: false }), isOpen: itemConflict, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } })));
    }
    buildFeaturesListUrl() {
        return routes.features + this.props.listParams;
    }
    render() {
        const { t, feature, featuresError } = this.props;
        const { categories } = this.state;
        return (React.createElement(Fragment, null,
            React.createElement(TitleSection, { title: this.isEditReady()
                    ? `${feature.name} (${t('EDIT_FEATURE')})`
                    : t('ADD_FEATURE'), isNarrow: false }),
            React.createElement(LoaderContainer, { className: "section" }, this.state.isReady && (React.createElement("div", { className: "container" },
                React.createElement("div", { className: "header-panel" },
                    React.createElement("div", { className: "buttons" },
                        React.createElement(BackToList, { to: this.buildFeaturesListUrl(), t: t }))),
                React.createElement("div", { className: "content" },
                    React.createElement(Formik, { enableReinitialize: true, initialValues: this.getInitialValues(), validationSchema: FeaturesSchema, onSubmit: this.onSubmit.bind(this) }, form => {
                        const { isSubmitting, errors, touched, setFieldError, setFieldTouched, } = form;
                        updateErrorMessagesOnLanguageChange(errors, setFieldError, setFieldTouched);
                        return (React.createElement(Form, null,
                            React.createElement(FormErrorNotification, Object.assign({ t: t, schema: FeaturesSchema, error: featuresError }, form)),
                            React.createElement("p", null, t('REQUIRED_FIELDS_TEXT')),
                            React.createElement("div", { className: "columns" },
                                React.createElement("div", { className: "column is-4" },
                                    React.createElement(FormControl, { label: t('CATEGORY'), required: true },
                                        React.createElement(Field, { name: "category", component: ReactSelect, placeholder: t('FIELD_FEATURE_CATEGORY_PLACEHOLDER'), options: categories.map(category => ({
                                                value: category,
                                                label: category,
                                            })), className: classnames({
                                                'is-danger': errors.category && touched.category,
                                            }) }),
                                        React.createElement(ErrorMsg, { name: "category" })))),
                            React.createElement("div", { className: "columns" },
                                React.createElement("div", { className: "column is-6" },
                                    React.createElement(FormControl, { label: `${t('NAME')} (${t('ENGLISH')})`, required: true },
                                        React.createElement("p", null, t('FEATURE_NAME_DESC')),
                                        React.createElement(Field, { className: classnames('input', {
                                                'is-danger': errors.name && touched.name,
                                            }), type: "text", placeholder: t('FIELD_FEATURE_NAME_PLACEHOLDER'), name: "name" }),
                                        React.createElement(ErrorMsg, { name: "name" })),
                                    React.createElement(Field, { name: "translations", label: t('TRANSLATIONS'), component: Translations, itemDefaultValues: { name: '' }, labelAdd: t('ADD_TRANSLATION_NAME'), item: (dataItem, index) => {
                                            let tName = `translations[${index}]name`;
                                            let langName = languages[dataItem.locale]
                                                ? ` (${languages[dataItem.locale].name})`
                                                : '';
                                            return (React.createElement(FormControl, { label: `${t('NAME')}${langName}`, required: true },
                                                React.createElement(Field, { type: "input", name: tName, placeholder: `${t('FIELD_FEATURE_TRANSLATION_NAME_PLACEHOLDER')}${langName}`, className: classnames('input', {
                                                        'is-danger': getIn(errors, tName) &&
                                                            getIn(touched, tName),
                                                    }) }),
                                                React.createElement(ErrorMsg, { name: tName })));
                                        } }))),
                            React.createElement(FormButtons, null,
                                React.createElement(Button, { type: "submit", className: "is-primary", isLoading: isSubmitting, disabled: isSubmitting, text: t('SAVE'), icon: React.createElement(FAIcon, { icon: ['fas', 'check'] }) }),
                                React.createElement(Button, { to: this.buildFeaturesListUrl(), className: "is-light", text: t('CANCEL') }))));
                    }))))),
            this.renderModals()));
    }
}
const mapStateToProps = state => {
    const { features: { feature, featuresError, listParams }, } = state;
    return {
        feature,
        featuresError,
        listParams,
    };
};
const mapDispatchToProps = dispatch => ({
    addFeature: data => dispatch(featuresActions.addFeature(data)),
    loadFeature: id => dispatch(featuresActions.loadFeature(id)),
    updateFeature: (id, data) => dispatch(featuresActions.updateFeature(id, data)),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(withErrorHandling(FeatureEdit))));
