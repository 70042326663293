import { featuresConstants } from '../constants';
export const featuresState = {
    features: [],
    feature: null,
    featuresError: null,
    featuresLoading: false,
    pageNumber: 0,
    pageSize: 20,
    sort: [],
    totalPages: 1,
    totalElements: 0,
    listParams: '',
};
const features = (state = featuresState, action) => {
    switch (action.type) {
        case featuresConstants.FETCH_FEATURES:
            return Object.assign(Object.assign({}, state), { features: [...action.features], pageNumber: action.pageNumber, pageSize: action.pageSize, sort: action.sort, totalPages: action.totalPages, featuresError: null, featuresLoading: false, totalElements: action.totalElements, listParams: action.listParams || '' });
        case featuresConstants.FETCH_SINGLE_FEATURE:
            return Object.assign(Object.assign({}, state), { feature: action.feature, featuresError: null, featuresLoading: false });
        case featuresConstants.ADD_FEATURE:
        case featuresConstants.UPDATE_FEATURE:
            return Object.assign(Object.assign({}, state), { featuresError: null, featuresLoading: false });
        case featuresConstants.ERROR_FEATURES:
            return Object.assign(Object.assign({}, state), { featuresError: action.error, featuresLoading: false });
        case featuresConstants.LOADING_FEATURES:
            return Object.assign(Object.assign({}, state), { featuresLoading: true });
        default:
            return state;
    }
};
export default features;
