import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
export const setTime = (date, h, m) => {
    const newDate = new Date(date);
    newDate.setHours(h, m, 0);
    return newDate;
};
export const changeTime = (date, h = 0, m = 0) => {
    const oldDate = new Date(date);
    const minutes = oldDate.getMinutes();
    const hours = oldDate.getHours();
    const newDate = new Date(date);
    newDate.setHours(hours + h, minutes + m, 0);
    return newDate;
};
export const compareDates = (firstDate, secondDate) => {
    const date = new Date(firstDate);
    date.setHours(0, 0, 0);
    const dateToCompare = new Date(secondDate);
    dateToCompare.setHours(0, 0, 0);
    return date.getTime() < dateToCompare.getTime();
};
export const areDateTimeRangesNotOverlapped = (startA, endA, startB, endB) => {
    const sA = trimToMinutes(startA);
    const eA = trimToMinutes(endA);
    const sB = trimToMinutes(startB);
    const eB = trimToMinutes(endB);
    return sA >= eB || eA <= sB;
};
export const trimToMinutes = dateTime => {
    return new Date(dateTime).setSeconds(0, 0);
};
export const fixEndDate = (minDate, maxDate) => {
    const newDate = new Date(minDate);
    const time = new Date(maxDate);
    newDate.setHours(time.getHours(), 0, 0);
    return newDate;
};
export const toGoogleCalendarCreateEventDate = date => {
    return new Date(date).toISOString().replace(/[-:.]/g, '');
};
export const getWeekRange = date => {
    return {
        startDate: startOfWeek(date),
        endDate: endOfWeek(date),
    };
};
