import 'core-js';
import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-table/react-table.css';
import './index.scss';
import 'react-toggle/style.css';
import { Provider } from 'react-redux';
import ReactGa from 'react-ga';
import store from './store';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import GoogleLoader from './googleLoader';
import App from './components/App/App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleOAuthLoader } from './components/googleOAuthLoader';
ReactGa.initialize(process.env.PROJECT === 'production' ? 'UA-163967082-1' : 'UA-164766821-1');
ReactDOM.render(React.createElement(I18nextProvider, { i18n: i18n },
    React.createElement(Provider, { store: store },
        React.createElement(GoogleOAuthLoader, null,
            React.createElement(GoogleLoader, null,
                React.createElement(App, null))))), document.getElementById('root'));
serviceWorker.unregister();
