var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { adminsConstants, eventsConstants } from '../constants';
import { errorActions } from './';
import api from '../api';
import { roomAvailabilityConstants } from '../constants/roomAvailability.constants';
export const createEventLog = roomId => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            api.Events.createEventLog(roomId);
            dispatch(createEventLogDispatched());
        }
        catch (error) {
            dispatch(eventError(error, createEventLog, [{ roomId }]));
            throw error;
        }
    });
};
export const loadRoomEvents = (roomId, range) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            dispatch(showLoader());
            const response = yield api.Events.list(roomId, range);
            const mappedEvents = response.data.map(e => map(e));
            dispatch(roomEventSuccess(mappedEvents));
            dispatch(hideLoader());
        }
        catch (error) {
            dispatch(eventError(error, loadRoomEvents, [{ roomId, data: range }]));
            dispatch(hideLoader());
            throw error;
        }
    });
};
export const closeRoomAvailability = () => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(resetEvents());
    });
};
function map(event) {
    const start = new Date(event.beginDate);
    const end = new Date(event.endDate);
    return {
        id: event.eventId,
        title: event.eventName,
        allDay: false,
        start: start,
        end: end,
        organizer: event.organizer,
    };
}
const showLoader = () => ({
    type: roomAvailabilityConstants.SHOW_LOADER,
});
const hideLoader = () => ({
    type: roomAvailabilityConstants.HIDE_LOADER,
});
const roomEventSuccess = events => ({
    type: roomAvailabilityConstants.FETCH_EVENTS_SUCCESS,
    events,
});
const resetEvents = () => ({
    type: roomAvailabilityConstants.RESET_EVENTS,
});
const createEventLogDispatched = events => ({
    type: eventsConstants.CREATE_EVENT_LOG_DISPATCHED,
    events,
});
export const eventError = (error, actionFunction, actionParams) => {
    return dispatch => {
        dispatch(errorActions.error(error, actionFunction, actionParams, {
            type: adminsConstants.ERROR_ADMINS,
            error,
        }));
    };
};
export const roomAvailabilityActions = {
    closeRoomAvailability,
    loadRoomEvents,
    createEventLog,
};
