import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routes, userRoleConstants } from '../../constants';
import LandingAdmin from './LandingAdmin';
import LandingUser from './LandingUser';
export default function Landing() {
    const { role, isUserView } = useSelector(state => state.user);
    const history = useHistory();
    const location = useLocation();
    const { pathname } = location;
    const isAdmin = role => {
        return (role === userRoleConstants.ADMIN || role === userRoleConstants.SITE_ADMIN);
    };
    useEffect(() => {
        console.log(role);
        if (role)
            skipLanding();
    }, [role]);
    const skipLanding = () => {
        if (pathname !== routes.admin && isAdmin(role) && !isUserView) {
            history.push(routes.admin);
        }
        if (JSON.parse(localStorage.getItem('skipLanding'))) {
            isAdmin(role) && isUserView
                ? history.push(routes.userRooms)
                : history.push(routes.rooms);
        }
    };
    if (role) {
        skipLanding();
        return !isAdmin(role) || isUserView ? React.createElement(LandingUser, null) : React.createElement(LandingAdmin, null);
    }
    return null;
}
