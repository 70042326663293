var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { adminsConstants } from '../constants';
import { loadingActions } from './loading.actions';
import { errorActions } from './';
import api from '../api';
const loadAdmins = params => {
    const { sort } = params;
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(adminsLoading());
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Admins.list(params);
            const { content, number, totalPages, size, totalElements, } = response.data;
            dispatch(adminsSuccess(content, number, totalPages, size, sort, totalElements));
            dispatch(loadingActions.hideLoader());
        }
        catch (error) {
            dispatch(adminsError(error, loadAdmins, [params]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const loadAdmin = ({ email }) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.Admins.get(email);
            dispatch(adminSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            dispatch(adminsError(error, loadAdmin, [
                {
                    email,
                },
            ]));
            dispatch(loadingActions.hideLoader());
            throw error;
        }
    });
};
const addAdmin = data => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(adminsLoading());
        try {
            const response = yield api.Admins.create(data);
            return response.data;
        }
        catch (error) {
            dispatch(adminsError(error, addAdmin, [data]));
            return error;
        }
    });
};
const deleteAdmin = (email, conf) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(adminsLoading());
        try {
            const response = yield api.Admins.delete(email);
            dispatch(loadAdmins(conf));
            return response.data;
        }
        catch (error) {
            dispatch(adminsError(error, deleteAdmin, [email, conf]));
            return error;
        }
    });
};
const updateAdmin = (email, data) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(adminsLoading());
        try {
            const response = yield api.Admins.update(email, data);
            return response.data;
        }
        catch (error) {
            dispatch(adminsError(error, updateAdmin, [email, data]));
            return error;
        }
    });
};
const adminsSuccess = (admins, pageNumber, totalPages, pageSize, sort, totalElements) => ({
    type: adminsConstants.FETCH_ADMINS,
    admins,
    pageNumber,
    totalPages,
    pageSize,
    sort,
    totalElements,
});
const adminSuccess = admin => ({
    type: adminsConstants.FETCH_SINGLE_ADMIN,
    admin,
});
const adminsLoading = () => ({
    type: adminsConstants.LOADING_ADMINS,
});
const adminsError = (error, actionFunction, actionParams) => {
    return dispatch => {
        dispatch(errorActions.error(error, actionFunction, actionParams, {
            type: adminsConstants.ERROR_ADMINS,
            error,
        }));
    };
};
export const adminsActions = {
    loadAdmins,
    loadAdmin,
    addAdmin,
    deleteAdmin,
    updateAdmin,
};
