var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { roomTypesActions } from '../../actions';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { ConfirmModal, TitleSection, Button, Search, Table, LoaderContainer, TableHeaderCell, ExportButton, } from '../';
import { formatRoute } from 'react-router-named-routes';
import { userRoleConstants, routes, urlDefaultStateConstants, errorConstants, } from '../../constants';
import { toastError } from '../../toasts';
import { pathUtils } from '../../utils';
import { buttonsUtils } from '../../utils';
import api from '../../api';
import { GoogleLoginWrapper } from '../Common/GoogleLoginWrapper/GoogleLoginWrapper';
class RoomTypes extends Component {
    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.state = Object.assign(Object.assign({ itemToDelete: null, itemDeletedInfo: null, itemToDeleteNotFound: false, errorModal: null }, urlDefaultStateConstants), pathUtils.parseUrlParams(props.history.location.search));
    }
    componentDidMount() {
        const props = Object.assign(Object.assign({}, urlDefaultStateConstants), pathUtils.parseUrlParams(this.props.location.search));
        const { location } = this.props;
        if (location.state && location.state.errorModal) {
            this.setState({ errorModal: location.state.errorModal });
        }
        this.fetchData(props);
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            const props = Object.assign(Object.assign({}, urlDefaultStateConstants), pathUtils.parseUrlParams(this.props.location.search));
            this.fetchData(props);
        }
    }
    fetchData(state, instance) {
        return __awaiter(this, void 0, void 0, function* () {
            const { loadRoomTypes, history, t } = this.props;
            const parsedParams = pathUtils.parseUrlParams(history.location.search);
            const updatedState = Object.assign(Object.assign({}, state), parsedParams);
            const { page, pageSize, sorted, search } = updatedState;
            const response = yield loadRoomTypes({
                pageNumber: page,
                pageSize,
                sort: sorted,
                search,
                listParams: history.location.search,
            });
            if (response instanceof Error) {
                toastError(t(response.response.data.reason));
                this.setState({ errorLoading: true });
            }
        });
    }
    updateParams(params) {
        const { history } = this.props;
        const url = pathUtils.prepareUrl(pathUtils.updateState(history.location.search, params));
        history.push(`?${url}`);
    }
    getColumns() {
        const { t } = this.props;
        return [
            {
                Header: React.createElement(TableHeaderCell, { text: t('NAME') }),
                accessor: 'roomTypeName',
                Cell: cellInfo => (React.createElement(Link, { to: formatRoute(routes.roomTypeDetails, {
                        id: cellInfo.original.roomTypeId,
                    }) }, cellInfo.original.roomTypeName)),
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('DESCRIPTION') }),
                accessor: 'roomTypeDescription',
            },
            {
                Header: React.createElement(TableHeaderCell, { text: t('NUMBER_OF_ROOMS') }),
                accessor: 'numberOfRooms',
                width: 200,
                sortable: false,
            },
            {
                accessor: 'roomTypeId',
                sortable: false,
                width: 120,
                className: 'td-actions',
                Cell: cellInfo => this.renderButtons(cellInfo),
            },
            {
                accessor: 'roomTypeId',
                sortable: false,
                width: 120,
                Cell: cellInfo => cellInfo.original.numberOfRooms > 0 && (React.createElement(Link, { to: `${routes.rooms}?roomTypeIds[]="${cellInfo.original.roomTypeId}"&roomTypeName[]="${cellInfo.original.roomTypeName}"` }, t('SEE_ALL_ROOMS'))),
            },
        ];
    }
    renderButtons(cellInfo) {
        const { t, role } = this.props;
        const deleteSettings = buttonsUtils.isDeleteDisabled({
            item: cellInfo.original,
            hasRoomsTooltip: 'ROOM_TYPE_HAS_ROOMS',
            allowRemovalTooltip: 'ITEM_USED',
        });
        if (role === userRoleConstants.ADMIN)
            return (React.createElement("div", { className: "buttons is-right" },
                React.createElement(Button, { to: formatRoute(routes.roomTypeEdit, {
                        id: cellInfo.original.roomTypeId,
                    }), className: "is-text has-text-primary", tooltip: t('EDIT'), icon: React.createElement(FAIcon, { icon: ['far', 'edit'] }) }),
                React.createElement(Button, { className: "is-text has-text-primary", tooltip: t(deleteSettings.tooltip), disabled: deleteSettings.disabled, icon: React.createElement(FAIcon, { icon: deleteSettings.icon }), onClick: () => this.setState({
                        itemToDelete: cellInfo.original,
                    }) })));
        return null;
    }
    updateSearch(search) {
        this.updateParams({ search });
        this.setState({ search, errorLoading: false });
    }
    renderModals() {
        const { itemToDelete, itemDeletedInfo, itemToDeleteNotFound, errorModal, } = this.state;
        const { t, deleteRoomType, page, pageSize, sort } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(ConfirmModal, { title: t('CONFIRM'), text: t('DELETE_CONFIRM_MESSAGE_ROOM_TYPE', {
                    item: itemToDelete ? itemToDelete.roomTypeName : '',
                }), onClose: () => this.setState({ itemToDelete: null }), isOpen: itemToDelete != null, primaryButton: {
                    label: t('DELETE'),
                    type: 'is-danger',
                    action: (onClose) => __awaiter(this, void 0, void 0, function* () {
                        const roomTypeDeleted = yield deleteRoomType(itemToDelete.roomTypeId, {
                            page,
                            pageSize,
                            sort,
                        });
                        if (!(roomTypeDeleted instanceof Error)) {
                            this.setState({ itemDeletedInfo: Object.assign({}, itemToDelete) });
                        }
                        else {
                            if (roomTypeDeleted.response.data.reason ===
                                errorConstants.ROOM_TYPE_NOT_FOUND_DELETE) {
                                this.fetchData(urlDefaultStateConstants);
                                this.setState({ itemToDeleteNotFound: true });
                            }
                            else {
                                toastError(t(roomTypeDeleted.response.data.reason));
                            }
                        }
                        onClose();
                    }),
                    icon: React.createElement(FAIcon, { icon: ['far', 'trash-alt'] }),
                }, secondaryButton: {
                    label: t('CANCEL'),
                    type: 'is-light',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t('MODAL_DELETE_ROOM_TYPE_INFO', {
                    name: itemDeletedInfo ? itemDeletedInfo.roomTypeName : '',
                }), onClose: () => this.setState({ itemDeletedInfo: null }), isOpen: itemDeletedInfo != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t('ROOM_TYPE_NOT_FOUND_DELETE'), onClose: () => this.setState({ itemToDeleteNotFound: false }), isOpen: itemToDeleteNotFound, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { text: t(errorModal), onClose: () => {
                    pathUtils.clearLocationState('errorModal');
                    this.setState({ errorModal: null });
                }, isOpen: errorModal !== null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } })));
    }
    render() {
        const { t, roomTypes, roomTypesLoading, totalPages, page, pageSize, totalElements, role, } = this.props;
        const { search } = this.state;
        return (React.createElement(Fragment, null,
            React.createElement(TitleSection, null,
                React.createElement(Search, { placeholder: t('SEARCH_PLACEHOLDER_ROOM_TYPES'), defaultValue: search, updateSearch: this.updateSearch.bind(this) })),
            this.state.errorLoading && React.createElement(GoogleLoginWrapper, null),
            React.createElement(LoaderContainer, { className: "section" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "header-panel" },
                        React.createElement("div", { className: "buttons" },
                            role === userRoleConstants.ADMIN && (React.createElement(Button, { to: routes.roomTypeAdd, className: "is-primary", icon: React.createElement(FAIcon, { icon: ['fas', 'plus'] }), text: t('ADD_ROOM_TYPE') })),
                            React.createElement(ExportButton, { fetchExportData: api.RoomTypes.export, fileName: "export-roomtypes" }))),
                    React.createElement(Table, { resultsData: { page, totalElements, pageSize }, pages: totalPages, data: roomTypes, columns: this.getColumns(), loading: roomTypesLoading, search: search ? search : '', pageSize: pageSize, tableChange: this.updateParams.bind(this) }))),
            this.renderModals()));
    }
}
const mapStateToProps = state => {
    const { roomTypes: { roomTypes, roomTypesLoading, totalPages, pageSize, pageNumber, sort, totalElements, }, user: { role }, } = state;
    return {
        roomTypes,
        roomTypesLoading,
        totalPages,
        pageSize,
        page: pageNumber,
        sort,
        totalElements,
        role,
    };
};
const mapDispatchToProps = dispatch => ({
    loadRoomTypes: conf => dispatch(roomTypesActions.loadRoomTypes(conf)),
    deleteRoomType: (id, conf) => dispatch(roomTypesActions.deleteRoomType(id, conf)),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(RoomTypes)));
