import Yup from '../yup';
const floor = Yup.object().shape({
    name: Yup.string()
        .required('BUILDING_FLOOR_NAME_REQUIRED')
        .matches(/^[^,]*$/, 'BUILDING_FLOOR_NAME_ILLEGAL_CHARACTERS')
        .max(15, 'BUILDING_FLOOR_NAME_MAX'),
});
const name = Yup.string()
    .label('NAME')
    .max(32, 'BUILDING_NAME_MAX')
    .required('BUILDING_NAME_REQUIRED');
const floors = Yup.array()
    .label('FLOORS')
    .of(floor)
    .min(1, 'BUILDING_FLOOR_REQUIRED')
    .unique('name', 'FLOOR_NAME_UNIQUE');
const siteCode = Yup.string()
    .label('SITE')
    .required('BUILDING_SITE_REQUIRED');
const externalLocation = Yup.boolean();
const latitude = Yup.string()
    .nullable()
    .label('LATITUDE');
const longitude = Yup.string()
    .nullable()
    .label('LONGITUDE');
const BuildingsSchema = Yup.object().shape({
    name,
    floors,
    siteCode,
    latitude,
    longitude,
    externalLocation,
});
export { BuildingsSchema };
