export const urlDefaultStateConstants = {
    buildingIds: [],
    roomTypeIds: [],
    featureIds: [],
    siteCodes: [],
    search: '',
    page: 0,
    pageSize: 20,
    sorted: [],
};
