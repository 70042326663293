var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { loadingActions } from './loading.actions';
import { roomTypesConstants, errorTypesConstants } from '../constants';
import { errorActions } from './';
import api from '../api';
const loadRoomTypes = params => {
    const { sort, listParams } = params;
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(roomTypesLoading());
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.RoomTypes.list(params);
            const { content, number, totalPages, size, totalElements, } = response.data;
            dispatch(roomTypesSuccess(content, number, totalPages, size, sort, totalElements, listParams));
            dispatch(loadingActions.hideLoader());
        }
        catch (error) {
            dispatch(roomTypesError(error, loadRoomTypes, [params]));
            dispatch(loadingActions.hideLoader());
            return error;
        }
    });
};
const loadRoomType = ({ id }) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(loadingActions.showLoader());
        try {
            const response = yield api.RoomTypes.get(id);
            dispatch(roomTypeSuccess(response.data));
            dispatch(loadingActions.hideLoader());
            return response.data;
        }
        catch (error) {
            error.type = errorTypesConstants.LOAD_RESOURCE;
            dispatch(roomTypesError(error, loadRoomType, [{ id }]));
            dispatch(loadingActions.hideLoader());
            throw error;
        }
    });
};
const addRoomType = data => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(roomTypesLoading());
        try {
            const response = yield api.RoomTypes.create(data);
            dispatch(roomTypeAddSuccess());
            return response.data;
        }
        catch (error) {
            dispatch(roomTypesError(error, addRoomType, [data]));
            return error;
        }
    });
};
const deleteRoomType = (id, conf) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(roomTypesLoading());
        try {
            const response = yield api.RoomTypes.delete(id);
            dispatch(loadRoomTypes(conf));
            return response.data;
        }
        catch (error) {
            dispatch(roomTypesError(error, deleteRoomType, [id, conf]));
            return error;
        }
    });
};
const updateRoomType = (id, data) => {
    return (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(roomTypesLoading());
        try {
            const response = yield api.RoomTypes.update(id, data);
            dispatch(roomTypeUpdateSuccess());
            return response.data;
        }
        catch (error) {
            dispatch(roomTypesError(error, updateRoomType, [id, data]));
            return error;
        }
    });
};
const roomTypesSuccess = (roomTypes, pageNumber, totalPages, pageSize, sort, totalElements, listParams) => ({
    type: roomTypesConstants.FETCH_ROOM_TYPES,
    roomTypes,
    pageNumber,
    totalPages,
    pageSize,
    sort,
    totalElements,
    listParams,
});
const roomTypeSuccess = roomType => ({
    type: roomTypesConstants.FETCH_SINGLE_ROOM_TYPE,
    roomType,
});
const roomTypeAddSuccess = () => ({
    type: roomTypesConstants.ADD_ROOM_TYPE,
});
const roomTypeUpdateSuccess = () => ({
    type: roomTypesConstants.UPDATE_ROOM_TYPE,
});
const roomTypesLoading = () => ({
    type: roomTypesConstants.LOADING_ROOM_TYPES,
});
const roomTypesError = (error, actionFunction, actionParams) => {
    return dispatch => {
        dispatch(errorActions.error(error, actionFunction, actionParams, {
            type: roomTypesConstants.ERROR_ROOM_TYPES,
            error,
        }));
    };
};
export const roomTypesActions = {
    loadRoomTypes,
    addRoomType,
    deleteRoomType,
    updateRoomType,
    loadRoomType,
};
