var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { components } from 'react-select';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames/bind';
import debounce from 'awesome-debounce-promise';
import i18n from '../../../../i18n';
import { appConstants } from '../../../../constants';
const getValueProperty = (option, getOptionValue) => {
    return getOptionValue ? getOptionValue(option) : option.value;
};
const DropdownIndicator = props => {
    return (components.DropdownIndicator && (React.createElement(components.DropdownIndicator, Object.assign({}, props),
        React.createElement(FAIcon, { icon: ['fas', 'search'], style: { width: 20 } }))));
};
const AsyncReactSelectExternalValue = (_a) => {
    var { addValue, value, loadOptions, field, form, name, onChange, onChangeSelect, isMulti, getOptionValue, className, components, debounceTime = appConstants.DEFAULT_DEBOUNCE_TIME } = _a, props = __rest(_a, ["addValue", "value", "loadOptions", "field", "form", "name", "onChange", "onChangeSelect", "isMulti", "getOptionValue", "className", "components", "debounceTime"]);
    return (React.createElement(AsyncSelect, Object.assign({ value: value, cacheOptions: true, isClearable: true, classNamePrefix: "rs", components: Object.assign({ DropdownIndicator }, components), onBlur: () => form.setFieldTouched(field.name), loadOptions: debounce(loadOptions, debounceTime), name: field.name, getOptionValue: getOptionValue, isMulti: isMulti, loadingMessage: () => i18n.t('LOADING'), placeholder: i18n.t('SELECT'), noOptionsMessage: () => i18n.t('NO_OPTIONS'), onChange: option => {
            addValue(option);
            form.setFieldValue(field.name, option
                ? isMulti
                    ? option.map(o => getValueProperty(o, getOptionValue))
                    : getValueProperty(option, getOptionValue)
                : '');
            if (onChange)
                onChange(option);
        }, className: classnames('react-select', className) }, props)));
};
export default AsyncReactSelectExternalValue;
