var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userRoomsActions } from '../../../actions';
import { withNamespaces } from 'react-i18next';
import { TitleSection, Search, Table, LoaderContainer, TableFilter, ImageCarousel, Tag, LabelBlock, TableSort, TableTooltip, RoomRowInfo, } from '../../';
import { formatRoute } from 'react-router-named-routes';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { routes, urlDefaultStateConstants, statusConstants, rooms, } from '../../../constants';
import { pathUtils } from '../../../utils';
import { roomPlaceholder, icon_users } from '../../../images';
import ReactHintFactory from 'react-hint';
import i18n from '../../../i18n';
import classnames from 'classnames/bind';
import ReactGa from 'react-ga';
import Button from '../../Common/Button/Button';
import RoomAvailabilityModal from '../../Common/Modal/Room/RoomAvailabilityModal/RoomAvailabilityModal';
import tableFilterType from '../../../enums/tableFilterType.enum';
import { toastError } from '../../../toasts';
import { GoogleLoginWrapper } from '../../Common/GoogleLoginWrapper/GoogleLoginWrapper';
const ReactHint = ReactHintFactory(React);
class UserRooms extends Component {
    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.state = Object.assign(Object.assign({ availabilityRoom: null }, urlDefaultStateConstants), pathUtils.parseUrlParams(props.history.location.search));
    }
    fetchUrlParams() {
        this.setState({ errorLoading: false });
        const props = Object.assign(Object.assign({}, urlDefaultStateConstants), pathUtils.parseUrlParams(this.props.location.search));
        this.fetchData(props);
    }
    componentDidMount() {
        this.fetchUrlParams();
        ReactGa.pageview(rooms, [], `${window.location.host}${rooms}`);
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.fetchUrlParams();
        }
    }
    updateParams(params) {
        const { history } = this.props;
        const url = pathUtils.prepareUrl(pathUtils.updateState(history.location.search, params));
        history.push(`?${url}`);
    }
    fetchData(state) {
        return __awaiter(this, void 0, void 0, function* () {
            const { loadRooms, history, t } = this.props;
            const parsedParams = pathUtils.parseUrlParams(history.location.search);
            const updatedState = Object.assign(Object.assign({}, state), parsedParams);
            const { page, pageSize, sorted, search, filter, buildingIds, roomTypeIds, featureIds, siteCodes, capacityFrom, capacityTo, floorIds, } = updatedState;
            let response = yield loadRooms({
                pageNumber: page,
                pageSize,
                sort: sorted,
                filter,
                search,
                buildingIds,
                roomTypeIds,
                featureIds,
                siteCodes,
                capacityFrom,
                capacityTo,
                floorIds,
            });
            if (response instanceof Error) {
                this.setState({ errorLoading: true });
                toastError(t(response.response.data.reason));
            }
        });
    }
    getColumns() {
        const { t } = this.props;
        return [
            {
                Header: () => (React.createElement(TableSort, { t: t, fields: [
                        { value: 'roomName', label: t('NAME') },
                        { value: 'roomCapacity', label: t('CAPACITY') },
                        { value: 'siteName', label: t('SITE') },
                        { value: 'buildingName', label: t('BUILDING') },
                        { value: 'floorName', label: t('FLOOR') },
                    ], onChange: selected => {
                        this.updateParams({
                            sorted: selected.value
                                ? [
                                    {
                                        id: selected.value,
                                        desc: selected.sort === 'desc',
                                    },
                                ]
                                : [],
                        });
                    } })),
                headerClassName: 'table-sort-header',
                columns: [
                    {
                        width: 190,
                        className: 'user-rooms-image',
                        Cell: cellInfo => {
                            const r = cellInfo.original;
                            return this.renderRoomImages(r);
                        },
                    },
                    {
                        className: 'user-rooms-name',
                        width: 220,
                        Cell: cellInfo => {
                            const r = cellInfo.original;
                            return (React.createElement(Fragment, null,
                                React.createElement(Link, { to: formatRoute(routes.userRoomDetails, {
                                        id: r.roomId,
                                    }), title: r.roomName }, r.roomName),
                                React.createElement("h5", null, r.roomTypeName),
                                React.createElement(Tag, { className: "is-size-6", title: t('CAPACITY'), img: icon_users, text: `max. ${r.roomCapacity}` })));
                        },
                    },
                    {
                        className: 'user-rooms-building',
                        width: 250,
                        Cell: cellInfo => {
                            const r = cellInfo.original;
                            return (React.createElement(Fragment, null,
                                r.buildingLatitude === null ||
                                    r.buildingLongitude === null ? (React.createElement("div", { className: "no-map" },
                                    React.createElement("span", { className: "fa-layers fa-fw" },
                                        React.createElement(FAIcon, { icon: ['fas', 'map-marked-alt'] }),
                                        React.createElement(FAIcon, { icon: ['fas', 'slash'], color: "red" })))) : (React.createElement("img", { alt: "map", src: `https://maps.googleapis.com/maps/api/staticmap?center=${r.buildingLatitude},${r.buildingLongitude}&zoom=14&size=81x81&scale=2&markers=size:small%7Ccolor:0x3e78bd%7C${r.buildingLatitude},${r.buildingLongitude}&key=${process.env.REACT_APP_MAP_API_KEY}` })),
                                React.createElement("div", { className: "building-data" },
                                    React.createElement("p", null,
                                        t('SITE'),
                                        ": ",
                                        r.siteName,
                                        " (",
                                        r.siteCode,
                                        ")"),
                                    React.createElement("p", null,
                                        t('BUILDING'),
                                        ": ",
                                        r.buildingName),
                                    React.createElement("p", null,
                                        t('FLOOR'),
                                        ": ",
                                        r.floorName))));
                        },
                    },
                    {
                        className: 'user-rooms-features',
                        width: 230,
                        Cell: cellInfo => {
                            const r = cellInfo.original;
                            const maxNumberOfDispayedFeatures = 6;
                            const notFittingFeatures = r.roomFeatures.slice(maxNumberOfDispayedFeatures);
                            let show = notFittingFeatures.length > 0 ? 'true' : null;
                            let featuresToRender = r.roomFeatures;
                            if (featuresToRender.length > maxNumberOfDispayedFeatures) {
                                featuresToRender = featuresToRender.slice(0, maxNumberOfDispayedFeatures - 1);
                                featuresToRender.push({ name: '(...)' });
                            }
                            return this.shouldRenderTableTooltip(r) ? (React.createElement("ul", { "data-rh": show, "data-features": r.roomFeatures
                                    .map(feature => feature.name)
                                    .join(', ') }, featuresToRender.map(feature => (React.createElement("li", { key: feature.name }, feature.name))))) : null;
                        },
                    },
                    {
                        className: 'user-rooms-button',
                        minWidth: 220,
                        Cell: cellInfo => {
                            const r = cellInfo.original;
                            return this.getBookingCell(r);
                        },
                    },
                ],
            },
        ];
    }
    renderRoomImages(r) {
        const imageCarousel = (React.createElement(ImageCarousel, { images: [
                r.roomImages.sort(function (a, b) {
                    return a.order - b.order;
                })[0],
            ], imgMaxWidth: 190, imgMaxHeight: 145, imgCrop: true }));
        return r.roomImages.length > 0 ? (React.createElement(TableTooltip, { minWidth: 350, name: `table-tooltip-${r.roomId}`, text: imageCarousel },
            React.createElement(RoomRowInfo, { room: r, isStatic: true }))) : (React.createElement("img", { alt: "Roche logo", src: roomPlaceholder, className: "img-broken" }));
    }
    getBookingCell(r) {
        const { t } = this.props;
        let bookingCell = (React.createElement("div", { className: "private_room" },
            React.createElement("h4", null, t('Not available for booking'))));
        if (!r.calendarId) {
            return bookingCell;
        }
        if (r.privacy && r.googleOwnerVisible) {
            bookingCell = (React.createElement("div", { className: "private_room" },
                React.createElement("h4", null, t('AVIALABILE_ON_DEMAND')),
                React.createElement("p", null,
                    t('PLEASE_CONTACT'),
                    ":"),
                React.createElement("p", null,
                    React.createElement("a", { href: `mailto:${r.googleOwner}` }, r.googleOwner))));
        }
        else if (!r.privacy) {
            bookingCell = (React.createElement(Button, { className: "button is-primary", onClick: () => this.setState({
                    availabilityRoom: r,
                }), icon: React.createElement(FAIcon, { icon: ['far', 'calendar'] }), text: t('CHECK_AVAILABILITY') }));
        }
        return bookingCell;
    }
    shouldRenderTableTooltip(room) {
        return room.roomFeatures.length > 0;
    }
    updateSearch(search) {
        this.setState({ search, errorLoading: false });
        this.updateParams({ search });
    }
    render() {
        const { t, rooms, roomsLoading, totalPages, page, pageSize, totalElements, history, } = this.props;
        const { search, availabilityRoom } = this.state;
        return (React.createElement(Fragment, null,
            React.createElement(TitleSection, null,
                React.createElement(Search, { placeholder: t('SEARCH_PLACEHOLDER_ROOMS'), updateSearch: this.updateSearch.bind(this), defaultValue: search })),
            this.state.errorLoading && React.createElement(GoogleLoginWrapper, null),
            React.createElement(LoaderContainer, { className: "section" },
                React.createElement("div", { className: "container" },
                    React.createElement(TableFilter, { type: tableFilterType.USER_ROOMS, history: history, statusOptions: [
                            {
                                value: statusConstants.NOT_YET_ACTIVE,
                                label: t(statusConstants.NOT_YET_ACTIVE),
                            },
                            {
                                value: statusConstants.ACTIVE,
                                label: t(statusConstants.ACTIVE),
                            },
                            {
                                value: statusConstants.MAINTENANCE,
                                label: t(statusConstants.MAINTENANCE),
                            },
                        ] }),
                    React.createElement(ReactHint, { className: classnames('react-hint', 'box', 'tooltip-box'), position: 'bottom', events: { hover: true }, onRenderContent: target => (React.createElement(LabelBlock, { label: i18n.t('FEATURES') }, target.dataset.features)) }),
                    React.createElement(Table, { resultsData: { page, totalElements, pageSize }, pages: totalPages, data: rooms, pageSize: pageSize, columns: this.getColumns(), loading: roomsLoading, search: search ? search : '', fetchData: this.fetchData, tableChange: this.updateParams.bind(this), sortable: false, className: "User-Rooms-Table" }))),
            React.createElement(RoomAvailabilityModal, { room: availabilityRoom, onClose: () => this.setState({
                    availabilityRoom: null,
                }) })));
    }
}
const mapStateToProps = state => {
    const { userRooms: { rooms, roomsLoading, totalPages, pageNumber, pageSize, totalElements, }, } = state;
    return {
        rooms,
        roomsLoading,
        totalPages,
        page: pageNumber,
        pageSize,
        totalElements,
    };
};
const mapDispatchToProps = dispatch => ({
    loadRooms: conf => dispatch(userRoomsActions.loadUserRooms(conf)),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserRooms)));
