var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { withNamespaces } from 'react-i18next';
import { Accordion, FormControl, StandaloneAsyncReactSelect, StandaloneReactSelect, UserOrGroupSearch, } from '../../';
import api from '../../../api';
import { pathUtils } from '../../../utils';
import { userRoleConstants } from '../../../constants';
import { connect } from 'react-redux';
import { userActions } from '../../../actions';
import Select, { createFilter } from 'react-select';
import { convertToBoolean } from '../../../utils/boolean.utils';
import { DebounceInput } from 'react-debounce-input';
import tableFilterType from '../../../enums/tableFilterType.enum';
import { AdminSearch } from '../UserSearch/UserSearch';
class TableFilter extends Component {
    constructor() {
        super(...arguments);
        this.emptyFormState = {
            siteCodes: [],
            buildingIds: [],
            floorIds: [],
            capacityFrom: null,
            capacityTo: null,
            featureIds: [],
            roomTypeIds: [],
            siteName: [],
            buildingName: [],
            floorName: [],
            featureName: [],
            roomTypeName: [],
            status: null,
            googleOwner: null,
            googleOwnerVisible: null,
            roomOwner: null,
            admin: null,
            contactInformationVisible: null,
            privacy: null,
        };
        this.state = {
            buildings: [],
            floors: [],
            features: [],
            roomTypes: [],
            inputSearch: '',
            filters: Object.assign({}, this.emptyFormState),
        };
        this.onSiteSelected = (site) => __awaiter(this, void 0, void 0, function* () {
            return this.onSelected(site, 'code', 'siteCodes', 'siteName', () => __awaiter(this, void 0, void 0, function* () {
                yield this.clearFields(['buildingIds', 'buildingName']);
                yield this.clearFields(['floorIds', 'floorName']);
                yield this.fetchBuildings(site);
            }));
        });
        this.onBuildingSelected = (building) => __awaiter(this, void 0, void 0, function* () {
            return this.onSelected(building, 'id', 'buildingIds', 'buildingName', () => __awaiter(this, void 0, void 0, function* () {
                yield this.clearFields(['floorIds', 'floorName']);
                yield this.fetchFloors(building);
            }));
        });
        this.onFloorSelected = (floor) => __awaiter(this, void 0, void 0, function* () {
            this.onSelected(floor, 'id', 'floorIds', 'floorName');
        });
        this.onRoomTypesSelected = (type) => __awaiter(this, void 0, void 0, function* () { return this.onSelected(type, 'id', 'roomTypeIds', 'roomTypeName'); });
        this.onFeatureSelected = (feature) => __awaiter(this, void 0, void 0, function* () { return this.onSelected(feature, 'id', 'featureIds', 'featureName'); });
        this.onCapacityFromChange = (e) => __awaiter(this, void 0, void 0, function* () {
            const capacityFrom = e.target.value;
            yield this.updateFiltersState({ capacityFrom: capacityFrom });
            this.updateUrl();
        });
        this.onCapacityToChange = (e) => __awaiter(this, void 0, void 0, function* () {
            const capacityTo = e.target.value;
            yield this.updateFiltersState({ capacityTo: capacityTo });
            this.updateUrl();
        });
        this.onStatusSelected = (status) => __awaiter(this, void 0, void 0, function* () {
            yield this.updateFiltersState({ status: status ? status.value : null });
            this.updateUrl();
        });
        this.onGoogleOwnerChange = (googleOwner) => __awaiter(this, void 0, void 0, function* () {
            yield this.updateFiltersState({ googleOwner: googleOwner });
            this.updateUrl();
        });
        this.onRoomOwnerChange = (roomOwner) => __awaiter(this, void 0, void 0, function* () {
            yield this.updateFiltersState({ roomOwner: roomOwner });
            this.updateUrl();
        });
        this.onAdminChange = (admin) => __awaiter(this, void 0, void 0, function* () {
            yield this.updateFiltersState({ admin: admin });
            this.updateUrl();
        });
        this.onGoogleOwnerVisibleChange = (googleOwnerVisible) => __awaiter(this, void 0, void 0, function* () {
            yield this.updateFiltersState({
                googleOwnerVisible: googleOwnerVisible ? googleOwnerVisible.value : null,
            });
            this.updateUrl();
        });
        this.onContactInformationVisible = (contactInformationVisible) => __awaiter(this, void 0, void 0, function* () {
            yield this.updateFiltersState({
                contactInformationVisible: contactInformationVisible
                    ? contactInformationVisible.value
                    : null,
            });
            this.updateUrl();
        });
        this.onPrivacyChange = (privacy) => __awaiter(this, void 0, void 0, function* () {
            yield this.updateFiltersState({
                privacy: privacy ? privacy.value : null,
            });
            this.updateUrl();
        });
        this.fetchFloors = (building) => __awaiter(this, void 0, void 0, function* () {
            const buildingIds = building || this.state.filters.buildingIds;
            let result = [];
            if (buildingIds.length > 0) {
                for (let s = 0; s < buildingIds.length; s++) {
                    const buildingId = typeof buildingIds[s] === 'object'
                        ? buildingIds[s].id
                        : buildingIds[s];
                    const res = yield api.Buildings.get(buildingId);
                    if (res && res.data) {
                        let floors = res.data.floors;
                        floors.forEach(floor => {
                            floor.name += ` (${res.data.googleName})`;
                            return floor;
                        });
                        result = [...result, ...floors];
                    }
                }
                this.setState({
                    floors: result,
                });
                return result;
            }
        });
        this.fetchBuildings = (site) => __awaiter(this, void 0, void 0, function* () {
            const siteCodes = site || this.state.filters.siteCodes;
            let result = [];
            if (siteCodes.length > 0) {
                for (let s = 0; s < siteCodes.length; s++) {
                    const code = typeof siteCodes[s] === 'object' ? siteCodes[s].code : siteCodes[s];
                    const siteName = typeof siteCodes[s] === 'object' ? siteCodes[s].name : '';
                    const res = yield api.Buildings.getAllInSite(code);
                    if (res && res.data) {
                        let buildings = res.data;
                        buildings.forEach(building => {
                            building.name += ` - ${siteName} (${code})`;
                            return building;
                        });
                        result = [...result, ...buildings];
                    }
                }
                this.setState({
                    buildings: result,
                });
                return result;
            }
        });
        this.fetchSites = (searchQuery = '') => __awaiter(this, void 0, void 0, function* () {
            if (!searchQuery && this.isSiteAdmin()) {
                return this.props.sites;
            }
            const res = yield api.Sites.search(searchQuery, false);
            return res.data;
        });
        this.fetchRoomTypes = (searchQuery = '') => __awaiter(this, void 0, void 0, function* () {
            const res = yield api.RoomTypes.search(searchQuery);
            return res.data;
        });
        this.fetchFeatures = (searchQuery = '') => __awaiter(this, void 0, void 0, function* () {
            const res = yield api.Features.search(searchQuery);
            return res.data;
        });
        this.clearFields = (fields) => __awaiter(this, void 0, void 0, function* () {
            const cleanFields = {};
            fields.forEach(field => (cleanFields[field] = []));
            this.updateFiltersState(cleanFields);
        });
    }
    updateFormState() {
        const { history, filters } = this.props;
        const search = history.location.search;
        const page = `filters${history.location.pathname}`;
        if (!search && filters && filters[page]) {
            const notYetSyncedState = this.setFormState(filters[page]);
            if (this.state.filters)
                this.updateUrl(notYetSyncedState);
        }
        else {
            this.setFormState(search);
        }
    }
    setFormState(source) {
        const { siteCodes, buildingIds, floorIds, capacityFrom, capacityTo, featureIds, roomTypeIds, siteName, buildingName, floorName, featureName, roomTypeName, status, googleOwner, googleOwnerVisible, roomOwner, admin, contactInformationVisible, privacy, } = pathUtils.parseUrlParams(source);
        this.emptyFormState = {
            siteCodes: siteCodes ? siteCodes : [],
            buildingIds: buildingIds ? buildingIds.map(id => parseInt(id, 10)) : [],
            floorIds: floorIds ? floorIds.map(id => parseInt(id, 10)) : [],
            capacityFrom: capacityFrom ? capacityFrom : null,
            capacityTo: capacityTo ? capacityTo : null,
            featureIds: featureIds ? featureIds.map(id => parseInt(id, 10)) : [],
            roomTypeIds: roomTypeIds ? roomTypeIds.map(id => parseInt(id, 10)) : [],
            siteName: siteName ? siteName : [],
            buildingName: buildingName ? buildingName : [],
            floorName: floorName ? floorName : [],
            featureName: featureName ? featureName : [],
            roomTypeName: roomTypeName ? roomTypeName : [],
            status: status ? status : null,
            googleOwner: googleOwner ? googleOwner : null,
            googleOwnerVisible: googleOwnerVisible ? googleOwnerVisible : null,
            roomOwner: roomOwner ? roomOwner : null,
            admin: admin ? admin : null,
            contactInformationVisible: contactInformationVisible
                ? contactInformationVisible
                : null,
            privacy: privacy ? privacy : null,
        };
        this.updateFiltersState(this.emptyFormState);
        return this.emptyFormState;
    }
    componentWillMount() {
        this.updateFormState();
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.prepareSiteAdminFilters();
            yield this.fetchBuildings();
            yield this.fetchFloors();
            this.setState({
                roomTypes: Object.values(yield this.fetchRoomTypes()),
            });
        });
    }
    componentDidUpdate(prevState) {
        return __awaiter(this, void 0, void 0, function* () {
            if (prevState.sites.length !== this.props.sites.length) {
                yield this.prepareSiteAdminFilters();
            }
        });
    }
    prepareSiteAdminFilters() {
        return __awaiter(this, void 0, void 0, function* () {
            const keys = Object.keys(this.emptyFormState);
            for (const key of keys) {
                if (Array.isArray(this.emptyFormState[key]) &&
                    this.emptyFormState[key].length > 0)
                    return false;
            }
            const { sites, filters, history } = this.props;
            if (this.isSiteAdmin() &&
                (!filters || !filters[`filters${history.location.pathname}`])) {
                yield this.updateFiltersState({
                    siteCodes: sites.map(site => site.code),
                    siteName: sites.map(site => site.name),
                });
                this.updateUrl();
                this.fetchBuildings();
            }
        });
    }
    onSelected(field, idParam, idsArray, namesArray, affterFn) {
        return __awaiter(this, void 0, void 0, function* () {
            if (field.length > 0) {
                yield this.updateFiltersState({
                    [idsArray]: field.map(o => o[idParam]),
                    [namesArray]: field.map(f => f.name),
                });
            }
            else {
                yield this.clearFields([idsArray, namesArray]);
            }
            if (affterFn)
                yield affterFn();
            this.updateUrl();
        });
    }
    onChange(field, idParam, idsArray, namesArray, affterFn) {
        return __awaiter(this, void 0, void 0, function* () {
            if (field.length > 0) {
                yield this.updateFiltersState({
                    [idsArray]: field.map(o => o[idParam]),
                    [namesArray]: field.map(f => f.name),
                });
            }
            else {
                yield this.clearFields([idsArray, namesArray]);
            }
            if (affterFn)
                yield affterFn();
            this.updateUrl();
        });
    }
    updateFiltersState(filters) {
        this.setState(prevState => ({
            filters: Object.assign(Object.assign({}, prevState.filters), filters),
        }));
    }
    isSiteAdmin() {
        const { role } = this.props;
        return role === userRoleConstants.SITE_ADMIN;
    }
    isAdmin() {
        const { role } = this.props;
        return (role === userRoleConstants.SITE_ADMIN || role === userRoleConstants.ADMIN);
    }
    isUserView() {
        return this.props.isUserView;
    }
    updateUrl(notYetSyncedState) {
        const { history, updateFilters, fetchWithDataPrepared } = this.props;
        const values = notYetSyncedState ? notYetSyncedState : this.state.filters;
        const parsedParams = pathUtils.parseUrlParams(history.location.search);
        const updatedState = Object.assign(Object.assign({}, parsedParams), values);
        if (fetchWithDataPrepared) {
            fetchWithDataPrepared(updatedState, true);
        }
        Object.keys(updatedState).forEach(field => {
            if (this.shouldNotApplyFilter(updatedState, field)) {
                delete updatedState[field];
            }
        });
        const url = pathUtils.prepareUrl(updatedState);
        updateFilters(`filters${history.location.pathname}`, url);
        history.push(`?${url}`);
    }
    shouldNotApplyFilter(updatedState, field) {
        return ((typeof updatedState[field] !== 'boolean' && !updatedState[field]) ||
            updatedState[field].length === 0);
    }
    isOpen() {
        const { siteCodes, siteName, buildingIds, buildingName, roomTypeIds, roomTypeName, featureIds, featureName, status, } = this.emptyFormState;
        return ((siteCodes.length > 0 && siteName.length > 0) ||
            (buildingIds.length > 0 && buildingName.length > 0) ||
            (roomTypeIds.length > 0 && roomTypeName.length > 0) ||
            (featureIds.length > 0 && featureName.length > 0) ||
            status !== null);
    }
    mapDefaultValues(idName, idsArray, namesArray) {
        return idsArray.length > 0
            ? idsArray.map((id, i) => ({ [idName]: id, name: namesArray[i] }))
            : [];
    }
    getDefaultValues() {
        const { siteCodes, siteName, buildingIds, buildingName, floorIds, floorName, roomTypeIds, roomTypeName, featureIds, featureName, status, privacy, contactInformationVisible, googleOwnerVisible, roomOwner, googleOwner, admin, } = this.state.filters;
        const { privacyOptions, visibilityOptions } = this.props;
        const defaultSite = this.mapDefaultValues('code', siteCodes, siteName);
        const defaultBuilding = this.mapDefaultValues('id', buildingIds, buildingName);
        const defaultFloor = this.mapDefaultValues('id', floorIds, floorName);
        const defaultRoomType = this.mapDefaultValues('id', roomTypeIds, roomTypeName);
        const defaultFeature = this.mapDefaultValues('id', featureIds, featureName);
        const defaultStatus = status
            ? { label: this.props.t(status), value: status }
            : null;
        const defaultPrivacy = this.getDefaultLabelForBoolean(privacyOptions, privacy);
        const defaultContactInformationVisible = this.getDefaultLabelForBoolean(visibilityOptions, contactInformationVisible);
        const defaultGoogleOwnerVisible = this.getDefaultLabelForBoolean(visibilityOptions, googleOwnerVisible);
        const defaultRoomOwner = roomOwner ? { email: roomOwner } : null;
        const defaultGoogleOwner = googleOwner ? { email: googleOwner } : null;
        const defaultAdmin = admin ? { email: admin } : null;
        return {
            defaultSite,
            defaultBuilding,
            defaultRoomType,
            defaultFeature,
            defaultStatus,
            defaultFloor,
            defaultPrivacy,
            defaultContactInformationVisible,
            defaultGoogleOwnerVisible,
            defaultRoomOwner,
            defaultGoogleOwner,
            defaultAdmin,
        };
    }
    getDefaultLabelForBoolean(arrOptions, value) {
        const val = convertToBoolean(value);
        let defaultObj;
        if (typeof val === 'boolean') {
            defaultObj = arrOptions.find(option => {
                return option.value === val;
            });
            return defaultObj;
        }
        return null;
    }
    render() {
        const { t, statusOptions, privacyOptions, visibilityOptions, type, } = this.props;
        const { filters } = this.state;
        const { defaultSite, defaultBuilding, defaultRoomType, defaultFeature, defaultStatus, defaultFloor, defaultPrivacy, defaultContactInformationVisible, defaultGoogleOwnerVisible, defaultRoomOwner, defaultGoogleOwner, defaultAdmin, } = this.getDefaultValues();
        const statusFilter = (React.createElement("div", { className: "column is-4" },
            React.createElement(FormControl, { label: t('STATUS') },
                React.createElement(Select, { instanceId: "STATUS_SELECT", classNamePrefix: "table-filter-select", placeholder: t('SELECT_STATUS'), isClearable: true, onChange: this.onStatusSelected, defaultValue: defaultStatus, options: statusOptions }))));
        return (React.createElement(Accordion, { textShow: t('SHOW_FILTERS'), textHide: t('HIDE_FILTERS'), isOpen: this.isOpen() },
            React.createElement("div", { className: "notification table-filters" },
                React.createElement("div", { className: "columns" },
                    React.createElement("div", { className: "column is-4" },
                        React.createElement(FormControl, { label: t('SITE') },
                            React.createElement(StandaloneAsyncReactSelect, { instanceId: "SITE_SELECT", name: "siteCodes", defaultOptions: true, placeholder: t('SEARCH_SITE'), getOptionLabel: e => `${e.name} (${e.code})`, getOptionValue: e => e.code, onInputChange: inputSearch => this.setState({ inputSearch }), onChange: this.onSiteSelected, loadOptions: this.fetchSites, defaultValue: defaultSite, isClearable: true, isMulti: true, noOptionsMessage: () => this.state.inputSearch === ''
                                    ? t('START_TYPING_TO_SEARCH')
                                    : t('NO_OPTIONS') }))),
                    type === tableFilterType.ADMINISTRATORS && (React.createElement("div", { className: "column is-4" },
                        React.createElement(FormControl, { className: "grid-align-bottom", label: t('ADMIN') },
                            React.createElement(AdminSearch, { instanceId: "ADMIN_SELECT", defaultValue: defaultAdmin, placeholder: t('SEARCH_SITE_ADMIN'), name: "siteAdmin", onChange: this.onAdminChange })))),
                    (type === tableFilterType.ROOMS ||
                        type === tableFilterType.USER_ROOMS ||
                        type === tableFilterType.ROOMS_MAINTENANCES) && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "column is-4" },
                            React.createElement(FormControl, { label: t('BUILDING') },
                                React.createElement(StandaloneReactSelect, { instanceId: "BUILDING_SELECT", filterOption: this.filterByLabel(), getOptionLabel: e => e.name, getOptionValue: e => e.id, options: this.state.buildings, onChange: this.onBuildingSelected, placeholder: filters.siteCodes.length === 0
                                        ? t('SEARCH_SITE_TO_SELECT_BUILDING')
                                        : t('SELECT_BUILDING'), isDisabled: filters.siteCodes.length === 0, value: defaultBuilding, isClearable: true, isMulti: true }))),
                        React.createElement("div", { className: "column is-4" },
                            React.createElement(FormControl, { label: t('FLOOR') },
                                React.createElement(StandaloneReactSelect, { instanceId: "FLOOR_SELECT", filterOption: this.filterByLabel(), getOptionLabel: e => e.name, getOptionValue: e => e.id, options: this.state.floors, onChange: this.onFloorSelected, placeholder: filters.buildingIds.length === 0
                                        ? t('SEARCH_BUILDING_TO_SELECT_FLOOR')
                                        : t('SELECT_FLOOR'), isDisabled: filters.buildingIds.length === 0, value: defaultFloor, isClearable: true, isMulti: true }))))),
                    type === tableFilterType.BUILDINGS && statusFilter),
                (type === tableFilterType.ROOMS ||
                    type === tableFilterType.USER_ROOMS) && (React.createElement("div", { className: "columns" },
                    React.createElement("div", { className: "column is-4" },
                        React.createElement(FormControl, { label: t('ROOM_TYPE') },
                            React.createElement(StandaloneReactSelect, { filterOption: this.filterByLabel(), instanceId: "ROOM_TYPE_SELECT", placeholder: t('SELECT_ROOM_TYPE'), getOptionLabel: e => e.name, getOptionValue: e => e.id, options: Object.values(this.state.roomTypes), onChange: this.onRoomTypesSelected, value: defaultRoomType, isClearable: true, isMulti: true }))),
                    React.createElement("div", { className: "column is-4" },
                        React.createElement(FormControl, { label: t('FEATURES') },
                            React.createElement(StandaloneAsyncReactSelect, { instanceId: "FEATURES_SELECT", placeholder: t('SEARCH_FEATURE'), onInputChange: inputSearch => this.setState({ inputSearch }), getOptionLabel: e => e.name, getOptionValue: e => e.id, loadOptions: this.fetchFeatures, onChange: this.onFeatureSelected, defaultValue: defaultFeature, isClearable: true, isMulti: true, noOptionsMessage: () => this.state.inputSearch === ''
                                    ? t('START_TYPING_TO_SEARCH')
                                    : t('NO_OPTIONS') }))),
                    React.createElement("div", { className: "column is-2" },
                        React.createElement(FormControl, { label: t('CAPACITY_FROM') },
                            React.createElement(DebounceInput, { debounceTimeout: 900, class: "input", value: filters.capacityFrom, placeholder: t('ENTER_CAPACITY'), type: "number", onChange: this.onCapacityFromChange }))),
                    React.createElement("div", { className: "column is-2" },
                        React.createElement(FormControl, { label: t('CAPACITY_TO') },
                            React.createElement(DebounceInput, { debounceTimeout: 900, className: "input", value: filters.capacityTo, placeholder: t('ENTER_CAPACITY'), type: "number", onChange: this.onCapacityToChange }))))),
                this.isAdmin() &&
                    !this.isUserView() &&
                    type === tableFilterType.ROOMS && (React.createElement(Fragment, null,
                    React.createElement("div", { className: "columns" },
                        statusFilter,
                        React.createElement("div", { className: "column is-4" },
                            React.createElement(FormControl, { className: "grid-align-bottom", label: t('GOOGLE_OWNER') },
                                React.createElement(UserOrGroupSearch, { instanceId: "GOOGLE_OWNER_SELECT", defaultValue: defaultGoogleOwner, placeholder: t('SEARCH_GOOGLE_OWNER'), name: "googleOwner", onChange: this.onGoogleOwnerChange }))),
                        React.createElement("div", { className: "column is-4" },
                            React.createElement(FormControl, { className: "grid-align-bottom", label: t('VISIBILITY_GOOGLE_OWNER') },
                                React.createElement(Select, { filterOption: this.filterByLabel(), instanceId: "VISIBILITY_GOOGLE_OWNER_SELECT", classNamePrefix: "table-filter-select", placeholder: t('SELECT_VISIBILITY_STATUS'), isClearable: true, onChange: this.onGoogleOwnerVisibleChange, options: visibilityOptions, defaultValue: defaultGoogleOwnerVisible })))),
                    React.createElement("div", { className: "columns" },
                        React.createElement("div", { className: "column is-4" },
                            React.createElement(FormControl, { className: "grid-align-bottom", label: t('ROOM_OWNER') },
                                React.createElement(UserOrGroupSearch, { instanceId: "ROOM_OWNER_SELECT", defaultValue: defaultRoomOwner, placeholder: t('SEARCH_ROOM_OWNER'), name: "roomOwner", onChange: this.onRoomOwnerChange }))),
                        React.createElement("div", { className: "column is-4" },
                            React.createElement(FormControl, { className: "grid-align-bottom", label: t('VISIBILITY_SUPPORT_CONTACT') },
                                React.createElement(Select, { filterOption: this.filterByLabel(), instanceId: "SELECT_VISIBILITY_STATUS_SELECT", classNamePrefix: "table-filter-select", placeholder: t('SELECT_VISIBILITY_STATUS'), isClearable: true, onChange: this.onContactInformationVisible, defaultValue: defaultContactInformationVisible, options: visibilityOptions }))),
                        React.createElement("div", { className: "column is-4" },
                            React.createElement(FormControl, { className: "grid-align-bottom", label: t('BOOKING_METHOD') },
                                React.createElement(Select, { filterOption: this.filterByLabel(), instanceId: "BOOKING_METHOD_SELECT", classNamePrefix: "table-filter-select", placeholder: t('SELECT_BOOKING_PRIVACY_METHOD'), isClearable: true, onChange: this.onPrivacyChange, defaultValue: defaultPrivacy, options: privacyOptions })))))))));
    }
    filterByLabel() {
        return createFilter({
            matchFrom: 'any',
            stringify: option => `${option.label}`,
        });
    }
}
TableFilter.defaultProps = {
    role: userRoleConstants.USER,
    sites: [],
};
const mapStateToProps = state => {
    const { user: { filters, sites, role, isUserView }, } = state;
    return {
        filters,
        sites,
        role,
        isUserView,
    };
};
const mapDispatchToProps = dispatch => ({
    updateFilters: (page, filters) => dispatch(userActions.updateUserFilters(page, filters)),
});
export default withNamespaces('translations')(connect(mapStateToProps, mapDispatchToProps)(TableFilter));
