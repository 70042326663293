var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import classnames from 'classnames/bind';
const FormControl = (_a) => {
    var { label, children, required, t, className } = _a, props = __rest(_a, ["label", "children", "required", "t", "className"]);
    return (React.createElement("div", Object.assign({ className: classnames('field', className) }, props),
        label && (React.createElement("label", { className: "label" },
            label,
            required ? React.createElement("span", { className: "required-indicator" }, "*") : '')),
        React.createElement("div", { className: "control" }, children)));
};
export default FormControl;
