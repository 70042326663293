var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import * as moment from 'moment-timezone';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import React, { Component, Fragment } from 'react';
import { Trans, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { Accordion, Auditing, BackToList, ConfirmModal, DetailsViewButtons, FormSection, LabelBlock, LoaderContainer, MediaTabs, RoomDetailsIconPanel, TitleSection, } from '../';
import { roomsActions, roomsMaintenancesActions, userSearchActions, } from '../../actions';
import { appConstants, errorConstants, errorTypesConstants as err, rooms, routes, statusConstants, userRoleConstants, } from '../../constants';
import { languages } from '../../enums';
import { paginateArray, areDateTimeRangesNotOverlapped } from '../../utils';
import withErrorHandling from '../Common/WithErrorHandling/withErrorHandling';
import { toastError } from '../../toasts';
import i18n from '../../i18n';
import ReactGa from 'react-ga';
import MaintenanceModal from '../Common/Modal/Room/MaintenanceModal/MaintenanceModal';
import DeleteRoomModal from '../Common/Modal/Room/DeleteRoomModal/DeleteRoomModal';
import DeletedRoomInfoModal from '../Common/Modal/Room/DeletedRoomInfoModal/DeletedRoomInfoModal';
import RestoreRoomModal from '../Common/Modal/Room/RestoreRoomModal/RestoreRoomModal';
import RestoredRoomInfoModal from '../Common/Modal/Room/RestoredRoomInfoModal/RestoredRoomInfoModal';
import RoomDetailsMaintenancesList from './RoomDetailsMaintenance/RoomDetailsMaintenances';
import MaintenanceOverlappedModal from '../Common/Modal/Room/MaintenanceOverlappedModal/MaintenanceOverlappedModal';
import { dateTextFormatter } from '../../utils/textFormatter.utils';
import { isRoomAdmin } from '../../utils/admin.utils';
import RoomAvailabilityModal from '../Common/Modal/Room/RoomAvailabilityModal/RoomAvailabilityModal';
import Button from '../Common/Button/Button';
class RoomDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmDeleteModal: false,
            showRestoreRoomModal: false,
            showEditModal: false,
            showItemDeletedModal: false,
            showItemRestoredModal: false,
            showMaintenanceModal: false,
            editMaintenance: null,
            showMaintenanceCancelModal: false,
            showMaintenanceOverlappedModal: false,
            availabilityRoom: null,
            isReady: false,
            userNames: [],
            startDate: null,
            endDate: null,
        };
        const id = props.match.params.id;
        if (!isNaN(id)) {
            this.id = parseInt(id);
        }
        moment.locale(i18n.language);
        this.maintenanceChangeHandler = this.maintenanceChangeHandler.bind(this);
        this.deleteHandler = this.deleteHandler.bind(this);
        this.restoreHandler = this.restoreHandler.bind(this);
        this.editMaintenanceHandler = this.editMaintenanceHandler.bind(this);
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            const { loadRoom, adminView, location } = this.props;
            if (location.pathname.split('/')[1] !== 'admin') {
                ReactGa.pageview(`${rooms}/details`, [], `${window.location.host}${rooms}/details`);
            }
            try {
                let loadedRoom = yield loadRoom({ id: this.id });
                if (adminView && this.isRoomAdmin(loadedRoom)) {
                    this.fetchAuditlog(this.id);
                }
                this.fetchUsersFullNames(loadedRoom);
                this.setState({ isReady: true });
            }
            catch (error) {
                const { t, history, handleError404, roomsError } = this.props;
                if (roomsError && roomsError.type === err.LOAD_RESOURCE) {
                    handleError404({
                        text: {
                            msg: t('ERROR_404_ROOM'),
                            email: appConstants.DEFAULT_CONTACT_EMAIL,
                        },
                        backLinkPath: adminView ? this.buildRoomsListUrl() : routes.userRooms,
                    });
                }
                else {
                    history.push(adminView ? this.buildRoomsListUrl() : routes.userRooms);
                }
            }
        });
    }
    fetchAuditlog(roomId, cursor) {
        const { loadRoomAuditlog } = this.props;
        loadRoomAuditlog({ id: roomId, cursor });
    }
    fetchUsersFullNames(loadedRoom) {
        const { auditing } = loadedRoom;
        const auditingEmails = [];
        if (auditing) {
            auditingEmails.push(auditing.createdBy);
            auditingEmails.push(auditing.modifiedBy);
        }
        userSearchActions
            .fetchUserNamesByEmails([
            loadedRoom.googleOwner,
            loadedRoom.owner,
            ...auditingEmails,
        ])
            .then(names => this.setState({ userNames: names }));
    }
    renderFeatureListItem(feature, i) {
        return (React.createElement("li", { key: i },
            React.createElement("span", { className: "fa-li" },
                React.createElement(FAIcon, { icon: ['fas', 'check'] })),
            `${feature.category} ${this.getFeatureName(feature)}`));
    }
    getFeatureName(feature) {
        return feature.name.toUpperCase() !== feature.category.toUpperCase()
            ? `(${feature.name})`
            : '';
    }
    renderFeatureList(room) {
        if (room.features.length === 0)
            return '-';
        let features = paginateArray(room.features, Math.max(3, Math.ceil(room.features.length / 3)));
        return (React.createElement("div", { className: "columns is-multiline" }, features.map((page, i) => (React.createElement("div", { className: "column is-4", key: i },
            React.createElement("ul", { className: "fa-ul" }, page.map((feature, i) => this.renderFeatureListItem(feature, i))))))));
    }
    getUserFullName(email) {
        let user = this.state.userNames.find(e => e.email === email);
        if (user) {
            return user.fullName;
        }
    }
    renderButtons() {
        const { room, history } = this.props;
        if (this.isRoomAdmin(room))
            return (React.createElement(DetailsViewButtons, { roomDetailsViewData: room, editProps: {
                    onClick: () => this.setState({
                        showEditModal: true,
                    }),
                }, activateProps: {
                    onClick: this.activateRoom.bind(this),
                }, restoreRoomProps: {
                    onClick: () => this.setState({
                        showRestoreRoomModal: true,
                    }),
                }, deleteProps: {
                    onClick: () => this.setState({
                        showConfirmDeleteModal: true,
                    }),
                }, maintenanceProps: {
                    onClick: () => this.setState({
                        showMaintenanceModal: true,
                    }),
                }, duplicateProps: {
                    onClick: () => history.push(formatRoute(routes.roomDuplicate, {
                        id: room.id,
                    })),
                } }));
    }
    activateRoom() {
        this.props.activateRoom(this.props.room);
    }
    isRoomAdmin(room) {
        const { SITE_ADMIN } = userRoleConstants;
        const { role, sites } = this.props;
        const adminSites = sites.map(site => `${site.name} (${site.code})`);
        const { siteName, siteCode } = room;
        return (this.isGlobalAdmin() ||
            (role === SITE_ADMIN && adminSites.includes(`${siteName} (${siteCode})`)));
    }
    isGlobalAdmin() {
        const { ADMIN } = userRoleConstants;
        const { role } = this.props;
        return role === ADMIN;
    }
    maintenanceChangeHandler(data) {
        const { editMaintenance } = this.state;
        if (editMaintenance) {
            const maintenanceData = Object.assign(Object.assign({}, data), { id: editMaintenance.id, version: editMaintenance.version });
            this.patchMaintenance(maintenanceData);
        }
        else {
            this.saveMaintenance(data);
        }
    }
    saveMaintenance(data) {
        let overlappedMaintenances = this.getOverlappedMaintenances(data);
        if (overlappedMaintenances.length > 0) {
            this.setState({ showMaintenanceOverlappedModal: true });
        }
        else {
            this.createMaintenance(data);
        }
    }
    createMaintenance(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const { room, createMaintenance, t } = this.props;
            const response = yield createMaintenance(this.id, Object.assign({ version: room.version }, data));
            if (response instanceof Error) {
                toastError(t(response.response.data.reason));
            }
        });
    }
    patchMaintenance(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const { patchMaintenance, room, t } = this.props;
            const response = yield patchMaintenance(room.id, data);
            if (response instanceof Error) {
                toastError(t(response.response.data.reason));
            }
            else {
                this.setState({ editMaintenance: null });
            }
        });
    }
    getOverlappedMaintenances(data) {
        const { room } = this.props;
        let overlappedMaintenances = [];
        if (room.maintenances.length > 0) {
            overlappedMaintenances = room.maintenances.filter(m => {
                return !areDateTimeRangesNotOverlapped(m.from, m.to, data.startDate, data.endDate);
            });
        }
        return overlappedMaintenances;
    }
    editMaintenanceHandler(row) {
        this.setState({ editMaintenance: row, showMaintenanceModal: true });
    }
    deleteHandler() {
        return __awaiter(this, void 0, void 0, function* () {
            const { t, deleteRoom, room, history } = this.props;
            const deletedRoom = yield deleteRoom(room.id, { pageNumber: 0 });
            if (!(deletedRoom instanceof Error)) {
                this.setState({ showItemDeletedModal: true });
            }
            else if (deletedRoom.response.data.reason === errorConstants.ROOM_NOT_FOUND_DELETE) {
                history.push({
                    pathname: formatRoute(this.buildRoomsListUrl()),
                    state: { errorModal: deletedRoom.response.data.reason },
                });
            }
            else {
                toastError(t(deletedRoom.response.data.reason));
            }
        });
    }
    restoreHandler() {
        return __awaiter(this, void 0, void 0, function* () {
            const { t, restoreRoom, room } = this.props;
            const restoredRoom = yield restoreRoom(room.id);
            if (!(restoredRoom instanceof Error)) {
                this.setState({ showItemRestoredModal: true });
            }
            else {
                toastError(t(restoredRoom.response.data.reason));
            }
        });
    }
    renderModals() {
        const { t, room, history } = this.props;
        const { showConfirmDeleteModal, showRestoreRoomModal, showItemRestoredModal, showEditModal, showItemDeletedModal, showMaintenanceModal, showMaintenanceOverlappedModal, editMaintenance, } = this.state;
        return (React.createElement(Fragment, null,
            React.createElement(RestoreRoomModal, { names: room.name.split(), isOpen: showRestoreRoomModal, onClose: () => this.setState({ showRestoreRoomModal: false }), onRestore: this.restoreHandler }),
            React.createElement(ConfirmModal, { title: t('EDIT'), text: t('MODAL_EDIT_ROOM_INFO', {
                    name: room.name,
                }), onClose: () => this.setState({ showEditModal: false }), isOpen: showEditModal, primaryButton: {
                    label: t('EDIT'),
                    type: 'is-primary',
                    action: onClose => {
                        history.push(formatRoute(routes.roomEdit, {
                            id: room.id,
                        }));
                        onClose();
                    },
                    icon: React.createElement(FAIcon, { icon: ['far', 'edit'] }),
                } }),
            React.createElement(DeleteRoomModal, { roomsNames: room.name ? room.name.split() : [], isOpen: showConfirmDeleteModal, onClose: () => this.setState({ showConfirmDeleteModal: false }), onDelete: this.deleteHandler }),
            React.createElement(DeletedRoomInfoModal, { name: room.name, isOpen: showItemDeletedModal, onClose: () => this.setState({ showItemDeletedModal: false }), onConfirm: () => this.props.history.push(this.buildRoomsListUrl()) }),
            React.createElement(RestoredRoomInfoModal, { name: room.name, isOpen: showItemRestoredModal, onClose: () => {
                    this.setState({ showItemRestoredModal: false });
                    history.push(this.buildRoomsListUrl());
                } }),
            React.createElement(MaintenanceModal, { isOpen: showMaintenanceModal, onSet: this.maintenanceChangeHandler, onClose: data => {
                    if (data && data.preserveEdit === true) {
                        this.setState({
                            showMaintenanceModal: false,
                        });
                    }
                    else {
                        this.setState({
                            showMaintenanceModal: false,
                            editMaintenance: null,
                        });
                    }
                }, roomIds: [room.id], editMaintenance: editMaintenance }),
            React.createElement(MaintenanceOverlappedModal, { isOpen: showMaintenanceOverlappedModal, onClose: () => this.setState({ showMaintenanceOverlappedModal: false }) })));
    }
    renderAvailabilityModal() {
        return (React.createElement(RoomAvailabilityModal, { room: this.state.availabilityRoom, onClose: () => this.setState({
                availabilityRoom: null,
            }) }));
    }
    buildRoomsListUrl() {
        return routes.rooms + this.props.listParams;
    }
    render() {
        const { t, room, roomAuditlog, role, lng, adminView } = this.props;
        const { isReady } = this.state;
        if (isReady) {
            const { auditing } = room;
            if (adminView && auditing) {
                auditing.createdByName = this.getUserFullName(auditing.createdBy);
                auditing.modifiedByName = this.getUserFullName(auditing.modifiedBy);
            }
        }
        return (React.createElement(LoaderContainer, null, isReady && (React.createElement(Fragment, null,
            React.createElement(TitleSection, { title: room.name, small: !adminView &&
                    role !== userRoleConstants.USER &&
                    t('TITLE_USER_VIEW') },
                React.createElement(RoomDetailsIconPanel, { room: room })),
            React.createElement("section", { className: "section room-details details" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "header-panel" },
                        React.createElement("div", { className: "buttons" },
                            React.createElement(BackToList, { to: adminView ? this.buildRoomsListUrl() : routes.userRooms, t: t }),
                            adminView && this.renderButtons())),
                    React.createElement(FormSection, null,
                        room.googleName && (React.createElement(LabelBlock, { label: t('FULL_NAME') }, room.googleName)),
                        React.createElement("div", { className: "columns" },
                            React.createElement("div", { className: "column" }, room.status === statusConstants.PENDING_DELETION &&
                                room.expectedDeletionDate && (React.createElement("p", { className: "has-text-danger has-text-weight-bold" }, `${t('THE_ROOM_WILL_BE_DELETED_ON')}: ${dateTextFormatter(room.expectedDeletionDate)}`)))),
                        React.createElement(RoomDetailsMaintenancesList, { room: room, isRoomAdmin: this.isRoomAdmin(room), editMaintenance: this.editMaintenanceHandler }),
                        React.createElement("div", { className: "columns" },
                            React.createElement("div", { className: "column" },
                                room.localDescription && (React.createElement(LabelBlock, { label: t('DESCRIPTION') },
                                    room.localDescription,
                                    room.translations && room.translations.length > 0 && (React.createElement(Accordion, { textShow: t('SHOW_TRANSLATIONS'), textHide: t('HIDE_TRANSLATIONS') }, room.translations.map((e, index) => {
                                        let langName = languages[e.locale]
                                            ? ` (${languages[e.locale].name})`
                                            : '';
                                        return (React.createElement(LabelBlock, { key: index, label: `${t('DESCRIPTION')}${langName}`, className: "m-bottom-1" }, e.description));
                                    }))))),
                                this.renderBookingDetails(),
                                room.catering && (React.createElement(LabelBlock, { label: t('CATERING') },
                                    React.createElement(Trans, { defaults: "CATERING_REQUEST_DETAILS", components: [
                                            React.createElement("a", { href: room.catering, target: "_blank", rel: "noopener noreferrer" }, room.catering),
                                        ] }))),
                                room.contactInformation &&
                                    room.contactInformationVisible && (React.createElement(LabelBlock, { label: t('SUPPORT_CONTACT') }, room.contactInformation)),
                                room.owner && (React.createElement(LabelBlock, { label: t('ROOM_OWNER') },
                                    React.createElement(Fragment, null,
                                        React.createElement("a", { href: `mailto:${room.owner}` }, this.getUserFullName(room.owner) || room.owner))))),
                            React.createElement("div", { className: "column is-5" },
                                React.createElement(MediaTabs, { className: "has-text-centered", images: room.images.sort(function (a, b) {
                                        return a.order - b.order;
                                    }), imgMaxWidth: 455, imgMaxHeight: 400, coordinates: room.coordinates }))),
                        room.features.length !== 0 && (React.createElement(LabelBlock, { label: t('FEATURES'), className: "features" }, this.renderFeatureList(room))),
                        adminView && (React.createElement(LabelBlock, { label: t('STATUS') }, t(room.status))),
                        room.clickableLinks.length !== 0 && (React.createElement(LabelBlock, { label: t('CLICKABLE_LINKS') }, this.renderClickableLinksList(room)))),
                    adminView && (React.createElement(Auditing, { t: t, type: 'ROOM', auditing: room.auditing, auditlog: roomAuditlog, fetchAuditlog: cursor => this.fetchAuditlog(room.id, cursor), auditlogFieldMap: {
                            name: t('SHORT_NAME'),
                            googleName: t('FULL_NAME'),
                            type: t('ROOM_TYPE'),
                            floor: t('FLOOR'),
                            floorSection: t('FLOOR_SECTION'),
                            localDescription: t('DESCRIPTION'),
                            translations: t('FULL_NAME'),
                            catering: t('CATERING'),
                            googleOwner: t('GOOGLE_OWNER'),
                            contactInformation: t('SUPPORT_CONTACT'),
                            owner: t('ROOM_OWNER'),
                            images: t('IMAGES'),
                            features: t('FEATURES'),
                            capacity: t('CAPACITY'),
                            privacy: t('BOOKING_METHOD'),
                            restrictedAccess: t('RESTRICTION'),
                        }, role: role, lng: lng })))),
            adminView && this.renderModals(),
            this.renderAvailabilityModal()))));
    }
    renderClickableLinksList(room) {
        return (React.createElement(React.Fragment, null, room.clickableLinks.map((link, i) => {
            return (React.createElement("div", { key: i },
                React.createElement("a", { href: link.url, target: "_blank", rel: "noopener noreferrer" }, link.name)));
        })));
    }
    map() {
        const { room } = this.props;
        return Object.assign(Object.assign({}, room), { roomId: room.id, roomName: room.name, buildingLatitude: room.coordinates.latitude, buildingLongitude: room.coordinates.longitude });
    }
    renderBookingDetails() {
        const { t, room } = this.props;
        return room.privacy ? (React.createElement(LabelBlock, { label: t('BOOKING_DETAILS') }, this.getBookingDetailsForPrivateRoom(room))) : (React.createElement(LabelBlock, { label: t('BOOKING_DETAILS') },
            React.createElement(Button, { className: "button is-primary", onClick: () => {
                    const mappedRoom = this.map(room);
                    this.setState({
                        availabilityRoom: mappedRoom,
                    });
                }, icon: React.createElement(FAIcon, { icon: ['far', 'calendar'] }), text: t('CHECK_AVAILABILITY') }),
            React.createElement("br", null),
            React.createElement("br", null),
            this.getBookingDetailsForPublicRoom('BOOKING_DETAILS_BOOK_DIRECTLY')));
    }
    getBookingDetailsForPublicRoom(linkText) {
        const { t, room } = this.props;
        let notProvisionedRoomDetails = (React.createElement("span", null, t('BOOKING_DETAILS_NOT_ACTIVE_BOOK_DIRECTLY')));
        let provisionedRoomDetails = (React.createElement(Trans, { defaults: linkText, components: [
                React.createElement("a", { href: room.calendarId
                        ? `https://calendar.google.com/calendar/r?cid=${room.calendarId}`
                        : 'https://calendar.google.com', target: "_blank", rel: "noopener noreferrer" }, "univers"),
            ] }));
        let pendingDeletionRoomDetails = (React.createElement("span", null,
            t('PENDING_DELETION_ROOM_DETAIL_INFO'),
            this.isRoomAdmin(room) &&
                t('PENDING_DELETION_ROOM_DETAIL_INFO_ADMIN_ADDITION')));
        if (room.status === statusConstants.PENDING_DELETION) {
            return pendingDeletionRoomDetails;
        }
        else if (room.calendarId) {
            return provisionedRoomDetails;
        }
        else {
            return notProvisionedRoomDetails;
        }
    }
    getBookingDetailsForPrivateRoom(room) {
        const { t, role, sitesCodes } = this.props;
        let googleOwnerContact = null;
        if (room.googleOwnerVisible) {
            googleOwnerContact = (React.createElement(Fragment, null,
                React.createElement("span", null,
                    t(room.status === statusConstants.ACTIVE ||
                        room.status === statusConstants.MAINTENANCE
                        ? 'BOOKING_DETAILS_CONTACT_WITH'
                        : 'BOOKING_DETAILS_NOT_ACTIVE_CONTACT_WITH'),
                    ":",
                    ' '),
                React.createElement("a", { href: `mailto:${room.googleOwner}` }, this.getUserFullName(room.googleOwner) || room.googleOwner)));
        }
        let calendarLink = null;
        if (this.shouldShowCalendarLinkForSiteAdmin(room, role, sitesCodes)) {
            calendarLink = (React.createElement("div", null, this.getBookingDetailsForPublicRoom('BOOKING_DETAILS_PRIVATE')));
        }
        return (React.createElement(Fragment, null,
            googleOwnerContact,
            calendarLink));
    }
    shouldShowCalendarLinkForSiteAdmin(room, role, sitesCodes) {
        return (isRoomAdmin(room.siteCode, role, sitesCodes) &&
            room.calendarId &&
            (room.status === statusConstants.ACTIVE ||
                room.status === statusConstants.MAINTENANCE));
    }
}
const mapStateToProps = state => {
    const { rooms: { room, roomsError, roomAuditlog, listParams }, user: { role, sites, sitesCodes }, } = state;
    return {
        room,
        roomAuditlog,
        role,
        sites,
        roomsError,
        listParams,
        sitesCodes,
    };
};
const mapDispatchToProps = dispatch => ({
    loadRoom: conf => dispatch(roomsActions.loadRoom(conf)),
    loadRoomAuditlog: conf => dispatch(roomsActions.loadRoomAuditlog(conf)),
    deleteRoom: (id, conf) => dispatch(roomsActions.deleteRoom(id, conf)),
    activateRoom: conf => dispatch(roomsActions.updateState(conf, statusConstants.ACTIVE)),
    createMaintenance: (id, data) => dispatch(roomsActions.createMaintenance(id, data)),
    patchMaintenance: (id, data) => dispatch(roomsMaintenancesActions.patchMaintenance(id, data, {
        roomDetailsView: true,
    })),
    restoreRoom: id => dispatch(roomsActions.restoreRoom(id, {})),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(withErrorHandling(RoomDetails))));
