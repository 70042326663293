var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import i18n from '../../../i18n';
import { MediaTabs, LabelBlock } from '../../index';
const RoomRowInfo = (_a) => {
    var { room, isStatic } = _a, props = __rest(_a, ["room", "isStatic"]);
    return (React.createElement("div", { className: "room-row-info" },
        React.createElement("div", { className: "properties" },
            React.createElement(LabelBlock, { label: i18n.t('FEATURES'), show: Array.isArray(room.roomFeatures) && room.roomFeatures.length }, room.roomFeatures.map(feature => (React.createElement("span", { className: "tooltip-freatures", key: feature.name }, feature.name))))),
        React.createElement(MediaTabs, { images: room.roomImages, coordinates: {
                latitude: room.buildingLatitude,
                longitude: room.buildingLongitude,
            }, imgMaxWidth: 400, imgMaxHeight: 300, imgCrop: true, isStatic: isStatic })));
};
export default RoomRowInfo;
