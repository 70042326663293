import { errorConstants, errorTypesConstants } from '../constants';
import { toastError } from '../toasts';
import i18n from '../i18n';
const error = (error, actionFunction, actionParams, errorAction) => {
    return dispatch => {
        dispatch({
            type: errorConstants.ERROR,
            data: {
                error,
                actionFunction,
                actionParams,
            },
        });
        console.log(errorAction, error, error.name, error.message, error.type, error.response, error.status);
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 400:
                    dispatch(badRequest(error, errorAction));
                    break;
                case 401:
                    break;
                case 403:
                    dispatch(forbidden(errorAction));
                    break;
                case 404:
                    dispatch(notFound(error, errorAction));
                    break;
                case 409:
                    dispatch(conflict(errorAction));
                    break;
                case 500:
                    if (errorAction.error &&
                        (errorAction.error.type === errorTypesConstants.EDIT_RESOURCE ||
                            errorAction.error.type === errorTypesConstants.ADD_RESOURCE)) {
                        dispatch(badRequest(error, errorAction));
                        break;
                    }
                default:
                    dispatch(showError(error, errorAction));
                    break;
            }
        }
        else {
            dispatch(showError(error, errorAction));
        }
    };
};
const notFound = (error, errorAction) => {
    return dispatch => {
        if (errorAction.error &&
            errorAction.error.type &&
            errorAction.error.type === errorTypesConstants.LOAD_RESOURCE) {
            return dispatch(errorAction);
        }
        if (errorAction.error && errorAction.error.response.data.reason) {
            console.log(errorAction.error.response.data.reason);
            switch (errorAction.error.response.data.reason) {
                case errorConstants.SITE_NOT_FOUND:
                case errorConstants.SITES_NOT_FOUND:
                case errorConstants.UNSUPPORTED_FORMAT_TO_RESIZE:
                    return toastError(i18n.t(errorAction.error.response.data.reason));
                case errorConstants.ROOM_TYPE_NOT_FOUND_DELETE:
                case errorConstants.FEATURE_NOT_FOUND_DELETE:
                case errorConstants.BUILDING_NOT_FOUND_DELETE:
                case errorConstants.ROOM_NOT_FOUND_DELETE:
                case errorConstants.ROOM_NOT_FOUND_UPDATE:
                case errorConstants.BUILDING_NOT_FOUND_UPDATE:
                case errorConstants.ROOM_TYPE_NOT_FOUND_UPDATE:
                case errorConstants.FEATURE_NOT_FOUND_UPDATE:
                case errorConstants.USER_NOT_FOUND_UPDATE:
                case errorConstants.USER_NOT_FOUND_DELETE:
                case errorConstants.FEATURES_NOT_FOUND:
                case errorConstants.SITE_NOT_FOUND_DELETE:
                case errorConstants.SITE_NOT_FOUND_UPDATE:
                default:
                    return dispatch(errorAction);
            }
        }
        return dispatch(showError(error, errorAction));
    };
};
const forbidden = errorAction => {
    return dispatch => {
        return dispatch(errorAction);
    };
};
const conflict = errorAction => {
    return dispatch => {
        return dispatch(errorAction);
    };
};
const badRequest = (error, errorAction) => {
    return dispatch => {
        if (errorAction.error && errorAction.error.response.data.reason) {
            switch (errorAction.error.response.data.reason) {
                case errorConstants.SITE_NOT_FOUND:
                case errorConstants.USER_DUPLICATE:
                    return toastError(i18n.t(errorAction.error.response.data.reason));
                default:
                    return dispatch(errorAction);
            }
        }
        return dispatch(errorAction);
    };
};
const showError = (error, errorAction) => {
    return dispatch => {
        dispatch({ type: errorConstants.SHOW_ERROR });
        const message = error.response && error.response.statusText
            ? error.response.statusText
            : error.message;
        if (message) {
            toastError(i18n.t(message));
        }
        dispatch(errorAction);
    };
};
const showToast = error => {
    return dispatch => {
        toastError(i18n.t(error.response ? error.response.statusText : error.message));
    };
};
export const errorActions = {
    error,
    showError,
    showToast,
};
