import React from 'react';
import { withNamespaces } from 'react-i18next';
const DevData = () => {
    const { PROJECT, NODE_ENV, GIT_HASH, GIT_BRANCH } = process.env;
    if (PROJECT === 'dev' ||
        PROJECT === 'integration' ||
        PROJECT === 'test' ||
        NODE_ENV !== 'production') {
        return (React.createElement("div", { className: "content is-small is-pulled-right has-text-grey-light" },
            PROJECT,
            " | ",
            GIT_HASH,
            " | ",
            GIT_BRANCH));
    }
    return null;
};
const Footer = ({ t }) => {
    return (React.createElement("footer", { className: "footer" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "content is-small is-pulled-left" }, t('FOOTER_TEXT', { year: new Date().getFullYear() })),
            DevData())));
};
export default withNamespaces('translations')(Footer);
