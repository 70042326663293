var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { Button } from '../../index';
import { routes, statusConstants } from '../../../constants';
import { withNamespaces } from 'react-i18next';
import MaintenanceModal from '../../Common/Modal/Room/MaintenanceModal/MaintenanceModal';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { roomsActions } from '../../../actions';
import { ConfirmModal } from '../../';
import DeleteRoomModal from '../../Common/Modal/Room/DeleteRoomModal/DeleteRoomModal';
import DeletedRoomInfoModal from '../../Common/Modal/Room/DeletedRoomInfoModal/DeletedRoomInfoModal';
import { toastError } from '../../../toasts';
import RestoreRoomModal from '../../Common/Modal/Room/RestoreRoomModal/RestoreRoomModal';
import RestoredRoomInfoModal from '../../Common/Modal/Room/RestoredRoomInfoModal/RestoredRoomInfoModal';
import { bulkActionTypes } from '../../../constants';
import { areDateTimeRangesNotOverlapped } from '../../../utils';
import { roomMaintenanceStatusConstants } from '../../../constants/roomMaintenanceStatus.constants';
class RoomsBulkActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMaintenanceModal: false,
            showConfirmDeleteModal: false,
            showRestoreModal: false,
            showRestoredModal: false,
            selectedNames: [],
            roomsDeleted: false,
            cannotPerformBulk: false,
            filterForBulkActionType: null,
            maintenanceData: null,
        };
        this.setMaintenanceHandler = this.setMaintenanceHandler.bind(this);
        this.editHandler = this.editHandler.bind(this);
        this.deleteHandler = this.deleteHandler.bind(this);
        this.deleteConfirmedHandler = this.deleteConfirmedHandler.bind(this);
        this.deletedRoomsInfoConfirmed = this.deletedRoomsInfoConfirmed.bind(this);
        this.restoreHandler = this.restoreHandler.bind(this);
        this.restoreConfirmedHandler = this.restoreConfirmedHandler.bind(this);
        this.filterRoomsForAction = this.filterRoomsForAction.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (this.props.shouldPushToBulk === true) {
            this.props.history.push(routes.roomsBulkEdit);
        }
    }
    deleteHandler() {
        const selectedNames = this.getSelectedRoomsNames();
        this.setState({ showConfirmDeleteModal: true, selectedNames });
    }
    deleteConfirmedHandler() {
        const selectedRooms = [...this.props.selectedRooms];
        this.somePendingDeletion(selectedRooms)
            ? this.showCannotPerformBulk(bulkActionTypes.DELETE)
            : this.bulkDelete(selectedRooms);
    }
    bulkDelete(rooms) {
        return __awaiter(this, void 0, void 0, function* () {
            const { t } = this.props;
            const roomIds = rooms.map(r => r.roomId);
            const deletedRooms = yield this.props.deleteRoomsBulk(roomIds);
            if (!(deletedRooms instanceof Error)) {
                this.setState({ roomsDeleted: true });
            }
            else {
                toastError(t(deletedRooms.response.data.reason));
            }
        });
    }
    restoreHandler() {
        const selectedNames = this.getSelectedRoomsNames();
        this.setState({
            showRestoreModal: true,
            selectedNames: selectedNames,
        });
    }
    restoreConfirmedHandler() {
        const selectedRooms = [...this.props.selectedRooms];
        this.someNotPendingDeletion()
            ? this.showCannotPerformBulk(bulkActionTypes.RESTORE)
            : this.restoreRooms(selectedRooms);
    }
    restoreRooms(selectedRooms) {
        return __awaiter(this, void 0, void 0, function* () {
            const { t } = this.props;
            const roomsDto = selectedRooms.map(r => ({
                roomId: r.roomId,
                status: statusConstants.ACTIVE,
            }));
            const roomsRestored = yield this.props.updateBulkStatus(roomsDto);
            if (!(roomsRestored instanceof Error)) {
                this.setState({ showRestoredModal: true });
            }
            else {
                toastError(t(roomsRestored.response.data.reason));
            }
        });
    }
    setMaintenanceHandler(data) {
        this.setState({ maintenanceData: data }, () => {
            const selectedRooms = [...this.props.selectedRooms];
            this.someNotAvailableForMaintenance(data)
                ? this.showCannotPerformBulk(bulkActionTypes.MAINTENANCE)
                : this.setMaintenance(selectedRooms);
        });
    }
    setMaintenance(selectedRooms) {
        const { maintenanceData } = this.state;
        const roomIds = selectedRooms.map(r => r.roomId);
        const data = Object.assign({}, maintenanceData);
        this.props
            .setMaintenanceBulk(roomIds, data)
            .then(() => this.props.fetchRooms());
    }
    editHandler() {
        const selectedRooms = [...this.props.selectedRooms];
        const selectedRoomsIds = [...this.props.selectedRoomsIds];
        this.somePendingDeletion(selectedRooms)
            ? this.showCannotPerformBulk(bulkActionTypes.EDIT)
            : this.pushToBulkEdit(selectedRooms, selectedRoomsIds);
    }
    pushToBulkEdit(rooms, ids) {
        this.props.updateSelectedRooms(rooms, ids);
    }
    getSelectedRoomsNames() {
        const selectedRooms = [...this.props.selectedRooms];
        return selectedRooms.map(r => r.roomName);
    }
    somePendingDeletion(selectedRooms) {
        return selectedRooms.some(r => r.roomStatus === statusConstants.PENDING_DELETION);
    }
    someMaintenanceAvailable() {
        return this.props.selectedRooms.some(r => this.isCorrectStatusForMaintenance(r));
    }
    someNotAvailableForMaintenance(newMaintenanceData) {
        return this.props.selectedRooms.some(r => {
            if (this.isCorrectStatusForMaintenance(r)) {
                if (r.maintenances.length === 0) {
                    return false;
                }
                return r.maintenances
                    .filter(m => m.status === roomMaintenanceStatusConstants.CREATED)
                    .some(m => {
                    return !areDateTimeRangesNotOverlapped(m.from, m.to, newMaintenanceData.startDate, newMaintenanceData.endDate);
                });
            }
            return true;
        });
    }
    isCorrectStatusForMaintenance(r) {
        return (r.roomStatus === statusConstants.ACTIVE ||
            r.roomStatus === statusConstants.MAINTENANCE);
    }
    someNotPendingDeletion() {
        return this.props.selectedRooms.some(r => r.roomStatus !== statusConstants.PENDING_DELETION);
    }
    showCannotPerformBulk(bulkType) {
        this.setState({
            cannotPerformBulk: true,
            filterForBulkActionType: bulkType,
        });
    }
    filterRoomsForAction() {
        const { filterForBulkActionType } = this.state;
        switch (filterForBulkActionType) {
            case bulkActionTypes.EDIT:
                this.filterAvailableForEdit();
                break;
            case bulkActionTypes.DELETE:
                this.filterAvailableForDelete();
                break;
            case bulkActionTypes.MAINTENANCE:
                this.filterAvailableForMaintenance();
                break;
            case bulkActionTypes.RESTORE:
                this.filterAvailableForRestore();
                break;
        }
        this.setState({ filterForBulkActionType: null });
    }
    filterAvailableForEdit() {
        const selectedRooms = [...this.props.selectedRooms];
        const filteredRooms = selectedRooms.filter(r => r.roomStatus !== statusConstants.PENDING_DELETION);
        const filteredIds = filteredRooms.map(r => r.roomId);
        this.pushToBulkEdit(filteredRooms, filteredIds);
    }
    filterAvailableForDelete() {
        const selectedRooms = [...this.props.selectedRooms];
        const filteredPendingDeletion = selectedRooms.filter(r => r.roomStatus !== statusConstants.PENDING_DELETION);
        this.bulkDelete(filteredPendingDeletion);
    }
    filterAvailableForMaintenance() {
        const selectedRooms = [...this.props.selectedRooms];
        const roomsAvailableForMaintenanace = selectedRooms.filter(r => {
            return this.isAvailableForMaintenance(r);
        });
        this.setMaintenance(roomsAvailableForMaintenanace);
    }
    isAvailableForMaintenance(room) {
        return (this.isCorrectStatusForMaintenance(room) &&
            (this.dontHaveAnyMaintenance(room) ||
                this.everyNotOverlappedWithNew(room.maintenances)));
    }
    dontHaveAnyMaintenance(room) {
        return room.maintenances.length === 0;
    }
    everyNotOverlappedWithNew(maintenances) {
        const { maintenanceData } = this.state;
        return maintenances
            .filter(m => m.status !== roomMaintenanceStatusConstants.CANCELED)
            .every(m => {
            return areDateTimeRangesNotOverlapped(m.from, m.to, maintenanceData.startDate, maintenanceData.endDate);
        });
    }
    filterAvailableForRestore() {
        const selectedRooms = [...this.props.selectedRooms];
        const pendingDeletionRooms = selectedRooms.filter(r => r.roomStatus === statusConstants.PENDING_DELETION);
        this.restoreRooms(pendingDeletionRooms);
    }
    deletedRoomsInfoConfirmed() {
        this.props.fetchRooms();
        this.setState({ roomsDeleted: false });
    }
    renderModals() {
        const { showMaintenanceModal, showConfirmDeleteModal, showRestoreModal, showRestoredModal, selectedNames, roomsDeleted, cannotPerformBulk, } = this.state;
        const { t } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(MaintenanceModal, { isOpen: showMaintenanceModal, onSet: this.setMaintenanceHandler, onClose: () => this.setState({ showMaintenanceModal: false }), roomIds: this.props.selectedRooms.map(room => room.roomId) }),
            React.createElement(DeleteRoomModal, { roomsNames: selectedNames, isOpen: showConfirmDeleteModal, onClose: () => this.setState({ showConfirmDeleteModal: false }), onDelete: this.deleteConfirmedHandler }),
            React.createElement(DeletedRoomInfoModal, { isOpen: roomsDeleted, onClose: () => this.setState({ roomsDeleted: false }), onConfirm: this.deletedRoomsInfoConfirmed }),
            React.createElement(RestoreRoomModal, { names: selectedNames, isOpen: showRestoreModal, onClose: () => this.setState({ showRestoreModal: false }), onRestore: this.restoreConfirmedHandler }),
            React.createElement(RestoredRoomInfoModal, { isMulti: true, isOpen: showRestoredModal, onClose: () => {
                    this.setState({ showRestoredModal: false });
                    this.props.fetchRooms();
                } }),
            React.createElement(ConfirmModal, { title: t('CANNOT_PERFORM_BULK_ACTION'), text: t('CANNOT_PERFORM_BULK_ACTION_TEXT'), onClose: () => this.setState({ cannotPerformBulk: false }), isOpen: cannotPerformBulk, customModalClassName: "bulk-pending-warning-modal", primaryButton: {
                    label: t('PROCEED'),
                    type: 'is-primary',
                    action: onClose => {
                        this.filterRoomsForAction();
                        onClose();
                    },
                }, secondaryButton: {
                    label: t('GO_BACK_TO_LIST'),
                    type: 'is-light',
                    action: onClose => onClose(),
                } })));
    }
    render() {
        const { t, selectedRooms } = this.props;
        let editButton = null;
        let deleteButton = null;
        let restoreButton = null;
        let maintenanceButton = null;
        if (this.someNotPendingDeletion()) {
            editButton = (React.createElement(Button, { onClick: this.editHandler, className: "is-primary", text: t('EDIT_ROOMS') }));
            deleteButton = (React.createElement(Button, { onClick: this.deleteHandler, className: "is-danger", text: t('DELETE_ROOMS') }));
        }
        if (this.somePendingDeletion(selectedRooms)) {
            restoreButton = (React.createElement(Button, { onClick: this.restoreHandler, className: "is-light", text: t('RESTORE_ROOMS') }));
        }
        if (this.someMaintenanceAvailable()) {
            maintenanceButton = (React.createElement(Button, { onClick: () => this.setState({ showMaintenanceModal: true }), className: "is-primary", text: t('PUT_IN_MAINTENANCE') }));
        }
        return (React.createElement(Fragment, null,
            editButton,
            deleteButton,
            maintenanceButton,
            restoreButton,
            this.renderModals()));
    }
}
const mapStateToProps = state => {
    const { rooms: { shouldPushToBulk }, user: { role, sites }, } = state;
    return {
        shouldPushToBulk,
        role,
        sites,
    };
};
const mapDispatchToProps = dispatch => ({
    setMaintenanceBulk: (ids, dateRange) => dispatch(roomsActions.setMaintenanceBulk(ids, dateRange)),
    updateSelectedRooms: (rooms, roomsId) => dispatch(roomsActions.updateSelectedRooms(rooms, roomsId)),
    deleteRoomsBulk: roomIds => dispatch(roomsActions.deleteRoomsBulk(roomIds)),
    updateBulkStatus: roomsDto => dispatch(roomsActions.updateBulkStatus(roomsDto)),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(RoomsBulkActions)));
