var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../actions';
import Modal from '../Modal/ConfirmModal/ConfirmModal';
export const GoogleLoginWrapper = props => {
    const [isOpen, setIsOpen] = useState(true);
    const dispatch = useDispatch();
    const login = useGoogleLogin({
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/cloud_search.query',
        onSuccess: (response) => __awaiter(void 0, void 0, void 0, function* () {
            dispatch(userActions.newTokenRequest(response.code, true));
        }),
        onError: errorResponse => console.log(errorResponse),
    });
    const onClose = () => {
        setIsOpen(false);
    };
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose, title: 'Insufficient permissions', text: 'CReMS is missing permissions to Cloud Search which are required to enable search and filtering. Please click the button below to reauthorize and add the missing permissions.', primaryButton: {
            action: login,
            type: 'is-primary',
            label: 'Reauthorize and add permissions',
        } }));
};
