export function convertToBoolean(value) {
    if (typeof value === 'string') {
        value = value.toLowerCase();
    }
    switch (value) {
        case true:
        case 'true':
        case 'evet':
        case 't':
        case 'e':
        case '1':
        case 'on':
        case 'yes':
        case 1:
            return true;
        case false:
        case 'false':
        case 'hayır':
        case 'f':
        case 'h':
        case '0':
        case 'off':
        case 'no':
        case 0:
            return false;
        default:
            return null;
    }
}
