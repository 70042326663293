import i18n from '../i18n';
const featureCategory = {
    PROJECTOR: i18n.t('PROJECTOR'),
    FLIPCHART: i18n.t('FLIPCHART'),
    CONFERENCE_PHONE: i18n.t('CONFERENCE_PHONE'),
    SPEAKERPHONE: i18n.t('SPEAKERPHONE'),
    MICROPHONE: i18n.t('MICROPHONE'),
    ROOM_CONTROL: i18n.t('ROOM_CONTROL'),
    ROOMBOARD: i18n.t('ROOMBOARD'),
    VIDEOCONFERENCE_EQUIPMENT: i18n.t('VIDEOCONFERENCE_EQUIPMENT'),
    WHITEBOARD: i18n.t('WHITEBOARD'),
    WORLD_CLOCK: i18n.t('WORLD_CLOCK'),
    WEBCKAM: i18n.t('WEBCAM'),
};
export default featureCategory;
