var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as moment from 'moment-timezone';
import { sitesActions } from '../../actions';
import { withNamespaces } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { ErrorMsg, FormControl, ReactSelect, TitleSection, Button, FormButtons, LoaderContainer, LocationSelector, ConfirmModal, BackToList, } from '../';
import { toastError } from '../../toasts';
import { SitesSchema } from '../../validations';
import classnames from 'classnames/bind';
import { appConstants, errorConstants, errorTypesConstants as err, routes, } from '../../constants';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { formatRoute } from 'react-router-named-routes';
import withErrorHandling from '../Common/WithErrorHandling/withErrorHandling';
import i18n from '../../i18n';
import { updateErrorMessagesOnLanguageChange } from '../../utils/formik.utils';
class SitesAdd extends Component {
    constructor(props) {
        super(props);
        this.emptyFormState = {
            code: '',
            name: '',
            description: '',
            catering: '',
            city: '',
            countryCode: '',
            latitude: '',
            longitude: '',
            timezone: '',
            _hidden_coords: '',
        };
        this.state = {
            isReady: false,
            itemToAdd: null,
            itemToEdit: null,
            isMapOpen: false,
            itemConflict: false,
            accessDenied: false,
        };
        const editId = props.match.params.code;
        if (editId) {
            this.id = editId;
        }
    }
    isEdit() {
        return this.id !== undefined;
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.loadData();
        });
    }
    componentWillUnmount() {
        i18n.off('languageChanged');
    }
    loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            const { loadSite } = this.props;
            const timeZones = moment.tz.names().map(tz => ({ label: tz, value: tz }));
            if (this.isEdit()) {
                try {
                    yield loadSite({ code: this.id });
                    this.setState({ isReady: true, timeZones });
                }
                catch (error) {
                    const { t, history, handleError404, sitesError } = this.props;
                    if (sitesError && sitesError.type === err.LOAD_RESOURCE) {
                        handleError404({
                            text: {
                                msg: t('ERROR_404_SITE'),
                                email: appConstants.DEFAULT_CONTACT_EMAIL,
                            },
                            backLinkPath: routes.sites,
                        });
                    }
                    else {
                        history.push(routes.sites);
                    }
                }
            }
            else {
                this.setState({ isReady: true, timeZones });
            }
        });
    }
    isEditReady() {
        return this.isEdit() && this.state.isReady;
    }
    getInitialValues() {
        return this.isEdit()
            ? Object.assign(Object.assign({}, this.props.site), { _hidden_coords: `${this.props.site.latitude},${this.props.site.longitude}` }) : this.emptyFormState;
    }
    onSubmit(values, { setSubmitting }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { addSite, updateSite, t, history } = this.props;
            const savedSite = this.isEdit()
                ? yield updateSite(this.id, values)
                : yield addSite(values);
            setSubmitting(false);
            if (!(savedSite instanceof Error)) {
                const modal = this.isEdit()
                    ? { itemToEdit: Object.assign({}, values) }
                    : { itemToAdd: Object.assign(Object.assign({}, values), { id: savedSite.id }) };
                this.setState(modal);
            }
            else if (savedSite.response.data.reason === errorConstants.SITE_NOT_FOUND_UPDATE ||
                savedSite.response.data.reason === errorConstants.SITE_NOT_FOUND_DELETE) {
                history.push({
                    pathname: formatRoute(routes.sites),
                    state: { errorModal: savedSite.response.data.reason },
                });
            }
            else if (savedSite.response.data.reason === errorConstants.ACCESS_DENIED) {
                this.setState({ accessDenied: true });
            }
            else if (savedSite.response.data.reason === errorConstants.CONFLICT) {
                this.loadData();
                this.setState({ itemConflict: true });
            }
            else {
                toastError(t(savedSite.response.data.reason));
            }
        });
    }
    toggleMapModal() {
        this.setState(state => ({
            isMapOpen: !state.isMapOpen,
        }));
    }
    renderModals() {
        const { itemToEdit, itemToAdd, itemConflict, accessDenied } = this.state;
        const { t, history } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(ConfirmModal, { title: t('ADD_SITE'), text: t('MODAL_ADD_SITE_CONFIRM', {
                    name: itemToAdd ? itemToAdd.name : '',
                }), onClose: () => {
                    this.setState({ itemToAdd: null });
                    history.push(routes.sites);
                }, isOpen: itemToAdd != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { title: t('EDIT'), text: t('MODAL_EDIT_SITE_CONFIRM', {
                    name: itemToEdit ? itemToEdit.name : '',
                }), onClose: () => {
                    this.setState({ itemToEdit: null });
                    history.push(formatRoute(routes.sites));
                }, isOpen: itemToEdit != null, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { title: t('ACCESS_DENIED'), text: t('ACCESS_DENIED_LOST_RIGHTS'), onClose: () => history.push(routes.logout), isOpen: accessDenied, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } }),
            React.createElement(ConfirmModal, { title: t('CONFLICT'), text: t('SITE_EDIT_CONFLICT'), onClose: () => this.setState({ itemConflict: false }), isOpen: itemConflict, primaryButton: {
                    label: t('OK'),
                    type: 'is-primary',
                    action: onClose => onClose(),
                } })));
    }
    render() {
        const { t, site } = this.props;
        return (React.createElement(Fragment, null,
            React.createElement(TitleSection, { title: this.isEditReady()
                    ? `${site.name} (${t('EDIT_SITE')})`
                    : t('ADD_SITE') }),
            React.createElement(LoaderContainer, { className: "section" }, this.state.isReady && (React.createElement("div", { className: "container" },
                React.createElement("div", { className: "header-panel" },
                    React.createElement("div", { className: "buttons" },
                        React.createElement(BackToList, { to: routes.sites, t: t }))),
                React.createElement("div", { className: "content" },
                    React.createElement(Formik, { enableReinitialize: true, initialValues: this.getInitialValues(), validationSchema: SitesSchema, onSubmit: this.onSubmit.bind(this) }, form => {
                        const { isSubmitting, values, errors, touched, setFieldValue, setFieldTouched, setFieldError, } = form;
                        updateErrorMessagesOnLanguageChange(errors, setFieldError, setFieldTouched);
                        return (React.createElement(Form, null,
                            React.createElement("div", { className: "columns" },
                                React.createElement("div", { className: "column is-4" },
                                    React.createElement(FormControl, { label: t('NAME'), required: true },
                                        React.createElement(Field, { className: classnames('input', {
                                                'is-danger': errors.name && touched.name,
                                            }), type: "text", placeholder: t('NAME'), name: "name", disabled: this.isEdit() && site.used }),
                                        React.createElement(ErrorMsg, { name: "name" }))),
                                React.createElement("div", { className: "column is-4" },
                                    React.createElement(FormControl, { label: t('CODE'), required: true },
                                        React.createElement(Field, { className: classnames('input', {
                                                'is-danger': errors.code && touched.code,
                                            }), type: "text", placeholder: t('CODE'), name: "code", disabled: this.isEdit() }),
                                        React.createElement(ErrorMsg, { name: "code" })))),
                            React.createElement("div", { className: "columns" },
                                React.createElement("div", { className: "column is-8" },
                                    React.createElement(FormControl, { label: t('DESCRIPTION'), required: true },
                                        React.createElement(Field, { component: "textarea", maxLength: 255, className: classnames('textarea', {
                                                'is-danger': errors.description && touched.description,
                                            }), placeholder: t('DESCRIPTION'), name: "description" }),
                                        React.createElement(ErrorMsg, { name: "description" })))),
                            React.createElement("div", { className: "columns" },
                                React.createElement("div", { className: "column is-8" },
                                    React.createElement(FormControl, { label: t('CATERING_URL') },
                                        React.createElement(Field, { type: "text", className: classnames('input', {
                                                'is-danger': errors.catering && touched.catering,
                                            }), placeholder: t('CATERING_URL'), name: "catering" }),
                                        React.createElement(ErrorMsg, { name: "catering" })))),
                            React.createElement("div", { className: "columns" },
                                React.createElement("div", { className: "column is-4" },
                                    React.createElement(FormControl, { label: t('CITY'), required: true },
                                        React.createElement(Field, { className: classnames('input', {
                                                'is-danger': errors.city && touched.city,
                                            }), type: "text", placeholder: t('CITY'), name: "city" }),
                                        React.createElement(ErrorMsg, { name: "city" }))),
                                React.createElement("div", { className: "column is-4" },
                                    React.createElement(FormControl, { label: t('COUNTRY_CODE'), required: true },
                                        React.createElement(Field, { className: classnames('input', {
                                                'is-danger': errors.countryCode && touched.countryCode,
                                            }), type: "text", placeholder: t('COUNTRY_CODE'), name: "countryCode" }),
                                        React.createElement(ErrorMsg, { name: "countryCode" })))),
                            React.createElement("div", { className: "columns" },
                                React.createElement("div", { className: "column is-8" },
                                    React.createElement(FormControl, { label: t('LOCATION') },
                                        React.createElement("div", { className: "box" },
                                            React.createElement("div", { className: "columns" },
                                                React.createElement("div", { className: "column is-6" },
                                                    React.createElement(FormControl, { label: t('LATITUDE') },
                                                        React.createElement(Field, { className: classnames('input', {
                                                                'is-danger': errors.latitude && touched.latitude,
                                                            }), type: "text", placeholder: t('LATITUDE'), name: "latitude", disabled: true }),
                                                        React.createElement(ErrorMsg, { name: "latitude" }))),
                                                React.createElement("div", { className: "column is-6" },
                                                    React.createElement(FormControl, { label: t('LONGITUDE') },
                                                        React.createElement(Field, { className: classnames('input', {
                                                                'is-danger': errors.longitude &&
                                                                    touched.longitude,
                                                            }), type: "text", placeholder: t('LONGITUDE'), name: "longitude", disabled: true }),
                                                        React.createElement(ErrorMsg, { name: "longitude" })))),
                                            React.createElement("div", { className: "columns" },
                                                React.createElement("div", { className: "column is-8" },
                                                    React.createElement("a", { onClick: this.toggleMapModal.bind(this) }, t('LOCATION_SEARCH')))))))),
                            React.createElement("div", { className: "columns" },
                                React.createElement("div", { className: "column is-4" },
                                    React.createElement(FormControl, { label: t('TIME_ZONE'), required: true },
                                        React.createElement(Field, { name: "timezone", className: classnames({
                                                'is-danger': errors.timezone && touched.timezone,
                                            }), component: ReactSelect, options: this.state.timeZones }),
                                        React.createElement(ErrorMsg, { name: "timezone" })))),
                            React.createElement(FormButtons, null,
                                React.createElement(Button, { type: "submit", className: "is-primary", isLoading: isSubmitting, disabled: isSubmitting, text: t('SAVE'), icon: React.createElement(FAIcon, { icon: ['fas', 'check'] }) }),
                                React.createElement(Button, { to: routes.sites, className: "is-light", text: t('CANCEL') })),
                            React.createElement(LocationSelector, { placeholder: t('LOCATION_PLACEHOLDER'), coords: values._hidden_coords, onClose: this.toggleMapModal.bind(this), isOpen: this.state.isMapOpen, locationSelected: location => {
                                    let lat = '';
                                    let lng = '';
                                    if (location.lat || location.lng) {
                                        lat = parseFloat(location.lat.toFixed(7));
                                        lng = parseFloat(location.lng.toFixed(7));
                                    }
                                    lat !== 0 && lng !== 0
                                        ? setFieldValue('_hidden_coords', `${lat},${lng}`)
                                        : setFieldValue('_hidden_coords', '');
                                    setFieldValue('latitude', lat);
                                    setFieldValue('longitude', lng);
                                } })));
                    }))))),
            this.renderModals()));
    }
}
const mapStateToProps = state => {
    const { sites: { site, sitesError }, } = state;
    return {
        site,
        sitesError,
    };
};
const mapDispatchToProps = dispatch => ({
    addSite: data => dispatch(sitesActions.addSite(data)),
    loadSite: data => dispatch(sitesActions.loadSite(data)),
    updateSite: (id, data) => dispatch(sitesActions.updateSite(id, data)),
});
export default withNamespaces('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(withErrorHandling(SitesAdd))));
