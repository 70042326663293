import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import bulmaCarousel from 'bulma-carousel/dist/js/bulma-carousel.min';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { ImageCarousel } from '../../index';
import classnames from 'classnames/bind';
import Map from '../Map/Map';
class MediaTabs extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            imagesLoaded: [],
        };
    }
    componentDidMount() {
        this.setState({ isLoading: true }, () => {
            this.props.images.sort(function (a, b) {
                return a.order - b.order;
            });
            bulmaCarousel.attach();
        });
    }
    imagesProvided() {
        const { images } = this.props;
        return images && images.length > 0;
    }
    coordinatesProvided() {
        const { coordinates } = this.props;
        return (coordinates &&
            coordinates.latitude !== null &&
            coordinates.longitude !== null);
    }
    isOnlyOneTab() {
        return ((this.coordinatesProvided() && !this.imagesProvided()) ||
            (this.imagesProvided() && !this.coordinatesProvided()));
    }
    render() {
        const { t, coordinates, className, isStatic } = this.props;
        if (!this.imagesProvided() && !this.coordinatesProvided())
            return null;
        return (React.createElement(Tabs, { forceRenderTabPanel: true, className: classnames('media-tabs', className) },
            React.createElement(TabList, { className: classnames('react-tabs__tab-list', {
                    'is-hidden': this.isOnlyOneTab(),
                }) },
                this.imagesProvided() ? React.createElement(Tab, null, t('SEE_THE_ROOM')) : null,
                this.coordinatesProvided() ? React.createElement(Tab, null, t('MAP')) : null),
            this.imagesProvided() ? this.showImageCarousel() : null,
            this.coordinatesProvided() ? (React.createElement(TabPanel, null, isStatic ? (React.createElement("img", { alt: "map", src: `https://maps.googleapis.com/maps/api/staticmap?center=${coordinates.latitude},${coordinates.longitude}&zoom=14&size=322x250&scale=2&markers=color:0x3e78bd%7C${coordinates.latitude},${coordinates.longitude}&key=${process.env.REACT_APP_MAP_API_KEY}` })) : (React.createElement(Map, { coordinates: {
                    lat: coordinates.latitude,
                    lng: coordinates.longitude,
                } })))) : null));
    }
    showImageCarousel() {
        const { images, imgMaxWidth, imgMaxHeight, imgCrop } = this.props;
        return (React.createElement(TabPanel, null,
            React.createElement(ImageCarousel, { images: images, imgMaxWidth: imgMaxWidth, imgMaxHeight: imgMaxHeight, imgCrop: imgCrop })));
    }
}
MediaTabs.propTypes = {
    images: PropTypes.array,
    coordinates: PropTypes.object,
    imgMaxWidth: PropTypes.number,
    imgMaxHeight: PropTypes.number,
    imgCrop: PropTypes.bool,
};
export default withNamespaces('translations')(MediaTabs);
