import React from 'react';
import PropTypes from 'prop-types';
const Checkbox = ({ type = 'checkbox', name, checked = false, disabled = false, onChange, className, }) => (React.createElement("input", { type: type, name: name, checked: checked, onChange: onChange, disabled: disabled, className: className }));
Checkbox.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
};
export default Checkbox;
