import { userConstants } from '../constants';
export const userState = {
    firstname: null,
    lastname: null,
    fullname: null,
    photo: null,
    email: null,
    isLogged: false,
    role: null,
    sites: [],
    sitesCodes: [],
    filters: {},
    isUserView: false,
};
const user = (state = userState, action) => {
    switch (action.type) {
        case userConstants.UPDATE_USER_LOGIN_STATUS:
            return Object.assign(Object.assign({}, state), { isLogged: action.isLogged });
        case userConstants.UPDATE_USER_DATA:
            return Object.assign(Object.assign({}, state), action.userData);
        case userConstants.GET_USER_ROLE:
            return Object.assign(Object.assign({}, state), { role: action.role });
        case userConstants.GET_USER_SITES:
            const sitesCodes = [...action.sites].map(site => site.code);
            return Object.assign(Object.assign({}, state), { sites: [...action.sites], sitesCodes: sitesCodes });
        case userConstants.UPDATE_USER_FILTERS:
            return Object.assign(Object.assign({}, state), { filters: Object.assign(Object.assign({}, state.filters), { [action.page]: action.filters }) });
        case userConstants.TOGGLE_USER_VIEW:
            return Object.assign(Object.assign({}, state), { isUserView: !state.isUserView });
        default:
            return state;
    }
};
export default user;
