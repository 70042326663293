var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Tooltip } from '../../';
import classnames from 'classnames/bind';
const TableTooltip = (_a) => {
    var { text, name, minWidth, children, icon, tableTooltipClassName } = _a, props = __rest(_a, ["text", "name", "minWidth", "children", "icon", "tableTooltipClassName"]);
    return (React.createElement("div", Object.assign({}, { [name]: name }, { className: classnames('table-tooltip', tableTooltipClassName) }),
        React.createElement("span", { className: "icon-with-text" },
            icon && React.createElement("span", { className: "icon" }, icon),
            React.createElement("span", { className: "text" }, text)),
        React.createElement(Tooltip, Object.assign({ persist: true, name: name, position: "bottom", autoPosition: true }, props),
            React.createElement("div", { className: "box tooltip-box", style: { minWidth } }, children))));
};
export default TableTooltip;
