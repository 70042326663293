import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { Field, Formik } from 'formik';
import { Button, FormControl, ReactSelect, UserOrGroupSearch, } from '../../../index';
import { deleteKeyIfBlankOrWhitespace } from '../../../../utils/object.utils';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
class RoomsOwnershipBulkEditForm extends Component {
    constructor(props) {
        super(props);
        this.emptyFormState = {
            owner: null,
            contactInformation: '',
            contactInformationVisible: null,
            googleOwner: null,
            googleOwnerVisible: null,
        };
        this.onSetIsSubmitTypeApply = isSubmitApply => () => {
            this.setState({ isSubmitApply: isSubmitApply });
        };
        this.state = {
            isSubmitApply: false,
        };
    }
    render() {
        const { t, lastRoomsState } = this.props;
        return (React.createElement(Formik, { initialValues: this.emptyFormState, onSubmit: (values, { resetForm }) => {
                if (this.state.isSubmitApply) {
                    const valuesToSave = deleteKeyIfBlankOrWhitespace(values);
                    this.props.onChangeApply(valuesToSave, resetForm);
                }
                else {
                    this.props.onUndoClicked(resetForm);
                }
            } }, ({ handleSubmit, dirty }) => (React.createElement("form", { onSubmit: handleSubmit, className: "bulk-room-ownership-wrapper" },
            React.createElement("div", { className: "bulk-button-wrapper" },
                React.createElement(Button, { type: "submit", className: "is-primary apply-button", text: t('APPLY_ALL'), disabled: !dirty, onClick: this.onSetIsSubmitTypeApply(true) }),
                React.createElement(Button, { className: "is-grey", icon: React.createElement(FAIcon, { icon: ['fas', 'undo'] }), disabled: lastRoomsState && lastRoomsState.length === 0, tooltip: t('UNDO_LAST_CHANGE'), type: "submit", onClick: this.onSetIsSubmitTypeApply(false) })),
            React.createElement(FormControl, { label: t('ROOM_OWNER'), className: "grid-align-bottom" },
                React.createElement(Field, { className: "bulk-roomOwner", component: UserOrGroupSearch, name: "owner", placeholder: "", key: "bulk-roomOwner" })),
            React.createElement(FormControl, { className: "grid-align-bottom support-contact-label", label: t('SUPPORT_CONTACT') },
                React.createElement(Field, { className: "input", type: "text", name: "contactInformation" })),
            React.createElement(FormControl, { className: "grid-align-bottom", label: t('VISIBILITY_SUPPORT_CONTACT') },
                React.createElement(Field, { name: "contactInformationVisible", component: ReactSelect, placeholder: "", isClearable: true, getOptionLabel: e => e.name, getOptionValue: e => e.privacy, options: [
                        { privacy: true, name: t('VISIBLE') },
                        { privacy: false, name: t('HIDDEN') },
                    ] })),
            React.createElement(FormControl, { className: "grid-align-bottom", label: t('GOOGLE_OWNER') },
                React.createElement(Field, { component: UserOrGroupSearch, name: "googleOwner", placeholder: "", className: "bulk-googleOwner" })),
            React.createElement(FormControl, { className: "grid-align-bottom", label: t('VISIBILITY_GOOGLE_OWNER') },
                React.createElement(Field, { name: "googleOwnerVisible", component: ReactSelect, placeholder: "", isClearable: true, getOptionLabel: e => e.name, getOptionValue: e => e.privacy, options: [
                        { privacy: true, name: t('VISIBLE') },
                        { privacy: false, name: t('HIDDEN') },
                    ] }))))));
    }
}
export default withNamespaces('translations')(RoomsOwnershipBulkEditForm);
