import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Search, ToggleInfo } from '../';
import { routes } from '../../constants';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
class LandingUser extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            skipLanding: false,
            search: '',
        };
        this.searchRooms = () => {
            this.props.history.push({
                pathname: routes.userRooms,
                search: '?search=' + this.state.search,
            });
        };
    }
    render() {
        const { t } = this.props;
        return (React.createElement("section", { className: "landing-enduser hero is-fullheight-view" },
            React.createElement("div", { className: "hero-body has-text-centered" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "text-carousel" },
                        React.createElement("span", { className: "text-carousel-label" }, t('LANDING_ENDUSER_CAROUSEL_TEXT_1'))),
                    React.createElement("h1", null, t('LANDING_ENDUSER_TITLE')),
                    React.createElement("div", { className: "landing-search" },
                        React.createElement(Search, { withButton: true, isLarge: true, onChange: e => this.setState({ search: e.target.value }), placeholder: t('SEARCH_PLACEHOLDER_ROOMS'), updateSearch: this.searchRooms })))),
            React.createElement("div", { className: "hero-foot" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "landing-elements" },
                        React.createElement("div", { className: "columns" },
                            React.createElement("div", { className: "column is-3" },
                                React.createElement("div", { className: "element is-clearfix" },
                                    React.createElement("div", { className: "element-circle" },
                                        React.createElement(FAIcon, { icon: ['fas', 'search'] })),
                                    React.createElement("h6", { className: "element-text" }, t('LANDING_ENDUSER_BLOCK_ROOMS')))),
                            React.createElement("div", { className: "column is-3" },
                                React.createElement("div", { className: "element is-clearfix" },
                                    React.createElement("div", { className: "element-circle" },
                                        React.createElement(FAIcon, { icon: ['fas', 'eye'] })),
                                    React.createElement("h6", { className: "element-text" }, t('LANDING_ENDUSER_BLOCK_PHOTOS')))),
                            React.createElement("div", { className: "column is-3" },
                                React.createElement("div", { className: "element is-clearfix" },
                                    React.createElement("div", { className: "element-circle" },
                                        React.createElement(FAIcon, { icon: ['fas', 'map-marker-alt'] })),
                                    React.createElement("h6", { className: "element-text" }, t('LANDING_ENDUSER_BLOCK_LOCATIONS')))),
                            React.createElement("div", { className: "column is-3" },
                                React.createElement("div", { className: "element is-clearfix" },
                                    React.createElement("div", { className: "element-circle" },
                                        React.createElement(FAIcon, { icon: ['fas', 'info-circle'] })),
                                    React.createElement("h6", { className: "element-text" }, t('LANDING_ENDUSER_BLOCK_INFO')))))),
                    React.createElement("div", { className: "landing-skip" },
                        React.createElement(ToggleInfo, { name: "restrictedAccess", labelOn: t('LANDING_SKIP_LABEL'), labelOff: t('LANDING_SKIP_LABEL'), onChange: e => {
                                localStorage.setItem('skipLanding', JSON.stringify(e.target.checked));
                                this.setState({ skipLanding: e.target.checked });
                            }, checked: this.state.skipLanding }))))));
    }
}
export default withNamespaces('translations')(withRouter(LandingUser));
