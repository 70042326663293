var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { ConfirmModal } from '../../../../index';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { withNamespaces, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import TagsWrappedList from '../../../TagsWrappedList/TagsWrappedList';
class DeleteRoomModal extends Component {
    render() {
        const { t, isOpen, onClose, onDelete, roomsNames } = this.props;
        let confirmText = React.createElement("p", null, t('DELETE_CONFIRM_MESSAGE_ROOM'));
        if (roomsNames && roomsNames.length > 1) {
            confirmText = React.createElement("p", null, t('DELETE_CONFIRM_MESSAGE_ROOMS'));
        }
        return (React.createElement(ConfirmModal, { title: t('CONFIRM'), text: React.createElement("div", null,
                confirmText,
                React.createElement(TagsWrappedList, { names: roomsNames }),
                React.createElement("span", { className: "has-text-weight-normal has-text-centered is-size-6 ", style: { paddingTop: '20px' } },
                    React.createElement("span", { className: "bold" },
                        React.createElement(Trans, { defaults: "DELETE_ROOM_MESSAGE_TEXT", components: [
                                React.createElement("span", { className: "not_yet_active-color" }, "univers"),
                                React.createElement("span", { className: "active-color " }, "univers"),
                                React.createElement("span", { className: "error-text" }, "univers"),
                            ] })),
                    React.createElement("br", null),
                    t('DELETING_ROOM_MESSAGE_GRACE_PERIOD_NOTE'))), onClose: () => {
                onClose();
            }, isOpen: isOpen, primaryButton: {
                label: t('DELETE'),
                type: 'is-danger',
                action: (onClose) => __awaiter(this, void 0, void 0, function* () {
                    onDelete();
                    onClose();
                }),
                icon: React.createElement(FAIcon, { icon: ['far', 'trash-alt'] }),
            }, secondaryButton: {
                label: t('CANCEL'),
                type: 'is-light',
                action: onClose => onClose(),
            } }));
    }
}
DeleteRoomModal.propTypes = {
    name: PropTypes.array,
};
export default withNamespaces('translations')(DeleteRoomModal);
