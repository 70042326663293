import roche_logo from './roche_logo.png';
import tile_admins from './tile-admins.svg';
import tile_booking from './tile-booking.svg';
import tile_buildings from './tile-buildings.svg';
import tile_features from './tile-features.svg';
import tile_locations from './tile-locations.svg';
import tile_room_types from './tile-room-types.svg';
import tile_rooms from './tile-rooms.svg';
import error_img from './error.svg';
import icon_building from './icon-builiding.svg';
import icon_lock from './icon-lock.svg';
import icon_map from './icon-map.svg';
import icon_stairs from './icon-stairs.svg';
import icon_type from './icon-type.svg';
import icon_users from './icon-users.svg';
import marker from './marker.png';
import marker2x from './marker@2.png';
import marker3x from './marker@3.png';
import roomPlaceholder from './room-placeholder.jpg';
export { roche_logo, tile_admins, tile_booking, tile_buildings, tile_features, tile_locations, tile_room_types, tile_rooms, error_img, icon_building, icon_lock, icon_map, icon_stairs, icon_type, icon_users, marker, marker2x, marker3x, roomPlaceholder, };
