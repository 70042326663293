import * as Yup from 'yup';
import { countOccurrences } from './utils';
Yup.addMethod(Yup.array, 'unique', function (prop, message) {
    return this.test('unique', message, function (list) {
        const errors = [];
        let items = list.map(e => e[prop]);
        items.forEach((e, index) => {
            if (e && countOccurrences(e, items) > 1) {
                errors.push(new Yup.ValidationError(message, null, `${this.path}[${index}]${prop}`));
            }
        });
        if (errors.length) {
            let error = new Yup.ValidationError();
            error.inner = errors;
            return error;
        }
        return true;
    });
});
export default Yup;
