import { statusConstants } from '../../../constants';
import { Button } from '../../index';
import i18n from '../../../i18n';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
export function getRoomButtons(room, restoreRoomProps, activateProps, maintenanceProps, maintenanceCancelProps, duplicateProps) {
    let statusButtons = getActionOnStatusButtons(activateProps, maintenanceProps, maintenanceCancelProps, room);
    let duplicateRoomButton = getDuplicateButton(duplicateProps);
    return [statusButtons, duplicateRoomButton];
}
export function getRoomPendingDeletionButtons(restoreRoomProps, duplicateProps) {
    let restoreButtons = getRestoreRoomButton(restoreRoomProps);
    let duplicateRoomButton = getDuplicateButton(duplicateProps);
    return [restoreButtons, duplicateRoomButton];
}
function getDuplicateButton(duplicateProps) {
    return (React.createElement(Button, Object.assign({ className: "is-light", text: i18n.t('DUPLICATE'), icon: React.createElement(FAIcon, { icon: ['far', 'copy'] }) }, duplicateProps)));
}
function getRestoreRoomButton(restoreRoomProps) {
    return (React.createElement(Button, Object.assign({ icon: React.createElement(FAIcon, { icon: ['fas', 'undo'] }), className: "is-primary", text: i18n.t('RESTORE') }, restoreRoomProps)));
}
function getActionOnStatusButtons(activateProps, maintenanceProps, maintenanceCancelProps, room) {
    if (canBeActivate(room)) {
        return (React.createElement(Button, Object.assign({ icon: React.createElement(FAIcon, { icon: ['fas', 'check'] }), className: "is-primary", text: i18n.t('ACTIVATE_ROOM') }, activateProps)));
    }
    else if (maintenanceCanBeSet(room)) {
        return (React.createElement(Button, Object.assign({ className: "is-light", text: i18n.t('ADD_MAINTENANCE'), icon: React.createElement(FAIcon, { icon: ['far', 'clock'] }) }, maintenanceProps)));
    }
}
function maintenanceCanBeSet(room) {
    return (room.status === statusConstants.ACTIVE ||
        room.status === statusConstants.MAINTENANCE);
}
function canBeActivate(room) {
    return room.status === statusConstants.NOT_YET_ACTIVE;
}
