import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../index';
import { roomsUploadActions } from '../../../actions/index';
import connect from 'react-redux/es/connect/connect';
import withErrorHandling from '../WithErrorHandling/withErrorHandling';
import UploadItem from './UploadItem';
class UploadPanel extends Component {
    componentDidUpdate() {
        if (this.isUploading()) {
            window.onbeforeunload = () => true;
        }
        else {
            window.onbeforeunload = undefined;
        }
    }
    isUploading() {
        return this.props.uploads.some(upload => !upload.finished);
    }
    render() {
        const { isVisible } = this.props;
        return (React.createElement("div", { className: "UploadPanel" }, isVisible ? this.renderUploadWindow() : this.renderShowButton()));
    }
    renderShowButton() {
        const { t, showUploadPanel } = this.props;
        return (React.createElement("div", { className: "UploadPanel__show-button" },
            React.createElement(Button, { onClick: showUploadPanel, className: "is-text has-text-primary", text: t('SHOW_UPLOAD_PANEL'), icon: React.createElement(FAIcon, { icon: ['fas', 'chevron-up'] }) })));
    }
    renderUploadWindow() {
        const { uploads, t, hideUploadPanel } = this.props;
        return (React.createElement("nav", { className: "panel" },
            React.createElement("p", { className: "panel-heading" },
                t('UPLOADS'),
                React.createElement("button", { className: "delete is-pulled-right", onClick: hideUploadPanel })),
            React.createElement("div", { className: "UploadPanel__uploads-list" }, uploads.length ? (uploads.map(upload => (React.createElement(UploadItem, { key: upload.id, upload: upload })))) : (React.createElement("div", { className: "panel-block" }, t('NOT_UPLOADING'))))));
    }
}
const mapStateToProps = state => {
    const { roomsUpload: { isVisible, uploads }, } = state;
    return {
        isVisible,
        uploads,
    };
};
const mapDispatchToProps = dispatch => ({
    showUploadPanel: () => dispatch(roomsUploadActions.showUploadPanel()),
    hideUploadPanel: () => dispatch(roomsUploadActions.hideUploadPanel()),
    removeFinishedUpload: id => dispatch(roomsUploadActions.removeFinishedUpload(id)),
});
export default withNamespaces('translations')(connect(mapStateToProps, mapDispatchToProps)(withErrorHandling(UploadPanel)));
