var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Flatpickr from 'react-flatpickr';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames/bind';
import { German } from 'flatpickr/dist/l10n/de.js';
import i18n from '../../../i18n';
const translations = {
    de: German,
};
const DEFAULT_OPTIONS = {
    dateFormat: 'Z',
    altInput: true,
    altFormat: 'Y-m-d',
    wrap: true,
};
const DateTimePicker = (_a) => {
    var { options, className, placeholder, icon, form, field } = _a, props = __rest(_a, ["options", "className", "placeholder", "icon", "form", "field"]);
    return (React.createElement(Flatpickr, Object.assign({ options: Object.assign(Object.assign({ locale: translations[i18n.language] }, DEFAULT_OPTIONS), options), className: classnames('date-time-picker', className), onBlur: form && field && (() => form.setFieldTouched(field.name)) }, props),
        React.createElement("div", { className: "control has-icons-right" },
            React.createElement("input", { type: "text", placeholder: placeholder, "data-input": true }),
            React.createElement("span", { className: "icon is-small is-right" }, icon ? icon : React.createElement(FAIcon, { icon: ['fas', 'calendar-alt'] })))));
};
export default DateTimePicker;
