var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Select from 'react-select';
import classnames from 'classnames/bind';
import i18n from '../../../../i18n';
const getValueProperty = (option, getOptionValue) => {
    return getOptionValue ? getOptionValue(option) : option.value;
};
const ReactSelect = (_a) => {
    var { options, field, form, value, onChange, isMulti, getOptionValue, className } = _a, props = __rest(_a, ["options", "field", "form", "value", "onChange", "isMulti", "getOptionValue", "className"]);
    return (React.createElement(Select, Object.assign({ onBlur: () => form.setFieldTouched(field.name), classNamePrefix: "rs", options: options, name: field.name, getOptionValue: getOptionValue, isMulti: isMulti, placeholder: i18n.t('SELECT'), loadingMessage: () => i18n.t('LOADING'), noOptionsMessage: () => i18n.t('NO_OPTIONS'), value: isMulti
            ? options.filter(option => field.value.includes(getValueProperty(option, getOptionValue)))
            : options.find(option => getValueProperty(option, getOptionValue) === field.value) || '', onChange: option => {
            form.setFieldValue(field.name, option
                ? isMulti
                    ? option.map(o => getValueProperty(o, getOptionValue))
                    : getValueProperty(option, getOptionValue)
                : undefined);
            if (onChange)
                onChange(option);
        }, className: classnames('react-select', className) }, props)));
};
export default ReactSelect;
