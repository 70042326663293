import { lazy } from 'react';
export const Buildings = lazy(() => import('../../../Buildings/Buildings'));
export const BuildingsEdit = lazy(() => import('../../../BuildingEdit/BuildingEdit'));
export const BuildingDetails = lazy(() => import('../../../BuildingDetails/AdminBuildingDetails'));
export const Sites = lazy(() => import('../../../Sites/Sites'));
export const SitesEdit = lazy(() => import('../../../SitesEdit/SitesEdit'));
export const Features = lazy(() => import('../../../Features/Features'));
export const FeatureEdit = lazy(() => import('../../../FeatureEdit/FeatureEdit'));
export const FeatureDetails = lazy(() => import('../../../FeatureDetails/FeatureDetails'));
export const Rooms = lazy(() => import('../../../Rooms/Rooms'));
export const RoomEdit = lazy(() => import('../../../RoomEdit/RoomEdit'));
export const RoomsBulkEdit = lazy(() => import('../../../RoomsBulkEdit/RoomsBulkEdit'));
export const RoomTypes = lazy(() => import('../../../RoomTypes/RoomTypes'));
export const RoomTypeEdit = lazy(() => import('../../../RoomTypeEdit/RoomTypeEdit'));
export const RoomTypeDetails = lazy(() => import('../../../RoomTypeDetails/RoomTypeDetails'));
export const RoomDetails = lazy(() => import('../../../RoomDetails/AdminRoomDetails'));
export const Administrators = lazy(() => import('../../../Administrators/Administrators'));
export const AdministratorEdit = lazy(() => import('../../../AdministratorEdit/AdministratorEdit'));
export const RoomsMaintenances = lazy(() => import('../../../RoomsMaintenances/RoomsMaintenances'));
