import { buildingsConstants } from '../constants';
export const buildingsState = {
    buildings: [],
    building: null,
    buildingsError: null,
    buildingsLoading: false,
    pageNumber: 0,
    pageSize: 20,
    sort: [],
    totalPages: 1,
    totalElements: 0,
    listParams: '',
};
const buildings = (state = buildingsState, action) => {
    switch (action.type) {
        case buildingsConstants.FETCH_BUILDINGS:
            return Object.assign(Object.assign({}, state), { buildings: [...action.buildings], pageNumber: action.pageNumber, pageSize: action.pageSize, sort: action.sort, totalPages: action.totalPages, buildingsError: null, buildingsLoading: false, totalElements: action.totalElements, listParams: action.listParams || '' });
        case buildingsConstants.FETCH_SINGLE_BUILDING:
            return Object.assign(Object.assign({}, state), { building: action.building, buildingsError: null, buildingsLoading: false });
        case buildingsConstants.FETCH_SINGLE_BUILDING_AUDITLOG:
            if (!action.buildingAuditlog) {
                return Object.assign(Object.assign({}, state), { buildingAuditlog: null });
            }
            if (state.buildingAuditlog && state.buildingAuditlog.content) {
                const oldContent = state.buildingAuditlog.content;
                const existingIds = oldContent.map(entry => entry.id);
                const newContent = action.buildingAuditlog.content.filter(entry => existingIds.indexOf(entry.id) < 0);
                action.buildingAuditlog.content = state.buildingAuditlog.content.concat(newContent);
            }
            return Object.assign(Object.assign({}, state), { buildingAuditlog: action.buildingAuditlog });
        case buildingsConstants.ADD_BUILDING:
        case buildingsConstants.UPDATE_BUILDING:
            return Object.assign(Object.assign({}, state), { buildingsError: null, buildingsLoading: false });
        case buildingsConstants.ERROR_BUILDINGS:
            return Object.assign(Object.assign({}, state), { buildingsError: action.error, buildingsLoading: false });
        case buildingsConstants.LOADING_BUILDINGS:
            return Object.assign(Object.assign({}, state), { buildingsLoading: true });
        default:
            return state;
    }
};
export default buildings;
